import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  TextField,
  Typography,
  Modal,
  Grid,
  Stack,
  Pagination,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";
import React, { useEffect, useRef, useState } from "react";

import DatePicker from "react-datepicker";

import EventIcon from "@mui/icons-material/Event";

import Swal from "sweetalert2";

import { convertDateFormat, convertDateToCustomFormat, swalAlertPopProps } from "../../utils/commonUtils";
import { addExpense, updateExpense } from "./expanceApi";
import ExpenseModalCard from "./expenseModalCard";
import { addData, updateBill } from "./expenseCustomApiHandlers";
import { validationsAdd, validationsSave } from "./expenceValidationFunction";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import { totalPrice } from "../purchase/purFunction";
import { totalPriceExpense } from "./expenseFunction";
import { ModalHeader } from "../../common/modalHeader";

export default function ExpenseModal({
  open,
  setOpen,
  getexpence,
  filterByProp,
  getPurchase,
  idProp,
  expenseItemArrProps,
  updateModalData,
}) {
  //   const [pagination, setPagination] = useState({ page: 1, total: 10 });
  const [search, setSearch] = useState("");



  const data = {
    customerMobile: "",
  };
  const [billData, setBillData] = useState(data);

  const onClose = () => {
    setOpen(false);
  };
  const handleChange = (event, value) => {
    setPagination({
      ...pagination,
      page: value,
    });
  };

  const [searchItem, setSearchItem] = useState([]);

  let payload = {
    amount: 0,
    description: "",
  };
  const [ExpenseItems, setExpenseItems] = useState(payload);

  const stockPayload = {
    page: 1,
    pageSize: 10,
  };

  const [ExpenseItemssArr, setExpenseItemssArr] = useState([]);
  const [id, setId] = useState();
  const [pagination, setPagination] = useState({
    page: 1,
    total: 10,
    records: 0,
  });

  useEffect(() => {
    if (open === true) {
      if (filterByProp) {
        setFilterBy(filterByProp);
        setExpenseItemssArr(expenseItemArrProps);
        setId(idProp);
      }
    } else {
      console.log("close");
      setExpenseItems(payload);
      setFilterBy(filters[0].value);
      setExpenseItemssArr([]);
      setId();

      // setExpenseItemssArr([]);
    }
  }, [open]);
  const addLeadingZero = (value) => {
    return value < 10 ? "0" + value : value;
  };
  const [date, setDate] = useState(
    (() => {
      var currentDate = new Date();
      let dateContainer = String(currentDate).split(" ");
      var year = dateContainer[2];
      var month = dateContainer[1];
      var day = dateContainer[3];
      var formattedDate =
        year + "-" + addLeadingZero(month) + "-" + addLeadingZero(day);

      return formattedDate;
    })()
  );


  useEffect(() => {
    if(updateModalData?.id){
      setDate(convertDateToCustomFormat(updateModalData?.date?.split("T")[0]))
    }
 
  }, [updateModalData])

  useEffect(() => { }, [filterByProp]);
  const [startDate, setStartDate] = useState(new Date());
  const datePickerRef = useRef();

  const openDatePicker = () => {
    if (datePickerRef.current) {
      datePickerRef.current.setOpen(true);
    }
  };

  const [filters, setFilters] = useState([
    {
      name: "Raw Material",
      value: "Raw material",
    },
    { name: "Salary", value: "Salary" },
    {
      name: "Shop Maintenance",
      value: "Shop Maintenance",
    },
    { name: "Other", value: "Other" },
  ]);
  const [filterBy, setFilterBy] = useState("RowMaterial");
  // const addData = async () => {};
  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          background: "white",
          width: "52%",
          overflowY: "auto",
          // height: "90vh",
          position: "absolute",
          left: "25%",
          top: "10%",
        }}
      >
        <ModalHeader title={"Expenses"} 
        onClose={(data)=>{
          setOpen(data)
        }}
        />
        
        <Grid container sx={{ position: "relative" }}>

          {/* <Grid item xs={12} sm={6} sx={{ p: 2 }}>
            <Stack
              direction="row"
              justifyContent="space-between"
              sx={{ mt: 2 }}
            >
              <Typography color="primary">
                <strong>Total Items: &nbsp;</strong>
              </Typography>
              <Pagination
                count={pagination.total}
                page={pagination.page}
                onChange={handleChange}
              />
            </Stack>
          </Grid> */}
          <Grid item xs={12} sm={6} sx={{ borderRight: "1px solid #dfdfdf" }}>
            <DialogContent sx={{ pr: 2 }}>

              <Box sx={{ mt: 0, cursor: "pointer" }}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label"
                  >Category </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={filterBy}
                    label="Category"
                    onChange={(e) => {
                      setFilterBy(e.target.value);
                    }}
                    sx={{


                      height: "40px"
                    }}
                    InputProps={{
                      style: {
                        height: '40px',
                        // background:"yellow"
                      },
                    }}
                  >
                    {filters.map((item, index) => {
                      return (
                        <MenuItem value={item.value} key={"option" + index}>
                          {item.name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Box>

              <Box sx={{ mt: 2, cursor: "pointer" }}>
                <TextField
                  fullWidth
                  // disabled
                  readonly
                  onClick={openDatePicker}
                  id="date"
                  value={date}
                  label={
                    <Box
                      sx={{
                        flex: 4,
                        color: "grey",
                        cursor: "pointer",
                        whiteSpace: "nowrap",
                      }}
                    >
                      Date : {/* {new Date().toLocaleDateString()} */}
                    </Box>
                  }
                  onChange={(e) => { }}
                  InputProps={{
                    style: {
                      height: "40px",
                    },
                    endAdornment: (
                      <EventIcon
                        sx={{
                          color: (theme) => theme.palette.primary.main,
                          cursor: "pointer",
                        }}
                      />
                    ),
                  }}
                />
                <DatePicker
                  hidden={true}
                  selected={startDate}
                  maxDate={new Date()}
                  onChange={(date) => {
                    const dateStr2 = date.toLocaleDateString("en-GB", {
                      year: "numeric",
                      month: "long",
                      day: "2-digit",
                    });
                    let dateContainer = String(dateStr2).split(" ");
                    var year2 = dateContainer[2];
                    var month2 = dateContainer[1];
                    var day2 = dateContainer[0];
                    var formattedDate2 = day2 + "-" + month2 + "-" + year2;

                    setDate(formattedDate2);
                    setStartDate(date);
                  }}
                  className="custom-datepicker"
                  ref={datePickerRef}
                  onClickOutside={() => datePickerRef.current.setOpen(false)}
                />
              </Box>
              <Box sx={{ mt: 2 }}>
                <TextField
                  fullWidth
                  size="small"
                  autoComplete="off"
                  type="text"
                  id="name"
                  label="Description of Expenses"
                  value={ExpenseItems?.description}
                  onChange={(e) => {
                    // getUnpaid({
                    //   customerMobile: e.target.value,
                    // });
                    setExpenseItems({
                      ...ExpenseItems,
                      description: e.target.value,
                    });
                  }}
                  InputProps={{
                    style: {
                      // height: '40px',
                      // background:"yellow"
                    },
                  }}
                />
              </Box>

              <Box sx={{ mt: 2 }}>
                <TextField
                  fullWidth
                  id="briefAddress"
                  size="small"
                  label="Amount"
                  InputProps={{
                    style: {
                      // height: '40px',
                      // background:"yellow"
                    },
                  }}
                  value={ExpenseItems?.amount !== 0 ? ExpenseItems?.amount : ""}
                  onChange={(e) => {
                    let val = e.target.value;
                    val = val.replace(/\D/g, "");
                    setExpenseItems({
                      ...ExpenseItems,
                      amount: val > 0 ? Number(val) : "",
                    });
                  }}
                />
              </Box>
              <Box sx={{ display: "flex", justifyContent: "center", mt: 1 }}>
                <DialogActions>
                  <Button
                    variant="contained"
                    onClick={() => {
                      setExpenseItemssArr([]);
                    }}
                  >
                    Clear
                  </Button>
                  <Button
                    variant="contained"
                    onClick={() => {
                      const valid = validationsAdd(ExpenseItems);
                      if (valid.status) {
                        setExpenseItemssArr([
                          ...ExpenseItemssArr,
                          {
                            ...ExpenseItems,
                          },
                        ]);

                        setExpenseItems(payload);
                      } else {
                        Swal.fire({
                          text: valid?.msg,
                          icon: "warning",

                          ...swalAlertPopProps,
                        });
                        setOpen(false);
                      }
                    }}
                  >
                    Add
                  </Button>
                </DialogActions>

              </Box>
            </DialogContent>
          </Grid>
          <Grid item sm={6} sx={{ justifyContent: "space-between", flexDirection: "row-reverse" }}>
            <Box
              sx={{
                height: "28vh",
                overflowY: "scroll",
              }}
            >
              {ExpenseItemssArr?.map((item, index) => {
                return (
                  <ExpenseModalCard
                    index={index}
                    item={item}
                    ExpenseItemssArr={ExpenseItemssArr}
                    setExpenseItemssArr={setExpenseItemssArr}
                  />
                );
              })}
            </Box>
            <Box>

              <Divider
                sx={{
                  background: (theme) => theme.palette.primary.main,
                  width: "100%",
                  mt: 2,
                }}
              />
              <Box sx={{
                display: 'flex',
                justifyContent: 'space-around'
              }}>
                <Typography color="primary">
                  <strong>Total Amount: &nbsp;
                  </strong>
                </Typography>
                <Box
                  component="span"
                  sx={{
                    // marginLeft: "8px",
                    color: (theme) => theme.palette.secondary.main,
                  }}
                >
                  {" "}
                  <CurrencyRupeeIcon sx={{ fontSize: "14px" }} />
                  {/* {counterSales?.totalAmount} */}
                  {/* {100} */}
                  {totalPriceExpense(ExpenseItemssArr)}
                </Box>
              </Box>

            </Box>
            <DialogActions>
              <Button
                variant="contained"
                fullWidth
                onClick={() => {
                  setOpen(false);
                  console.log("bill Data ", billData);
                  const valid = validationsSave(ExpenseItemssArr);
                  if (valid.status) {
                    id
                      ? updateBill(
                        {
                          id: id,
                          date: convertDateFormat(date),
                          category: filterBy,
                          expenseDetails: ExpenseItemssArr,
                        },
                        updateExpense,
                        Swal,
                        getPurchase
                      )
                      : addData(
                        {
                          date: convertDateFormat(date),
                          category: filterBy,
                          expenseDetails: ExpenseItemssArr,
                        },
                        addExpense,
                        Swal,
                        getPurchase
                      );
                  } else {
                    Swal.fire({
                      text: valid.msg,
                      icon: "warning",
                      ...swalAlertPopProps,
                    });
                  }
                }}
              >
                Save
              </Button>
            </DialogActions>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
}
