export const validationAdd = (billData, upPaidBillData) => {
  if (billData?.customer?.trim() === "" || !billData?.customer) {
    return { status: false, msg: "Please Select Customer" };
  } else if (billData?.paymentMode?.trim() === "" || !billData?.paymentMode) {
    return { status: false, msg: "Please Select Payment Mode" };
  } else if (!upPaidBillData?.balanceAmount) {
    return {
      status: false,
      msg: "Please update the bill to retrieve the Balance amount.",
    };
  } else if (billData?.amount?.trim() === "" || !billData?.amount) {
    return { status: false, msg: "Please Enter Amount" };
  }
  return { status: true, msg: "" };
};
