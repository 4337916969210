import Swal from "sweetalert2";
import { swalAlertPopProps } from "../../utils/commonUtils";

export const getCate = async (getCategory, setFilterBy, setFilters) => {
  await getCategory()
    .then((res) => {
      console.log("getcategory ", res?.data?.data);
      // setFilters

      setFilterBy(res?.data?.data[0]);
      setFilters(res?.data?.data);
    })
    .catch((err) => {
      console.log("Err ", err);
    });
};

export const getFaq = async (
  param,
  getAllUserFaq,
  setNumberOfRecords,
  setfaq
) => {
  await getAllUserFaq(param)
    .then((res) => {
      console.log("get all faq ", res);
      setNumberOfRecords(res?.data?.data?.totalQuestions);

      setfaq(res?.data?.data?.faq);
    })
    .catch((err) => {
      console.log("error ", err);
    });
};

export const addDataBillerService = async (param, addUserFaq) => {
  await addUserFaq(param)
    .then((res) => {
      console.log("Add faq ", res);
      if (res?.data?.data) {
        Swal.fire({
          text: "Successfully saved",
          icon: "success",
          ...swalAlertPopProps,
        });
      } else {
        Swal.fire({
          text: res?.data?.message,
          icon: "warning",
          ...swalAlertPopProps,
        });
      }
    })
    .catch((err) => {
      console.log("err ", err);
    });
};
