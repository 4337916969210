import {
  Box,
  Button,
  Switch,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControlLabel,
  FormGroup,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import AssessmentIcon from "@mui/icons-material/Assessment";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import BarChartIcon from "@mui/icons-material/BarChart";

import {
  getAssociatedUserMenu,
  setAssociatedUserMenu,
} from "./associatedUsersApi";

const data = {
  name: "",
  loginId: "",
  passcode: "",
  myBusinessId: 0,
  status: "",
  buyerGroupIdList: [],
};

export default function ScopeModal({ open, setOpen, selectedUser }) {
  const Close = () => {
    setOpen(false);
  };

  const [menu, setMenu] = useState([]);
  const arrIcon = [
    <AssessmentIcon sx={{ mr: "10px" }} color="secondary" />,
    <CurrencyRupeeIcon sx={{ mr: "10px" }} color="secondary" />,
    <BarChartIcon sx={{ mr: "10px" }} color="secondary" />,
  ];
  const getUserData = async (id) => {
    await getAssociatedUserMenu(id)
      .then((res) => {
        setMenu(res?.data?.data);
      })
      .catch((err) => {
        console.error("usermenu ", err);
      });
  };
  const setUserMenu = async (param) => {
    await setAssociatedUserMenu(param)
      .then((res) => {
        getUserData({
          id: selectedUser?.associateUserId,
        });
      })
      .catch((err) => {
        console.error("setAsccoited user ", err);
      });
  };
  useEffect(() => {
    if (open === true) {
      getUserData({
        id: selectedUser?.associateUserId,
      });
    }
  }, [open]);
  const update = async () => {
    const arr = menu.map((item) => {
      return {
        isRequired: item.isRequired,
        systemFuctionId: item.systemFuctionId,
      };
    });

    setUserMenu({
      associatedUserId: selectedUser?.associateUserId,
      systemMenu: arr,
    });
  };

  return (
    <Dialog open={open} fullWidth={true} maxWidth="xs" onClose={Close}>
      <DialogTitle color="primary">Scope</DialogTitle>

      <Divider />

      <DialogContent sx={{ p: 1, pt: 2 }}>
        <Typography
          color="primary"
          sx={{
            overflow: "Hidden",
            textOverflow: "ellipsis",
            display: "-webkit-box",
            "-webkit-line-clamp": "1",
            "-webkit-box-orient": "vertical",
            fontWeight: 600,
          }}
        >
          Set Authorities
        </Typography>
        {menu.map((item2, index) => {
          return (
            <Box>
              <FormGroup sx={{ mt: 2 }}>
                <FormControlLabel
                  sx={{
                    justifyContent: "space-between",
                    m: 0,
                    flexDirection: "row-reverse",
                  }}
                  control={
                    <Switch
                      checked={item2?.isRequired}
                      onChange={(e) => {
                        let menus = [...menu];
                        menus[index].isRequired = e.target.checked;
                        setMenu(menus);
                      }}
                    />
                  }
                  label={
                    <Box sx={{ display: "flex", color: "primary" }}>
                      {arrIcon[index]}
                      <Typography
                        color="primary"
                        sx={{
                          fontSize: "15px",
                          overflow: "Hidden",
                          textOverflow: "ellipsis",
                          display: "-webkit-box",
                          "-webkit-line-clamp": "1",
                          "-webkit-box-orient": "vertical",
                          fontWeight: 600,
                        }}
                      >
                        {item2?.regionalFunctionName}
                      </Typography>
                    </Box>
                  }
                />
              </FormGroup>
              <Divider sx={{ background: "black" }} />
            </Box>
          );
        })}
      </DialogContent>

      <DialogActions>
        <Button
          variant="contained"
          fullWidth
          onClick={() => {
            setOpen(false);
            update();
          }}
        >
          Update
        </Button>
      </DialogActions>
    </Dialog>
  );
}
