import { Box, DialogTitle, Grid, Modal, TextField,Divider } from "@mui/material"; 

import SearchIcon from "@mui/icons-material/Search";

import CloseIcon from "@mui/icons-material/Close";
import { useEffect, useState } from "react";   

// import { getAllCustomerDetail,  } from "./api";
import { searchedElementDisplay } from "../advancePayment/receiptFunctions";
import { getAllCustomerDetail,getLastDetail } from "../../common/searchModal/api";
import UserCard from "../advancePayment/userCard";
import { getAllIncentive } from "./paymentApi";

const PoprchModal = ({
  searchModal,
  setSearchModal,
  callback,
  cardValue,
  setCardValue,
  // userDetail,
}) => {
    


    const [collection,setCollection]=useState([])
  const [searchCurrentModal, setSearchCurrentModal] = useState("")
  const getCounterSalesCusomter = async (param) => {
    // await getCounterSalesCustomers(param).then((res) => {
    //   console.lo g("countersales res data   ", res?.data?.data, " ", {
    //     page: res.data.pageNumber,
    //     total: res.data.totalPages,
    //   });
    //   setPagination({
    //     page: res.data.pageNumber,
    //     total: res.data.totalPages,
    //   });
    //   console.log("res?.data",res?.data?.data?.list)
    //   setUnPaidBill([...res?.data?.data?.list]);
    // });
  };
  useEffect(() => {
    console.log("dddd")
    if (searchModal === true) {
        
        getAllIncentive( (data)=>{
              
              console.log("data detail ",data?.data?.data?.list)
              setCollection(data?.data?.data?.list)
            }
      )
    //   getCounterSalesCusomter({
    //     filter: "",
        
    //     search: "",
    //     page: 1,
    //     pageSize: 1000,
    //   });
      //   setBillData({ ...billData, mybusinessId: userDetail?.myBusinessId });
    } else {
      console.log("close");
      //   setBillData(data);
    //   setUnPaidBillData();

    //   setSearchCurrentModal("")
    }
  }, [searchModal]);
  return (
    <Modal
      open={searchModal} sx={{ zIndex: 999999 }} // Yo
      onClose={() => {
        setSearchModal(false)
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Grid container sx={{
        // backgroundColor: "white",
        // width:"40%"
        background: "white",
        width: "40%",
        overflowY: "auto",
        height: "70vh",
        position: "absolute",
        left: "30%",
        top: "10%",
      }}>
        <Grid item xs={12} sx={{ p: "3px" }}>
        <DialogTitle
        color="primary"
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          m: 0,
          p: "3px",
        }}
      >
        Assistant list{" "}
        <CloseIcon
          sx={{ float: "right", cursor: "pointer" }}
          onClick={() =>{setSearchModal(false)}}
        />
      </DialogTitle>
      <Divider />
          <Box sx={{ mt: 1, textAlign: "center" }}>
            <TextField
              sx={{ width: "90%" }}
              size="small"
              label="Search by Name/Mobile Number"
              value={searchCurrentModal}
              onChange={(e) => {
                // getPaymentRec(
                //   { 
                //       "page": 1,
                //       "pageSize": 10,
                //       "filter": e.target.value,//"filter": null,
                //       "search": "",
                //       "date": null

                //   },
                //   getPaymentReceipt,
                //   setNumberOfRecords,
                //   setTotals,
                //   totals,
                //   setReceipts
                // );
                setSearchCurrentModal(e.target.value);
              }}
              InputProps={{
                endAdornment: (
                  <SearchIcon
                    sx={{
                      color: (theme) => theme.palette.primary.main,
                    }}
                  />
                ),
              }}
            />
            {/* <CloseIcon sx={{ float: "right", cursor: "pointer", color: (theme) => theme.palette.primary.main, }} onClick={() => setSearchModal(false)} /> */}
          </Box>
          <Box sx={{ height: "55vh", overflowY: "auto" }}>
            {collection.map((item, index) => {
            //   if (searchedElementDisplay(item, searchCurrentModal)) {
            //     return null;
            //   }
              return (
                <UserCard 
                key={"employee"+index}
                  data={item}
                  callBack={(data) => {
                    setCardValue(data)
                    // getUnpaid({
                    //   customerMobile: item?.mobile,
                    // });
                    // setBillData({
                    //   ...item,
                    //   customerMobile: item?.mobile,
                    //   customer: item?.name,
                    // // });
                    // setSearch(item?.name)
                    // setUSerObject(item)
                    // callSearchApi(item?.name)
                    setSearchModal(false)
                  }}
                />
              );
            })}
          </Box>

        </Grid>
      </Grid>
    </Modal>
  )
}

export default PoprchModal;