import {
  Box,
  Card,
  CardContent,
  Grid,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import React from "react";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import { convertDateToCustomFormat } from "../../utils/commonUtils";
export default function CardPayment({ item, setOpen, setModalData, cardKet }) {
  return (
    <Card
      sx={{
        width: "100%",
        // m: "0px",
        // mt: "10px",
        ":hover": {
          bgcolor: "#2892ba17",
        },
        cursor: "pointer",
        // p: "0rem 0.3rem",
      }}
      onClick={() => {
        setOpen(true);
        setModalData(item);
      }}
    >
      <CardContent sx={{ padding: "0.5px 10px !important", margin: "10px" }}>
        <Grid container>
          {cardKet.map((ele, index) => {
            return (
              <>
              <Grid item xs={6} key={"value"+index}>
                <Typography
                  sx={{
                    fontSize: "15px",
                    overflow: "Hidden",

                    textOverflow: "ellipsis",
                    display: "-webkit-box",
                    "-webkit-line-clamp": "1",
                    "-webkit-box-orient": "vertical",
                  }}
                >
                  {ele.label}
                </Typography>
              </Grid>
                <Grid item xs={6}>
                <Typography
                  color="secondary"
                  sx={{
                    fontSize: "15px",
                    overflow: "Hidden",
    
                    textOverflow: "ellipsis",
                    display: "-webkit-box",
                    "-webkit-line-clamp": "1",
                    "-webkit-box-orient": "vertical",
                    // fontWeight: 600,
                  }}
                >{item[ele.key]}
                </Typography>
              </Grid>
              </>
            );
          })}

        
          {/* <Grid item xs={6}>
            <Typography
              sx={{
                fontSize: "15px",
                overflow: "Hidden",

                textOverflow: "ellipsis",
                display: "-webkit-box",
                "-webkit-line-clamp": "1",
                "-webkit-box-orient": "vertical",
              }}
            >
              Amount
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography
              color="primary"
              sx={{
                fontSize: "15px",
                overflow: "Hidden",

                textOverflow: "ellipsis",
                display: "-webkit-box",
                "-webkit-line-clamp": "1",
                "-webkit-box-orient": "vertical",
                // fontWeight: 600,
              }}
            >
              {item?.amount}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography
              sx={{
                fontSize: "15px",
                overflow: "Hidden",

                textOverflow: "ellipsis",
                display: "-webkit-box",
                "-webkit-line-clamp": "1",
                "-webkit-box-orient": "vertical",
              }}
            >
              Percentage
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography
              color="primary"
              sx={{
                fontSize: "15px",
                overflow: "Hidden",

                textOverflow: "ellipsis",
                display: "-webkit-box",
                "-webkit-line-clamp": "1",
                "-webkit-box-orient": "vertical",
                // fontWeight: 600,
              }}
            >
              {item?.percentage}
            </Typography>
          </Grid> */}
        </Grid>
      </CardContent>
    </Card>
  );
}
