import {
  Box,
  Button,
  Typography,
  Modal,
  Grid,
  FormControl,
  OutlinedInput,
  InputAdornment,
  FormHelperText,
  Stack,
} from "@mui/material";

import React, { useEffect, useState } from "react";
import MenuSideBar from "../../common/menuSideBar";
import { useNavigate } from "react-router";
import { routePath } from "../../constants/routePath";
import CloseIcon from "@mui/icons-material/Close";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useDispatch, useSelector } from "react-redux";
import CancelIcon from "@mui/icons-material/Cancel";
import {
  setCashCounterSaleSummaryPayload,
  setNavBarInputFields,
  setRateChangeHistoryPayload,
} from "../../app/store/reducer";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import MyLocationIcon from "@mui/icons-material/MyLocation";
import { CardLocation } from "./cardLocation";
import { information } from "../../constants/locationData";
const LocationModal = ({ open, setOpen, currentLocation }) => {
  const serviceViceSearch = useSelector(
    (state) => state.reducer.serviceViceSearch
  );
  const navBarInputFields = useSelector(
    (state) => state.reducer.navBarInputFields
  );
  const [searchValue, setSeacrchValue] = useState("");
  const [indexTableItem, setIndexTableItem] = useState();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          background: "white",
          //   overflowY: "auto",
          width: {
            xs: "100vw",
            md: "40%",
          },

          borderRadius: "10px",

          position: "absolute",
          left: { xs: "0", md: "30%" },
          top: "20%",
        }}
      >
        <Box>
          <Grid
            container
            sx={{
              overflowY: "scroll",
              height: "60vh",
              borderTopLeftRadius: "20px",
            }}
          >
            <Grid item xs={12} sx={{}}>
              <Box sx={{ padding: "30px" }}>
                <Stack direction={"row"} gap={2}>
                  <FormControl sx={{ width: "100%" }} variant="outlined">
                    <OutlinedInput
                      value={searchValue}
                      id="outlined-adornment-weight"
                      sx={{
                        cursor: "pointer",
                        borderRadius: "10px",
                      }}
                      startAdornment={<ArrowBackIcon position="end" />}
                      endAdornment={
                        searchValue.trim().length > 0 ? (
                          <CancelIcon
                            position="end"
                            onClick={() => setSeacrchValue("")}
                          />
                        ) : null
                      }
                      aria-describedby="outlined-weight-helper-text"
                      inputProps={{
                        "aria-label": "weight",
                      }}
                      onChange={(e) => setSeacrchValue(e.target.value)}
                    />
                  </FormControl>
                  <Button
                    sx={{
                      backgroundColor: "#3695B6 !important",
                      borderRadius: "75px",
                      mx: 2,
                      px:4,
                      fontSize:'15px'
                    }}
                    variant="contained"
                    onClick={() =>
                      dispatch(setNavBarInputFields({ location: searchValue }))
                    }
                  >
                    Submit
                  </Button>
                </Stack>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",

                    marginTop: "24px",
                  }}
                  onClick={() => {
                    dispatch(
                      setNavBarInputFields({ location: currentLocation })
                    );
                  }}
                >
                  <MyLocationIcon
                    sx={{
                      fontSize: "20px",
                      color: "rgb(110, 66, 229)",
                    }}
                  />
                  <Typography
                    sx={{
                      fontSize: "14px",
                      color: "rgb(110, 66, 229)",
                      marginLeft: "20px",
                      fontWeight: "600",
                    }}
                  >
                    Use Current Location
                  </Typography>
                </Box>
              </Box>
              <Box
                sx={{
                  height: "10px",
                  width: "100%",
                  display: "block",
                  backgroundColor: "rgba(245,245,245,1.00)",
                }}
              ></Box>
              <Box
                sx={{
                  padding: "10px",
                }}
              >
                {information.map((item, ele) => {
                  if (
                    !item.location
                      ?.toLowerCase()
                      .includes(searchValue?.toLowerCase()) &&
                    searchValue.trim() != ""
                  ) {
                    return null;
                  }
                  return (
                    <CardLocation
                      state={"item.location"}
                      cityName={item.location}
                      onClick={(data) => {
                        console.log("Card ", data);
                        dispatch(setNavBarInputFields({ location: data.city }));
                      }}
                    />
                  );
                })}
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Modal>
  );
};
export default LocationModal;
