import * as React from "react";
import Snackbar from "@mui/material/Snackbar";
import { useDispatch, useSelector } from "react-redux";
import { setSnackbar } from "../app/store/reducer";
import { Alert } from "@mui/material";

export default function SimpleSnackbar() {
  const { snackbar } = useSelector((state) => state.reducer);
  const dispatch = useDispatch();
  return (
    <div>
      <Snackbar
        open={snackbar.open}
        autoHideDuration={2000}
        onClose={() => {
          dispatch(setSnackbar({ open: false, message: "" }));
        }}
      >
        <Alert
          onClose={() => {
            dispatch(setSnackbar({ open: false, message: "" }));
          }}
          severity='success'
          sx={{ width: "100%" }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </div>
  );
}
