import { ADDCOUNTERSALESCUTOMERDETAIL, UPDATECUSTOMERDETAIL } from "../../utils/api"
import axios from "../../utils/axios"

export const addCounter=async(payload,callback)=>{
    await axios.post(ADDCOUNTERSALESCUTOMERDETAIL,payload).then((res)=>{
        callback(res?.data)
    })
    
}
export const updateCounter=async(payload,callback)=>{
    await axios.post(UPDATECUSTOMERDETAIL,payload).then((res)=>{
        callback(res?.data)
    })
    
}

