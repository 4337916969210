import { Box, Grid, Tab, Tabs } from "@mui/material";
import React from "react";
import Purchase from "../purchase/purchase";
import Stocks from "../stocks/stocks";
import { useSelector } from "react-redux";

export const Inventory=({})=>{
  const [value, setValue] = React.useState("all");

  const handleChange = (event, newValue) => {
    // setPageNo(1);
    
    setValue(newValue);
  };
  const businessChooseTab=useSelector((state)=>state.reducer.businessChooseTab)

    return ( 
    
    <Box>
        
      <Grid container>
      <Grid item xs={12}>
        {
         (!businessChooseTab || businessChooseTab?.value=="stock" )&& 
<Stocks/>
        }
        {
          businessChooseTab?.value=="Purchase" && 
<Purchase/>
        }
          
      </Grid>
      </Grid>
    </Box>
      )
}