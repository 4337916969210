import React, { useEffect, useRef, useState } from "react";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
// import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import EventIcon from "@mui/icons-material/Event";

import {
  Box,
  Divider,
  Fab,
  Grid,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import {
  getAccountReachageDetails,
  getAllEmployeeList,
  getAlleducatio,
} from "./appoinmentDashboardApi";
import SmbAccountReachargeCard from "./AppoinmentDashboardCard";
import AddIcon from "@mui/icons-material/Add";
import { getData } from "./smbAccountRechargeCustomApiHandler";

import {
  convertDateToCustomFormat,
  getFirstDateOfMonth,
  getStartOfMonth,
} from "../../utils/commonUtils";
import AppoinmentDashboardCard from "./AppoinmentDashboardCard";
import { DatePicker } from "@mui/x-date-pickers";
import DatePickerComponent from "../../common/curstomDatePicker";
import FormModal from "./FormModal";
import DynamicBreadcrumbs from "../../common/Breadcrumb";
import { useSelector } from "react-redux";
import { TextFieldSearch } from "../../common/fieldText";
import PoprchModal from "../payment/popSearch";
import CardPayment from "../payment/cardPayment";
import FormModalT from "./FormDatatt";

export default function EmployeeDetail({ gridSize }) {
  // const [data, setData] = useState();
  const [open, setOpen] = useState(false);

  const [search, setSearchvalue] = useState("");
  const [searchModal, setSearchModal] = useState(false);

  const datePickerRef = useRef();
  const openDatePicker = () => {
    if (datePickerRef.current) {
      datePickerRef.current.setOpen(true);
    }
  };
  const [date, setDate] = useState(getStartOfMonth());

  const [SelectedStartDate, setSelectedStartDate] = useState(
    getFirstDateOfMonth()
  );
  const [modalData, setModalData] = useState(null);
  const [value, setValue] = React.useState("Unassigned");
  const [data, setData] = useState();
  const [valuet, setValuet] = React.useState("Assigned");
  const businessChooseTab=useSelector((state)=>state.reducer.businessChooseTab)


  const cardKet = {
    educationalDetail: [
      
      {
        key: "courseName",
        label: "Course Name",
      },
      {
        key: "courseDetails",
        label: "Component Name",
      },
      {
        key: "durationInYear",
        label: "Duration In Year",
      },
      {
        key: "percentage",
        label: "Percentage",
      },

      {
        key: "grade",
        label: "grade",
      },      
      {
        key:"institute",
        label:'institute'
      },
      {
        key: "university",
        label: "university",
      },

 
      
    ],

    incentrive: [
      {
        key: "incentiveName",
        label: "Incentive Name",
      },
      {
        key: "minimumValue",
        label: "Minimum Value",
      },
      {
        key: "maximumValue",
        label: "Maximum Value",
      },

      {
        key: "percentage",
        label: "percentage",
      },
    ],
    Deductions: [
      {
        key: "componentName",
        label: "Component Name",
      },
      {
        key: "amount",
        label: "Amount",
      },

      {
        key: "percentage",
        label: "Percentage",
      },
    ],
  };
  const handleChange = (event, newValue) => {
    // setPageNo(1);

    setValue(newValue);
  };
  const handleChanget = (event, newValue) => {
    // setPageNo(1);

    setValuet(newValue);
  };

  useEffect(() => {
    getAllEmployeeList((res) => {
      setData(res?.list);
    });
    // getData(getAccountReachageDetails, setData);
  }, []);
  const [selectedDate, setSelectedDate] = React.useState(null);
  const [isDatePickerOpen, setIsDatePickerOpen] = React.useState(false);
  const [cardValue, setCardValue] = useState(null);
const [list,setList]=useState([])
  useEffect(()=>{
    console.log("search ",search,"cardValue",cardValue)
    if(search){
        getAlleducatio({
            employeeId:search?.id
        },(data)=>{
      console.log("getAllEmployeeList", data?.data?.data?.list);

            setList(data?.data?.data?.list)
        })
    }

  },[search,cardValue])
  const handleOpenDatePicker = () => {
    setIsDatePickerOpen(true);
  };

  const handleCloseDatePicker = () => {
    setIsDatePickerOpen(false);
  };
  useEffect(() => {
    console.log("modalData parent", modalData);
  }, [modalData]);
  useEffect(() => {
    if (open == false) {
      // setModalData({});
    }
  }, [open]);
  
  return (
    <><TextFieldSearch search={search?.name} setOpen={setSearchModal} />
<PoprchModal
          cardValue={cardValue}
          setCardValue={(data) => {
            setSearchvalue(data);
            setCardValue(data);
          }}
          callback={() => {}}
          searchModal={searchModal}
          setSearchModal={setSearchModal}
        />
    
      
      
      <Box
        sx={{
          margin: {
            xs: "10px",
            sm: "50px 10px 10px 10px ",
            md: "50px",
          },
        }}
      >
        <Grid container>
          <Grid
            item
            xs={6}
            sx={{
              display: "flex",
              justifyContent: "right",
              alignItems: "center",
            }}
          >
            {" "}
          </Grid>

          <Grid
            item
            xs={6}
            sx={{
              display: "flex",
              justifyContent: "right",
              alignItems: "center",
            }}
          >
            {" "}
            {!gridSize && (
              <Fab
                onClick={() => {
                  // setOpen(true);

                  setOpen(true);
                }}
                sx={{
                  zIndex: "0",
                  textTransform: "capitalize",
                  width: {
                    xs: "100%",
                    sm: "auto",
                  },
                  // p: "10px",
                  whiteSpace: "nowrap",
                }}
                size="small"
                color="primary"
                variant="extended"
                aria-label="add"
              >
                Add Employee
                <AddIcon />
              </Fab>
            )}
          </Grid>

          <Grid container spacing={2}>
          {list.map((ele, index) => {
            if (search != "" && search?.id != ele?.employeeId) {
              return null;
            }
            return (
              <Grid item lg={4}>
                <CardPayment
                  item={ele}
                  setOpen={(data) => {
                    // if (value == "SalaryComponents") {
                    //   setOpen(data);
                    // }
                    // if (value == "incentrive") {
                    //   setOpent(data);
                    // }
                    // if (value == "Deductions") {
                    //   setopenDeduction(true);
                    // }
                  }}
                  setModalData={(data) => {
                    console.log("dataupdate ", data);
                    setModalData(data)
                    setOpen(true)
                    
                    // // setFormda({...formda,id:data.id,});

                    // setFormda({
                    //   ...formda,
                    //   id: data.id,
                    //   employeeId: data?.employeeId,
                    //   componentName: data?.componentName,
                    //   amount: data?.amount,
                    //   percentage: data?.percentage,
                    // });
                    // setFormdat({
                    //   ...formdat,
                    //   id: data.id,
                    //   employeeId: data?.employeeId,
                    //   incentiveName: data?.incentiveName,
                    //   amount: data?.amount,
                    //   percentage: data?.percentage,
                    //   minimumValue: data?.minimumValue,
                    //   maximumValue: data?.maximumValue,
                    // });
                    // setFormdatTDence({
                    //   employeeId: data?.employeeId,
                    //   componentName: data?.componentName,
                    //   amount: data?.amount,
                    //   percentage: data?.percentage,
                    // });
                  }}
                  cardKet={cardKet?.educationalDetail}
                />
              </Grid>
            );
          })}
        </Grid>
        <FormModalT
            open={open}
            setOpen={setOpen}
            getAll={() => {
              if(search){
                getAlleducatio({
                    employeeId:search?.id
                },(data)=>{
              console.log("getAllEmployeeList", data?.data?.data?.list);
        
                    setList(data?.data?.data?.list)
                })
            }
        
            }}
            modalData={modalData}
          />
        </Grid>
      </Box>
    </>
  );
}
