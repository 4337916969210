

import React, { useEffect, useState } from "react";
import {
  TextField,
  Button,
  FormControl,
  Container,
  Grid,
  Box,
  Stack,
  OutlinedInput,
  InputLabel,
  MenuItem,
  ListItemText,
  Select,
  Checkbox,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormLabel,
} from "@mui/material";
// import "./test.css";

import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { bookApoinment } from "./asppoinmentApi";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { routePath } from "../../constants/routePath";
import { setSession } from "../../utils/commonUtils";
import { setCustomerAppointmentId } from "../../app/store/reducer";
// import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { getAlldetailSerice } from "../services/servicesListApi";

dayjs.extend(utc);
dayjs.extend(timezone);

const Appoinment = () => {
  const appnType = useSelector((state) => state.reducer.apppoinmentUerType);
  const dispatch=useDispatch();
  const info = useSelector((state) => state.reducer.detail);
  const [formData, setFormData] = useState({
    billerId:"",
    customerName: "",
    mobileNumber: "",
    // otp: "",
    mailId: "",
    gender:"",
    service: "",
    date :"",
    hour :"",
    minute :"",
    status:"Unassigned"
  });

  useEffect(()=>{
    console.log("formate date  ",timeValue)
    console.log("ppoinment22 ",info)
  },[formData])
  const [dateValue, setDateValue] = useState(dayjs());
  const [timeValue, setTimeValue] = useState(dayjs());
  const [otpEnabled, setOtpEnabled] = useState(true);
 
  const handleChange = (e) => {
    const { name, value } = e.target;
    // console.log()
    if(typeof (value)=="object"){
        setFormData({
            ...formData,
            [name]: value[0],
          });
    }
    else{
        setFormData({
            ...formData,
            [name]: value,
          });
    }
  
  };

  const setDate=(newValue)=>{
    setDateValue(newValue)
    
    // const date = dayjs(newValue).tz('UTC').format('YYYY-MM-DDTHH:mm:ss.SSZ');
                       


    // console.log("date ",date)

    const date = dayjs(newValue).tz('UTC').format('YYYY-MM-DDTHH:mm:ss');

    console.log("date", date);

    // console.log("data ",date)
    setFormData((prev)=>{
      return  {...prev,date :date}
      // return {...prev,date:date}
    })
  }

  const setTime=(newValue)=>{
    setFormData((prev)=>{
      if(newValue['$H']==20){
          prev.hour =newValue['$H']+12
      }
      else{
          prev.hour =newValue['$H']
      }
      prev.minute =newValue['$m']
      return  {...prev}
    })
  setTimeValue(newValue)
  }

 
  
  const handleTagsChange = (e) => {
    const { value } = e.target;

    


    console.log("val8e ",value)
    setFormData({
      ...formData,
      service:value,
    });
  };

  const gender =(e)=>{
    setFormData((prev)=>{
        let temp={...prev}
        if(e.target){
          temp.gender=e.target.value
        }else{
          temp.gender=e
        }
        return {...temp}
    })
}

  const handleVerifymobileNumber = () => {
    if (formData.mobileNumber) {
      setOtpEnabled(true);
    }
  };



  const navigate=useNavigate()
  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(appnType,"appnType");


    const formDataobj=new FormData();
    Object.keys(formData).forEach((key, index) => {
      formDataobj.append(key, formData[key]);
    });
    const condition=appnType=="profile"
    
    bookApoinment(appnType,(condition?{...formData,date :formData.date}:{...formData,date :formData.date}),   async(re)=>{

      await setSession("appoinment",re)
        console.log("data 222 ",re?.data?.id)
        dispatch(setCustomerAppointmentId(re?.data?.id))
        if(!condition){
          // alert('navigating to otp')
          navigate(routePath.OTP)
        }
    })
  };

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };
  const [servicesArr, setservicesArr] = useState([
    {
      serviceName: "Shaving",
      rate: "100.00/Per Head",
    },
    {
      serviceName: "Hair cutting",
      rate: "150.00/Per Head",
    },
  ]);
  const names = [
    "Haircut",
    "Hair Coloring",
    "Hair Styling",
    "Manicure",
    "Pedicure",
    "Facial",
    "Waxing",
    "Massage",
    "Makeup",
    "Bridal Makeup",
    "Threading",
    "Hair Spa",
    "Nail Art",
    "Skin Treatment",
  ];
  useEffect(() => {
    setFormData({
      ...formData, billerId:info.billerId
    })
    setDate(timeValue)
    setTime(timeValue)
    gender("male")
  }, [])


  const infoD = useSelector((state) => state.reducer.detail);
  useEffect(() => {
    getAlldetailSerice(
      {
        billerId: infoD?.billerId,
      },
      (res) => {
        console.log("Data res", res);
        setservicesArr(res?.data);
      }
    );
  }, []);
  return (
    <Container>
      <Box sx={{ mt: 4, my: 5 }} display="flex" justifyContent="center">
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12}>
              <FormControl fullWidth margin="normal">
                <TextField
                  label="Customer Name"
                  name="customerName"
                  value={formData.customerName}
                  onChange={handleChange}
                  required
                  InputLabelProps={{ className: 'mulish' }}
                  InputProps={{ classes: { input: 'mulish' } }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth margin="normal">
                <TextField
                  label="Email"
                  name="mailId"
                  type="email"
                  value={formData.mailId}
                  onChange={handleChange}
                  required
                  InputLabelProps={{ className: 'mulish' }}
                  InputProps={{ classes: { input: 'mulish' } }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth margin="normal">
                <TextField
                  label="mobileNumber"
                  name="mobileNumber"
                  type="number"
                  value={formData.mobileNumber}
                  onChange={handleChange}
                  required
                  InputLabelProps={{ className: 'mulish' }}
                  InputProps={{ classes: { input: 'mulish' } }}
                />
              </FormControl>
            </Grid>
          
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth margin="normal">
                <InputLabel id="tags-label" className='mulish'>Services</InputLabel>
                <Select
                  labelId="tags-label"
                  id="services"
                //   multiple
                  name="tags"
                  value={formData.service}
                  onChange={handleTagsChange}
                  input={<OutlinedInput label="Services" />}
                //   renderValue={(selected) => selected.join(", ")}
                  MenuProps={MenuProps}
                >
                  {servicesArr?.map((name) => (
                    <MenuItem key={name.itemDescription} value={name.itemDescription}>
                      {/* <Checkbox checked={formData.tags.indexOf(name) > -1} /> */}
                      <ListItemText primary={name.itemDescription} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl>
                <FormLabel id="demo-row-radio-buttons-group-label" className='mulish'>
                  Gender:
                </FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                >
                  <FormControlLabel
                    value="female"
                    control={<Radio />}
                    checked={formData.gender=="female"}
                    label="Female"
                    className='mulish'
                    onClick={gender}
                  />
                  <FormControlLabel
                    value="male"
                    control={<Radio />}
                    checked={formData.gender=="male"}
                    label="Male"
                    className='mulish'
                    onClick={gender}
                  />
                  <FormControlLabel
                    value="other"
                    control={<Radio />}
                    checked={formData.gender=="other"}
                    label="Other"
                    className='mulish'
                    onClick={gender}
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth margin="normal">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="Select Date"
                    value={dateValue}
                    minDate={dayjs()} 
                    onChange={(newValue) => {
                      // setDateValue(date);
                      setDate(newValue)                       
                    }
                    }
                    renderInput={(params) => (
                      <TextField {...params} fullWidth InputLabelProps={{ className: 'mulish' }} InputProps={{ classes: { input: 'mulish' } }} />
                    )}
                  />
                </LocalizationProvider>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth margin="normal">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <TimePicker
                    label="Select Time"
                    value={timeValue}
                    onChange={(newValue) =>{
                   setTime(newValue)                        
                    }}
                    renderInput={(params) => (
                      <TextField {...params} fullWidth InputLabelProps={{ className: 'mulish' }} InputProps={{ classes: { input: 'mulish' } }} />
                    )}
                  />
                </LocalizationProvider>
              </FormControl>
            </Grid>
            <Grid item xs={12} sx={{ display: "flex", justifyContent: "end" }}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                sx={{
                  backgroundColor: "#3695B6",
                  padding: "14px",
                  fontSize: "16px",
                }}
              >
                Book an Appointment
              </Button>
            </Grid>
          </Grid>
        </form>
      </Box>
    </Container>
  );
};

export default Appoinment;