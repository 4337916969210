import { GETBILL, GETTABLE, GETTABLESECTION } from "../../utils/api";
import axios from "../../utils/axios";

export const tableListApi = async (params) => {
    const res = await axios.post(GETTABLE, params); 
    return res;
  };
  
export const TableSectionListApi = async (params) => {
    const res = await axios.post(GETTABLESECTION, params);
    return res;
  };
  
  export const getBillCounterSell = async (params) => {
    const res = await axios.post(GETBILL, params);
    return res;
  };