import { useSelector } from "react-redux";
import CounterSellBilling from "../counterSellBilling/counterSellBilling";
import CounterSell from "../counterSell/counterSell";

export const ContainerCont=()=>{
  const billerMenuseletec = useSelector((state) => state.reducer.billerMenuseletec);

    return <>
    {billerMenuseletec==0  && <CounterSellBilling/>}
    {billerMenuseletec!=0  && <CounterSell/>}

    </>
}