// import { Box, Button, Typography, Modal, Grid } from "@mui/material";

import React, { useEffect, useState } from "react";
import {
  Button,
  Grid,
  Box,
  Modal,
  DialogTitle,
  FormGroup,
  FormControl,
  FormControlLabel,
  TextField,
} from "@mui/material";

import {
  getFirstDateOfCurrentMonthPayloadFormate,
  getLastDateOfCurrentMonthPayloadFormat,
  swalAlertPopProps,
} from "../../utils/commonUtils";

import CloseIcon from "@mui/icons-material/Close";

// import "./test.css";

import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
// import { bookApoinment } from "./asppoinmentApi";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
// import dayjs from 'dayjs';
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { TextFieldSearch } from "../../common/fieldText";
import PoprchModal from "../payment/popSearch";
import { Checkbox } from "@mui/material";
import { ModalHeader } from "../../common/modalHeader";
import { validationEmp, validationPersonalInfo } from "../payment/validation";
import Swal from "sweetalert2";

dayjs.extend(utc);
dayjs.extend(timezone);

export default function Pop({
  open,
  setOpen,
  modalData,
  data,
  newEmpArr,
  setnewEmpArr,
  arrCollectionCb,
  eleCompairedIndex,
  NextDisplayCb
}) {
  const onClose = () => {
    setOpen(false);
  };
  const struct = {
    employeeId: 0,
    companyName: "",
    location: "",
    fromDate: "",
    toDate: "",
    designation: "",
    primarySkillSet: "",
    specialization: "",
    otherSkillSet: "",
  };
  const [intialArr, setInitialArr] = useState([]);
  const [DisplayArr, setDisplayArr] = useState([]);
  const [calculatedArr, setCalculatedArr] = useState([]);
  const [formData, setFormData] = useState({ ...struct });

  const [collection, setCollection] = useState([]);
  const [search, setSearchvalue] = useState("");
  const [searchModal, setSearchModal] = useState(false);
  const [cardValue, setCardValue] = useState(null);
  const [percemtageAmount, setPercentageAmount] = useState({});
  const [hideModal, setHideModal] = useState(false);

  const [errorEmpModal,seterrorEmpModal]  =useState(false)
  useEffect(() => {
    let arr = JSON.parse(JSON.stringify(DisplayArr));
      //focu startn
    let allPerSPlited=true
  
    arr.forEach((ele, index) => {
      updateValueOfArr(ele);
      if(ele[1].employeePayPercentage>0)  
      {
        allPerSPlited=false
      }
      console.log("allPerSPlited",arr)
    });
    if(NextDisplayCb && arr.length>0){
      NextDisplayCb(allPerSPlited)
    }
    
//focu end
  }, [DisplayArr]);
  const info = useSelector((state) => state.reducer.detail);

  const navigate = useNavigate();

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;

  useEffect(() => {
    if (open === true) {
      console.log("data== ", data);

      if (data) {
        let arrnew = data.map((item, index) => {
          let ele = item;
          ele[1]["employeePayPercentage"] = 100;
          return ele;
        });
        setDisplayArr(arrnew);
        setCalculatedArr(JSON.parse(JSON.stringify(arrnew)));
        setInitialArr(JSON.parse(JSON.stringify(arrnew)));
      }
      // setDateValuet()
      // getDataRechageDetail(getNewSubscriptionReachageDetails, setSubscription);
    } else {
      console.log("close");

      setCalculatedArr([]);
      setInitialArr([]);
      setDisplayArr([]);
      setCollection([])
      setSearchvalue("")
      setSearchModal()
      setHideModal(false)
      setFormData({ ...struct });
    }
    // setstartDateFormat(dayjs("2024-12-12T00:00:00"));
  }, [open]);
  useEffect(() => {
    console.log("percemtageAmount ", percemtageAmount);
    console.log("arrtemp DisplayArr ", DisplayArr);

    console.log("arrtemp calculatedArr ", calculatedArr);
    console.log("arrtemp intialArr ", intialArr);

    console.log("he  collection ", collection);
  }, [percemtageAmount, calculatedArr, DisplayArr, intialArr, collection]);
  const checkPresent = (ele, index) => {
    console.log();
  };
  const checkAndHideModal = (DisplayArr) => {
    let zeroCount = 0;
    let noOneZero=true
    DisplayArr.forEach((ele) => {
      if (
        (ele[1].employeePayPercentage == 0 ||
        ele[1].employeePayPercentage < 0) && ele[1].employeePayPercentage!=="" 
      ) {
        noOneZero=false
      }
    });
  
    console.log("Zero count:", zeroCount, "DisplayArr:", DisplayArr);
  
    if (!noOneZero) {
      setHideModal(true);  // Hide modal if all percentages are 0
    }
  };
  
  useEffect(() => {
    console.log("collection ", collection);
  }, [collection]);
  useEffect(() => {});

  useEffect(() => {
    console.log("search search ", search);
  }, [search]);
  const handleCheckboxChange = (checked, ele) => {
    let eleIndex = calculatedArr.findIndex((arrEle) => {
      return arrEle && arrEle.length > 1 && arrEle[1]?.id === ele[1]?.id;
    });

    if (checked && eleIndex === -1) { 
      const tempArry = [...calculatedArr, ele];
      setCalculatedArr(tempArry);
    } else {
      if (eleIndex !== -1) {
        const tempArry = [...calculatedArr];
        tempArry.splice(eleIndex, 1);
        setCalculatedArr(tempArry);
      }
    }
  };
  useEffect(() => {
    if (hideModal) {
      setOpen(false);
    }
  }, [hideModal]);
  useEffect(() => {
  }, [collection]);
  const updateValueOfArr = (ele) => {
    // Find the index of the element in calculatedArr
    let eleIndex = calculatedArr.findIndex((arrEle) => {
      return arrEle && arrEle.length > 1 && arrEle[1]?.id === ele[1]?.id;
    });

    if (eleIndex !== -1) {
      // Use functional set state to ensure proper updates
      setCalculatedArr((prevCalculatedArr) => {
        const tempArry = [...prevCalculatedArr]; // Create a copy of the previous array
        tempArry[eleIndex] = ele; // Update the element
        return tempArry; // Return the updated array
      });
    }
  };

  return (
    <Modal
      open={open}
      onClose={()=>{}}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 500,
          bgcolor: "background.paper",
          borderRadius: "8px",
          boxShadow: 24,

          overflowY: "auto",
          height: "80vh",
        }}
      >
        <ModalHeader
          title={"Assign Employee"}
          onClose={(data) => {
            setOpen(data);
          }}
          // closeIcon={true}
        />
        <Box sx={{ mt: 4, my: 5, p: 4 }} display="flex" justifyContent="center">
          <Grid container spacing={2}>
            <Box
              sx={{ width: "100%", backgroundColor: "green", top: "200px" }}
            ></Box>
                <Grid container sx={{mt:"20px",mb:"20px"}}>
                      <Grid item xs={8}>
                        Cart Item
                      </Grid>
                      <Grid item xs={2}>Pay % </Grid>
                      <Grid item xs={2}> Assign</Grid>
                      </Grid>
            <FormControl sx={{ width: "100%" }} component="fieldset">
              {DisplayArr &&
                DisplayArr?.map((ele, index) => {
                  console.log("eleemployeePayPercentage op  ",ele[1].employeePayPercentage," ",typeof(ele[1].employeePayPercentage) )
                  if 
                  ( (ele[1].employeePayPercentage === 0 || ele[1].employeePayPercentage < 0) &&
                  ele[1].employeePayPercentage !== null &&
                  ele[1].employeePayPercentage !== undefined &&
                  ele[1].employeePayPercentage !== ""

                     
                  ) {
                    return;
                  }
                  return (
                    <Grid container>
                      <Grid item xs={8} sx={{
                        color:(theme) => theme.palette.primary.main
                      }}>
                        {ele[1].itemDescription}
                      </Grid>
                      <Grid item xs={2} sx={{display:'flex',alignItems:'center'}}>
                        <TextField  
                        
                          disabled={(() => {
                            const indexT = calculatedArr.findIndex((arrEle) => {
                              // Ensure arrEle exists and has at least 2 elements
                              return (
                                arrEle &&
                                arrEle.length > 1 &&
                                arrEle[1]?.id === ele[1].id
                              );
                            });
                            return indexT === -1;
                          })()}
                          value={ele[1].employeePayPercentage}
                          onChange={(e) => {
                            let arrt = [...DisplayArr];
                            const indexT = calculatedArr.findIndex((arrEle) => {
                              // Ensure arrEle exists and has at least 2 elements
                              return (
                                arrEle &&
                                arrEle.length > 1 &&
                                arrEle[1]?.id === ele[1].id
                              );
                            });
                            console.log("intialArr employeePayPercentage ",intialArr[indexT].employeePayPercentage," == ",e.target.value)
                             
                          
                              arrt[index][1] = {
                                ...arrt[index][1],
                                employeePayPercentage: e.target.value.replace(/[^0-9]/g, ''),
                              };
                            
                            
                            setDisplayArr(arrt);
                          }}
                        />
                        %
                      </Grid>
                      <Grid item xs={2}>
                        <Checkbox
                          checked={(() => {
                            const indexT = calculatedArr.findIndex((arrEle) => {
                              // Ensure arrEle exists and has at least 2 elements
                              return (
                                arrEle &&
                                arrEle.length > 1 &&
                                arrEle[1]?.id === ele[1].id
                              );
                            });
                            return indexT !== -1;
                          })()}
                          onChange={(e) => {
                            handleCheckboxChange(e.target.checked, ele);
                          }}
                        />
                      </Grid>
                    </Grid>
                  );
                })}
            </FormControl>

            <Grid item xs={12}>
              <TextFieldSearch search={search?.name} setOpen={setSearchModal} error={errorEmpModal} />
            </Grid>
            <React.Fragment>
              <PoprchModal
                cardValue={cardValue}
                setCardValue={(data) => {
                  setSearchvalue(data);
                  setFormData({ ...formData, employeeId: data?.id });
                  setCardValue(data);
                  seterrorEmpModal(false)

                  //update value of calclatedArr purpuse storeing only selected value
                  // let calculatedTmp=[...calculatedArr]
                  // DisplayArr.forEach((ele, index) => {
                  //   if (ele && ele[1] && ele[1].id) {
                  //     // Find the matching index in `calculatedArr`
                  //     const indexT = calculatedArr.findIndex((arrEle) => {
                  //       // Ensure `arrEle[1]` exists and has the necessary properties
                  //       return arrEle && arrEle[1] && arrEle[1].id === ele[1].id;
                  //     });
                  //     if(indexT!=-1){
                  //       calculatedTmp[indexT]=ele
                  //     }
                  //   }
                  // })
                  // setCalculatedArr(calculatedTmp)

                  // Update the state
                  // setInitialArr(updatedArr);
                }}
                callback={() => {}}
                searchModal={searchModal}
                setSearchModal={setSearchModal}
              />
            </React.Fragment>

            <Grid item xs={12} sx={{ display: "flex", justifyContent: "end" }}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  let valid=validationEmp(search)
                  if(valid?.status==false){
                    // Swal.fire({
                    //   text: valid.text,
                    //   icon: "warning",
                    //   ...swalAlertPopProps,
                    // });
                    seterrorEmpModal(true)
                    // setValidationMsgFlag(true)
                    // setOpen(false)
              
                    // return;
                  }
                  else{
                    
                  let tmpArr = [...DisplayArr];

                  let percemtageAmountTmp = { ...percemtageAmount };

                  percemtageAmountTmp[data?.id] = DisplayArr;
                  setPercentageAmount(percemtageAmountTmp);
                  let remaingPrice = [];
                  let payremaingPrice = [];
                  let splitedValue = [];
                  let validData=true
                  let intiialTmp = JSON.parse(JSON.stringify(intialArr));
                  intiialTmp.forEach((ele, index) => {
                    let eleCopy;
                    // Ensure `ele[1]` exists and has the necessary properties
                    if (ele && ele[1] && ele[1].id) {
                      // Find the matching index in `calculatedArr`
                      const indexT = calculatedArr.findIndex((arrEle) => {
                        // Ensure `arrEle[1]` exists and has the necessary properties
                        return (
                          arrEle && arrEle[1] && arrEle[1].id === ele[1].id
                        );
                      });

                      // If a match is found
                      if (indexT !== -1) {
                       

                        // Update the `employeePayPercentage` safely
                        if (
                          indexT !== -1 ||
                          Number(ele[1].employeePayPercentage) !=
                            Number(
                              calculatedArr[indexT][1].employeePayPercentage
                            )
                        ) {
                          let ans =
                            Number(ele[1].employeePayPercentage) -
                            Number(
                              calculatedArr[indexT][1].employeePayPercentage
                            );
                          if(ans<0){

                            alert('please enter less valid per',ele[1].employeePayPercentage)
                            validData=false
                            
                            return;
                          }


                          if (ans >= 0) {
                            eleCopy = JSON.parse(JSON.stringify(ele));

                            eleCopy[1].employeePayPercentage =
                              calculatedArr[indexT][1].employeePayPercentage;

                            ele[1].employeePayPercentage = ans;
                          } else {
                            ele[1].employeePayPercentage = 0;
                            eleCopy[1].employeePayPercentage = 0;
                          }

                          ele[1]["employeeMasterId"] = search?.id ?? 0;
                          eleCopy[1]["employeeMasterId"] = search?.id ?? 0;
                          eleCopy[1]["nameEmp"]=search?.name ?? ""
                        }
                        
                        console.log("Updated Object:", ele[1]);
                      }
                      remaingPrice.push(ele);
                      if (eleCopy && eleCopy.length>1 && eleCopy[1]?.employeePayPercentage != 0) {
                        payremaingPrice.push(eleCopy);
                      }
                    }
                  });
                  if(!validData){
                    return
                  }
                  console.log("remaingPrice ", remaingPrice);
                  console.log("remaingPrice payremaingPrice", payremaingPrice);
                  setCollection([
                    ...collection,
                    ...JSON.parse(JSON.stringify(payremaingPrice)),
                  ]);
                  setDisplayArr(JSON.parse(JSON.stringify(remaingPrice)));
                  setInitialArr(JSON.parse(JSON.stringify(remaingPrice)));
              //focu startn
                  arrCollectionCb([
                    ...collection,
                    ...JSON.parse(JSON.stringify(payremaingPrice)),
                  ]);
 
                 checkAndHideModal(DisplayArr)
                  }

                }}
                sx={{
                  backgroundColor: "#3695B6",
                  padding: "14px",
                  fontSize: "16px",
                }}
              >
                {modalData ? "Update" : "Add"}
              </Button>
             
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Modal>
  );
}
