// import { Box, Button, Typography, Modal, Grid } from "@mui/material";

import React, { useEffect, useState } from "react";
import {
  TextField,
  Button,
  FormControl,
  Container,
  Grid,
  Box,
  Stack,
  OutlinedInput,
  InputLabel,
  MenuItem,
  ListItemText,
  Select,
  Checkbox,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormLabel,
  Modal,
  DialogTitle,
  Tabs,
  Tab,
  Switch,
  FormGroup,
} from "@mui/material";

import {
  addEmployee,
  getNewSubscriptionReachageDetails,
  updateEmployee,
} from "./appoinmentDashboardApi";
import { payloadDateFormat, swalAlertPopProps } from "../../utils/commonUtils";
import { getDataRechageDetail } from "./smbAccountRechargeCustomApiHandler";
import { getMonthDifference } from "./smbAccounrReachargeDateFunction";
import CloseIcon from "@mui/icons-material/Close";
import { getAlldetailSerice } from "../services/servicesListApi";

// import "./test.css";

import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
// import { bookApoinment } from "./asppoinmentApi";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { routePath } from "../../constants/routePath";
import { setSession } from "../../utils/commonUtils";
import { setCustomerAppointmentId } from "../../app/store/reducer";
// import dayjs from 'dayjs';
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { validationPersonalInfo } from "../payment/validation";
import Swal from "sweetalert2";
// import { getAlldetailSerice } from "../services/servicesListApi";

dayjs.extend(utc);
dayjs.extend(timezone);

export default function  FormModal({ open, setOpen, modalData, getAll }) {
  const [pagination, setPagination] = useState({ page: 1, total: 10 });
  const [subscription, setSubscription] = useState();
  const [value, setValue] = React.useState("personalInfo");

  const handleChange_Tab = (event, newValue) => {
    // setPageNo(1);

    setValue(newValue);
  };

  const onClose = () => {
    setOpen(false);
  };

  const struct = {
    billerId: "",
    employeeId: "",
    employeeName: "",
    alternateMobileNumber: "",
    customerName: "",
    mobileNumber: "",
    designation: "",
    pan: "",
    address: "",
    email:"",
    aadhar:"",
    
    paymentPercentage: "",
    // otp: "",
    mailId: "",
    gender: "",
    bankName: "",
    bankAccountNumber: "",
    bankBranch: "",
    service: "",
    salary: "",
    deduction: "",
    date: "",
    ifsc:"",
    hour: "",
    minute: "",
    isActive: false,
  };
  const appnType = useSelector((state) => state.reducer.apppoinmentUerType);
  const dispatch = useDispatch();
  const info = useSelector((state) => state.reducer.detail);
  const [formData, setFormData] = useState({ ...struct });

  useEffect(() => {
    console.log("formate date  ", timeValue);
    console.log("ppoinment22 ", info);
  }, [formData]);
  const [dateValue, setDateValue] = useState();
  const [dateValuet, setDateValuet] = useState();

  const [timeValue, setTimeValue] = useState(dayjs());
  const [otpEnabled, setOtpEnabled] = useState(true);

  const handleChange = (e) => {
    const { name, value } = e.target;
    const regex = /^[a-zA-Z\s]*$/;
    // console.log()
    if(name=="employeeName" &&   !regex.test(value)){ 
       setFormData({
      ...formData,
      [name]: value[0],
    });

    }
    else if (typeof value == "object") {
      setFormData({
        ...formData,
        [name]: value[0],
      });
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  const setDate = (newValue) => {
    setDateValue(newValue);

    // const date = dayjs(newValue).tz('UTC').format('YYYY-MM-DDTHH:mm:ss.SSZ');

    // console.log("date ",date)

    const date = dayjs(newValue).tz("UTC").format("YYYY-MM-DDTHH:mm:ss");

    console.log("date", date);

    // console.log("data ",date)
    setFormData((prev) => {
      return { ...prev, doj: date };
      // return {...prev,date:date}
    });
  };

  const setDatet = (newValue) => {
    setDateValuet(newValue);

    // const date = dayjs(newValue).tz('UTC').format('YYYY-MM-DDTHH:mm:ss.SSZ');

    // console.log("date ",date)

    const date = dayjs(newValue).tz("UTC").format("YYYY-MM-DDTHH:mm:ss");

    console.log("date", date);

    // console.log("data ",date)
    setFormData((prev) => {
      return { ...prev, dob: date };
      // return {...prev,date:date}
    });
  };

  const setTime = (newValue) => {
    setFormData((prev) => {
      if (newValue["$H"] == 20) {
        prev.hour = newValue["$H"] + 12;
      } else {
        prev.hour = newValue["$H"];
      }
      prev.minute = newValue["$m"];
      return { ...prev };
    });
    setTimeValue(newValue);
  };

  const handleTagsChange = (e) => {
    const { value } = e.target;

    console.log("val8e ", value);
    setFormData({
      ...formData,
      service: value,
    });
  };

  const gender = (e) => {
    setFormData((prev) => {
      let temp = { ...prev };
      if (e.target) {
        temp.gender = e.target.value;
      } else {
        temp.gender = e;
      }
      return { ...temp };
    });
  };

  const handleVerifymobileNumber = () => {
    if (formData.mobileNumber) {
      setOtpEnabled(true);
    }
  };

  const navigate = useNavigate();
  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(appnType, "appnType");
    let valid=validationPersonalInfo(formData)
    if(valid?.status==false){
      Swal.fire({
        text: valid.text,
        icon: "warning",
        ...swalAlertPopProps,
      });
      setValidationMsgFlag(true)
      setOpen(false)

      // return;
    }
    else{
      setValidationMsgFlag(null)
   
    const formDataobj = new FormData();
    Object.keys(formData).forEach((key, index) => {
      formDataobj.append(key, formData[key]);
    });
    const condition = appnType == "profile";
    const payload = {
      id: 0,
      name: formData.employeeName,
      address: formData.address,
      email:formData.email,
      aadhar:formData.aadhar,
      bankName:formData?.bankName ,
  bankAccountNumber:formData?.bankAccountNumber ,
  bankBranch:formData?.bankBranch ,
      mobile: formData.mobileNumber,
      grade:formData?.grade,
      ifsc:formData.ifsc,
      paymentPercentage: formData.paymentPercentage.includes(".")
        ? Number(formData.paymentPercentage)
        : Number(formData.paymentPercentage + ".00"),
      isActive: false,
      alternateMobile: formData.alternateMobileNumber,


      deduction: parseFloat(formData.deduction),
 
      isActive:formData.isActive,
      gender: formData.gender,
       
      pan: formData.pan,
      salary: parseFloat(formData.salary),
      // "salary": 0,
      doj: formData?.doj,
      dob: formData?.dob,
      designation: formData?.designation,
      employeeId: formData?.employeeId,
      // "aadhar": formData?.aadhar
    };
    console.log("formdata", payload, " ", payload?.paymentPercentage);
    if (!formData?.id) {
      addEmployee(payload, () => {
        setOpen(false);
        getAll();
        // console.log("payload",)
      });
    } else {
      payload["id"] = modalData?.id;
      updateEmployee(payload, () => {
        console.log("data");
        setOpen(false);
        getAll();
      });
    }
       
  }
  };

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };
  const [servicesArr, setservicesArr] = useState([
    {
      serviceName: "Shaving",
      rate: "100.00/Per Head",
    },
    {
      serviceName: "Hair cutting",
      rate: "150.00/Per Head",
    },
  ]);
  const [validationMsgFlag,setValidationMsgFlag]=useState(null)

  const names = [
    "Haircut",
    "Hair Coloring",
    "Hair Styling",
    "Manicure",
    "Pedicure",
    "Facial",
    "Waxing",
    "Massage",
    "Makeup",
    "Bridal Makeup",
    "Threading",
    "Hair Spa",
    "Nail Art",
    "Skin Treatment",
  ];
  useEffect(() => {
    setFormData({
      ...formData,
      billerId: info.billerId,
    });
    setDate(timeValue);
    setTime(timeValue);
    gender("male");
  }, []);

  const infoD = useSelector((state) => state.reducer.detail);
  useEffect(() => {
    // getAlldetailSerice(
    //   {
    //     billerId: infoD?.billerId,
    //   },
    //   (res) => {
    //     console.log("Data res", res);
    //     setservicesArr(res?.data);
    //   }
    // );
  }, []);
  useEffect(() => {
    if (open === true && modalData ) {
      console.log("modalData", modalData?.employeeName);
      setFormData({
        ...formData,

        id: modalData?.id,
        bankName:modalData?.bankName ,
  bankAccountNumber:modalData?.bankAccountNumber ,
  bankBranch:modalData?.bankBranch ,
        employeeId: modalData?.employeeId,
        employeeName: modalData?.name,
        alternateMobileNumber: modalData?.alternateMobile,
        // customerName:modalData?.customerName,
        mobileNumber: modalData?.mobile,
        designation: modalData?.designation,
        pan: modalData?.pan,
        salary: modalData?.salary,
        ifsc:modalData?.ifsc,
        address: modalData?.address,
        paymentPercentage: modalData?.paymentPercentage
          ? String(modalData?.paymentPercentage)
          : "",
        // otp: "",
        dob: modalData?.dob,
        doj: modalData?.doj,
        deduction: modalData?.deduction,
        email: modalData?.email,
        aadhar:modalData?.aadhar,
        gender: modalData?.gender,
        grade:modalData?.grade,
        service: "",

        date: "",
        hour: "",
        minute: "",
        isActive:  modalData?.isActive,
      });
      setDateValue(dayjs(modalData?.dob));
      setDateValuet(dayjs(modalData?.doj));

      // setDateValuet()
      // getDataRechageDetail(getNewSubscriptionReachageDetails, setSubscription);
    } else {
      console.log("close");
      if(!validationMsgFlag){
        setFormData({ ...struct });
      }
      
    }
  }, [open]);
  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >

      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 500,
          bgcolor: "background.paper",
          borderRadius: "8px",
          boxShadow: 24,
          p: 4,
          overflowY: "auto",
          height: "80vh",
        }}
      >
        <DialogTitle
          color="primary"
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            height: 'auto',
            p: "0px 10px !important"
          }}
        >
          Employee Information    {" "}
          <CloseIcon sx={{ float: "right", cursor: "pointer" }} onClick={() => setOpen(false)} />
        </DialogTitle>
         
        <Box sx={{ mt: 4, my: 5 }} display="flex" justifyContent="center">
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
             
                 
                  <React.Fragment>
                    <Grid item xs={12} sm={12}>
                      <FormControl fullWidth >
                        <TextField
                          label="Employee Id"
                          name="employeeId"
                          value={formData.employeeId}
                          onChange={handleChange}
                          required
                          InputLabelProps={{ className: "mulish" }}
                          InputProps={{ classes: { input: "mulish" } }}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <FormControl fullWidth >
                        <TextField
                          label="Employee Name"
                          name="employeeName"
                          value={formData.employeeName}
                          onChange={handleChange}
                          required
                          InputLabelProps={{ className: "mulish" }}
                          InputProps={{ classes: { input: "mulish" } }}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <FormControl fullWidth >
                        <TextField
                          label="Mobile Number"
                          name="mobileNumber"
                          type="number"
                          value={formData.mobileNumber}
                          onChange={(e) => {
                            const inputValue = e.target.value;

                            // Regular expression to allow only numbers and one decimal point
                            const regex = /^[0-9]{0,10}$/;

                            // Check if the input matches the regex
                            if (regex.test(inputValue)) {
                              handleChange(e);
                            }
                          }}
                          required
                          InputLabelProps={{ className: "mulish" }}
                          InputProps={{ classes: { input: "mulish" } }}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <FormControl fullWidth >
                        <TextField
                          label="Alternate Mobile Number"
                          name="alternateMobileNumber"
                          value={formData.alternateMobileNumber}
                          onChange={(e) => {
                            const inputValue = e.target.value;

                            // Regular expression to allow only numbers and one decimal point
                            const regex = /^[0-9]{0,10}$/;

                            // Check if the input matches the regex
                            if (regex.test(inputValue)) {
                              handleChange(e);
                            }
                          }}
                          required
                          InputLabelProps={{ className: "mulish" }}
                          InputProps={{ classes: { input: "mulish" } }}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                      <FormControl>
                        <FormLabel
                          id="demo-row-radio-buttons-group-label"
                          className="mulish"
                        >
                          Gender:
                        </FormLabel>
                        <RadioGroup
                          row
                          aria-labelledby="demo-row-radio-buttons-group-label"
                          name="row-radio-buttons-group"
                        >
                          <FormControlLabel
                            value="female"
                            control={<Radio />}
                            checked={formData.gender == "female"}
                            label="Female"
                            className="mulish"
                            onClick={gender}
                          />
                          <FormControlLabel
                            value="male"
                            control={<Radio />}
                            checked={formData.gender == "male"}
                            label="Male"
                            className="mulish"
                            onClick={gender}
                          />
                          <FormControlLabel
                            value="other"
                            control={<Radio />}
                            checked={formData.gender == "other"}
                            label="Other"
                            className="mulish"
                            onClick={gender}
                          />
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <FormControl fullWidth >
                        <TextField
                          label="Address"
                          name="address"
                          value={formData.address}
                          onChange={handleChange}
                          required
                          InputLabelProps={{ className: "mulish" }}
                          InputProps={{ classes: { input: "mulish" } }}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <FormControl fullWidth >
                        <TextField
                          label="Email"
                          name="email"
                          value={formData.email}
                          onChange={handleChange}
                          required
                          InputLabelProps={{ className: "mulish" }}
                          InputProps={{ classes: { input: "mulish" } }}
                        />
                      </FormControl>
                    </Grid>

                    <Grid item xs={12} sm={12}>
                      <FormControl fullWidth >
                        <TextField
                          label="PAN"
                          name="pan"
                          value={formData.pan}
                          onChange={(e) => {
                            const inputValue = e.target.value;

                            // Regular expression to allow only numbers and one decimal point
                            const regex = /^[0-9]{0,10}$/;

                            // Check if the input matches the regex
                            if (inputValue.length <= 10) {
                              handleChange(e);
                            }
                          }}
                          required
                          InputLabelProps={{ className: "mulish" }}
                          InputProps={{ classes: { input: "mulish" } }}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <FormControl fullWidth >
                        <TextField
                          label="Aadhaar Card Number"
                          name="aadhar"
                          value={formData.aadhar}
                          onChange={(e) => {
                            const inputValue = e.target.value;

                            // Regular expression to allow only numbers and one decimal point
                            const regex = /^[0-9]{0,12}$/;

                            // Check if the input matches the regex
                            if (regex.test(inputValue)) {
                              handleChange(e);
                            }
                          }}
                          required
                          InputLabelProps={{ className: "mulish" }}
                          InputProps={{ classes: { input: "mulish" } }}
                        />
                      </FormControl>
                    </Grid>
                    
                    <Grid item xs={12} sm={12}>
                      <FormControl fullWidth >
                        <TextField
                          label="Bank Account Number"
                          name="bankAccountNumber"
                          value={formData.bankAccountNumber}
                          onChange={handleChange}
                          required
                          InputLabelProps={{ className: "mulish" }}
                          InputProps={{ classes: { input: "mulish" } }}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <FormControl fullWidth >
                        <TextField
                          label="Bank Account Number"
                          name="bankName"
                          value={formData.bankName}
                          onChange={handleChange}
                          required
                          InputLabelProps={{ className: "mulish" }}
                          InputProps={{ classes: { input: "mulish" } }}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <FormControl fullWidth >
                        <TextField
                          label="Bank Branch"
                          name="bankBranch"
                          value={formData.bankBranch}
                          onChange={handleChange}
                          required
                          InputLabelProps={{ className: "mulish" }}
                          InputProps={{ classes: { input: "mulish" } }}
                        />
                      </FormControl>
                    </Grid>
                                        <Grid item xs={12} sm={12}>
                      <FormControl fullWidth >
                        <TextField
                          label="IFSC Code "
                          name="ifsc"
                          value={formData.ifsc}
                          onChange={handleChange}
                          required
                          InputLabelProps={{ className: "mulish" }}
                          InputProps={{ classes: { input: "mulish" } }}
                        />
                      </FormControl>
                    </Grid>
                    
                    <Grid item xs={12}>
                      <FormControl fullWidth >

                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker
                            label="DOJ "
                            value={dateValue}
                            // minDate={dayjs()}
                            onChange={(newValue) => {
                              // setDateValue(date);
                              setDate(newValue);
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                fullWidth
                                InputLabelProps={{ className: "mulish" }}
                                InputProps={{ classes: { input: "mulish" } }}
                              />
                            )}
                          />
                        </LocalizationProvider>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                      <FormControl fullWidth >
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker
                            label="DOB"
                            value={dateValuet}
                            // minDate={dayjs()}
                            onChange={(newValue) => {
                              // setDateValue(date);
                              setDatet(newValue);
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                fullWidth
                                InputLabelProps={{ className: "mulish" }}
                                InputProps={{ classes: { input: "mulish" } }}
                              />
                            )}
                          />
                        </LocalizationProvider>
                      </FormControl>
                    </Grid>

                    <Grid item xs={12} sm={12}>
                      <FormControl fullWidth >
                        <TextField
                          label="Designation"
                          name="designation"
                          value={formData.designation}
                          onChange={handleChange}
                          required
                          InputLabelProps={{ className: "mulish" }}
                          InputProps={{ classes: { input: "mulish" } }}
                        />
                      </FormControl>
                    </Grid>
                  </React.Fragment>
                
                  <Grid item xs={12} sm={12}>
                      <FormControl fullWidth >
                        <TextField
                          label="Grade"
                          name="grade"
                          value={formData.grade}
                          onChange={handleChange}
                          required
                          InputLabelProps={{ className: "mulish" }}
                          InputProps={{ classes: { input: "mulish" } }}
                        />
                      </FormControl>
                    </Grid>
                    
              
                  <React.Fragment>
                    <Grid item xs={12} sm={12}>
                      <FormControl fullWidth >
                        <TextField
                          label="Salary"
                          name="salary"
                          value={formData.salary}
                          onChange={(e) => {
                            const inputValue = e.target.value;

                            // Regular expression to allow only numbers and one decimal point
                            const regex = /^[0-9]{0,10}$/;

                            // Check if the input matches the regex
                            if (regex.test(inputValue)) {
                              handleChange(e);
                            }
                          }}
                          required
                          InputLabelProps={{ className: "mulish" }}
                          InputProps={{ classes: { input: "mulish" } }}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <FormControl fullWidth >
                        <TextField
                          label="Payment Percentage"
                          name="paymentPercentage"
                          value={formData.paymentPercentage}
                          onChange={(e) => {
                            const inputValue = e.target.value;

                            // Regular expression to allow only numbers and one decimal point
                            const regex = /^[0-9]*\.?[0-9]*$/;

                            // Check if the input matches the regex
                            if (regex.test(inputValue)) {
                              // setValue(inputValue);
                              handleChange(e);
                            }
                          }}
                          required
                          InputLabelProps={{ className: "mulish" }}
                          InputProps={{ classes: { input: "mulish" } }}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <FormControl fullWidth >
                        <TextField
                          label="Deduction"
                          name="deduction"
                          value={formData.deduction}
                          onChange={(e) => {
                            const inputValue = e.target.value;

                            // Regular expression to allow only numbers and one decimal point
                            const regex = /^[0-9]*\.?[0-9]*$/;

                            // Check if the input matches the regex
                            if (regex.test(inputValue)) {
                              // setValue(inputValue);
                              handleChange(e);
                            }
                          }}
                          required
                          InputLabelProps={{ className: "mulish" }}
                          InputProps={{ classes: { input: "mulish" } }}
                        />
                      </FormControl>
                    </Grid>
                  </React.Fragment>
                 





              {modalData ?.id && (


                <Grid item xs={12} sm={12}>
                  <FormGroup>
                    <FormControlLabel
                      sx={{
                        justifyContent: "space-between",
                        m: 0,
                        flexDirection: "row-reverse",
                      }}
                      control={
                        <Switch
                          checked={formData.isActive}
                          onChange={(e) => {
                             
                              setFormData({
                                ...formData, isActive:e.target.        checked
                              });

                            
                          }}
                        />
                      }
                      label="Status"
                    />
                  </FormGroup>
                </Grid>
              )

              }
              <Grid
                item
                xs={12}
                sx={{ display: "flex", justifyContent: "end" }}
              >
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  sx={{
                    backgroundColor: "#3695B6",
                    padding: "14px",
                    fontSize: "16px",
                  }}
                >
                  {modalData ? "Update" : "Add"}
                </Button>
              </Grid>
            </Grid>
          </form>
        </Box>
      </Box>
    </Modal>
  );
}
