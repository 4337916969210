import React, { useEffect, useState } from "react";
import {
  TextField,
  Box,
  Button,
  Typography,
  Container,
  Grid,
  Card,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import LogoImage from "../../assets/img/logo.svg";
import qs from "qs";
import { generateRandomString, getSession, swalAlertPopProps } from "../../utils/commonUtils";
import { encrypt } from "../../utils/encryption";
import { routePath } from "../../constants/routePath";
import { useSelector } from "react-redux";
import { ENCRYPTIONKEY, VALIDCUSTOMERAPPOINMENTANAMONUS, VALIDOTP } from "../../utils/api";
import { otpApi } from "../Otp/otpApi";
import Timer_opt from "../../common/timerOt";
import { LoginApi, getValidateOtp, getresemndOtp } from "../userLogin/loginApi";
import Swal from "sweetalert2";
import { stopLoading } from "../../app/store/reducer";
import store from "../../app/store/store";


const OTPForget = () => {
  const [otp, setOtp] = useState(new Array(4).fill(""));
  const customerAppointmentId = useSelector((state) => state.reducer.customerAppointmentId);
  const mobileNumner = useSelector((state) => state.reducer.mobileNumner);
  const apppoinmentUerType = useSelector((state) => state.reducer.apppoinmentUerType);
  const handleChange = (value, index) => {
    let newOtp = [...otp];
    if (/^[0-9]?$/.test(value)) {
      newOtp[index] = value;
      setOtp(newOtp);
    }
  };
  useEffect(()=>{
    if(!mobileNumner){
      navigate(routePath.LOGIN)
    }
  },[mobileNumner])
  useEffect(() => {
  console.log(apppoinmentUerType,'apppoinmentUerType')

}, [apppoinmentUerType])









  const handleKeyUp = (e, index) => {
    const nextInput = document.querySelector(`input[name=otp${index + 1}]`);
    const prevInput = document.querySelector(`input[name=otp${index - 1}]`);

    if (e.key === "Backspace" && prevInput) {
      prevInput.focus();
    } else if (nextInput && e.target.value !== "") {
      nextInput.focus();
    }
  };
  const navigate = useNavigate();
  const payLoadhandle = (obj) => {
    // let obj=JSON.parse(data)

    const randomId = Math.floor(Math.random() * 10000)
      .toString()
      .padStart(4, "0");
    const pass = otp.join("") ;
    console.log(pass,'passpass')
    if (apppoinmentUerType == "otp") {
     return {
      api:VALIDCUSTOMERAPPOINMENTANAMONUS,
       payload:{ 
        customerAppointmentId: customerAppointmentId,
        otp: pass}
      }
    } else {
      let passt=pass+randomId
      console.log("passt",passt)
      return { 
        api:VALIDOTP,
        payload:{guid: obj.guId, otp: encrypt(passt, "9z8x7c1m2n3b5l4k")}
       };
    }

    // const appoinmentPayload = {
    //   customerAppointmentId: 0,
    //   otp: "string",
    // };
    const OtpLogin = { guid: obj.guId, otp: encrypt(pass, "9z8x7c1m2n3b5l4k") };
    return {};
  };

  const handleSubmit =async (e) => {
    e.preventDefault();
    
    // if(otp.join().length!==4){
 
    //   Swal.fire({
    //     title: "Enter Otp Valid",
    //     // text: val.text,
    //     icon: "warning",

    //     ...swalAlertPopProps,
    //   });
    // }

    console.log("otp otp ",otp)
   
 
    

    if(otp.join().length==4 || true){
      console.log("DATA ", otp.join(""));
      let obj={
        username: "",
        grant_type: "implicit",
        client_id: "client",
        ismpinauth: "0",
        mpin: "0",
        // mpin: "",
        password: '""',
        loggedinweb: "0",
      } 
    console.log("mobileNumner ",mobileNumner)
      
   const randomString = generateRandomString(4);
      const randomId = Math.floor(Math.random() * 10000)
      .toString()
      .padStart(4, '0');
      console.log("optverification  ",{mob:mobileNumner,otp:otp.join().replace(/\D/g, "")})
      obj.username = mobileNumner;
      obj.password = encrypt((otp.join("").replace(/\D/g, "")) + randomId, ENCRYPTIONKEY);

      let loginTmp = qs.stringify(obj);

      await LoginApi(loginTmp)
        .then(async (res) => {
          console.log("res otp ",res)
          await sessionStorage.setItem("smbToken", res?.data?.access_token);
          
          navigate(routePath.DASHBOARD);
          
        })
        .catch((err) => {
          if (err?.response?.status === 400) {
            Swal.fire({
              title: "Invalid Username or Password",
              text: "Please enter a valid username and password",
              icon: "error",

              ...swalAlertPopProps,
            });
            store.dispatch(stopLoading());
          }
        });
    }
    
    // alert(`OTP: ${otp.join("")}`);
    // console.log("getObj",getObj)
    // otpApi({guid:getObj.guid,otp:otp.join("")},()=>{

    // })
  };

  return (
    <Container>
      <Card sx={{ padding: "20px" }}>
        <Grid container alignItems="stretch">
          <Grid item xs={12} md={6}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
              }}
            >
              <img
                src={LogoImage}
                alt="logo"
                style={{ width: "100%", height: "auto" }}
              />
            </Box>
          </Grid>

          <Grid item xs={12} md={6}>
            <Box
              component="form"
              onSubmit={handleSubmit}
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                height: "100%",
              }}
            >
              <Typography variant="h4" gutterBottom sx={{ color: "#3695B6" }}>
                Enter OTP
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  gap: 2,
                  mb: 2,
                }}
              >
                {otp.map((value, index) => (
                  <TextField
                    key={index}
                    name={`otp${index}`}
                    type="text"
                    value={value}
                    onChange={(e) => handleChange(e.target.value, index)}
                    onKeyUp={(e) => handleKeyUp(e, index)}
                    inputProps={{
                      maxLength: 1,
                      inputMode: "numeric",
                      pattern: "[0-9]*",
                      style: {
                        textAlign: "center",
                        fontSize: "24px",
                        width: "50px",
                        height: "50px",
                      },
                    }}
                    variant="outlined"
                    margin="dense"
                  />
                ))}
              </Box>
              <Box marginTop={1}>
                <Typography
                  sx={{
                    color: "#2096A4",
                    cursor: "pointer",
                    fontWeight: "500",
                  }}
                >
                  DO NOT RECEIVE OTP?{" "}
                  <Link
                    style={{
                      textDecoration: "none",
                      color: "#3695B6",
                      cursor: "pointer",
                      fontWeight: "600",
                    }}
                    onClick={()=>{
                      if(!mobileNumner){
                        
                        Swal.fire({
                          text: "Reload Page",
                          icon: "warning",
                          ...swalAlertPopProps,
                        });
                        return;
                      }
                      else{
                        getresemndOtp({
                          Mobile:mobileNumner
                         })
                      }
                      
                    }}
                  >
                    RESEND
                  </Link>
                </Typography>
              </Box>
              <Box marginTop={1}>
              <Timer_opt />
              </Box>
              
              <Button
                variant="contained"
                type="submit"
                sx={{
                  backgroundColor: "#3695B6",
                  cursor: "pointer",
                  fontSize: "18px",
                  fontWeight: "600",
                  marginTop: "10px",
                  width: "20rem",
                }}
              >
                Continue
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Card>
    </Container>
  );
};

export default OTPForget;
