import { USERMASTER, VALIDCUSTOMERAPPOINMENTANAMONUS, VALIDOTP } from "../../utils/api";
import axios from "../../utils/axios"
import { Toast } from "../../utils/commonUtils";

export const otpApi=async(payload,callback)=>{ 
    await axios
    .post (payload.api,payload.payload)
    .then((res) => {
      console.log("res search ", res);
      
      if(res?.data){
        callback(res.data) 
        Toast.fire({
          icon: "success",
          title: "Appointment booked succesfully.",
        });
      }
      else{
        Toast.fire({
          icon: "error",
          title: res?.data?.message
        }); 
      }
    })
    .catch((err) => {
      console.log(err);
    });
  
}