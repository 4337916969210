import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import CloseIcon from "@mui/icons-material/Close";

import {
  Button,
  Modal,
  TextField,
  RadioGroup,
  Radio,
  FormControlLabel,
} from "@mui/material";

export default function DiscountModal({
  open,
  handleClose,
  bill,
  setBill,
  setOpen,
}) {

  const [data,setData]=React.useState({
    discount:"",
    discountType:"Amount",
    paybill:""
  })
  




























  const limitAmountAccept={
    Amount:(val,value)=>{
      return value<val
      
    },
    Percent:(per,value)=>{
      

      return 100>=value
    }
  }
  const discountType_call={
    Amount:(val,value)=>{
      return val - Number(value)
    },
    Percent:(val,value)=>{
      return val - (val*(Number(value) / 100) );
    }
  }
  React.useEffect(()=>{
    if(bill){
      console.log("billdata==",bill.amount," ",bill.discount)
      setData({...bill,
        paybill:bill.paybill
      })
    }
    
  },[bill])
  
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          background: "white",
          width: "40%",
          position: "absolute",
          color: (theme) => theme.palette.primary.main,
          left: "30%",
          top: "30%",
        }}
      >
        <CloseIcon
          fontWeight="400"
          sx={{ fontSize: "30px", float: "right", m: "10px", cursor: "pointer" }}
          onClick={() => {
            setOpen(false);
          }}
        />
        <Typography
          component="h1"
          variant="h5"
          textAlign="center"
          sx={{
            p: "10px",
            boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
          }}
          color="primary"
        >
          Discount
        </Typography>
        <Box
          sx={{
            p: "10px",
          }}
        >
          <Grid container>
            <Grid item xs={12}>
              <Box
                sx={{
                  border: "1px solid grey",
                  padding: "7px 14px",
                  borderRadius: "4px",
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: "4px",
                }}
              >
                <Typography
                  textAlign="left"
                  sx={{
                    color: (theme) => theme.palette.primary.main,
                    fontWeight: "550",
                  }}
                >
                  Bill Amount
                </Typography>

                <Typography
                  textAlign="right"
                  sx={{
                    color: (theme) => theme.palette.secondary.main,
                    fontWeight: "600",
                  }}
                >
                  {bill.amount
                    ? bill.amount
                    : (function () {
                      let sum = 0;
                      bill?.counterSalesChild?.forEach((item) => {
                        sum += item.amount;
                      });

                      return sum;
                    })()}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Typography
                textAlign="left"
                sx={{ pt: "10px" }}
                onClick={() => setOpen(true)}
              >
                Discount Type
              </Typography>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                value={data.discountType}
                onChange={(e) => {



                  setData({
                    ...data,discountType:e.target.value,
                      discount: 0,
                      paybill:bill?.amount
                  })
                  // setBill({
                  //   ...bill,
                  //   discountType: e.target.value,
                  //   discount: 0,
                  // });
                }}
              >
                <FormControlLabel
                  value="Amount"
                  selected={true}
                  control={<Radio />}
                  label="Amount"
                />
                <FormControlLabel
                  value="Percent"
                  control={<Radio />}
                  label="Percentage"
                />
              </RadioGroup>
              <TextField
                fullWidth
                id="BrifeAddress"
                size="small"
                placeholder="enter value"
                sx={{
                  width: "100%",
                  color: (theme) => theme.palette.secondary.main,
                  fontWeight: "600",
                }}
                name="BrifeAddress"
                value={data.discount ? data.discount : ""}
                onChange={(e) => {
                  let val = bill.paybill 
                  let per ;
                  console.log("billData ",bill)
                  
                  per=discountType_call[data?.discountType](val,e.target.value)
                    
                  if(limitAmountAccept[data?.discountType](val,e.target.value)){
                    setData((prev)=>{
                      return {
                        ...prev,
    
                        discount: e.target.value, 
                        paybill:per
    
                      }
                    }
                      )
                  }
                  
                  // if (
                  //   bill.discountType === "Percent" &&
                  //   e.target.value <= 100
                  // ) {
                  //   let per = val - (Number(e.target.value) / 100) * val;

                  //   setBill({
                  //     ...bill,

                  //     ...(!isNaN(bill.paybill) ? { paybill: per } : {}),
                  //     discount: e.target.value,
                  //   });
                  // }
                  // if (bill.discountType === "Amount" && e.target.value <= val) {
                  //   let per = val - Number(e.target.value);

                  //   setBill({
                  //     ...bill,
                  //     ...// bill.paybill !== NaN
                  //     (!isNaN(bill.paybill) ? { paybill: per } : {}),
                  //     discount: e.target.value,
                  //   });
                  // }
                }}
              />
              <Box
                sx={{
                  border: "1px solid grey",
                  padding: "7px 14px",
                  borderRadius: "4px",
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: "4px",
                }}
              >
                <Typography
                  sx={{
                    color: (theme) => theme.palette.primary.main,
                    fontWeight: "600",
                  }}
                >
                  Payable Amount
                </Typography>
                <Typography
                  sx={{
                    color: (theme) => theme.palette.secondary.main,
                    fontWeight: "600",
                  }}
                >
                  {data.paybill !== null && data.paybill !== undefined
                    ? data.paybill
                    : "t"}
                </Typography>
              </Box>
            </Grid>
          </Grid>
          <Button
            variant="contained"
            sx={{
              width: "100%",
              mt: "10px",
            }}
            onClick={() => {
              setOpen(false);
              setBill(data)
            }}
          >
            {" "}
            Save
          </Button>
        </Box>
      </Box>
    </Modal>
  );
}
