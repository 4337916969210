import {
  Box,
  Button,
  Container,
  Grid,
  Link,
  Stack,
  Typography,
  TextField,
} from "@mui/material";
import { useNavigate } from "react-router";
import PlayStoreLogo from "../../assets/img/google-play-badge.svg";
import TopBanner from "../../assets/img/top_banner.jpeg";
import Bookmark from "../../assets/img/bookmark.png";
import { Footer } from "./footer";
import { useMediaQuery } from "@mui/material";
import "./landingPage.css";
import TurnedInNotIcon from "@mui/icons-material/TurnedInNot";
// import { Header } from "./header";
import { RestaurantPage } from "./restaurantPage";
import { HeaderLandingPage } from "./header";
import { routePath } from "../../constants/routePath";
import { Description } from "@mui/icons-material";

// logo image start
// services images start
import salon from "../../assets/img/services/Salon.jpg";
import mensParlour from "../../assets/img/services/SalonMale.jpg";
import beautyParlour from "../../assets/img/services/Beauty.jpg";
import unisex from "../../assets/img/services/unisex.jpeg";
import wellness from "../../assets/img/services/Wellness.jpg";
import spa from "../../assets/img/services/Spa.jpg";
import massage from "../../assets/img/services/Massage.jpg";
import tattoo from "../../assets/img/services/Tatoo.jpg";
import mehendi from "../../assets/img/services/Mehendi.jpg";
import skinCare from "../../assets/img/services/SkinCare.jpg";
import PanchKarma from "../../assets/img/services/Panchkarma.jpg";
import MakeUpHairStyle from "../../assets/img/services/Makeup.jpg";
// services images end

// social media icons start
import instagram from "../../assets/img/social/instagram.png";
import facebook from "../../assets/img/social/facebook.png";
import linkedIn from "../../assets/img/social/linkedin.png";
// social media icons end

import businessOne from "../../assets/img/banner-bottom.jpeg";
import businessTwo from "../../assets/img/portrait_black.jpeg";
import { useEffect, useState } from "react";
import { cityName, cityNameCll } from "./landingPageApi";
import LocationModal from "./locationModal";
import { useDispatch, useSelector } from "react-redux";
import { setNavBarInputFields } from "../../app/store/reducer";
import { NavBar } from "../../common/landingPage/navbar";

const LandingPage = () => {
  const navigate = useNavigate();
  const navBarInputFields = useSelector(
    (state) => state.reducer.navBarInputFields
  );
  const dispatch=useDispatch();
  const whyElegantArr = [
    {
      title: "Availability",
      description:
        "Some parlours might have limited slots available, especially during peak hours or busy seasons. Searching quickly increases your chances of securing an appointment at your preferred time.",
    },
    {
      title: "Urgency",
      description:
        "You might have an upcoming event or occasion for which you need beauty services. Searching quickly allows you to find a parlour that can accommodate your needs within the timeframe you have.",
    },
    {
      title: "Location",
      description:
        "If you are in a new area or traveling, you may need to find a nearby parlour quickly for convenience Elegant Prime application provide you geographical location and show GPS direction to you.",
    },
    {
      title: "Emergency Situations",
      description:
        "Sometimes, you might have an unexpected beauty emergency, such as a broken nail or a last-minute haircut requirement. Searching quickly helps you find a parlour that can address your immediate needs.",
    },
    {
      title: "Comparison",
      description:
        "Searching quickly allows you to compare different parlours based on their services, reviews, and prices to make an informed decision.",
    },
    {
      title: "Rating & Feedback",
      description:
        "You can check credibility of Parlour through feedback and rating registered on application.",
    },
    {
      title: "Offers",
      description:
        "Application will display To customer if any offer has given by parlour.",
    },
    {
      title: "Contact Number",
      description:
        "Application it self provide you all parlour numbers to Call and confirm availability of slots for direct communication.",
    },
    {
      title: "Rare available Products",
      description:
        "Application will provide you the list of parlours where your favourite & rare available product which is not available on stores or medical shops.",
    },
  ];
  const whyParlourArr = [
    {
      title: "Personal Grooming",
      description:
        "Beauty salons provide essential services like haircuts, hair styling, manicures, pedicures, facials, and waxing, helping individuals maintain their personal grooming standards.",
    },
    {
      title: "Self-Confidence",
      description:
        "Looking good often translates to feeling good. Beauty salons can boost self-confidence by helping individuals achieve the appearance they desire, which can positively impact their social and professional interactions.",
    },
    {
      title: "Special Occasions",
      description:
        "Beauty salons are often visited before special occasions such as weddings, parties, and job interviews. Clients seek professional styling and makeup services to ensure they look their best for these important events.",
    },
    {
      title: "Expertise and Advice",
      description:
        "Many beauty treatments offer relaxation and pampering experiences, providing clients with a break from their daily routines and stresses. Services like massages, facials, and spa treatments can promote relaxation and mental well-being.",
    },
    {
      title: "Relaxation and Pampering",
      description:
        "Many beauty treatments offer relaxation and pampering experiences, providing clients with a break from their daily routines and stresses. Services like massages, facials, and spa treatments can promote relaxation and mental well-being.",
    },
    {
      title: "Community and Socialization",
      description:
        "Beauty salons serve as social hubs where clients can interact with each other and form connections. They provide a space for conversation and camaraderie, fostering a sense of community.",
    },
    {
      title: "Skill Enhancement",
      description:
        "Beauty salons offer training and skill enhancement opportunities for aspiring beauty professionals. They serve as platforms for learning and development within the beauty industry.",
    },
  ];
  const servicesArr = [
    {
      title: "Salon",
      image: salon,
    },
    {
      title: "Men's Parlour",
      image: mensParlour,
    },
    {
      title: "Beauty Parlour",
      image: beautyParlour,
    },
    {
      title: "Unisex Parlour",
      image: unisex,
    },
    {
      title: "Wellness Center",
      image: wellness,
    },
    {
      title: "Spa",
      image: spa,
    },
    {
      title: "Massage Center",
      image: massage,
    },
    {
      title: "Menandi Artist",
      image: mehendi,
    },
    {
      title: "Tattoo Art Studio",
      image: tattoo,
    },
    {
      title: "Skincare",
      image: skinCare,
    },
    {
      title: "Panchkarm",
      image: PanchKarma,
    },
    {
      title: "Makeup & Hairstyle Artist",
      image: MakeUpHairStyle,
    },
  ];
  const businessOpsArr = [
    {
      title: "Financial Management",
      description:
        "Tracking revenue, expenses, and profits helps in maintaining financial stability. It allows you to identify areas of high expenditure or low revenue and make necessary adjustments to improve profitability.",
    },
    {
      title: "Customer Details and History",
      description:
        "Keeping track of customer visits, preferences, and feedback helps in understanding customer behaviour and improving services to meet their needs better. This can lead to increased customer satisfaction and give them discount and offers.",
    },
    {
      title: "Inventory Management",
      description:
        "Monitoring inventory levels and tracking usage helps in preventing stockouts or overstock situations. It ensures that you have the right products available for your customers when they need them, thereby maximizing sales opportunities.",
    },
    {
      title: "Employee Payroll",
      description:
        "Tracking employee schedules, productivity, and customer interactions helps in evaluating employee performance & Payroll. It enables you to identify top performers, provide necessary training or support to underperforming staff, and ensure optimal staffing levels to meet customer demand.",
    },
  ];
  const businessOpsArrTwo = [
    {
      title: "Marketing Effectiveness",
      description:
        "Monitoring the performance of marketing campaigns and promotions helps in assessing their effectiveness in attracting customers and driving sales. It allows you to allocate resources more efficiently to strategies that yield the highest returns.",
    },
    {
      title: "Business Growth",
      description:
        "By analysing key performance indicators (KPIs) and trends over time, tracking can help in identifying opportunities for growth and expansion. It enables you to make informed decisions about scaling your business operations.",
    },
    {
      title: "Display offers",
      description:
        "Display offers on Elegant prime portal to show customers, It will help promotion of services to attract customers to visit shop.",
    },
    {
      title: "Share Messages",
      description:
        "Every parlour/salon wish to send discount and offer, festival wishes messages to customers, Elegant prime provide you to draft and send messages.",
    },
    {
      title: "Contact Number and Address",
      description:
        "Parlour or salon Contact number and Address with Geographical location will get display on Elegant portal.",
    },
    {
      title: "Rate Card",
      description:
        "Every customer need rate card of beauty services before visit parlour, Elegant parlour showing these details on mobile app to customers.",
    },
  ];


  const [cityName,asignCityName]=useState("")
const [locationModel,setLocationModal]=useState(false)
const [currentLocation, setCurrentLocation] = useState("")
  useEffect(()=>{
    cityNameCll((data)=>{
      asignCityName(data?.city)
      setCurrentLocation(data?.city)
      dispatch(setNavBarInputFields({location:data?.city}))
    })
  },[])
  useEffect(()=>{
    setLocationModal(false)
  },[navBarInputFields.location])
  return (
    <Box container pt={4}>
      {/* <Header/> */}
      {/* <HeaderLandingPage /> */}
      {/* <LocationModal searchBarValue={cityName} currentLocation={currentLocation} open={locationModel} setOpen={setLocationModal}/>
      <NavBar navBarInputFields={navBarInputFields} setLocationModal={()=>{
        setLocationModal((prev)=>{
          return !prev
        })
      }}  /> */}

      {/* hero section start*/}
      <Box>
        <Container
              >
          <Grid container alignItems={"center"}>
            <Grid item md={6}>
              <Box
                sx={{
                  mb: { lg: 0, xs: 5 },
                  textAlign: { lg: "start", xs: "center" },
                }}
              >
                <Typography
                  className="fontNewsreader"
                  sx={{
                    fontSize:{xs:'2rem', sm:'3.5rem'}
                  }}
                  fontWeight={"600"}
                  mb={3}
                  lineHeight={"1"}
                  color={"#212529"}
                >
                  Search desired parlour/salon services near by.
                </Typography>
                <Typography
                  className="mulish"
                  fontSize={"20px"}
                  fontWeight={"400"}
                  color={"#6c757d "}
                  mb={5}
                >
                  Elegant Prime will help you to search list of parlours and
                  salons with contact Number, address, geographical location,
                  service rate card, offers, rating and feedback registered on
                  Elegant prime.
                </Typography>
                <Stack
                  sx={{
                    flexDirection: { lg: "row", xs: "column" },
                    alignItems: "center",
                  }}
                >
                  <a
                    class="me-lg-3 mb-4 mb-lg-0"
                    href="https://play.google.com/store/apps/details?id=com.mybills.elegantprime"
                    target="_blank"
                  >
                    <Box
                      height={"3rem"}
                      component={"img"}
                      src={PlayStoreLogo}
                      alt="playstorelogo"
                    />
                  </a>
                </Stack>
              </Box>
            </Grid>
            <Grid item md={6} >
              <Box
                sx={{ borderRadius: "10px" }}
                width={"100%"}
                component={"img"}
                src={TopBanner}
                alt="playstorelogo"
              />
            </Grid>
          </Grid>
        </Container>
      </Box>
      {/* hero section end */}

      {/* What is Elegant Prime start */}
      <Box sx={{ py: "5rem", backgroundColor: "#fff" }}>
        <Container sx={{ px: 5 }}>
          <Box textAlign={"center"}>
            <Typography
              className="fontNewsreader"
              fontWeight={"600"}
              lineHeight={"1.2"}
              color={"#212529"}
              sx={{
                fontSize:{xs:'2rem', sm:'3.5rem'}
              }}
            >
              What is Elegant Prime?
            </Typography>
            <Typography
              className="mulish"
              fontSize={"20px"}
              fontWeight={"400"}
              color={"#6c757d "}
              mb={"3rem"}
            >
              Searching quickly for a beauty parlour/salon can be important for
              several reasons
            </Typography>
          </Box>

          <Grid container sx={{px:{sm:'60px', xs:'0px'}}}>
            {whyElegantArr.map((item, index) => (
              <Grid key={index} item md={4} mb={5} width={'100%'}>
                <Stack alignItems={"center"} justifyItems={"center"} gap={"1"}>
                  {/* <TurnedInNotIcon
                    sx={{ color: "#9f1ae2", fontSize: "75px" }}
                  /> */}
                  <Box
                    sx={{ borderRadius: "10px" }}
                    width={"37px"}
                    height={"40px"}
                    component={"img"}
                    src={Bookmark}
                    alt="playstorelogo"
                  />
                  <Typography
                    className="kanit"
                    fontSize={"22px"}
                    fontWeight={"600"}
                    textAlign={"center"}
                    color={"#212529"}
                    margin={"5px 0px"}
                  >
                    {item.title}
                  </Typography>
                  <Typography
                    className="mulish"
                    fontSize={"14px"}
                    fontWeight={"400"}
                    color={"#6c757d "}
                    textAlign={"center"}
                  >
                    {item.description}
                  </Typography>
                </Stack>
              </Grid>
            ))}
          </Grid>
        </Container>
      </Box>
      {/* What is Elegant Prime end */}

      {/* query start */}
      <Box class="cta">
        <div class="cta-content">
          <Container sx={{ paddingLeft: "77px !important" }}>
            <Typography
              class="fontNewsreader querytitle"
              sx={{ fontSize: "48px", margin: "0px !important" }}
            >
              Please call Us In case of Any Query <br />
              or information <a href="tel:9209054709">9209054709</a>.
            </Typography>

            <Link
              href="https://play.google.com/store/apps/details?id=com.mybills.elegantprime"
              target="_blank"
            >
              <Button
                className="mulish"
                variant="outlined"
                sx={{
                  px: "1.5rem",
                  py: "1rem",
                  borderRadius: "3rem",
                  borderColor: "#212529",
                  color: "#212529",
                  "&:hover": {
                    backgroundColor: "#212529",
                    borderColor: "#212529",
                    color: "#fff",
                  },
                }}
              >
                Download for free
              </Button>
            </Link>
          </Container>
        </div>
      </Box>
      {/* query end */}

      {/* why parlour start */}
      <Box sx={{ py: "5rem", backgroundColor: "#fff" }}>
        <Container sx={{ px: 5 }}>
          <Box textAlign={"center"}>
            <Typography
              className="fontNewsreader"
              sx={{
                fontSize:{xs:'2rem', sm:'3.5rem'}
              }}
              fontWeight={"600"}
              lineHeight={"1.2"}
              mb={5}
            >
              Why Everyone need parlours?
            </Typography>
            {/* <Typography
              className="mulish"
              fontSize={"1.5rem"}
              fontWeight={"400"}
              color={"#6c757d "}
              mb={"3rem"}
            >
              Searching quickly for a beauty parlour/salon can be important for
              several reasons
            </Typography> */}
          </Box>

          <Grid
            container
            sx={{px:{sm:'60px', xs:'0px'}}}
            justifyContent={"center"}
            padding={"0px !important"}
          >
            {whyParlourArr.map((item, index) => (
              <Grid
                key={index}
                item
                md={3}
                justifyContent={"center"}
                px={"24px"}
                mb={5}
              >
                <Stack alignItems={"center"} justifyItems={"center"} gap={1}>
                  <Box
                    sx={{ borderRadius: "10px" }}
                    width={"37px"}
                    height={"40px"}
                    component={"img"}
                    src={Bookmark}
                    alt="playstorelogo"
                  />
                  <Typography
                    className="kanit"
                    fontSize={"22px"}
                    fontWeight={"600"}
                    textAlign={"center"}
                    color={"#212529"}
                    margin={"5px 0px"}
                  >
                    {item.title}
                  </Typography>
                  <Typography
                    className="mulish"
                    fontSize={"14px"}
                    fontWeight={"400"}
                    color={"#6c757d "}
                    textAlign={"center"}
                  >
                    {item.description}
                  </Typography>
                </Stack>
              </Grid>
            ))}
          </Grid>
        </Container>
      </Box>
      {/* why parlour  end */}

      {/* services start */}
      <Box sx={{ py: "5rem" }}>
        <Container sx={{ px: 5 }}>
          <Box textAlign={"center"}>
            <Typography
              className="fontNewsreader"
              sx={{
                fontSize:{xs:'2rem', sm:'3.5rem'}
              }}
              fontWeight={"600"}
              lineHeight={"1.2"}
              mb={5}
            >
              You can search below services on Elegant Prime
            </Typography>
            {/* <Typography
              className="mulish"
              fontSize={"1.5rem"}
              fontWeight={"400"}
              color={"#6c757d "}
              mb={"3rem"}
            >
              Searching quickly for a beauty parlour/salon can be important for
              several reasons
            </Typography> */}
          </Box>

          <Grid
            container
            rowSpacing={3}
            columnSpacing={"50px"}
          >
            {servicesArr.map((item, index) => (
              <Grid key={index} item md={3} xs={12} sm={6}>
                <Stack
                  alignItems={"start"}
                  justifyContent={"space-between"}
                  sx={{
                    height: "308px",
                    backgroundColor: "#fff",
                    border: "1px solid #ededed",
                    borderRadius: "10px",
                  }}
                >
                  <Typography
                    margin={"12px 0px 0px 12px"}
                    className="kanit"
                    fontSize={"18px"}
                  >
                    {item.title}
                  </Typography>
                  <Box
                    sx={{
                      objectFit: "cover",
                      borderRadius: "0px 0px 10px 10px",
                    }}
                    width={"100%"}
                    height={"200px"}
                    component={"img"}
                    src={item.image}
                    alt={item.title}
                  />
                </Stack>
              </Grid>
            ))}
          </Grid>
        </Container>
      </Box>
      {/* services end */}

      {/* Busiess Operation start */}
      <Box sx={{ py: "5rem", backgroundColor: "#fff" }}>
        <Container sx={{ px: 5 }}>
          <Box textAlign={"center"}>
            <Typography
              className="fontNewsreader"
              sx={{
                fontSize:{xs:'2rem', sm:'3.5rem'}
              }}
              fontWeight={"600"}
              lineHeight={"1.2"}
            >
              Elegant Prime is a Business Operation Application for
              Parlours/Salons
            </Typography>
            <Typography
              className="mulish"
              fontSize={"1.5rem"}
              fontWeight={"400"}
              color={"#6c757d"}
              mb={"3rem"}
            >
              Tracking parlour business operations is crucial for several
              reasons
            </Typography>
          </Box>
          <Box sx={{px:{sm:'60px', xs:'0px'}}}>
            <Grid container alignItems={"center"} spacing={2} sx={{flexDirection:{md:'row', xs:'column-reverse'}}}>
              <Grid item md={7}>
                <Grid container>
                  {businessOpsArr.map((item, index) => (
                    <Grid key={index} item md={6} px={2} mb={5}>
                      <Stack
                        alignItems={"center"}
                        justifyItems={"center"}
                        gap={"10px"}
                      >
                        <Box
                          sx={{ borderRadius: "10px" }}
                          width={"37px"}
                          height={"40px"}
                          component={"img"}
                          src={Bookmark}
                          alt="playstorelogo"
                        />
                        <Typography
                          className="kanit"
                          fontSize={"22px"}
                          fontWeight={"600"}
                          textAlign={"center"}
                          color={"#212529"}
                          lineHeight={"1"}
                        >
                          {item.title}
                        </Typography>
                        <Typography
                          className="mulish"
                          fontSize={"14px"}
                          fontWeight={"400"}
                          color={"#6c757d "}
                          textAlign={"center"}
                        >
                          {item.description}
                        </Typography>
                      </Stack>
                    </Grid>
                  ))}
                </Grid>
              </Grid>
              <Grid item md={5}>
                <Box
                  sx={{ borderRadius: "10px" }}
                  width={"100%"}
                  component={"img"}
                  src={businessTwo}
                  alt="playstorelogo"
                />
              </Grid>
            </Grid>
            <Grid container alignItems={"center"} spacing={2}>
              <Grid item md={5}>
                <Box
                  sx={{ borderRadius: "10px" }}
                  width={"100%"}
                  component={"img"}
                  src={businessOne}
                  alt="playstorelogo"
                />
              </Grid>
              <Grid item md={7}>
                <Grid container>
                  {businessOpsArrTwo.map((item, index) => (
                    <Grid key={index} item md={6} px={2} mb={5} mt={5}>
                      <Stack
                        alignItems={"center"}
                        justifyItems={"center"}
                        gap={"10px"}
                      >
                        {/* <TurnedInNotIcon
                    sx={{ color: "#9f1ae2", fontSize: "75px" }}
                  /> */}
                        <Box
                          sx={{ borderRadius: "10px" }}
                          width={"37px"}
                          height={"40px"}
                          component={"img"}
                          src={Bookmark}
                          alt="playstorelogo"
                        />
                        <Typography
                          className="kanit"
                          fontSize={"22px"}
                          fontWeight={"600"}
                          textAlign={"center"}
                          color={"#212529"}
                          lineHeight={"1"}
                        >
                          {item.title}
                        </Typography>
                        <Typography
                          className="mulish"
                          fontSize={"14px"}
                          fontWeight={"400"}
                          color={"#6c757d "}
                          textAlign={"center"}
                        >
                          {item.description}
                        </Typography>
                      </Stack>
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Box>
      {/* Busiess Operation end */}

      {/* social media section start */}
      <Box
        sx={{
          height: "271px",
          background: "linear-gradient(45deg, #3695B6, #9f1ae2)",
          // width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          gap: "16px",
          // padding: "20px",
          textAlign: "center",
        }}
      >
        <Typography
          className="kanit"
          fontSize={"32px"}
          fontWeight={"600"}
          color={"#fff"}
        >
          Follow us on social media
        </Typography>
        <Stack direction="row" spacing={2}>
          <Box component="a" href="https://www.facebook.com/share/pvA1uawwosLT6exX/?mibextid=qi2Omg" target="_blank">
            <Box
              component="img"
              src={facebook}
              alt="Facebook"
              sx={{ width: "48px", height: "40px", objectFit: "contain" }}
            />
          </Box>
          <Box component="a" href="https://www.instagram.com/salontech_elegant?igsh=NGJpd3c1dHQzNGIy" target="_blank">
            <Box
              component="img"
              src={instagram}
              alt="instagram"
              sx={{ width: "48px", height: "40px", objectFit: "contain" }}
            />
          </Box>
          <Box component="a" href="https://www.linkedin.com/company/elegant-prime/about/" target="_blank">
            <Box
              component="img"
              src={linkedIn}
              alt="linkedIn"
              sx={{ width: "48px", height: "40px", objectFit: "contain" }}
            />
          </Box>
        </Stack>
      </Box>
      {/* social media section end */}

      {/* copyright section start */}
      <Box
        sx={{
          height: "146px",
          backgroundColor: "#212529",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          gap: "16px",
          padding: "20px",
          textAlign: "center",
        }}
      >
        <Typography className="kanit" fontSize={"14px"} color={"#ffffff80"}>
          © Powered by{" "}
          <Box
            component="a"
            href="https://www.posiviewtech.com"
            sx={{
              textDecoration: "none",
              color: "#ffffff80",
              cursor: "pointer",
              "&:hover": {
                color: "#ffffff",
              },
            }}
          >
            Posiview Application Technologies Pvt. Ltd.
          </Box>{" "}
          2024. All Rights Reserved.
        </Typography>
        <Stack
          direction="row"
          spacing={2}
          component="ul"
          sx={{ padding: 0, margin: 0, listStyle: "none" }}
        >
          <Box component="li">
            <Typography
              sx={{
                textDecoration: "none",
                color: "#ffffff80",
                fontSize: "14px",
                cursor: "pointer",
                "&:hover": {
                  color: "#ffffff",
                },
              }}
              onClick={()=>navigate(routePath.PRIVACYPOLICY)}
            >
              Privacy
            </Typography>
          </Box>
          <Box
            component="li"
            sx={{ position: "relative", paddingLeft: "16px" }}
          >
            <Typography
              sx={{
                textDecoration: "none",
                color: "#ffffff80",
                fontSize: "14px",
                cursor: "pointer",
                "&:hover": {
                  color: "#ffffff",
                },
                "&::before": {
                  content: '"•"',
                  color: "#ffffff80",
                  position: "absolute",
                  left: 0,
                  top: "50%",
                  transform: "translateY(-50%)",
                  fontSize: "12px",
                },
              }}
              onClick={()=>navigate(routePath.TERMSOFSERVICE)}
            >
              Terms
            </Typography>
          </Box>
        
        </Stack>
      </Box>

      {/* copyright section end */}
      {/* <Footer /> */}
    </Box>
  );
};

export default LandingPage;
