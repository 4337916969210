import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/database";

const firebaseConfig = {
  //---------------------testing
  // apiKey: "AIzaSyClsUcxLVe__hG7_hGw1TDcPCvzoUar2Jw",
  // authDomain: "react-contact-d9799.firebaseapp.com",
  // projectId: "react-contact-d9799",
  // storageBucket: "react-contact-d9799.appspot.com",
  // messagingSenderId: "405633416740",
  // appId: "1:405633416740:web:ffa811fe058398da46e2b1",
  // ------------------------------production
  apiKey: "AIzaSyDYOMvGOkRS8_5Gp4KAEPMnn2BDyQWrUGk",
  authDomain: "showmybills-a4a99.firebaseapp.com",
  databaseURL:
    "https://showmybills-a4a99-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "showmybills-a4a99",
  storageBucket: "showmybills-a4a99.appspot.com",
  messagingSenderId: "90849097072",
  appId: "1:90849097072:web:af87c606a00d747a820bb5",
  measurementId: "G-ZECB0DRLR5",
};

// export const auth = firebase.auth();
// export default firebase;

firebase.initializeApp(firebaseConfig);

const fireDb = firebase.database().ref();
export default fireDb;
