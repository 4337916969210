import React, { useRef, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import DatePicker from "react-datepicker";
import EventIcon from "@mui/icons-material/Event";
import "react-datepicker/dist/react-datepicker.css";
import {
  displayDateFormat,
  downloadXLSXFile,
  getEndOfMonth,
  getFirstDateOfCurrentMonthPayloadFormate,
  getFirstDateOfMonth,
  getLastDateOfCurrentMonthPayloadFormat,
  payloadDateFormat,
  Toast,
} from "../../utils/commonUtils";
import { Divider, FormControlLabel, Switch, TextField } from "@mui/material";
import FileUploader from "./fileUploader";
import { AddOffersApi } from "./addOffersApi";
import { ModalHeader } from "../../common/modalHeader";
export default function AddOfferModal({ open, handleClose }) {
  const [SelectedEndDate, setSelectedEndDate] = useState(null);

  const [displayEndDate, setDisplayEndDate] = useState(displayDateFormat());

  const [SelectedStartDate, setSelectedStartDate] = useState(
    getFirstDateOfMonth()
  );
  const [endDate, setEndDate] = useState(
    getLastDateOfCurrentMonthPayloadFormat()
  );
  const [startDateFormat, setstartDateFormat] = useState(
      getFirstDateOfCurrentMonthPayloadFormate()
    );
    console.log(startDateFormat,'endDateendDate')

  const datePickerRef2 = React.useRef();

  const datePickerOnChangeEnd = (date) => {
    const dateStr = date.toLocaleDateString("en-GB", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    });
    const dateStr2 = date.toLocaleDateString("en-GB", {
      year: "numeric",
      month: "long",
      day: "2-digit",
    });
    const [month, day, year] = dateStr.split("/");
    const formattedDate = `${year}-${day.padStart(2, "0")}-${month.padStart(
      2,
      "0"
    )}`;
    let dateContainer = String(dateStr2).split(" ");
    var year2 = dateContainer[2];
    var month2 = dateContainer[1];
    var day2 = dateContainer[0];
    var formattedDate2 = day2 + "-" + month2 + "-" + year2;
    console.log("format date ", formattedDate);
    datePickerRef2.current.setOpen(true);
    setSelectedEndDate(date);
    setDisplayEndDate(formattedDate2);
    setEndDate(formattedDate);
  };

  const datePickerOnChange = (date) => {
    const dateStr = date.toLocaleDateString("en-GB", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    });
    const dateStr2 = date.toLocaleDateString("en-GB", {
      year: "numeric",
      month: "long",
      day: "2-digit",
    });
    const [month, day, year] = dateStr.split("/");
    const formattedDate = `${year}-${day.padStart(2, "0")}-${month.padStart(
      2,
      "0"
    )}`;
    let dateContainer = String(dateStr2).split(" ");
    var year2 = dateContainer[2];
    var month2 = dateContainer[1];
    var day2 = dateContainer[0];
    var formattedDate2 = day2 + "-" + month2 + "-" + year2;
    datePickerRef2.current.setOpen(true);

    setStartDate(formattedDate2);
    setstartDateFormat(formattedDate);
    setSelectedStartDate(date);
  };

  const [startDate, setStartDate] = useState(displayDateFormat());
  const datePickerRef = useRef();

  const openDatePicker = () => {
    if (datePickerRef.current) {
      datePickerRef.current.setOpen(true);
    }
  };
  const [selectedFile, setSelectedFile] = useState(null);
  const [message, setMessage] = useState("");
  const [checked, setChecked] = React.useState(true);
  const [status, setStatus] = useState("active");
  const [errors, setErrors] = useState({});

    // Validation function
    const validateForm = () => {
      const newErrors = {};
      if (!message) newErrors.message = "Message is required.";
      if (!selectedFile) newErrors.file = "File upload is required.";
  
      setErrors(newErrors);
      return Object.keys(newErrors).length === 0;
    }
  const handleChange = (event) => {
    const isChecked = event.target.checked;
    setChecked(isChecked);
    setStatus(isChecked ? "active" : "inactive");
  };

  const handleFileChange = (file) => {
    setSelectedFile(file);
  };
  const formData = new FormData();
  formData.append("File", selectedFile);
  formData.append("OfferInformation", message);
  formData.append("FromDate", startDate);
  formData.append("ToDate", displayEndDate);
  formData.append("Status", status);

  const handleSubmit= async()=>{
    if (!validateForm()) return;
    await AddOffersApi(
      formData
      )
        .then((res) => {
          if (res?.data?.succeeded) {
            Toast.fire({
              icon: "success",
              title: "Offer Added succesfully.",
            });
            handleClose()
            resetForm()
          } else {
            Toast.fire({
              icon: "error",
              title: "Something Went Wrong",
            });
          }
        })
  }
  const resetForm = () => {
    setSelectedFile(null);
    setMessage("");
    setStartDate(displayDateFormat());
    setDisplayEndDate(displayDateFormat());
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "52%",
            bgcolor: "background.paper",
            boxShadow: 24,
            
          }}
        >     




<ModalHeader    title={"Add Offer"} onClose={(data)=>{handleClose(data)}}/> 







<Box sx={{p: 4}}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "right",
              mb: 2,
            }}
          >
            <DatePicker
              hidden={true}
              selected={SelectedStartDate}
              maxDate={new Date()}
              onChange={datePickerOnChange}
              className="custom-datepicker"
              ref={datePickerRef}
              onClickOutside={() => {
                datePickerRef.current.setOpen(false);
              }}
            />

            <DatePicker
              hidden={true}
              selected={SelectedEndDate}
              maxDate={new Date()}
              onChange={datePickerOnChangeEnd}
              className="custom-datepicker"
              ref={datePickerRef2}
              onClickOutside={() => {
                datePickerRef2.current.setOpen(false);
              }}
            />
            <TextField
              fullWidth
              readonly
              size="small"
              onClick={openDatePicker}
              id="date"
              sx={{
                mt: 2,
              }}
              value={startDate + " To " + displayEndDate}
              label={
                <Box
                  sx={{
                    flex: 4,
                    color: "grey",
                    cursor: "pointer",
                    whiteSpace: "nowrap",
                  }}
                >
                  Date :
                </Box>
              }
              onChange={(e) => {}}
              InputProps={{
                endAdornment: (
                  <EventIcon
                    sx={{
                      color: (theme) => theme.palette.primary.main,
                      cursor: "pointer",
                    }}
                  />
                ),
              }}
            />
          </Box>
          <TextField
               onChange={(e) => setMessage(e.target.value)}
            sx={{ mb: 2 }}
            fullWidth
            label="message"
            value={message} 
            multiline
            rows={3}
            maxRows={5} // Grows up to 5 rows, and then it will scroll
            variant="outlined"
            error={!!errors.message}
            helperText={errors.message}
          />
          <Box
            display={"flex"}
            sx={{ mb: 2 }}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <FileUploader onFileSelect={handleFileChange}/>
            {errors.file && <Typography color="error">{errors.file}</Typography>}
            <FormControlLabel
              control={
                <Switch
                  checked={checked}
                  onChange={handleChange}
                  inputProps={{ "aria-label": "controlled" }}
                />
              }
              label="Offer Status"
              labelPlacement="start"
            />
          </Box>

          <Button onClick={handleSubmit} variant="contained" sx={{ float: "right" }}>
            Save
          </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
}
