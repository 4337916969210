import React, { useEffect, useState } from 'react'
import { getTermService } from './privacyTermApi';
import { Container } from '@mui/material';

export const TermService = () => {

    const [documentContent, setDocumentContent] = useState('');
    const getPrivacyPolicyData=()=>{
        getTermService((data)=>{
          console.log("Data privacy policy",data)
          setDocumentContent(data)
        })
       }


    useEffect(() => {
        getPrivacyPolicyData()
    }, [])
  return (
    <>
           <Container>
      <div
        dangerouslySetInnerHTML={{ __html: documentContent }}
      />

</Container>
    </>
  )
}
