import React from "react";
import { Box, Grid, Typography } from "@mui/material";

import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import { convertDateToCustomFormat } from "../../utils/commonUtils";
export default function SmbAccountReachargeCard({ item }) {
  return (
    <Grid item xs={12} sm={6} md={6} lg={4} sx={{ p: "20px" }}>
      <Box
        sx={{
          p: "10px",

          fontWeight: "bold",
          height: "100px",
          borderRadius: "10px",

          boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
        }}
      >
        <Grid container>
          <Grid item xs={12}
          sx={{
            
          }}>
            <Typography
              fontSize={"14px"}
              sx={{
                m: "0px 10px",
                color: (theme) => theme.palette.primary.main,
                fontWeight: "600",
              }}
            >
              Annual
            </Typography>  
          </Grid>
          <Grid item xs={6} sx={{
            display:'flex', 
            alignItems:'center',
            justifyContent:'space-between'
          }}>
            <Typography
              fontSize={"14px"}
              sx={{
                m: "0px 10px",
                color: (theme) => theme.palette.primary.main,
                // fontWeight: "600",
              }}
            >
              Date
            </Typography>  
            <Typography
              fontSize={"14px"}
              sx={{
                m: "0px 10px",
                color: (theme) => theme.palette.primary.main,
                // fontWeight: "600",
              }}
            >
              :
            </Typography>  
          </Grid>
          <Grid item xs={6} 
          
          >
            <Typography
              fontSize={"14px"}
              sx={{
                display:'flex',
                justifyContent:"left",
                alignItems:'center',
                m: "0px  2px",
                // fontWeight: "600",
              }}
            >
              {convertDateToCustomFormat(item?.invoiceDate?.split("T")[0])}
            </Typography>

          </Grid>
          <Grid item xs={6} sx={{
            display:'flex', 
            alignItems:'center',
            justifyContent:'space-between'
          }}>  
            <Typography
              fontSize={"14px"}
              sx={{
                m: "0px 10px",
                color: (theme) => theme.palette.primary.main,
                // fontWeight: "600",
                
              }}
            >
              Reference
            </Typography>
            <Typography
              fontSize={"14px"}
              sx={{
                m: "0px 10px",
                color: (theme) => theme.palette.primary.main,
                // fontWeight: "600",
              }}
            >
              :
            </Typography>  
          </Grid>
          <Grid item xs={6}>
            <Typography
              fontSize={"14px"}
              sx={{
                display:'flex',
                justifyContent:"left",
                alignItems:'center',
                m: "0px 2px",
                // fontWeight: "600",
              }}
            >
              {item?.reference}
            </Typography>
          </Grid>
          <Grid item xs={6} sx={{
            display:'flex', 
            alignItems:'center',
            justifyContent:'space-between'
          }}>
            <Typography
              fontSize={"14px"}
              sx={{
                m: "0px 10px",
                display: "flex",
                justifyContent:"left",
                alignItems: "center",
                color: (theme) => theme.palette.primary.main,
                // fontWeight: "600",
              }}
            >
              Amount (<CurrencyRupeeIcon fontSize="10px" />)
            </Typography>
            <Typography
              fontSize={"14px"}
              sx={{
                m: "0px 10px",
                color: (theme) => theme.palette.primary.main,
                // fontWeight: "600",
              }}
            >
              :
            </Typography>  
          </Grid>
          <Grid item xs={6} sx={{
            display:'flex',
            justifyContent:"left",
            alignItems:'center'
          }}>
          <CurrencyRupeeIcon fontSize="10px" />
            <Typography
              fontSize={"14px"}
              sx={{
                m: "0px 1px",
                // fontWeight: "600",
              }}
            >
              {item?.amount}
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </Grid>
  );
}
