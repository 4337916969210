import React, { useState, useEffect } from "react";
import { Box, Typography } from "@mui/material";

const Timer = () => {
  const [currentDate, setCurrentDate] = useState(new Date());
  const [timer, setTimer] = useState(new Date());

  useEffect(() => {
    const timerInterval = setInterval(() => {
      setTimer(new Date());
    }, 1000);

    return () => clearInterval(timerInterval);
  }, []);
  const formatDate = (date) => {
    const options = { day: "2-digit", month: "short", year: "numeric" };
    return date.toLocaleDateString("en-US", options).replace(/,/g, "");
  };

  console.log("formatDate(currentDate) ", formatDate(currentDate));
  return (
    <Box
      // bgcolor="primary.main"
      textAlign="center"
      sx={{
        // display:{xs:'flex', lg:'block'},
        display:'flex',
        alignItems:'center',
        gap:'10px',
        borderRadius: "8px",
        padding: "0px 10px",
      }}
      // boxShadow={4} // Adds shadow effect to the box
    >
      <Typography
        color="primary.main"
        variant="subtitle2"
        fontWeight={"bold"}
        margin={0}
        sx={
          {
            fontSize:{xs:'12px', lg:'0.875rem'}
          }
        }
      >
        {formatDate(currentDate)}
      </Typography>
      <Typography
        color="primary.main"
        variant="subtitle2"
        margin={0}
        fontWeight={"bold"}
        sx={{fontSize:{xs:'12px', lg:'0.875rem'}}}
      >
        {timer.toLocaleTimeString()}
      </Typography>
      
    </Box>
  );
};

export default Timer;
