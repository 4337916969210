
import { ADDCASHPAYMENT, ADDCUSTOMERADVANCE, GETALLADVANCEFOR, GETCOUNTERSALESCUSTOMERS, GETPAYMENTRECEIPT, GETPAYMENTRECEIPTS, GETPAYMENTXLSX, GETUNPAIDBILLS } from "../../utils/api"
import axios from "../../utils/axios";
import { Toast } from "../../utils/commonUtils";


export const getUnpaidUser=async(param)=>{
    const res=await axios.post(GETUNPAIDBILLS,param);
    return res;
}

export const getPaymentReceipt=async(param)=>{
    const res=await axios.post(GETPAYMENTRECEIPT,param);
    return res;
}

 
export const getPaymentXlsx=async(param)=>{
    const res=await axios.post(GETPAYMENTXLSX,param)
    return res;
}

//----LATEST API
export const getCounterSalesCustomers=async(param)=>{
    const res=await axios.post(GETCOUNTERSALESCUSTOMERS,param)
    return res;
}

export const AddCashPayment=async(param)=>{
    const res=await axios.post(ADDCUSTOMERADVANCE,param);
    return res;
}
export const addCustomerAdvance=   async(payload,callback)=>{
    await axios
    .post (ADDCUSTOMERADVANCE , payload)
    .then((res) => {
      console.log("res search ", res); 
        callback(res.data) 
        if(res.data?.succeeded)
        Toast.fire({
          icon: "success",
          title: "Appointment booked succesfully.",
        });
     
    })
    .catch((err) => {
      console.log(err)
})
}

export const getAllaAdvance=async(payload,callback)=>{
    await axios
    .post (GETALLADVANCEFOR , payload)
    .then((res) => {
      console.log("res search ", res);
      
      
        callback(res.data) 
        // Toast.fire({
        //   icon: "success",
        //   title: "Appointment booked succesfully.",
        // });
     
    })
    .catch((err) => {
      console.log(err);
    });
  }
  
