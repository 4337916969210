import { Box, Button, Typography, Modal, Grid } from "@mui/material";

import React, { useEffect, useState } from "react";
import MenuSideBar from "../../common/menuSideBar";
import { useNavigate } from "react-router";
import { routePath } from "../../constants/routePath";
import CloseIcon from "@mui/icons-material/Close";

import { useDispatch, useSelector } from "react-redux";
import {
  setCashCounterSaleSummaryPayload,
  setRateChangeHistoryPayload,
} from "../../app/store/reducer";

const SearchModal = ({ open, setOpen, setFilterBy }) => {
  const [CollectionTableItems, setCollectionTableItems] = useState([]);
  const serviceViceSearch = useSelector(
    (state) => state.reducer.serviceViceSearch
  );

  const [indexTableItem, setIndexTableItem] = useState();

  const navigate = useNavigate();
  const dispatch = useDispatch();
  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          background: "white",
          //   overflowY: "auto",
          width: {
            xs: "93vw",
            sm: "92%",
          },

          height: "calc(70vh+46px)",
          position: "absolute",
          left: "4%",
          top: "5%",
        }}
      >
        <Grid container>
          <Grid item xs={12}>
            <Typography
              sx={{
                color: (theme) => theme.palette.primary.main,
                textAlign: "center",
                fontSize: "24px",
                p: "10px",
                // p:
              }}
            >
              {" "}
              Search  <CloseIcon sx={{ float: "right", cursor: "pointer" }} onClick={() => setOpen(false)} />
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sm={3}
            md={3}
            lg={3}
            sx={{
              p: {
                xs: "0px",
                sm: "20px",
              },
              overflowY: "auto",

              height: { xs: "auto", sm: "70vh" },
              //   paddingRight: "4px",
              display: {
                // xs: "none",
                sm: "block",
              },
            }}
          >
            <MenuSideBar
              CollectionTableItems={CollectionTableItems}
              setCollectionTableItems={setCollectionTableItems}
              indexTableItem={indexTableItem}
              setIndexTableItem={setIndexTableItem}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={9}
            md={9}
            lg={9}
            sx={{
              p: "20px",

              overflowY: "auto",
              //   height: { xs: "calc(70vh-64.5px)", sm: "70vh" },
              height: { xs: "calc(70vh-64.5px)", sm: "70vh" },
              paddingRight: "4px",
              display: {
                //   xs: "none",
                sm: "block",
              },
            }}
          >
            <Grid container gap={0}>
              {CollectionTableItems[indexTableItem]?.tableItems.map(
                (item, index) => {
                  return (
                    <Grid
                      item
                      key={"tableitems" + index}
                      xs={12}
                      md={4}
                      sm={6}
                      lg={3}
                      //   xs={
                      //     currentDataArr?.length > 0 && addToCardWindow ? 12 : 12
                      //   }
                      //   sm={currentDataArr?.length > 0 && addToCardWindow ? 6 : 4}
                      //   md={currentDataArr?.length > 0 && addToCardWindow ? 4 : 3}
                      sx={{ p: "0px", textAlign: "center" }}
                    >
                      <Box
                        onClick={() => {
                          //
                          console.log("item search", item);
                          if (serviceViceSearch === "rate") {
                            dispatch(
                              setRateChangeHistoryPayload({
                                itemName: item?.itemDescription,
                                itemId: item?.id,
                                page: 1,
                                pageSize: 10,
                              })
                            );
                            // navigate(routePath.RATECHANGE);

                            setOpen(false)
                            setFilterBy("RateChangeItemHistory")
                          }

                          if (serviceViceSearch === "cash") {
                            // dispatch(
                            //   setRateChangeHistoryPayload({
                            //     itemId: item?.id,

                            //   })
                            // );
                            dispatch(
                              setCashCounterSaleSummaryPayload({
                                itemId: item?.id,
                                itemName: item?.itemDescription,
                              })
                            );



                            setOpen(false)
                            setFilterBy("CashCounterSale")
                            // navigate(routePath.CASHCOUNTERSALE);
                          }
                          // if()
                        }}
                        sx={{
                          p: "0px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          fontWeight: "bold",
                          height: "4rem",
                          borderRadius: "10px",
                          color: "#5693a3",

                          cursor: "pointer",
                          boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
                          ":hover": {
                            bgcolor: "#2892ba17",
                          },
                        }}
                      >
                        {item.itemDescription}
                      </Box>
                    </Grid>
                  );
                }
              )}
            </Grid>
          </Grid>
        </Grid>
        {/* <Grid item xs={12}>
          <Typography
            sx={{
              color: (theme) => theme.palette.primary.main,
              fontSize: "24px",
            }}
          >
            {" "}
            Recommanded Plans for You
          </Typography>
          <Typography
            sx={{
              color: (theme) => theme.palette.primary.main,
              fontSize: "17px",
            }}
          >
            {" "}
            As per present count of your customers available plains are:
          </Typography>
        </Grid> */}
      </Box>
    </Modal>
  );
};
export default SearchModal;
