

export const validation_componetlary=(obj,radioType)=>{

    
    if(obj?.employeeId==''){
        return {status:false,text:'choose Employee'}
    }

    if(obj?.customerName==''){
        return {status:false,text:'Enter Component name'}
    }
    if(obj?.percentage=='' && radioType=="percentage"){
        return {status:false,text:'Enter percentage'}
    }
    if(obj?.amount=='' && radioType=="amount"){
        return {status:false,text:'Enter amount'}
    }
    
    return {status:true}
    
}
export const validationPersonalInfo=(obj)=>{
    const aadhaarRegex = /^[2-9][0-9]{11}$/;
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const panRegex = /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/;
    const bankRegex = /^\d{8,18}$/;
    const ifscRegex = /^[A-Z]{4}0[A-Z0-9]{6}$/;

    if(obj.mobileNumber.length!==10){
        return {status:false,text:"Please enter valid mobile number."}
    }
    if(obj.alternateMobileNumber.length!==10){

        return {status:false,text:"Please enter valid alternative mobile number."}
    }
    if (!obj.aadhar || !aadhaarRegex.test(obj.aadhar)) {
        return { status: false, text: "Please enter a valid 12-digit Aadhaar card number." };
    }
    if(!obj.email || !emailRegex.test(obj.email)){
        return {status: false, text:"Please enter a valid email address."}
    }
    if(!obj.pan || !panRegex.test(obj.pan)){
        return {status: false, text:"Please enter a valid pan card number."}
    }
    if(!obj.bankAccountNumber || !bankRegex.test(obj.bankAccountNumber)){
        return {status: false, text:"Please enter a valid bank account number."}
    }
    if(!obj.ifsc || !ifscRegex.test(obj.ifsc)){
        return {status: false, text:"Please enter a valid IFSC code."}
    }
    return {status:true}

}

export const validation=(obj,radioType)=>{
    
    if(obj?.employeeId==''){
        return {status:false,text:'choose Employee'}
    }

    if(obj?.incentiveName==''){
        return {status:false,text:'Enter incentiveName'}
    }
    if(obj?.percentage=='' && radioType=="percentage"){
        return {status:false,text:'Enter percentage'}
    }
    if(obj?.amount=='' && radioType=="amount"){
        return {status:false,text:'Enter amount'}
    }
    if(obj?.minimumValue==''){
        return {status:false,text:'Enter minimumValue'}
    }
    if(obj?.maximumValue==''){
        return {status:false,text:'Enter maximumValue'}
    }
    return {status:true}
    
}


export const validation_dedcution=(obj,radioType)=>{

    
    if(obj?.employeeId==''){
        return {status:false,text:'choose Employee'}
    }

    if(obj?.allowanceName==''){
        return {status:false,text:'Enter Component name'}
    }
    
    if(obj?.amount=='' && radioType=="amount"){
        return {status:false,text:'Enter amount'}
    }
    
    return {status:true}
    
}

export const validationEmp=(obj)=>{
    if(!obj?.id){
        return {status:false,text:"Please Select Employee"}
    }
    return {status:true}
}