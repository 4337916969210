import axios from "./axios";
import Swal from "sweetalert2";
export const payloadDateFormat = () => {
  const currentDate = new Date();
  return `${currentDate.getFullYear()}-${(currentDate.getMonth() + 1)
    .toString()
    .padStart(2, "0")}-${currentDate.getDate().toString().padStart(2, "0")}`;
};

export const convertDateFormat = (inputDate) => {
  const [day, month, year] = inputDate.split("-");
  const dateObject = new Date(`${month} ${day}, ${year}`);
  const monthNumeric = String(dateObject.getMonth() + 1).padStart(2, "0");
  return `${year}-${monthNumeric}-${day}`;
};

export const getStartOfMonth = () => {
  const today = new Date();
  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const day = today.getDate() < 10 ? "0" + today.getDate() : today.getDate();
  const formattedDate =
    "01" + "-" + monthNames[today.getMonth()] + "-" + today.getFullYear();
  return formattedDate;
};

export const getEndOfMonth = () => {
  const today = new Date();
  const lastDayOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);
  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const day =
    lastDayOfMonth.getDate() < 10
      ? "0" + lastDayOfMonth.getDate()
      : lastDayOfMonth.getDate();
  const formattedDate =
    day + "-" + monthNames[today.getMonth()] + "-" + today.getFullYear();
  return formattedDate;
};
export const getStartOfMonthISO = () => {
  const today = new Date();
  const month =
    today.getMonth() + 1 < 10
      ? "0" + (today.getMonth() + 1)
      : today.getMonth() + 1;
  const formattedDate = today.getFullYear() + "-" + month + "-01";
  return formattedDate;
};
export const getEndOfMonthISO = () => {
  const today = new Date();
  const nextMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);
  const month =
    nextMonth.getMonth() + 1 < 10
      ? "0" + (nextMonth.getMonth() + 1)
      : nextMonth.getMonth() + 1;
  const formattedDate =
    nextMonth.getFullYear() + "-" + month + "-" + nextMonth.getDate();
  return formattedDate;
};

export const downloadXLSXFile = async (urlxlxs, param) => {
  try {
    const response = await axios.post(urlxlxs, param, {
      responseType: "blob",
    });

    const blob = new Blob([response.data], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "file.xlsx");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  } catch (error) {
    console.error("Error downloading XLSX file:", error);
  }
};
function addLeadingZero(value) {
  return value < 10 ? "0" + value : value;
}
export const displayDateFormat = () => {
  var currentDate = new Date();
  let dateContainer = String(currentDate).split(" ");
  var year = dateContainer[2];
  var month = dateContainer[1];
  var day = dateContainer[3];
  var formattedDate =
    year + "-" + addLeadingZero(month) + "-" + addLeadingZero(day);
  return formattedDate;
};
export const swalAlertPopProps = {
  timer: 2000,
  showConfirmButton: false,
};
export const swap = (data, index) => {
  let tmp = data[index];
  data[index] = data[data.length - 1];
  data[data.length - 1] = tmp;
  return data;
};
const swapWithLastElement = (arr, i) => {
  if (i >= arr.length || i < 0) {
    return [...arr];
  }

  const temp = arr[arr.length - 1];
  arr[arr.length - 1] = arr[i];
  arr[i] = temp;

  return [...arr];
};
export const counterSellSetOnLastIndex = (arry) => {
  let ind = arry.findIndex((ele) => ele.systemFuctionName === "Cash Sale Bill");
  console.log("index header ", ind);
  return swapWithLastElement(arry, ind);

  // Swap()
};

export const getFirstDateOfMonth = () => {
  const currentDate = new Date();
  const firstDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
  return firstDayOfMonth;
}
export const getLastDateOfMonth = () => {

  const currentDate = new Date();
  const lastDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);

  return lastDayOfMonth;
}

export const convertDateToCustomFormat = (str) => {
  const originalDate = new Date(str);
  const day = originalDate.getDate();
  const month = originalDate.toLocaleString('default', { month: 'short' });
  const year = originalDate.getFullYear();

  return `${day}-${month}-${year}`;

}

export const formatDateFromCustomToStandard=(inputDate)=>{ 
  const parts = inputDate.split('-');
  if (parts.length === 3) {
    const day = parts[0];
    const month = parts[1];
    const year = parts[2];
    
    // Convert month name to its numerical representation
    const monthMap = {
      'January': '01',
      'February': '02',
      'March': '03',
      'April': '04',
      'May': '05',
      'June': '06',
      'July': '07',
      'August': '08',
      'September': '09',
      'October': '10',
      'November': '11',
      'December': '12'
    };
    
    const formattedDate = `${year}-${monthMap[month]}-${day}`;
    return formattedDate;
  } else {
    return 'Invalid Date';
  }

}
export const getFirstDateOfCurrentMonthPayloadFormate=()=> {
  const today = new Date();
  return `${today.getFullYear()}-${(today.getMonth() + 1).toString().padStart(2, '0')}-01`;
}

export const getLastDateOfCurrentMonthPayloadFormat = () => {
  const today = new Date();
  const lastDay = new Date(today.getFullYear(), today.getMonth() + 1, 0).getDate();
  return `${today.getFullYear()}-${(today.getMonth() + 1).toString().padStart(2, '0')}-${lastDay.toString().padStart(2, '0')}`;
}


export const   generateRandomString=(length)=> {
  const chars = "0123456789"; // Change this to include other characters if needed
  let result = "";
  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * chars.length);
    result += chars[randomIndex];
  }
  return result;
}

export const setSession=async(key,data,callback)=>{
  await sessionStorage.setItem(key, JSON.stringify(data));
  if(callback){
    callback()
  }
}
export const   getSession=async(key,callback)=>{
  const res=await sessionStorage.getItem(key)
  console.log("res get",res)
  callback(res)
   
}

export const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.onmouseenter = Swal.stopTimer;
    toast.onmouseleave = Swal.resumeTimer;
  }
});
 


export const formDataFN=(obj)=>{
  const formData = new FormData();

// Loop through the object and append each key-value pair to the FormData
for (let key in obj) {
    if (obj.hasOwnProperty(key)) {
        formData.append(key, obj[key]);
    }
}
return formData
}


export const accordingToUserRender=(userDetail)=>{
  return userDetail?.changeRoll=="dashboard" &&  userDetail.appMenu=="Buyer" 
  
}


export const attributeOfBill=({
  arr1,config
})=>{
  
  let sum = 0;
  let priceWithoutGst = 0;
  let sumOfGst = 0;
  let sumOfsgst=0
  let sumOfcgst=0
  let sumOfigst=0
  let property={

  };

  console.log("arrarr===  arr1",arr1)
  arr1.forEach((item) => {
    sum += Number(
      
      
      
      
      
      
      item[1].price * item[1].suppliedQuantity);
    priceWithoutGst += Number(item[1].currentPrice * item[1].suppliedQuantity);
    // sumOfGst += Number(item[1].gst?item[1].gst:0);
    sumOfsgst += Number((item[1].currentPrice*item[1].sgst/100) * item[1].suppliedQuantity) ;
    sumOfigst += Number((item[1].currentPrice*item[1].igst/100)* item[1].suppliedQuantity) ;
    sumOfcgst += Number((item[1].currentPrice*item[1].cgst/100) * item[1].suppliedQuantity);
  });
  
    
  console.log("billp sum :",sum);
  console.log("billp priceWithoutGst :",priceWithoutGst);
  console.log("billp sumOfGst :",sumOfGst);
  
  return { 
    amount:sum,
    paybill:(priceWithoutGst+sumOfsgst+sumOfigst+sumOfcgst),
    sumOfGst:sumOfGst,
    sgst :sumOfsgst,
    igst :sumOfigst,
    cgst :sumOfcgst,
    priceWithoutGst:priceWithoutGst,
    
  } 
}