import React, { useEffect, useState } from "react";
import {
  TextField,
  Button,
  FormControl,
  Container,
  Grid,
  Box,
  Stack,
  OutlinedInput,
  InputLabel,
  MenuItem,
  ListItemText,
  Select,
  Checkbox,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormLabel,
  Modal,
  Typography,
} from "@mui/material";
// import "./test.css";
import SearchIcon from "@mui/icons-material/Search";

import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
// import { bookApoinment } from "./asppoinmentApi";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { routePath } from "../../constants/routePath";
import { setSession } from "../../utils/commonUtils";
import { setCustomerAppointmentId } from "../../app/store/reducer";
// import dayjs from 'dayjs';
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { getAlldetailSerice } from "../services/servicesListApi";
import EmployeeModal from "../../common/employeeModel"; 
import { addOffer, bulImageUpload, bulkMessage, bulkMessageApload } from "./apiFunction";
import ImageUploadButton from "../../common/imgUpload";
import SearchModalCustomer from "../../common/searchModal/customer";
import { ModalHeader } from "../../common/modalHeader";
import { useRef } from "react";
dayjs.extend(utc);
dayjs.extend(timezone);

const PopUpWp = ({ open, setOpen ,data ,feature}) => {
  const fileInputRef = useRef(null);

  // State to store the uploaded image URL
  const [imageSrc, setImageSrc] = useState(null);
  const [filet,setFilet]=useState(null)

  const [openT,setOpenT]=useState(false)
  const [selectedData,setSeletecData]=useState(null)
  const struct={
    billerId: "",
    customerName: "",
    mobileNumber: "",
    // otp: "",
    mailId: "",
    gender: "",
    service: "",
    date: "",
    hour: "",
    minute: "",
    status: "Unassigned",
    employeeId: "",
  }


  
  const [selectedValue,setSelectedValue]=useState([])
  useEffect(()=>{
    let dd=""
console.log("selectedValueselectedValue ",selectedValue)
    selectedValue.forEach((ele,index)=>{
      dd+=ele.name +","
    })
    setSeletecData({name:dd})
  },[selectedValue])
  const [formData, setFormData] = useState({...struct});
  const handle=(e)=>{
    e.preventDefault()
    // addOffer({message:formData.customerName},(data)=>{
    //     alert('ioni')
    // })
    // const whatsappLink = `https://web.whatsapp.com/send?phone=${selectedData?.customerMobile}&text=${formData?.customerName}`;
    // window.open(whatsappLink, "_blank");
    selectedValue.forEach(async(ele,index)=>{
      bulkMessageApload({
      
        "app-key": "68b71452-6c72-4e16-92fa-f78d7328df3c",
        "auth-key": "9d9bced6b415ff1e3b7ab141c8c02bce19a5091e89505992c7",
        
        "template_id": "",
        "device_id": "6738338037d156722c02c587", 
        "media": filet?filet:"", 
        destination_number:ele.customerMobile?ele.customerMobile:"",
        
        "message": formData.customerName?formData.customerName:""
    
    },(res)=>{
      setOpen(false)
      console.log("resreswhatapp",res?.data?.status)
      

    })




    })
    
  }
  useEffect(()=>{
if(open==true){
  console.log("Data",data)

  if(data){
    setFormData({...formData,customerName:data?.offerInformation})
  }
  else{
   
  }
}
else{
  setSelectedValue([])
setFormData(struct)
setImageSrc(null)

setFilet(null)
}
  },[open])
 const [search,setSearch]=useState("")
  return (
    <Modal
      open={open}
      onClose={() => {setOpen(false)}}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          background: "white",
          width: "52%",
          overflowY: "auto",
          // height: "90vh",
          position: "absolute",
          left: "25%",
          top: "10%",
        }}
      ><ModalHeader    title={"Offer Message"} onClose={(data)=>{setOpen(data)}}/> 
        <Container>
          <Box sx={{ mt: 4, my: 5 }} display="flex" justifyContent="center">
            <form onSubmit={handle}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12}>
                <Box
                // disabled
                // size="small"
                readonly
                fullWidth
                onClick={() => {
                  // setSearchOutStandingsModal(true)

                }}

                // id="name"
                // label="Search by Name/Mobile Number"
                // value={search}
                sx={{
                  display: "flex",
                  height: "23px",
                  justifyContent: "space-between",
                  // width: "70%",
                  border: "1px solid #c9c9c9",
                  p: 1,
                  borderRadius: "4px ",
                  color: "grey",
                  '&:hover': {
                    cursor: 'pointer !important',
                  },
                }}
                onChange={(e) => {

                  // setSearch(e.target.value);
                }}
            
              >
                <Typography variant="p">
                  {/* {selectedData?.name !== "" ? search : "Search by Name/Mobile Number"} */}

                  {selectedData?.name!=="" ? selectedData?.name:"Search by Name/Mobile Number"}
                </Typography>
                <SearchIcon
                  onClick={() => {
                    // setSearchModal(true)
                    setOpenT(true)

                  }}
                  sx={{
                    color: (theme) => theme.palette.primary.main,
                    cursor: "pointer",
                  }}
                />
              </Box>
                </Grid>
                <Grid item xs={12} sm={12}>
                  <FormControl fullWidth margin="normal">
                  <TextField
                      label="Enter Message"
                      name="customerName"
                      value={formData.customerName}
                      onChange={(e) => {
                        setFormData({ ...formData, customerName: e.target.value })
                      }} 
                      required
                      multiline // Makes the TextField act as a textarea
                      rows={4} // Number of rows (you can adjust this)
                      InputLabelProps={{ className: "mulish" }}
                      InputProps={{ classes: { input: "mulish" } }}
                    />
                  </FormControl>
                </Grid>
                <ImageUploadButton imageSrc={imageSrc} setImageSrc={(data)=>{
                 
                  setImageSrc(data)

                  }} fileCb={(file)=>{


                    const formData=new FormData()
                    formData.append('File', file);  // Name should match the expected key on the server-side
              
                    bulImageUpload(formData,(res)=>{
                      console.log("ttytyt ",res?.data?.data?.imageBillPath)
                      setFilet(res?.data?.data?.imageBillPath)
                    })
                  }} fileInputRef={fileInputRef}/>
                
                <Grid
                  item
                  xs={12}
                  sx={{ display: "flex", justifyContent: "end" }}
                >
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    sx={{
                      backgroundColor: "#e80089ed",
                      padding: "14px",
                      fontSize: "16px",
                    }}
                  >
                    Send
                    
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Box>
        </Container>{" "}
         <SearchModalCustomer feature={feature} searchModal={openT}  selectedValue={selectedValue} setSelectedValue={setSelectedValue}   setSearchModal={setOpenT} callback={(data)=>{
          console.log("Data",data)
          setSeletecData(data)
          setOpenT(false)
         }}   />
      </Box>
    </Modal>
  );
};

export default PopUpWp;
