import { ADDCUSTOMERAPPOINMENT, ADDCUSTOMERAPPOINMENTANAMONUS, UPDATEBILL } from "../../utils/api";
import axios from "../../utils/axios"; 

import { Toast } from "../../utils/commonUtils";
export const bookApoinment = async(appnType,payload,callback)=>{ 
const api=appnType=="profile"?ADDCUSTOMERAPPOINMENT:ADDCUSTOMERAPPOINMENTANAMONUS
  await axios
  .post (api,payload)
  .then((res) => {
    console.log("res search ", res);
    if(appnType=="profile"){
      if(res?.data?.succeeded){
        callback(res?.data) 
        console.log("res search ", res?.data);
        Toast.fire({
          icon: "success",
          title: "Appointment booked succesfully.",
        });
      }
      else{
        Toast.fire({
          icon: "error",
          title: res?.data?.message
        });    
      }
    }else if(res?.data?.succeeded){
      callback(res?.data) 
      
    }else{
      Toast.fire({
        icon: "error",
        title: res?.data?.message
      });  
    }
    
  })
  .catch((err) => {
    console.log(err);
  });
}