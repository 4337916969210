import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { useSelector } from "react-redux";
import { Box, Grid, Typography } from "@mui/material";
// import { getdashBoardApi } from "../../utils/utils";
import ProductionQuantityLimitsIcon from "@mui/icons-material/ProductionQuantityLimits";
import InventoryIcon from "@mui/icons-material/Inventory";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import PaymentsIcon from "@mui/icons-material/Payments";
import AssessmentIcon from "@mui/icons-material/Assessment";
import TrendingDownIcon from "@mui/icons-material/TrendingDown";
import CreditScoreIcon from "@mui/icons-material/CreditScore";

import { routePath } from "../../constants/routePath";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import { getdashBoardApi } from "../../utils/utils";
export default function MobileMenuPage({ menuLoginHeader }) {
  const urlHeaderMenu = useSelector((state) => state.reducer.urlHeaderMenu);
  console.log("menu Login Header ", menuLoginHeader);
  const headerMenu = useSelector((state) => state.reducer.headerMenu);
  // useEffect()
  const navigate = useNavigate();
  const menu = [];
  const iconsObj = {
    ProductionQuantityLimitsIcon: ProductionQuantityLimitsIcon,
    InventoryIcon: InventoryIcon,
    ShoppingCartIcon: ShoppingCartIcon,
    PaymentsIcon: PaymentsIcon,
    AssessmentIcon: AssessmentIcon,
    TrendingDownIcon: TrendingDownIcon,
    CreditScoreIcon: CreditScoreIcon,
  };
  return (
    <Box>
      <Grid container>
        {headerMenu.map((item, index) => {
          return (
            <Grid
              item
              xs={6}
              bg="prima1ry"
              key={"mobileMenu" + index}
              sx={{ p: "10px" }}
            >
              <Box
                sx={{
                  height: "100px",
                  borderRadius: "5px",
                  background: (theme) => theme.palette.primary.main,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                onClick={() => {
                  const indexRoute = urlHeaderMenu.findIndex(
                    (it) => it.systemFuctionName === item.systemFuctionName
                  );
                  if (indexRoute !== -1)
                    navigate(urlHeaderMenu[indexRoute].route);
                }}
              >
                <Typography
                  sx={{
                    color: "white",
                    textAlign: "center",
                    fontWeight: 600,
                  }}
                >
                  {item?.regionalFunctionName}
                </Typography>
              </Box>
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
}
