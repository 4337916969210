import {
  Box,
  Button,
  Switch,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControlLabel,
  FormGroup,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import AutofocusTextFields from "./autoFocusedTextField";
import Swal from "sweetalert2";

import CloseIcon from "@mui/icons-material/Close";
import { useSelector } from "react-redux";
import { ModalHeader } from "../../common/modalHeader";

const data = {
  name: "",
  loginId: "",
  passcode: "",
  myBusinessId: 0,
  status: "",
  buyerGroupIdList: [],
};

export default function AddAssociatedUser({
  selectedUser,
  open,
  setOpen,
  addUser,
  setSelectedUser,
  updateUser,
}) {
  const [itemData, setItemData] = useState(data);
  const onClose = () => {
    setItemData(data);
    setOpen(false);
  };
  useEffect(() => {
    if (open === false) {
      setItemData(data);
      setSelectedUser();
    }
  }, [open]);
  useEffect(() => {
    if (selectedUser) {
      setItemData({
        ...selectedUser,
        buyerGroupIdList: [],
      });
    }
  }, [selectedUser]);
  const { userDetail } = useSelector((state) => state.reducer);
  return (
    <Dialog open={open} fullWidth={true} maxWidth="xs" onClose={onClose}>
     
      <ModalHeader title={"Associated Users"} 
        onClose={(data)=>{
          setOpen(data)
        }}
        />
      {/* <DialogTitle color="primary">Associated Users</DialogTitle> */}
      <Divider />
      <DialogContent sx={{ p: 2, pt: "0px" }}>
        <Box sx={{ mt: 2 }}>
          <TextField
            fullWidth
            size="small"
            variant="outlined"
            value={itemData.name}
            onChange={(e) => {
              let val = e.target.value.replace(/[^A-Za-z]/g, "");
              setItemData({ ...itemData, name: val });
            }}
            label={
              <span>
                Name<span style={{ color: "red" }}>*</span>
              </span>
            }
          />
        </Box>
        <Box sx={{ mt: 2 }}>
          <TextField
            fullWidth
            size="small"
            variant="outlined"
            value={itemData.loginId}
            onChange={(e) => {
              setItemData({ ...itemData, loginId: e.target.value });
            }}
            label={
              <span>
                User Id<span style={{ color: "red" }}>*</span>
              </span>
            }
          />
        </Box>
        <Box sx={{ mt: 2 }}>
          <Typography sx={{ fontSize: "14px", m: 0 }}>Enter M-Pin</Typography>
          <Box
            sx={{
              width: "100%",
            }}
          >
            <AutofocusTextFields
              itemData={itemData}
              setItemData={setItemData}
            />
          </Box>
        </Box>
        {selectedUser && (
          <FormGroup>
            <FormControlLabel
              sx={{
                justifyContent: "space-between",
                m: 0,
                flexDirection: "row-reverse",
              }}
              control={
                <Switch
                  checked={itemData.status === "active" ? true : false}
                  onChange={(e) => {
                    if (e.target.checked) {
                      setItemData({ ...itemData, status: "active" });
                    } else {
                      setItemData({ ...itemData, status: "inactive" });
                    }
                  }}
                />
              }
              label="Status"
            />
          </FormGroup>
        )}
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button
          disabled={
            itemData?.name?.trim().length === 0 ||
            itemData?.loginId?.trim()?.length <= 4 ||
            itemData?.passcode?.length !== 4
          }
          variant="contained"
          onClick={() => {
            // setOpen(false);

            if (selectedUser) {
              updateUser(itemData?.guId, {
                ...itemData,
                myBusinessId: userDetail?.myBusinessId,
              });
            } else {
              addUser({ ...itemData, myBusinessId: userDetail?.myBusinessId });
            }
            setOpen(false);
            // setItemData(data);
          }}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}
