import html2pdf from 'html2pdf.js';


import { api } from "../utils/api";
import axios from "../utils/axios";


// Function to handle PDF generation and sending the PDF blob to an API
export const PDfSend = (componentRef, billNumber, callback) => {
  const opt = {
      margin: 1,
      filename: `bill-${billNumber}.pdf`,
      html2canvas: { scale: 2 },
      jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' },
  };
  console.log("Generating PDF...");
  callback('y');

  // Convert HTML content to PDF and then send it to the API
  html2pdf().from(componentRef?.current).set(opt).output('blob').then((pdfBlob) => {
      console.log("PDF generated successfully");

      sendToAPI(pdfBlob, billNumber, (link) => {
          console.log("PDF sent to API, received link");

          // Call the callback with the link
      });
  });
};
  
  // export const sendToAPI = async (pdfBlob, BilNumber,callback) => {
  //   const formData = new FormData();
  
  //   // Convert Blob to a File and set it in FormData (like MultipartBody.Part)
  //   const file = new File([pdfBlob], `bill-${BilNumber || 'unknown'}.pdf`, { type: 'application/pdf' });
  
  //   // Append File and BillId to the formData
  //   formData.append('File', file);  // Name should match the expected key on the server-side
  //   formData.append('BillId', BilNumber);  // Add BillId to the form data
  
  //   try {
  //     const response = await axios.post(api + 'CounterSales/UpdatePDFBill', formData, {
  //       headers: {
  //         'Content-Type': 'multipart/form-data',
  //       },
  //     });
  //     console.log('PDF successfully sent to the API', response.data);
  //     callback(response?.data?.data)
  //   } catch (error) {
  //     console.error('Error sending PDF to the API:', error.response?.data || error.message);
  //   }
  // };

export   const sendToAPI = async (pdfBlob,billNumber) => {
    const formData = new FormData();
    formData.append('file', pdfBlob, `bill-${44}.pdf`);
  
    try {
      const response = await axios.post('/your-api-endpoint', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      console.log('PDF successfully sent to the API', response.data);
    } catch (error) {
      console.error('Error sending PDF to the API', error);
    }
  };
  


  export const handleSendFile = async (componentRef, BilNumber,callback) => {
    try {
      // Create a FormData object
      const formData = new FormData();
  
      // Convert the component to PDF/image
      const blob = await htmlToPDF(componentRef); // Pass componentRef to the conversion function
  
      // Append the file (converted content) to FormData
      formData.append("file", blob, "billing_receipt.pdf");
    
    // Add BillId and UserId to FormData
    formData.append("BillId", BilNumber);
      // Send the file in an API request
      const response = await axios.post(api + 'CounterSales/UpdatePDFBill', formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
  
      // Handle success response
      console.log("File sent successfully", response.data);
      callback(response?.data)
    } catch (error) {
      console.error("Error sending file:", error);
    }
  };
  
  // Dummy function to simulate converting to PDF/Image
  export const htmlToPDF = async (componentRef) => {
    // const printContent = componentRef.current;
    // const blob = new Blob([printContent.innerHTML], { type: "application/pdf" });
    // return blob;
    const element = componentRef.current;

    // Options for the PDF
    const options = {
      margin: 0.5, // Margin of the PDF
      filename: 'my-document.pdf', // PDF filename
      image: { type: 'jpeg', quality: 0.98 }, // Image quality
      html2canvas: { scale: 2, useCORS: true }, // Options for html2canvas
      jsPDF: { unit: 'in', format: 'a4', orientation: 'portrait' }, // jsPDF options
    };

    try {
      // Generate the PDF as a Blob
      const pdfBlob = await html2pdf().from(element).set(options).outputPdf('blob');
      
      // Return the Blob
      return pdfBlob;
    } catch (error) {
      console.error('Error generating PDF', error);
      return null; // Return null in case of error
    }
  };