export const getData = async (getAccountReachageDetails,setData) => {
    await getAccountReachageDetails()
      .then((res) => {
        console.log("Account res ", res);
        setData(res?.data?.data);
      })
      .catch((err) => {
        console.log("err ", err);
      });
  };

 export  const getDataRechageDetail = async (getNewSubscriptionReachageDetails,setSubscription) => {
    await getNewSubscriptionReachageDetails()
      .then((res) => {
        console.log("getNew subscription ", res);
        setSubscription(res?.data?.data?.subscriptionRateDetails);
      })
      .catch((err) => {
        console.log("err ", err);
      });
  };