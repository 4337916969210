import React from 'react';

const DateFormatter = ({ dateString }) => {
  function formatDate(dateString) {
    const date = new Date(dateString);
    let hours = date.getHours();
    const minutes = date.getMinutes();
    const seconds = date.getSeconds();
    const ampm = hours >= 12 ? 'pm' : 'am';
  
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
  
    const formattedTime = `${hours}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')} ${ampm}`;
    return formattedTime;
  }

  const formattedTime = formatDate(dateString);

  return (
    <div>
      {formattedTime}
    </div>
  );
};

export default DateFormatter;
