import * as React from "react";

import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

export default function ControlledComponent({
  date,
  onChange,
  isEdit = false,
}) {
  console.log("formData date",date)
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} >
      <DemoContainer components={["DatePicker"]} >
        <DatePicker
          label={`${isEdit ? "Rate " : "With "} Effective From`}
          sx={{ width: "100%", "& .MuiInputBase-input": { height: "0.5rem" }, }}
          value={date}
          onChange={(newValue) => onChange(newValue)}
        />
      </DemoContainer>
    </LocalizationProvider>
  );
}
