import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  TextField,
  Typography,
  Modal,
  Grid,
  Stack,
  Pagination,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";
import React, { useEffect, useRef, useState } from "react";
import CounterSalesItemCard from "./counterItemCard";


import "react-datepicker/dist/react-datepicker.css";
import { getAllAppoint } from "./displayAdvanceApi";

export default function DisplayAdvance({
  open,
  setOpen,
  data,setOpenAn
}) {
  // const []

  const [detail,setDetail]=useState(null)

  useEffect(() => {


    if (open == true) {
      console.log("Data ", data)
      getAllAppoint({
        buyerInfoId: data?.buyerInfoId,
        "page": 1,
        "pageSize": 1000,
        "filter": "",
        "search": ""
      },(data)=>{
        console.log("detail list ",data)
        setDetail(data)
      })
    }
  }, [data])

  return (
    <Modal
      open={open}
      onClose={() => {
        setOpen(false)
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          background: "white",
          width: "52%",
          overflowY: "auto",
          // height: "90vh",
          position: "absolute",
          left: "25%",
          top: "10%",
        }}
      >
        <DialogTitle
          color="primary"
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            height: 'auto',
            p: "0px 10px !important"
          }}
        >
          Advance{" "}
          <CloseIcon sx={{ float: "right", cursor: "pointer" }} onClick={() => setOpen(false)} />
        </DialogTitle>
        <Divider />
        <Grid container sx={{ position: "relative" }}>
          <Grid item xs={12} >
            {

              detail?.customerAdvanceDetail?.map((item,index)=>{
           
            return ( <CounterSalesItemCard item={item} />)
              })
            }
          </Grid>

          <Grid item xs={12} >
          <DialogActions sx={{}}>
          {/* <Button onClick={onClose}>Cancel</Button> */}
          <Button
            variant="contained"
            fullWidth
            onClick={() => {
              setOpenAn(true)


              setOpen(false)
              // } else {
              //   Swal.fire({
              //     text: valid.msg,

              //     ...swalAlertPopProps,
              //     icon: "warning",
              //   });
              // }
            }}
          >
            Add Advance
          </Button>
        </DialogActions>
            
          </Grid>
        </Grid>


      </Box>
    </Modal>
  );
}
