import React, { useEffect, useState } from "react";
import { Box, Divider, Fab, Grid, Typography } from "@mui/material";
import { getAccountReachageDetails } from "./smbAccountReachageApi";
import SmbAccountReachargeCard from "./smbAccountReachageCard";
import AddIcon from "@mui/icons-material/Add";
import DisplayAnnualRecharge from "./displayAnnualRecharge";
import { getData } from "./smbAccountRechargeCustomApiHandler";
import { convertDateToCustomFormat } from "../../utils/commonUtils";
import DynamicBreadcrumbs from "../../common/Breadcrumb";
export default function SmbAccountReacharge() {
  const [data, setData] = useState();
  const [open, setOpen] = useState(false);

  useEffect(() => {
    getData(getAccountReachageDetails, setData);
  }, []);
  return (
  <>
     <DynamicBreadcrumbs />
    <Box  sx={{
      margin: {
        xs: "10px",
        sm: "50px 10px 10px 10px ",
        md: "50px",
      },
    }}>
      <Grid container sx={{ p: "10px" }}>
        <Grid item xs={12} sm={6} sx={{ display: "flex" }}>
          <Typography sx={{ fontWeight: "600" }}>Date Signed Up:</Typography>
          <Typography
            sx={{
              m: "0px 10px",
              color: (theme) => theme.palette.secondary.main,
              fontWeight: "600",
            }}
          >
            {convertDateToCustomFormat(data?.accountSingedDate.split("T")[0])}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} sx={{ display: "flex" }}>
          <Typography sx={{ fontWeight: "600" }}>
            Account Valid Till:
          </Typography>
          <Typography
            sx={{
              m: "0px 10px",
              color: (theme) => theme.palette.secondary.main,
              fontWeight: "600",
            }}
          >
            {
            
            convertDateToCustomFormat(data?.accountValidityDate?.split("T")[0])}
          </Typography>
        </Grid>

        <Divider
          sx={{
            mt: "10px",
            background: (theme) => theme.palette.primary.main,
            width: "100%",
          }}
        />
        <Grid
          item
          xs={12}
          sx={{
            mt: "10px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography
            fontSize={"14px"}
            sx={{
              m: "0px 10px",
              color: (theme) => theme.palette.primary.main,
              fontWeight: "600",
            }}
          >
            Recharge history
          </Typography>
          <Fab
            onClick={() => {
              setOpen(true);
            }}
            sx={{ textTransform: "capitalize" }}
            size="small"
            color="primary"
            variant="extended"
            aria-label="add"
          >
            Click here to recharge
            <AddIcon />
          </Fab>
        </Grid>
        {data?.rechargeHistory?.map((item, index) => {
          return (
            <SmbAccountReachargeCard item={item} key={"anualCard" + index} />
          );
        })}
      </Grid>
      <DisplayAnnualRecharge open={open} setOpen={setOpen} />
    </Box></>
  );
}
