import { swalAlertPopProps } from "../../utils/commonUtils";

export const getCategories = async (param,getItemCategories,setItemCategories,setPagination,setTotalItems) => {
    await getItemCategories(param)
      .then((res) => {
        setItemCategories(res?.data?.data);
        setPagination({
          page: res.data.pageNumber,
          total: res.data.totalPages,
        });
        setTotalItems(res?.data?.totalRecords);
      })
      .catch((err) => {
        console.error("getitemcategories", err);
      });
  };

 export const getItemsNames = async (param,getItems,setAllItems) => {
    await getItems(param)
      .then((res) => {
        setAllItems(res?.data?.data);
      })
      .catch((err) => {});
  };

  export  const addCategoriesData = async (param,addCategories,Swal,setSelectedItems,getCategoriesKeys,getItemCategories,setItemCategories,setPagination,setTotalItems) => {
    await addCategories(param)
      .then((res) => {
        if (res?.data?.succeeded) {
          Swal.fire({
            title: "Item Saved Successfully",
            icon: "success",
            
            ...swalAlertPopProps,
          });
        } else {
          Swal.fire({
            title: res?.data?.message,
            
                      ...swalAlertPopProps,
            icon: "error",
            
          });
        }

        setSelectedItems([]);
        getCategories(
          getCategoriesKeys,
          getItemCategories,
          setItemCategories,
          setPagination,
          setTotalItems
        );
      })
      .catch((err) => {});
  };

  export   const updateCategoriesData = async (param,updateCategories,Swal,getCategoriesKeys,getCategories,getItemCategories,setItemCategories,setPagination,setTotalItems) => {
    await updateCategories(param)
      .then((res) => {
        if (res?.data?.succeeded) {
          Swal.fire({
            title: "Item Saved Successfully",
            icon: "success",
          });
        } else {
          Swal.fire({
            title: res?.data?.message,
            icon: "error",
            
            ...swalAlertPopProps,
            
          });
        }
        getCategories(
          getCategoriesKeys,
          getItemCategories,
          setItemCategories,
          setPagination,
          setTotalItems
        );
      })
      .catch((err) => {});
  };
