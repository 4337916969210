import { useTheme } from "@emotion/react";
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  TextField,
  Typography,
  RadioGroup,
  FormControlLabel,
  Radio,
  Modal,
  Grid,
} from "@mui/material";
import React, { useState } from "react";

export default function Language({ open, setOpen }) {
  const onClose = () => {
    // setItemData(data);
    setOpen(false);
  };

  const [languages, setLanguage] = useState([
    {
      name: "Englist",
    },
    {
      name: "Hindi",
    },
    {
      name: "marathi",
    },
    {
      name: "gujarati",
    },
  ]);
  const theme = useTheme();
  const [selected, setSelected] = useState("English");
  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          background: "white",
          width: "40%",
          position: "absolute",
          left: "32%",
          top: "20%",
        }}
      >
        <DialogTitle color="primary" fontWeight="bold">
          Select Language
        </DialogTitle>

        <Divider />
        <Grid container sx={{ position: "relative" }}>
          <Grid
            item
            xs={12}
            sx={{
              p: "20px",
            }}
          >
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              defaultValue="female"
              name="radio-buttons-group"
              value={selected}
              onChange={(e) => {
                setSelected(e.target.value);
              }}
            >
              <FormControlLabel
                value="English"
                sx={{
                  border:
                    selected === "English"
                      ? "1px solid " + theme.palette.secondary.main + ""
                      : "1px solid " + theme.palette.primary.main,
                  borderRadius: selected === "English" ? "5px" : "",
                  fontWeight: "600",
                  m: "10px",

                  color:
                    selected === "English"
                      ? theme.palette.secondary.main
                      : theme.palette.primary.main,
                }}
                control={<Radio color="secondary" />}
                label={
                  <Typography variant="body1" fontWeight={"bold"}>
                    English
                  </Typography>
                }
              />
              <FormControlLabel
                value="Hindi"
                sx={{
                  border:
                    selected === "Hindi"
                      ? "1px solid " + theme.palette.secondary.main + ""
                      : "1px solid " + theme.palette.primary.main,

                  fontWeight: "600",
                  m: "10px",
                  borderRadius: selected === "Hindi" ? "5px" : "",
                  color:
                    selected === "Hindi"
                      ? theme.palette.secondary.main
                      : theme.palette.primary.main,
                }}
                control={<Radio color="secondary" />}
                // label="Hindi"
                label={
                  <Typography variant="body1" fontWeight={"bold"}>
                    Hindi
                  </Typography>
                }
              />
              <FormControlLabel
                value="Marathi"
                sx={{
                  border:
                    selected === "Marathi"
                      ? "1px solid " + theme.palette.secondary.main + ""
                      : "1px solid " + theme.palette.primary.main,

                  fontWeight: "600",
                  m: "10px",

                  borderRadius: selected === "Marathi" ? "5px" : "",
                  color:
                    selected === "Marathi"
                      ? theme.palette.secondary.main
                      : theme.palette.primary.main,
                }}
                control={<Radio color="secondary" />}
                label={
                  <Typography variant="body1" fontWeight={"bold"}>
                    Marathi
                  </Typography>
                }
              />
            </RadioGroup>
          </Grid>
        </Grid>

        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <Button
            variant="contained"
            onClick={() => {
              setOpen(false);
            }}
          >
            Save Language
          </Button>
        </DialogActions>
      </Box>
    </Modal>
  );
}
