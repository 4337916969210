import React, { useEffect, useState } from "react";
import AddOfferModal from "./addOfferModal";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CardMedia,
  Container,
  Divider,
  Fab,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { GetAllOffersApi } from "./GetAllOffersApi";
import UpdateOfferModal from "./updateOfferModal";
import DynamicBreadcrumbs from "../../common/Breadcrumb";
export const AddOffers = () => {
  const [open, setOpen] = useState(false);
  const [openUpdateModal, setOpenUpdateModal] = useState(false);
  const [data, setData] = useState([]);
  const [selectedOffer, setSelectedOffer] = useState(null);
  
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleOpenUpdateModal = (offer = null) => {
    setSelectedOffer(offer);
    setOpenUpdateModal(true)
  };
  const handleCloseUpdateModal = () => {
    setSelectedOffer(null);
    setOpenUpdateModal(false)
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    
    const day = String(date.getDate()).padStart(2, '0'); // Get day and add leading zero if needed
    const month = date.toLocaleString('default', { month: 'short' }); // Get short month name (e.g., Sep)
    const year = date.getFullYear(); // Get full year (e.g., 2024)
  
    return `${day}-${month}-${year}`;
  };

  useEffect(() => {
    const fetchAllOffers = async () => {
      try {
        let res = await GetAllOffersApi();
        console.log(res?.data.data.list, "resss");
        setData(res?.data.data.list);
      } catch (error) {
        console.error("Failed to fetch states:", error);
      }
    };

    fetchAllOffers();
  }, [open,openUpdateModal]);

  return (
    <>
     <DynamicBreadcrumbs />
    <Box   sx={{
          margin: {
            xs: "10px",
            sm: "50px 10px 10px 10px ",
            md: "50px",
          },
        }}>
    <Grid
        container
        spacing={2}
        sx={{
          display: "flex",
          overflowY: "auto",
          maxHeight: "calc(100vh - 60px)",
        }}
      >
        <Grid
          item
          xs={12}
          sm={6}
          sx={{
            fontWeight: "bold",
            display: "flex",
            alignItems: "center",
            justifyContent: "right",
          }}
        ></Grid>
        <Grid
          item
          xs={12}
          sm={6}
          sx={{
            fontWeight: "bold",
            display: "flex",
            alignItems: "center",
            justifyContent: "right",
          }}
        >
          <Fab
            onClick={handleOpen}
            sx={{
              textTransform: "capitalize",
              width: {
                xs: "100%",
                sm: "auto",
              },
              whiteSpace: "nowrap",
                   zIndex:'0'
            }}
            size="small"
            color="primary"
            variant="extended"
            aria-label="add"
          >
            Add Offer
            <AddIcon />
          </Fab>
        </Grid>
      </Grid>

      <Container>
        <Grid container spacing={2}>
      {
        data.map((item)=>(
          <Grid key={item.id} item lg={4} sm={6} xs={12}>
          <Card>
          <CardHeader
        action={
          <IconButton onClick={()=>handleOpenUpdateModal(item)} aria-label="edit">
            <EditIcon />
          </IconButton>
        }
        title={item.offerInformation}
        subheader={`Status : ${item.status}`}
      />
            <CardMedia
              sx={{ height: 240 }}
              image={item.imagePath}
              title="green iguana"
            />
            <Divider sx={{pt:1}}/>
            <CardContent sx={{":last-child":{pb:2}}}>
            <Stack direction={"row"} justifyContent={"space-between"}>
                    <Typography gutterBottom variant="body2">
                      <Typography
                        variant="body2"
                        component="span"
                        sx={{ color: "primary.main", fontWeight: "600" }}
                      >
                        Offer Start :
                      </Typography>{" "}
                      {formatDate(item.fromDate)}
                    </Typography>
                    <Typography gutterBottom variant="body2">
                      <Typography
                        variant="body2"
                        component="span"
                        sx={{ color: "primary.main", fontWeight: "600" }}
                      >
                        Offer End :
                      </Typography>{" "}
                      {formatDate(item.toDate)}
                    </Typography>
                  </Stack>
            </CardContent>
          </Card>
        </Grid>
        ))
      }
        </Grid>
      </Container>

      <AddOfferModal open={open} handleClose={handleClose} />
      <UpdateOfferModal open={openUpdateModal} handleClose={handleCloseUpdateModal} offerData={selectedOffer}/>
    </Box>
    </>
  );
};
