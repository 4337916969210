import CryptoJS from "crypto-js";
export const encrypt = (plainText, key) => {
  const plainTextBytes = CryptoJS.enc.Utf8.parse(plainText);
  const keyBytes = getKeyBytes(key);

  const encryptedBytes = encryptAES(plainTextBytes, keyBytes, keyBytes);
  const base64Encoded = CryptoJS.enc.Base64.stringify(encryptedBytes);

  return base64Encoded;
};

export const encryptAES = (plainTextBytes, keyBytes, ivBytes) => {
  const key = CryptoJS.lib.WordArray.create(keyBytes);
  const iv = CryptoJS.lib.WordArray.create(ivBytes);

  const encrypted = CryptoJS.AES.encrypt(plainTextBytes, key, {
    iv: iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
  });

  return encrypted.ciphertext;
};

const getKeyBytes = (key) => {
  const keyBytes = new Uint8Array(16);
  const parameterKeyBytes = new TextEncoder().encode(key);

  keyBytes.set(
    parameterKeyBytes.subarray(
      0,
      Math.min(parameterKeyBytes.length, keyBytes.length)
    )
  );

  return keyBytes;
};
