import React, { useRef, useState } from "react";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import EventIcon from "@mui/icons-material/Event";
import { Box, Divider, Grid, TextField, Typography } from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";

import DatePicker from "react-datepicker";

import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import "react-datepicker/dist/react-datepicker.css";

// import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { useEffect } from "react";
import SearchIcon from "@mui/icons-material/Search";

import { getMonthlyDetails, getOverallCounterDetail } from "./reportsApi";
// import GstReportCard from "./gstReportCard";
// import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import dayjs from "dayjs";
import CounterSalesItemCard from "./counterItemCard";
import axios from "../../../utils/axios";
import { getEndOfMonth, getFirstDateOfCurrentMonthPayloadFormate, getFirstDateOfMonth, getLastDateOfCurrentMonthPayloadFormat, getStartOfMonth, payloadDateFormat } from "../../../utils/commonUtils";
import EmployeeModal from "../../../common/employeeModel";
const EmployeeAdvanceReport = () => {
  const [open, setOpen] = useState(false);

  const [employeeList,setEmployeeList]=useState([])

  const [formData,setFormData]=useState({})
  const [employeeDetail, setEmployeeDetail]=useState(null)
  function addLeadingZero(value) {
    return value < 10 ? "0" + value : value;
  }

  const [openT,setOpenT]=useState(false)

  const displayDateFormat = () => {
    var currentDate = new Date();
    let dateContainer = String(currentDate).split(" ");

    var year = dateContainer[2];
    var month = dateContainer[1];

    var day = dateContainer[3];

    var formattedDate =
      year + "-" + addLeadingZero(month) + "-" + addLeadingZero(day);

    return formattedDate;
  };
  const [gstReports, setgstReports] = useState({
    totalSales: "",
    gstReports: [],
  });
  const [counterSales, setCounterSales] = useState();
  const [billFilter, setBillFilter] = useState("cash");

  const [date, setDate] = useState(getStartOfMonth());
  const [displayEndDate, setDisplayEndDate] = useState(getEndOfMonth());

  const [SelectedStartDate, setSelectedStartDate] = useState(getFirstDateOfMonth());
  const [endDate, setEndDate] = useState(getLastDateOfCurrentMonthPayloadFormat());

  const [startDateFormat, setstartDateFormat] = useState(getFirstDateOfCurrentMonthPayloadFormate());


  const datePickerRef = useRef();
  const datePickerRef2 = useRef();

  const openDatePicker = () => {
    if (datePickerRef.current) {
      datePickerRef.current.setOpen(true);
    }
  };

  const getCounterSales = async (param) => {
    await getOverallCounterDetail(param)
      .then((res) => {
        console.log("GetOverall ", res?.data?.data?.records);
        setCounterSales(res?.data?.data?.records);
      })
      .catch((err) => {
        console.log("err ", err);
      });
  };
  const datePickerOnChange = (date) => {
    const dateStr = date.toLocaleDateString("en-GB", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    });
    const dateStr2 = date.toLocaleDateString("en-GB", {
      year: "numeric",
      month: "long",
      day: "2-digit",
    });
    const [month, day, year] = dateStr.split("/");
    const formattedDate = `${year}-${day.padStart(2, "0")}-${month.padStart(
      2,
      "0"
    )}`;
    let dateContainer = String(dateStr2).split(" ");
    var year2 = dateContainer[2];
    var month2 = dateContainer[1];
    var day2 = dateContainer[0];
    var formattedDate2 = day2 + "-" + month2 + "-" + year2;
    datePickerRef2.current.setOpen(true);

    setDate(formattedDate2);
    setstartDateFormat(formattedDate);
    setSelectedStartDate(date);
  };
  const datePickerOnChangeEnd = (date) => {
    const dateStr = date.toLocaleDateString("en-GB", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    });
    const dateStr2 = date.toLocaleDateString("en-GB", {
      year: "numeric",
      month: "long",
      day: "2-digit",
    });
    const [month, day, year] = dateStr.split("/");
    const formattedDate = `${year}-${day.padStart(2, "0")}-${month.padStart(
      2,
      "0"
    )}`;
    let dateContainer = String(dateStr2).split(" ");
    var year2 = dateContainer[2];
    var month2 = dateContainer[1];
    var day2 = dateContainer[0];
    var formattedDate2 = day2 + "-" + month2 + "-" + year2;
    console.log("format date ", formattedDate);
    datePickerRef2.current.setOpen(true);
    setSelectedEndDate(date);
    setDisplayEndDate(formattedDate2);
    setEndDate(formattedDate);
  };

  useEffect(() => {
    console.log("start date ", startDateFormat);
    console.log("display End ", endDate);
    getCounterSales({
      pageSize:200,
      page:1,
      fromDate: startDateFormat,
      toDate: endDate,
      employeeId:employeeDetail?.id
    });
  }, [startDateFormat, endDate]);
  useEffect(() => {
    console.log("datE ", payloadDateFormat());
    getCounterSales({
      pageSize:200,
      page:1,
      fromDate: startDateFormat,
      toDate: endDate,
      employeeId:employeeDetail?.id

    });
  }, [openT]);
  const [SelectedEndDate, setSelectedEndDate] = useState(null);
  const downloadXLSXFile = async (param) => {
    try {
      const response = await axios.post(
        "user/api/v1/Reports/GetAssistantPayrollReport",
        param,
        {
          responseType: "blob",
        }
      );

      const blob = new Blob([response.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "file.xlsx");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error downloading XLSX file:", error);
    }
  };

  return (
    <>
      <Grid
        container
        sx={{
          px: "10px",
          display: "flex",
        }}
      >
        <Grid
          item
          xs={12}
          sx={{ display: "flex", alignItems: "center", height: "56px" }}
        >
          <TextField
            fullWidth
            readonly
            size="small"
            onClick={openDatePicker}
            id="date"
            sx={{
              mt: 2,
              // "& .MuiInputBase-root": { height: "45px" }
            }}
            value={date + " To " + displayEndDate}
            label={
              <Box
                sx={{
                  flex: 4,
                  color: "grey",
                  cursor: "pointer",
                  whiteSpace: "nowrap",
                }}
              >
                Date :
              </Box>
            }
            onChange={(e) => { }}
            InputProps={{
              endAdornment: (
                <EventIcon
                  sx={{
                    color: (theme) => theme.palette.primary.main,
                    cursor: "pointer",
                  }}
                />
              ),
            }}
          />
          <Box
            sx={{
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <FileDownloadIcon
              sx={{
                ml: "10px",
                mr: "0px",
                height: "1.4375em",
                cursor: "pointer"
                // m: "16.5px 14px",
              }}
              onClick={() => {
                downloadXLSXFile({
                  fromDate: startDateFormat,
                  toDate: endDate,
                });
              }}
            />
          </Box>
        </Grid>
        <Grid item xs={12}
            sx={{
              // padding: { sm: "0px 16px" },
              fontWeight: "bold",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-around",
              mt: { xs: "10px", sm: "0px" },
            }}>
            <TextField
              fullWidth
              id="Value"
              label="Value"
              size="small"

              value={employeeDetail?.name}
              onChange={(e) => {
                // setPayloadGetCounterSales(
                //   {
                //     ...payloadGetCounterSales,
                //     search: e.target.value,
                //   },
                //   outStandingBills,
                //   getCounterSalesStatement,
                //   setNumberOfRecords,
                //   setOutStandingBills
                // );
              }}
              InputProps={{

                style: {
                  // height: "45px"
                },

                endAdornment: (
                  <SearchIcon
                    onClick={() => {
                      setOpenT(true)

                      // getCounterSales(
                      //   {
                      //     ...payloadGetCounterSales,
                      //     filter: payloadGetCounterSales?.filter
                      //   },
                      //   outStandingBills,
                      //   getCounterSalesStatement,
                      //   setNumberOfRecords,
                      //   setOutStandingBills
                      // );
                    }}
                    sx={{
                      color: (theme) => theme.palette.primary.main,
                      cursor: "pointer",
                    }}
                  />
                ),
              }}
            />
         
          </Grid>

        <DatePicker
          hidden={true}
          selected={SelectedStartDate}
          maxDate={new Date()}
          onChange={datePickerOnChange}
          className="custom-datepicker"
          ref={datePickerRef}
          onClickOutside={() => {
            datePickerRef.current.setOpen(false);
          }}
        />

        <DatePicker
          hidden={true}
          selected={SelectedEndDate}
          maxDate={new Date()}
          onChange={datePickerOnChangeEnd}
          className="custom-datepicker"
          ref={datePickerRef2}
          onClickOutside={() => {
            datePickerRef2.current.setOpen(false);
          }}
        />

        <Grid
          item
          xs={6}
          sx={{
            mt: "4px",
            padding: "8px 16px",
            fontWeight: "500",
          }}
        >
          <Box
            component="span"
            sx={{ color: (theme) => theme.palette.primary.main }}
          >
            Total Sale:
          </Box>
          <Box
            component="span"
            sx={{
              marginLeft: "8px",
              color: (theme) => theme.palette.secondary.main,
            }}
          >
            <CurrencyRupeeIcon sx={{ fontSize: "14px" }} />{" "}
            {counterSales?.totalSale?.toFixed(2)}
          </Box>
        </Grid>
        <Grid container>
          <Grid
            item
            xs={6}
            sx={{
              padding: "8px 16px",
              fontWeight: "500",
            }}
          >
            <Box
              component="span"
              sx={{ color: (theme) => theme.palette.primary.main }}
            >
              Cash Sale:
            </Box>
            <Box
              component="span"
              sx={{
                marginLeft: "8px",
                color: (theme) => theme.palette.secondary.main,
              }}
            >
              <CurrencyRupeeIcon sx={{ fontSize: "14px" }} />{" "}
              {counterSales?.cashSale?.toFixed(2)}
            </Box>
          </Grid>
          <Grid
            item
            xs={6}
            sx={{
              padding: "8px 16px",
              fontWeight: "500",
            }}
          >
            <Box
              component="span"
              sx={{ color: (theme) => theme.palette.primary.main }}
            >
              Credit Sale:
            </Box>
            <Box
              component="span"
              sx={{
                marginLeft: "8px",
                color: (theme) => theme.palette.secondary.main,
              }}
            >
              <CurrencyRupeeIcon sx={{ fontSize: "14px" }} />{" "}
              {counterSales?.creditSale?.toFixed(2)}
            </Box>
          </Grid>
        </Grid>
        <Divider
          sx={{
            width: "100%",
            mt: 2,
            background: (theme) => theme.palette.primary.main,
          }}
        />

        <Grid container>
          <Grid item xs={12}  >
            <Grid container sx={{ p: "10px" }}>
              <Grid item xs={6}>
                <Typography
                  sx={{
                    fontSize: "15px",
                    overflow: "Hidden",
                    display: "-webkit-box",
                    "-webkit-line-clamp": "1",
                    "-webkit-box-orient": "vertical",
                    fontWeight: 700,
                    p: "0px 0.5rem"
                  }}
                >
                  Date
                </Typography>
              </Grid>

              <Grid
                item
                xs={6}
                sx={{
                  fontSize: "15px",
                  overflow: "Hidden",
                  textOverflow: "ellipsis",
                  display: "-webkit-box",
                  "-webkit-line-clamp": "1",
                  "-webkit-box-orient": "vertical",
                  textAlign: "center",
                  fontWeight: 700,
                }}
              >
                Amount
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sx={{ height: "60vh", overflow: "auto" }}>
            {counterSales?.map((item, i) => {
              return (
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  // lg={6}
                  key={"payamentRecpt" + i}
                  sx={{ p: "0px" }}
                >
                  <CounterSalesItemCard item={item} />
                </Grid>
              );
            })}

          </Grid>
          <EmployeeModal
          open={openT}
          setOpen={setOpenT}
          callback={(data) => {
            setOpenT(false);
            setEmployeeDetail(data)
            setFormData((prev)=>{
                                
              let temp={...prev};
              temp.employeeId=data?.id
              console.log("employee detail",data)
              return temp;
            })
            console.log(data,"callback ");

          }}
          onClose={() => {
            setOpenT(false);
            
          }}
          employeeList={employeeList}
         
        />
        </Grid>
      </Grid>
    </>
  );
};

export default EmployeeAdvanceReport;

