import { Box, Drawer, Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import SearchIcon from "@mui/icons-material/Search";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { routePath } from "../../constants/routePath";
import { useDispatch, useSelector } from "react-redux";
import {
  setBillNo,
  setMobileTableDrawer,
  setTableName,
} from "../../app/store/reducer";
import fireDb from "../../utils/firebase";
import TableWithBox from "../../common/table";

import Swal from "sweetalert2";
import {
  TableSectionListApi,
  getBillCounterSell,
  tableListApi,
} from "./counterSellApi";
import { payloadDateFormat } from "../../utils/commonUtils";
import { getBill, getData } from "./counterSellApiHandlers";
import { callbackHandlerCounterSell_ } from "./countersellSetParameter";
import { getAllEmployeeList } from "../employeeInfo/appoinmentDashboardApi";
import DynamicBreadcrumbs from "../../common/Breadcrumb";
import { PopEmployee } from "./popEmploye";

export default function CounterSell() {
  const navigate = useNavigate();
  const [section, setSections] = useState([]);

  const [tables, setTables] = useState([]);

  const [sectionPresent, setSectionPresent] = useState(false);

  const dispatch = useDispatch();
  const [billCount, setBillCount] = useState({});
  const [bookedTables, setBookedTaBles] = useState();
  const billNumber = useSelector((state) => state.reducer.billNumber);
  const [pagination, setPagination] = useState({
    page: 1,
    total: 1,
    records: 0,
    pageSize: 4,
  });
  const [search, setSearch] = useState("");

  const [searchOutStandingsModal, setSearchOutStandingsModal] = useState(false);

  const [bills, setBills] = useState([]);
  const [numberOfRecords, setNumberOfRecords] = useState(1);
  const callbackHandlerCounterSell = callbackHandlerCounterSell_(
    getBill,
    {
      date: payloadDateFormat(),
      status: "null",
      filter: "null",
      search: "null",
      page: 1,
      pageSize: 10,
    },
    getBillCounterSell,
    Swal,
    billNumber,
    dispatch,
    setBillNo,
    setBills,
    setBillCount,
    billCount,
    getData,
    TableSectionListApi,
    setSections,
    tableListApi,
    setSectionPresent,
    setTables
  );

  useEffect(() => {
    getAllEmployeeList((res) => {
      console.log("getAllEmployeeList", res);
      setTables(res?.list);
    });

    // getData(
    //   TableSectionListApi,
    //   setSections,
    //   tableListApi,
    //   setSectionPresent,
    //   setTables
    // );

    // callbackHandlerCounterSell.getData.callback(
    //   callbackHandlerCounterSell.getData.parameter
    // );
    // console.log("callbackHandlerCounterSell");
    // callbackHandlerCounterSell.getBill.callback(
    //   callbackHandlerCounterSell.getBill.parameter
    // );
  }, []);

  const userDetail = useSelector((state) => state.reducer.userDetail);
  useEffect(() => {
    console.log("tables ", tables);
  }, [tables]);

  useEffect(() => {
    console.log("bookedTables ", bookedTables);
  }, [bookedTables]);
  const handleDataChange = (snapshot, eventMode) => {
    const tableOrderData = [];
    if (eventMode === "on") {
      snapshot.forEach((childSnapshot) => {
        const tableData = childSnapshot.val();
        tableOrderData.push(tableData.name);
      });
      console.log("bookedTables firebase ", tableOrderData);

      setBookedTaBles(tableOrderData);
    }
  };





  useEffect(() => {
    let parentNodeRef = null;
    if (userDetail?.mobileNumber) {
      parentNodeRef = fireDb.child(`${userDetail?.mobileNumber}/bookedTables`);
      parentNodeRef?.on("value", (e) => {
        console.log("bookedTables on ");
        handleDataChange(e, "on");
      });
    }
    return () => {
      parentNodeRef?.off("value", (e) => {
        console.log("bookedTables off ");
        handleDataChange(e, "off");
      });
    };
  }, [userDetail]);
  
  const billerMenuseletec = useSelector((state) => state.reducer.billerMenuseletec);
  const drawerShow = useSelector((state) => state.reducer.drawerShow);
  const mobileTableDrawer = useSelector(
    (state) => state.reducer.mobileTableDrawer
  );





  const changeState=(newState)=>{
    setSearchOutStandingsModal(newState)
  }
  return (
    <>
      <DynamicBreadcrumbs />
      <Box
        sx={{
          margin: {
            xs: "10px",
            sm: "50px 10px 10px 10px ",
            md: "50px",
          },
        }}
      >
        <Grid container>
      {!searchOutStandingsModal  &&
      <> 
       <Grid item xs={12} sm={12}>
       {billerMenuseletec==0 &&   <>
        
<Grid
            
            item
            sx={{}}
            >
            <PopEmployee sectionPresent={sectionPresent}  tables={tables}  dispatch={dispatch} navigate={navigate} bookedTables={bookedTables} section={section} setTableName={setTableName}/>
            </Grid>
        </>}
        {
          billerMenuseletec!==0 &&
          <TableWithBox
          changeState={changeState}
      data={bills}
      pagesCount={billCount?.totalBills}
      billApiCall={(da,search) => {
        // getBill(
        //   {
        //     date: da,
        //     status: "",
        //     filter: "",
        //     search: "",
        //     page: 1,
        //     pageSize: 10,
        //   },
        //   getBillCounterSell,
        //   Swal,
        //   billNumber,
        //   dispatch,
        //   setBillNo,
        //   setBills,
        //   setBillCount,
        //   billCount
        // );
        callbackHandlerCounterSell.getBill.callback({
          ...callbackHandlerCounterSell.getBill.parameter,
          payload: {
            ...callbackHandlerCounterSell.getBill.parameter.payload,
            ...search,date: da,
          },
        });
      }}
      paginationApiCall={(da, page, status, paymentMode) => {
        if (paymentMode) {
          // getBill(
          //   {
          //     date: da,
          //     status: status,
          //     filter: paymentMode,
          //     search: "null",
          //     page: page,
          //     pageSize: 10,
          //   },
          //   getBillCounterSell,
          //   Swal,
          //   billNumber,
          //   dispatch,
          //   setBillNo,
          //   setBills,
          //   setBillCount,
          //   billCount
          // );
          callbackHandlerCounterSell.getBill.callback({
            ...callbackHandlerCounterSell.getBill.parameter,
            payload: {
              ...callbackHandlerCounterSell.getBill.parameter.payload,
              date: da,
              status: status,
              filter: paymentMode,
              page: page,
              pageSize: 10,
            },
          });
        } else {
          // getBill(
          //   {
          //     date: da,
          //     status: status,
          //     filter: "null",
          //     search: "null",
          //     page: page,
          //     pageSize: 10,
          //   },
          //   getBillCounterSell,
          //   Swal,
          //   billNumber,
          //   dispatch,
          //   setBillNo,
          //   setBills,
          //   setBillCount,
          //   billCount
          // );
          callbackHandlerCounterSell.getBill.callback({
            ...callbackHandlerCounterSell.getBill.parameter,
            payload: {
              ...callbackHandlerCounterSell.getBill.parameter.payload,
              date: da,
              status: status,
              page: page,
              pageSize: 10,
            },
          });
        }
      }}
      billCount={billCount}
      callback={(item) => {}}
      filterData={(date, status, page) => {
        // getBill(
        //   {
        //     date: date,
        //     status: status,
        //     filter: "null",
        //     search: "null",
        //     page: page,
        //     pageSize: 10,
        //   },
        //   getBillCounterSell,
        //   Swal,
        //   billNumber,
        //   dispatch,
        //   setBillNo,
        //   setBills,
        //   setBillCount,
        //   billCount
        // );
        callbackHandlerCounterSell.getBill.callback({
          ...callbackHandlerCounterSell.getBill.parameter,
          payload: {
            ...callbackHandlerCounterSell.getBill.parameter.payload,
            date: date,
            status: status,
            page: page,
            pageSize: 10,
          },
        });
      }}
    />
        }      
           
              </Grid> 
          
              </>}
        {/* {searchOutStandingsModal && */}

        {/* } */}
          
        </Grid>
        <Drawer
          anchor={"right"}
          open={mobileTableDrawer}
          onClose={() => {
            dispatch(setMobileTableDrawer(false));
            // setDrawerShow(false);
          }}
          onClick={() => setMobileTableDrawer(false)}
        >
          <TableWithBox
            data={bills}
            pagesCount={billCount?.totalBills}
            billApiCall={(da) => {
              // getBill(
              //   {
              //     date: da,
              //     status: "",
              //     filter: "",
              //     search: "",
              //     page: 1,
              //     pageSize: 10,
              //   },
              //   getBillCounterSell,
              //   Swal,
              //   billNumber,
              //   dispatch,
              //   setBillNo,
              //   setBills,
              //   setBillCount,
              //   billCount
              // );
              callbackHandlerCounterSell.getBill.callback({
                ...callbackHandlerCounterSell.getBill.parameter,
                payload: {
                  ...callbackHandlerCounterSell.getBill.parameter.payload,
                  date: da,
                },
              });
            }}
            paginationApiCall={(da, page, status, paymentMode) => {
              if (paymentMode) {
                // getBill(
                //   {
                //     date: da,
                //     status: status,
                //     filter: paymentMode,
                //     search: "null",
                //     page: page,
                //     pageSize: 10,
                //   },
                //   getBillCounterSell,
                //   Swal,
                //   billNumber,
                //   dispatch,
                //   setBillNo,
                //   setBills,
                //   setBillCount,
                //   billCount
                // );
                callbackHandlerCounterSell.getBill.callback({
                  ...callbackHandlerCounterSell.getBill.parameter,
                  payload: {
                    ...callbackHandlerCounterSell.getBill.parameter.payload,
                    date: da,
                    status: status,
                    filter: paymentMode,
                    page: page,
                    pageSize: 10,
                  },
                });
              } else {
                // getBill(
                //   {
                //     date: da,
                //     status: status,
                //     filter: "null",
                //     search: "null",
                //     page: page,
                //     pageSize: 10,
                //   },
                //   getBillCounterSell,
                //   Swal,
                //   billNumber,
                //   dispatch,
                //   setBillNo,
                //   setBills,
                //   setBillCount,
                //   billCount
                // );
                callbackHandlerCounterSell.getBill.callback({
                  ...callbackHandlerCounterSell.getBill.parameter,
                  payload: {
                    ...callbackHandlerCounterSell.getBill.parameter.payload,
                    date: da,
                    status: status,
                    page: page,
                    pageSize: 10,
                  },
                });
              }
            }}
            billCount={billCount}
            callback={(item) => {}}
            filterData={(date, status, page) => {
              // getBill(
              //   {
              //     date: date,
              //     status: status,
              //     filter: "null",
              //     search: "null",
              //     page: page,
              //     pageSize: 10,
              //   },
              //   getBillCounterSell,
              //   Swal,
              //   billNumber,
              //   dispatch,
              //   setBillNo,
              //   setBills,
              //   setBillCount,
              //   billCount
              // );
              callbackHandlerCounterSell.getBill.callback({
                ...callbackHandlerCounterSell.getBill.parameter,
                payload: {
                  ...callbackHandlerCounterSell.getBill.parameter.payload,
                  date: date,
                  status: status,
                  page: page,
                  pageSize: 10,
                },
              });
            }}
          />
        </Drawer>
      </Box>
    </>
  );
}
