import { Box, Fab, Grid, Tooltip, Typography } from "@mui/material";
import { useNavigate } from "react-router";

import { useEffect, useState } from "react";

import { setUserDetail } from "../app/store/reducer";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router/dist";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
import PersonIcon from "@mui/icons-material/Person";
import StorefrontIcon from "@mui/icons-material/Storefront";
import TableBarIcon from "@mui/icons-material/TableBar";
import TableRestaurantIcon from "@mui/icons-material/TableRestaurant";
import SearchIcon from "@mui/icons-material/Search";
import HandshakeIcon from "@mui/icons-material/Handshake";
import DashboardIcon from "@mui/icons-material/Dashboard";
import CurrencyRupeeOutlinedIcon from "@mui/icons-material/CurrencyRupeeOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import HeadsetMicOutlinedIcon from "@mui/icons-material/HeadsetMicOutlined";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import ShareOutlinedIcon from "@mui/icons-material/ShareOutlined";
import { routePath } from "../constants/routePath";
import LogOutPop from "./logoutPop";

export default function SideBar() {
  const navigate = useNavigate();
  const iconsObj = {
    PersonIcon: PersonIcon,
    StorefrontIcon: StorefrontIcon,
    TableBarIcon: TableBarIcon,
    TableRestaurantIcon: TableRestaurantIcon,
    SearchIcon: SearchIcon,
    HandshakeIcon: HandshakeIcon,
    DashboardIcon: DashboardIcon,
    CurrencyRupeeOutlinedIcon: CurrencyRupeeOutlinedIcon,
    SettingsOutlinedIcon: SettingsOutlinedIcon,
    LockOutlinedIcon: LockOutlinedIcon,
    HeadsetMicOutlinedIcon: HeadsetMicOutlinedIcon,
    ShareOutlinedIcon: ShareOutlinedIcon,
    LogoutOutlinedIcon: PowerSettingsNewIcon,
  };
  const sideBarMenu = useSelector((state) => state.reducer.sideBarMenu);
  const urlSideBarMenu = useSelector((state) => state.reducer.urlSideBarMenu);
  const dispatch = useDispatch();

  const location = useLocation();
  const [open, setOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(-1);
  useEffect(() => {
    const indexRoute3 = urlSideBarMenu.findIndex(
      (it) => it.route === location.pathname
    );

    setCurrentPage(indexRoute3);
  }, [location.pathname]);

  return (
    <Grid
      container
      spacing={0}
      sx={{
        background: "#3695b6",
        overflowY: "auto",
        // maxHeight: "calc(100vh - 60px)",
        maxHeight: {
          xs: "100vh",
          // sm: "calc(100vh - 60px)",
          // sm:
        },
        scrollbarWidth: "thin",
        msOverflowStyle: "none",
        "&::-webkit-scrollbar": {},
        "&::-webkit-scrollbar-thumb": {
          background: (theme) => theme.palette.secondary.main, // Color of the thumb (scrollbar handle)
          borderRadius: "6px",
        },
        "&::-webkit-scrollbar-thumb:hover": {
          background: "#FFC107",
        },
      }}
    >
      <Grid sx={{ minHeight: "100vh", height: "auto" }} item md={12}>
        {sideBarMenu?.map((item, index) => {
          const indexRoute = urlSideBarMenu.findIndex(
            (it) => it.systemFuctionName === item.systemFuctionName
          );
          const IconComponent = iconsObj[urlSideBarMenu[indexRoute]?.icon];
if(item.systemFuctionName =="Sign Out")return null 
          return (
            <Box
              key={"siderbar" + index}
              bgcolor="primary"
              sx={{
                padding: item.systemFuctionName === "Sign Out" ? "4px" : "10px",
                height:
                  item.systemFuctionName !== "Sign Out" ? "1.5rem" : "3rem",
                borderBottom: "1px solid white",
                background: currentPage === index ? "#127093" : "",
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
              }}
              onClick={async (e) => {
                if (item.systemFuctionName === "Sign Out") {
                  // await sessionStorage.removeItem("smbToken");
                  // dispatch(setUserDetail({}));
                  // navigate(routePath.LANDINGPAGE);
                  e.stopPropagation();
                  setOpen(true);
                } else {
                  if (indexRoute !== -1) {
                    if (urlSideBarMenu[indexRoute].route.startsWith("http")) {
                      window.open(urlSideBarMenu[indexRoute].route, "_blank");
                    } else {
                      navigate(urlSideBarMenu[indexRoute].route);
                    }
                  }
                }
              }}
            >
              {item.systemFuctionName === "Sign Out" && (
                <Box
                  sx={{
                    mr: "10px",
                    "& > svg": {
                      fill: "white",
                    },
                    display: "flex",
                    justifyContent: "left",
                    alignItems: "center",
                  }}
                >
                  <Fab
                    // color="red"
                    sx={{
                      backgroundColor: "red !important",
                      width: "40px",
                      height: "40px",
                    }}
                    // sx={{ float: "right" , }}
                    onClick={() => {
                      // setOpen(true);
                    }}
                  >
                    {/* <LogoutIcon /> */}
                    <PowerSettingsNewIcon />
                  </Fab>
                </Box>
              )}
              {item.systemFuctionName !== "Sign Out" && (
                <Box
                  sx={{
                    mr: 1,
                    "& > svg": {
                      fill: "white",
                    },
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {IconComponent && <IconComponent />}
                </Box>
              )}
              <Typography variant="standard" color="white" textAlign={"left"}>
                {" "}
                {item.regionalFunctionName}
              </Typography>
            </Box>
          );
        })}
      </Grid>
      <LogOutPop open={open} setOpen={setOpen} />
    </Grid>
    // </Box>
  );
}
