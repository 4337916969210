import { Card, CardContent, Stack, Tooltip, Typography } from "@mui/material";
import React from "react";

export default function ItemCard({ item, setEditedItem }) {
  return (
    <Card
      sx={{
        cursor: "pointer",
        ":hover": {
          bgcolor: "#2892ba17",
        },
      }}
      onClick={setEditedItem}
    >
      <CardContent sx={{ padding: "0.5rem 0.7rem !important" }}>
        <Tooltip title={item.itemDescription}>
          <Typography
            color="primary"
            sx={{
              overflow: "Hidden",
              textOverflow: "ellipsis",
              display: "-webkit-box",
              "-webkit-line-clamp": "1",
              "-webkit-box-orient": "vertical",
              fontWeight: 600,
            }}
          >
            {item.itemDescription}
          </Typography>
        </Tooltip>
        <Stack direction="row">
          <Typography sx={{ fontSize: "14px" }}>Rate (₹):&nbsp;</Typography>
          <Typography sx={{ fontSize: "14px" }} color="primary">
            {item.price}/{item.unit}
          </Typography>
        </Stack>
        {/* <Stack direction="row">
          <Typography sx={{ fontSize: "14px" }}>GST:&nbsp;</Typography>
          <Typography sx={{ fontSize: "14px" }} color="primary">
            {item.gst} %
          </Typography>
        </Stack> */}
      </CardContent>
    </Card>
  );
}
