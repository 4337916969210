import { Tab, Tabs } from "@mui/material"
import { Product, Services } from "./detail"
import { useState } from "react";

export const ServicesContainer=()=>{
  const [value, setValue] =  useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
    return (
        <> 
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
          centered
          sx={{
            "& .MuiTabs-indicator": {
              backgroundColor: "#5A396D",
            },
          }}
        >
          <Tab
          value={0}
            label="Services" 
            className="mulish"
            sx={{
              "&.Mui-selected": {
                color: "#e80089ed",
              },
            }}
          />
          <Tab
          value={1}
            label="Products" 
            className="mulish"
            sx={{
              "&.Mui-selected": {
                color: "#e80089ed",
              },
            }}
          />
        </Tabs>
        {/* {value==0 && ( */}
        <Services value={value} />

        {/* )}

        {
            value==1 && (
                <Product />
            )
        } */}
        </>
    )
}