import { swalAlertPopProps } from "../../utils/commonUtils";

export const updateBil = async (param,UpdateBillCounterSell,Swal,navigate,routePath,callback) => {
    await UpdateBillCounterSell(param)
      .then((res) => {
        if (res?.data?.succeeded) {
          Swal.fire({
            text: "Update successfully",
            icon: "success",
            
            ...swalAlertPopProps,
          });
        } else {
          Swal.fire({
            text: res?.data?.message,
            icon: "success",
            ...swalAlertPopProps,
          });
        }
        callback(res)

        navigate(routePath.COUNTERSELL);
      })
      .catch((err) => {
        Swal.fire({
          text: "error ",
          icon: "warning",
          
          ...swalAlertPopProps,
        });
      });
  };