import {
    Card,
    CardContent,
    Grid,
    Stack,
    Tooltip,
    Typography,
  } from "@mui/material";
  import React from "react";
  import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee"; 
import { convertDateToCustomFormat } from "../../utils/commonUtils";
import { Gif } from "@mui/icons-material";
export const CardD=({ item })=> {
  console.log("item card ",item)
    return (
      <Card
        sx={{
          width: "100%",
          mt: "10px",
          cursor: "pointer",
          ":hover": {
            bgcolor: "#2892ba17",
          },
          "& .MuiCardContent-root": { p: "0rem" }
        }}
      >
        <CardContent sx={{ padding: "0rem 0.7rem !important" }}>
          <Grid container>
            <Grid item xs={6}>
              <Grid container >
                <Grid item xs={12}>
                <Typography
                color="primary"
                sx={{
                  fontSize: "15px",
                  overflow: "Hidden",
                  display: "-webkit-box",
                  "-webkit-line-clamp": "1",
                  "-webkit-box-orient": "vertical",
                  fontWeight: 600,
                }}
              >
                {
                 item?.name }
              </Typography>
                </Grid>
                <Grid item xs={12}>
                <Typography 
                sx={{
                  fontSize: "15px",
                  overflow: "Hidden",
                  display: "-webkit-box",
                  "-webkit-line-clamp": "1",
                  "-webkit-box-orient": "vertical",
                 
                }}
              >
                {
                 item?.address }
              </Typography>
                </Grid>
              </Grid>
            </Grid>
  
            <Grid
              item
              xs={6}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CurrencyRupeeIcon color="primary" fontSize="10px" />
              <Typography
                color="primary"
                sx={{
                  textOverflow: "ellipsis",
                }}
              >
                {item?.amount?.toFixed(2)}
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    );
  }
  