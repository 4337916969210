
import { ADDCASHPAYMENT, GETASSISTANTPAYROLLDETAILFOREMP, GETCOUNTERSALESCUSTOMERS, GETPAYMENTRECEIPT, GETPAYMENTRECEIPTS, GETPAYMENTXLSX, GETUNPAIDBILLS } from "../../utils/api"
import axios from "../../utils/axios";


export const getUnpaidUser=async(param)=>{
    const res=await axios.post(GETUNPAIDBILLS,param);
    return res;
}
export const getEmpearningSalary=async(param)=>{
    const res=await axios.post(GETASSISTANTPAYROLLDETAILFOREMP,param);
    return res;
}

export const getPaymentReceipt=async(param)=>{
    const res=await axios.post(GETPAYMENTRECEIPT,param);
    return res;
}

 
export const getPaymentXlsx=async(param)=>{
    const res=await axios.post(GETPAYMENTXLSX,param)
    return res;
}

//----LATEST API
export const getCounterSalesCustomers=async(param)=>{
    const res=await axios.post(GETCOUNTERSALESCUSTOMERS,param)
    return res;
}

export const AddCashPayment=async(param)=>{
    const res=await axios.post(ADDCASHPAYMENT,param);
    return res;
}