import { Box, DialogTitle, Divider, Grid, Modal, TextField } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import React, { useRef, useState } from "react";
import DatePicker from "react-datepicker";

import EventIcon from "@mui/icons-material/Event";
export const DateCp = ({label,setFormatDate}) => {
    const [formData, setFormData] = useState({
        customer: "",






        customerMobile: "",
        birthdate: "",
        anniversaryDate: "",
        customerMailID: "",
        gender: ""
    })
    const [startDate, setStartDate] = useState(new Date());
    const datePickerRef = useRef();
    const openDatePicker = () => {
        if (datePickerRef.current) {
            datePickerRef.current.setOpen(true);
        }
    };
    const addLeadingZero = (value) => {
        return value < 10 ? "0" + value : value;
    };
    const [date, setDate] = useState(
        (() => {
            var currentDate = new Date();
            let dateContainer = String(currentDate).split(" ");
            var year = dateContainer[2];
            var month = dateContainer[1];
            var day = dateContainer[3];
            var formattedDate =
                year + "-" + addLeadingZero(month) + "-" + addLeadingZero(day);

            return formattedDate;
        })()
    );
    return (



        <React.Fragment>
            <TextField
                fullWidth
                // disabled
                readonly
                onClick={openDatePicker}
                id="date"
                value={date}
                label={
                    <Box
                        sx={{
                            flex: 4,
                            color: "grey",
                            cursor: "pointer",
                            whiteSpace: "nowrap",
                        }}
                    >
                        {label}{/* {new Date().toLocaleDateString()} */}
                    </Box>
                }
                onChange={(e) => { }}
                InputProps={{
                    style: {
                        height: "40px",
                    },
                    endAdornment: (
                        <EventIcon
                            sx={{
                                color: (theme) => theme.palette.primary.main,
                                cursor: "pointer",
                            }}
                        />
                    ),
                }}
            />
            <DatePicker
                hidden={true}
                selected={startDate}
                maxDate={new Date()}
                onChange={(date) => {
                    const dateStr2 = date.toLocaleDateString("en-GB", {
                        year: "numeric",
                        month: "long",
                        day: "2-digit",
                    });
                    let dateContainer = String(dateStr2).split(" ");
                    var year2 = dateContainer[2];
                    var month2 = dateContainer[1];
                    var day2 = dateContainer[0];
                    var formattedDate2 = day2 + "-" + month2 + "-" + year2;

                    setDate(formattedDate2);
                    setFormatDate(date)
                    setStartDate(date);
                }}
                className="custom-datepicker"
                ref={datePickerRef}
                onClickOutside={() => datePickerRef.current.setOpen(false)}
            />

        </React.Fragment>
    )
}