import { GETACCOUNTRECHAGEDETAILS, GETCUSTOMERAPPOINMENTS, GETRENEWSUBSCRIPTIONDETAIL } from "../../utils/api"
import axios from "../../utils/axios"

export const getAppoinment=async()=>{
    const res=await axios.get(GETCUSTOMERAPPOINMENTS)
    return res;
}
export const getNewSubscriptionReachageDetails=async()=>{
    const res=await axios.get(GETRENEWSUBSCRIPTIONDETAIL)
    return res;
}

// GETRENEWSUBSCRIPTIONDETAIL



export const getAllAppoint=async(payload,callback)=>{
    await axios
    .post(GETCUSTOMERAPPOINMENTS, payload)
    .then((res) => {
      console.log("res search ", res);
      callback(res.data.data) 
    })
    .catch((err) => {
      console.log(err);
    });
}