export const PageSizeCalculate = (numberOfRecords, pagination) => {
  return Number.isInteger(numberOfRecords / pagination?.pageSize)
    ? numberOfRecords / pagination?.pageSize
    : parseInt(numberOfRecords / pagination?.pageSize) + 1;
};

export const totalPrice=(arr)=>{ 
  let sum=0;
  arr?.forEach((item)=>{
    sum+=item?.price
  })
  return sum?.toFixed(2); 
}