export const validationsAdd = (ExpenseItems) => {
  if (ExpenseItems?.description === "") {
    return { status: false, msg: "Please Select Product" };
  } else if (ExpenseItems?.amount === 0) {
    return { status: false, msg: "Please Enter Amount" };
  }
  return { status: true, msg: "" };
};

export const validationsSave = (ExpenseItemssArr) => {
  if (ExpenseItemssArr.length === 0) {
    return {
      status: false,
      msg: "Please Add Expense",
    };
  } else if (ExpenseItemssArr.length === 0) {
    return {
      status: false,
      msg: "Please Add Expense",
    };
  }
  return {
    status: true,
    msg: "",
  };
};
