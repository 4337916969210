// KYCDocument/GetKYCDocuments/{page}/{pageSize}
import { GETKYCDOCUMENTS, UPLOADDOCUMENTS } from "../../utils/api";
import axios from "../../utils/axios";

export const GetKYCDocuments = async (page, pageSize) => {
  const res = await axios.get(GETKYCDOCUMENTS + "/" + page + "/" + pageSize);
  return res;
};
export const uploadDocumnets = async (params) => {
  const res = await axios.post(UPLOADDOCUMENTS, params);
  return res;
};
