import { createTheme } from "@mui/material";

export default createTheme({
  palette: {
    myVariant: {
      main: "#F2F2F2",
    },
    primary: {
      main: "#3695B6",
      contrastText: "#fff",
    },
    secondary: {
      main: "#bb4a9b",
      contrastText: "#fff",
    },
    tertiary: {
      main: "green",
      contrastText: "#fff",
    },
    quaternary: {
      main: "#505050",
      contrastText: "#fff",
    },
  },
  components: {
    MuiInputBase: {
      styleOverrides: {
        input: {
          // This will apply the capitalize style to all placeholders
          '&::placeholder': {
            textTransform: 'capitalize',  // Capitalizes the first letter of each word in the placeholder
          },
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: {
        "@global": {
          // Apply styles to scrollbar for Firefox and Edge
          "*": {
            scrollbarColor: `${(theme) => theme.palette.secondary.main} ${(
              theme
            ) => theme.palette.myVariant.main}`,
          },
          "*::-webkit-scrollbar": {
            // Apply styles to scrollbar for Chrome, Safari, and newer versions of Edge
            width: "12px",
          },
          "*::-webkit-scrollbar-thumb": {
            backgroundColor: (theme) => theme.palette.secondary.main,
            borderRadius: "6px",
          },
          "*::-webkit-scrollbar-thumb:hover": {
            backgroundColor: (theme) => theme.palette.secondary.dark,
          },
          "*::-webkit-scrollbar-track": {
            backgroundColor: (theme) => theme.palette.myVariant.main,
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
        },
      },
    },

    MuiTypography: {
      defaultProps: {
        variantMapping: {
          description: "p",
          labelFilled: "p",
        },
      },
      MuiCancelIcon: {
        styleOverrides: {
          root: {
            color: "red",
            background: "red",
            fontSize: "100px",
          },
        },
      },
      MuiBreadcrumbs: {
        separator: {
          fontWeight: "bold",
          color: "red",
        },
      },
      styleOverrides: {
        // description: {
        //   color: "#505050",
        //   fontSize: "14px",
        // },
        // subtitle: {
        //   color: "#027fae",
        //   fontWeight: "800",
        // },
        // subtitle2: {
        //   fontSize: "16px",
        //   fontWeight: 700,
        // },
      },
    },
  },
});
