import React, { useEffect, useState } from 'react'; 
import BarcodeScannerComponent from 'react-qr-barcode-scanner';
import axios from '../utils/axios';
import { GETITEMBYBARCODE } from '../utils/api';
import { Button } from '@mui/material';
import QrCodeIcon from '@mui/icons-material/QrCode';
const BarcodeScanner = ({ui,callback,tryD           }) => {
  const [barcodeNumber, setBarcodeNumber] = useState({});
  const [flag, setFlag] = useState(true);
  const apiCall=async(payload)=>{
    await axios.post(GETITEMBYBARCODE,payload).then((res)=>{
      console.log("result result scan dd ",res?.data)
      ui(res?.data)
    }).catch((res)=>{

    })
  }
  useEffect(()=>{
    tryD()
  },[])
  // callback({
  //   "barCode": 1

  // })
  const handleBarcodeResult = (result) => {
    console.log("result result scan",result)
    if (result) {
      const barcode = result;  // Get the scanned barcode
      
      apiCall({
        "barCode": barcode
      })
      if (!flag) {
        let qtyCount = 0;

        // If barcode doesn't exist, add it with initial count, else update its count
        if (!barcodeNumber[barcode]) {
          qtyCount++;
          setBarcodeNumber({ ...barcodeNumber, [barcode]: qtyCount });
        } else {
          qtyCount = barcodeNumber[barcode];
          qtyCount++;
          setBarcodeNumber({ ...barcodeNumber, [barcode]: qtyCount });
        }

        setFlag(true);  // Set flag to true to avoid re-processing
        // setTimeout(() => setFlag(false), 2000);  // Reset flag after a delay
      }
    }
  };

  const [barcode, setBarcode] = useState('');
  const [scannedData, setScannedData] = useState('');

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Enter') {
        handleBarcodeResult(barcode)
        setScannedData(barcode); // Store the scanned data
        setBarcode(''); // Clear the barcode input
      } else {
        setBarcode((prev) => prev + event.key); // Append the scanned characters
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [barcode]);
  return (
    <div> 
      {
       !flag &&     <BarcodeScannerComponent
        width={500}
        // height={500}
        onUpdate={(err, result) => {
          if (result) {
            handleBarcodeResult(result);
          }
        }}
      />
      }
   
      <div>
       
      </div>
     {/* {flag && ( <Button variant='contained' sx={{m:2}}  startIcon={<QrCodeIcon />} onClick={()=>{ setFlag(false)}}>Scan Code</Button>)} */}
    </div>
  );
};

export default BarcodeScanner;
