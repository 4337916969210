import * as React from "react";
import Box from "@mui/material/Box";
import "../App.css";
import ReceiptIcon from "@mui/icons-material/Receipt";
import SettingsIcon from "@mui/icons-material/Settings";
import Typography from "@mui/material/Typography";
import SwapHorizIcon from "@mui/icons-material/SwapHoriz";
import Grid from "@mui/material/Grid";
import logo from "../assets/headers/logoSMB.png";
import FavoriteIcon from "@mui/icons-material/Favorite";

import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import { Button, Divider, Fab, Tooltip } from "@mui/material";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
import { routePath } from "../constants/routePath";
import LogoutIcon from "@mui/icons-material/Logout";
import {
  setAnalaticDisplay,
  setBarSide,
  setBussinessSideBar,
  setchangeRoll,
  setCustomerManagmentDisplay,
  setDrawerShow,
  setTableName,
} from "../app/store/reducer";
import MenuIcon from "@mui/icons-material/Menu";
import Timer from "./timer";
import { setActiveHeader } from "./commonUtils/commonUtilFunction";
import { useState } from "react";
// smbfavicon
import smbfavicon from "../assets/img/logo.svg";
import LogOutPop from "./logoutPop";
// import LogOutPop from "./logoutPop";
import { DrawerTableBills } from "./drawerTableBills";
export default function Header({ menuLoginHeader, setDrawerShowSideBar }) {
  const location = useLocation();

  const headerMenu = useSelector((state) => state.reducer.headerMenu);
  const urlHeaderMenu = useSelector((state) => state.reducer.urlHeaderMenu);
  const drawerShow = useSelector((state) => state.reducer.drawerShow);
  console.log(urlHeaderMenu, "urlHeaderMenu");
  const customerChategory = useSelector((state) => state.reducer.customerChategory);
  const bussinessMangementMenu = useSelector((state) => state.reducer.bussinessMangementMenu);
  
  const activeHeader={
    "bussinessMangment":0
  }
  const [currentPage, setCurrentPage] = React.useState(3);
  const userDetail = useSelector((state) => state.reducer.userDetail);
  const actvieBussinessDetails = useSelector(
    (state) => state.reducer.actvieBussinessDetails
  );
  const analatics = useSelector(
    (state) => state.reducer.analatics
  );
  
  console.log(actvieBussinessDetails, "actvieBussinessDetails");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);


  const [header1, setHeader1] = useState([]);
  const [header2, setHeader2] = useState([]);

  const [reportActive, setReportActive] = useState(false);
  const [active, setActive] = useState(0);
  
  const data={
    "advertice":0,
    "dashboard":0,
    "expence":1,
    "addOffers":2, 
    "countersale":3,
  }
  const checkHeaderUrl = (loc) => {
    const validPaths = [
      "/item-services",
      "/stocks",
      "/purchase",
      "/payament-receipt",
      "/outstanding-report",
      "/countersell",
      "/expence",
    ];

    return validPaths.includes(loc);
  };
  React.useEffect(() => {
    const key =
    location.pathname.split("/")[1]
    console.log("lcation",data[key]); 
    
    
      setActive(data[key] )
    
      
    
  }, [header1]); 
 
  const NameData = {
    Home: 1,
    "Business Mangment": 1,
    "Customer Managment": 1,
  };
  const [headerOption, setHeaderOption] = useState({
    arr1: [],
    arr2: [],
  });
  const titleHead1 = (ele) => {
    const indexRoute = headerMenu.findIndex(
      (it) => it.systemFuctionName === ele.systemFuctionName
    );
    if (NameData[ele?.systemFuctionName]) {
      return ele?.systemFuctionName;
    }
    if (headerMenu[indexRoute]?.regionalFunctionName.includes("Counter Sale")) {
      return "Billing";
    }

    return headerMenu[indexRoute]?.regionalFunctionName;
  };

  const titleheadT = (index) => {
    const indexRoute = headerMenu.findIndex(
      (it) => it.systemFuctionName === header2[index]?.systemFuctionName
    );

    if (!headerMenu[indexRoute]?.regionalFunctionName) {
      return header2[index]?.systemFuctionName;
    }

    console.log("indexRoute ", headerMenu[indexRoute], " ", indexRoute);
    return headerMenu[indexRoute]?.regionalFunctionName;
  };
  const arrN = (urlHeaderMenu) => {
    
    
      setHeader1(urlHeaderMenu)
     
  };
  const eventCallHeader = {
    BusinessMangment: () => {
      console.log("called function");

    },
    CustomerManagment: () => {

    },
  };
  console.log("urlHeaderMenuurlHeaderMenuurlHeaderMenu  ",urlHeaderMenu)
  React.useEffect(() => {
    arrN(urlHeaderMenu);
  }, [urlHeaderMenu]);
  React.useEffect(() => {
    console.log("header2 header1 ", header1);

    console.log("header2 ", header2);
  }, [header2]);
  return (
    <Box sx={{ flexGrow: 1, borderBottom: "1px solid #edebed" }}>
      <Grid container>
        <Grid
          item
          xs={12}
          sm={2}
          md={2}
          lg={2}
          sx={{
            borderRight: "0.2px solid #e5e5e5",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "#fff",
            cursor: "pointer",
            height: "auto",
          }}
          onClick={() => {
            if (location.pathname !== routePath.LOGIN) navigate("/");
          }}
        >
          {/* logo */}
          <Box component={'img'} src={smbfavicon} alt="logo" sx={{height:{xs:'80px', lg:'90px', xl:'100px'}}}/>
        </Grid>
        <Grid xs={10} sm={10} md={10} lg={10} sx={{ backgroundColor: "#fff" }}>
          {" "}
          <Grid
            container
            spacing={0}
            sx={{ p: "0px 0px", height: " ", backgroundColor: "#ffffff" }}
          >
            <Grid xs={12}>
              <Grid container sx={{ paddingTop: "10px" }}>
                <Grid
                  md={9}
                  lg={8}
                  sx={{ paddingLeft: { md: 2, lg: 5, xl: 8 } }}
                >
                  <Box sx={{ display: "flex",  gap:2, alignItem: "center" }}>
                    <Box
                      component={"img"}
                      src={actvieBussinessDetails?.logoPath}
                      sx={{
                        width: "50px",
                        height: "50px",
                        borderRadius: "50%",
                      }}
                    />{" "}
                    <Box
                      sx={{
                        display: {
                          md: "flex",
                          alignItems: "center",
                        },
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: "14px",
                          color: "primary.main",
                          fontWeight: "600",
                        }}
                      >
                        {" "}
                        {actvieBussinessDetails?.enterpriseName},
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "14px",
                          color: "primary.main",
                          fontWeight: "600",
                          margin: "0px 5px",
                        }}
                      >
                        {" "}
                        {actvieBussinessDetails?.branchName}
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: {
                          xs: "flex",
                          alignItems: "center",
                          gap: "5px",
                        },
                      }}
                    >
                      <LocalPhoneIcon
                        sx={{
                          fontSize: "21px",
                        }}
                        color="primary"
                      />
                      <Typography
                        color="primary.main"
                        variant="subtitle2"
                        margin={0}
                        fontWeight={"bold"}
                      >
                        {userDetail?.mobileNumber}
                      </Typography>
                      <Timer />
                    </Box>
                  </Box>
                
                </Grid>
                <Grid
                  md={3}
                  lg={4}
                  sx={{
                    display: "flex",
                    alignItem: "end",
                    justifyContent: "end",
                    paddingRight: 2,
                  }}
                >
                  {userDetail?.appMenu == "Biller" && (
                    <Tooltip title="Change Role">
                      <Fab
                        sx={{
                          mr: { md: 1.5 },
                        }}
                        color="primary"
                        aria-label="Add to Cart"
                        size="small"
                        onClick={() => {
                          // navigate(routePath.FAV)
                          navigate(routePath.SERVICESLIST);

                          dispatch(setchangeRoll("customer"));
                        }}
                      >
                        <SwapHorizIcon />
                      </Fab>
                    </Tooltip>
                  )}

                  <Fab
                    color="primary"
                    aria-label="Add to Cart"
                    size="small"
                    // sx={{ float: "right" , }}
                    onClick={() => setDrawerShowSideBar(true)}
                  >
                    <Tooltip title="Settings">
                      <SettingsIcon />
                    </Tooltip>
                  </Fab>

                  <Fab
                    // color="red"
                    sx={{
                      ml: { md: 1.5 },
                      background: "red",
                    }}
                    aria-label="Add to Cart"
                    size="small"
                    // sx={{ float: "right" , }}
                    onClick={() => {
                      setOpen(true);
                    }}
                  >
                    <Tooltip title="Log Out">
                      {/* <LogoutIcon /> */}
                      <PowerSettingsNewIcon />
                    </Tooltip>
                  </Fab>
                </Grid>
              </Grid>
            </Grid>

            <Grid
              xs={10.9}
              sx={{
                paddingLeft: { md: 1, lg: 4, xl: 7 },
                paddingTop: { md: 0.5, lg: 1, xl: 2 },
              }}
            >
              {menuLoginHeader && (
                <Grid
                  item
                  // xs={10}

                  sx={{ display: "flex", alignItems: "center" }}
                >
                  {" "}
                  {menuLoginHeader?.map((item, index) => (
                    <Typography
                      key={"menuLogin" + index}
                      variant="h6"
                      gutterBottom
                      align="center"
                      sx={{
                        margin: "0px 10px",
                      }}
                    >
                      {item.title}
                    </Typography>
                  ))}
                </Grid>
              )}
              {!menuLoginHeader && (
                <Grid
                  className="navHeader"
                  item
                  sx={{
                    display: "flex",
                    overflowY: "hidden",
                    justifyContent: "space-between",
                    overflowX: "auto", // Enable horizontal scrolling
                    whiteSpace: "nowrap",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    {" "}
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        // justifyContent: "left",
                        height: "35px",
                        width: "100%",
                        // background: "red",
                        // background: "red",
                      }}
                    >
                      {header1?.map((item, index) => {
                        return (
                          <>
                            {  (
                              <Box
                                // bgcolor={
                                //   index === header1?.length - 1
                                //     ? "secondary.main"
                                //     : "primary.main"
                                // }
                                bgcolor={
                                  index == active
                                    ? "primary.main"
                                    : "white"
                                }
                                textAlign="center"
                                sx={{
                                  borderRadius: "8px 8px 0px 0px",

                                  // minWidth: "12rem",
                                  // alignContent: "center",

                                  padding: "0px 20px",
                                  display: "flex",
                                  alignItems: "center",
                                  cursor: "pointer",

                                  margin: "10px 5px",
                                  justifyContent: "center",
                                  minHeight: "2.0rem",
                                  // width
                                  // margin: "0px 1px",
                                }}
                                onClick={() => {
                                  let tt = titleHead1(item);
                                  setActive(index);
                                  console.log(
                                    "item systemFuctionName ",
                                    tt
                                  );
                                  if (
                                    tt !== "Business Mangment" ||
                                    tt !== "Customer Managment"
                                  ) {

                                     
                                  }
                                  if (
                                    tt === "Business Mangment"
                                  ) {
                                   
                                    if(bussinessMangementMenu[0]){
                                      navigate(bussinessMangementMenu[0].route)
                                    }
                                    
                                    
                                    
                                    return;
                                  }
                                  if (
                                    
                                    tt === "Customer Managment"

                                  ) {
                                   
                                    if(customerChategory[0]){
                                      navigate(customerChategory[0].route)
                                    }
                                    
                                    
                                    return;
                                  }
                                  
                                  if (tt == "Dashboard") {
                                    console.log(
                                      "item systemFuctionName ",
                                      item.systemFuctionName
                                    );
                                    if(analatics[0]){
                                      navigate(analatics[0].route)
                                    }
                                    
                                    
                                    return;
                                  }
                                  
                                  

                                  navigate(item?.route);
                                }}
                                // boxShadow={4} // Adds shadow effect to the box
                              >
                                <Typography
                                  variant="subtitle2"
                                  gutterBottom
                                  key={"headerMenu" + index}
                                  align="center"
                                  sx={{
                                    fontSize: "12px",
                                    fontWeight: "bold",
                                    p: 0,
                                    m: 0,

                                    cursor: "pointer",
                                    color:
                                      index== active
                                        ? "white"
                                        : "black",
                                  }}
                                >
                                  {titleHead1(item) == "Dashboard"
                                    ? "Analysis"
                                    : titleHead1(item)}
                                </Typography>
                              </Box>
                            )}

                           
                          </>
                        );
                      })}
                    </Box>
                  </Box>
                </Grid>
              )}
            </Grid>
            {/* <Grid
            item
            xs={3}
            sx={{
              display: "flex",
              justifyContent: "space-around",
              alignItems: "center",
              p: "10px",
            }}
          >         
         
          </Grid> */}
          </Grid>
        </Grid>
      </Grid>

      <LogOutPop open={open} setOpen={setOpen} />

      {/* {userDetail?.businessLineCode === "CB"  && <DrawerTableBills />} */}

      {/* {location.pathname !== routePath.COUNTERSELL && <DrawerTableBills />} */}
    </Box>
  );
}
