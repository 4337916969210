import React, { useEffect, useState } from "react";
import {
  TextField,
  Button,
  FormControl,
  Container,
  Grid,
  Box,
  Stack,
  OutlinedInput,
  InputLabel,
  MenuItem,
  ListItemText,
  Select,
  Checkbox,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormLabel,
  Modal,
} from "@mui/material";
// import "./test.css";

import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
// import { bookApoinment } from "./asppoinmentApi";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { routePath } from "../../constants/routePath";
import { setSession } from "../../utils/commonUtils";
import { setCustomerAppointmentId } from "../../app/store/reducer";
// import dayjs from 'dayjs';
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { getAlldetailSerice } from "../services/servicesListApi";
import EmployeeModal from "../../common/employeeModel";
import PersonIcon from "@mui/icons-material/Person";
import { getItems } from "../items_services/itemApi";
import { addCustomerAppointment, UpdatedCustomerAppointment } from "./appoinmentModalApi";
import { getAllEmployeeList } from "../employeeInfo/appoinmentDashboardApi";
import { ModalHeader } from "../../common/modalHeader";
dayjs.extend(utc);
dayjs.extend(timezone);

const Appoinmentadle = ({ open, setOpen,apiCallback,selectedCustomer,setSelectedCustomer }) => {
  const [openT, setOpenT] = useState(false);
  const appnType = useSelector((state) => state.reducer.apppoinmentUerType);
  const dispatch = useDispatch();
  const info = useSelector((state) => state.reducer.detail);
  const struct={
    billerId: "",
    customerName: "",
    mobileNumber: "",
    // otp: "",
    mailId: "",
    gender: "",
    service: "",
    date: "",
    hour: "",
    minute: "",
    status: "Unassigned",
    employeeId: "",
  }
  const businessChooseTab=useSelector((state)=>state.reducer.businessChooseTab)
  
  const [formData, setFormData] = useState({...struct});
const [employeDetail,setEmployeeDetail]=useState(null)
const [employeeList,setEmployeeList]=useState([])
useEffect(()=>{
  console.log("formData",formData)
},[formData])
  useEffect(() => {
    console.log("formate date  ", timeValue);
    console.log("ppoinment22 ", info);
  }, [formData]);
  const [dateValue, setDateValue] = useState(dayjs());
  const [timeValue, setTimeValue] = useState(dayjs());
  const [otpEnabled, setOtpEnabled] = useState(true);
const [servicesList,setServicesList]=useState([])

  const handleChange = (e) => {
    const { name, value } = e.target;
    // console.log()
    if (typeof value == "object") {
      setFormData({
        ...formData,
        [name]: value[0],
      });
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  const setDate = (newValue) => {
    setDateValue(newValue);

    // const date = dayjs(newValue).tz('UTC').format('YYYY-MM-DDTHH:mm:ss.SSZ');

    // console.log("date ",date)

    const date = dayjs(newValue).tz("UTC").format("YYYY-MM-DDTHH:mm:ss");

    console.log("date", date);

    // console.log("data ",date)
    setFormData((prev) => {
      return { ...prev, date: date };
      // return {...prev,date:date}
    });
  };

  const setTime = (newValue) => {
    setFormData((prev) => {
      if (newValue["$H"] == 20) {
        prev.hour = newValue["$H"] + 12;
      } else {
        prev.hour = newValue["$H"];
      }
      prev.minute = newValue["$m"];
      return { ...prev };
    });
    setTimeValue(newValue);
  };

  const handleTagsChange = (e) => {
    const { value } = e.target;

    console.log("val8e ", value);
    setFormData({
      ...formData,
      service: value,
    });
  };

  const gender = (e) => {
    setFormData((prev) => {
      let temp = { ...prev };
      if (e.target) {
        temp.gender = e.target.value;
      } else {
        temp.gender = e;
      }
      return { ...temp };
    });
  };

  const handleVerifymobileNumber = () => {
    if (formData.mobileNumber) {
      setOtpEnabled(true);
    }
  };

  const navigate = useNavigate();
  const handleSubmit = (e) => {
    e.preventDefault();
    // console.log(appnType, "appnType");

    const formDataobj = new FormData();
    Object.keys(formData).forEach((key, index) => {
      formDataobj.append(key, formData[key]);
    });
    // const condition = appnType == "profile";
    if(!selectedCustomer){
      addCustomerAppointment( {...formData,date :formData.date} ,   async(re)=>{
      

      
        // await setSession("appoinment",re)
        //   console.log("data 222 ",re?.data?.id)
        //   dispatch(setCustomerAppointmentId(re?.data?.id))
        apiCallback()
         
      })
    }
    else{
      UpdatedCustomerAppointment( {...formData,date :formData.date} ,   async(re)=>{
      

       
        apiCallback()
         
      })
    }
   
   
    console.log("value ", { ...formData, date: formData.date });
  };

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };
  const [servicesArr, setservicesArr] = useState([
    {
      serviceName: "Shaving",
      rate: "100.00/Per Head",
    },
    {
      serviceName: "Hair cutting",
      rate: "150.00/Per Head",
    },
  ]);
  const names = [
    "Haircut",
    "Hair Coloring",
    "Hair Styling",
    "Manicure",
    "Pedicure",
    "Facial",
    "Waxing",
    "Massage",
    "Makeup",
    "Bridal Makeup",
    "Threading",
    "Hair Spa",
    "Nail Art",
    "Skin Treatment",
  ];
  useEffect(() => {
    setFormData({
      ...formData,
      billerId: info.billerId,
    });
    setDate(timeValue);
    setTime(timeValue);
    gender("male");
    getAllEmployeeList((res) => {
      console.log("getAllEmployeeList", res);
      setEmployeeList(res?.list);
    });
  }, []);
  const [pagination, setPagination] = useState({ page: 1, total: 1 });

  const infoD = useSelector((state) => state.reducer.detail);
  useEffect(() => {
   if(open==true){
    console.log("payload ",infoD)
    getAlldetailSerice(
      {
        billerId: infoD?.billerId,
      },
      (res) => {
        console.log("Data res", res);
        setservicesArr(res?.data);
      }
    );
   
    let pageSize = 30;
    getItems({ pagination, pageSize }).then((res) => {
      console.log("services ",res?.data?.data)
      
      // setItmes(res.data.data);
      // setPagination({
      //   page: res.data.pageNumber,
      //   total: res.data.totalPages,
      // });
      // setTotalItems(res.data.totalRecords);
      setServicesList(res?.data?.data)
      if(selectedCustomer){
        console.log("selectedCustomer ",selectedCustomer)



       

        let data=employeeList.findIndex((ele)=>ele.id==selectedCustomer?.employeeId)

        console.log("user log ",data," ",selectedCustomer   )
        console.log("user log" ,         employeeList)
        setFormData({...formData,...selectedCustomer})
        if(data!=-1){
          setEmployeeDetail(employeeList[data])

        }
        setDateValue(dayjs(selectedCustomer?.date));
       setTimeValue( dayjs().hour(selectedCustomer?.hour).minute(selectedCustomer?.minute))
      }
    });
   
   }
  }, [open]);
  
  
  return (
    <Modal
      open={open}
      onClose={() => {setOpen(false)}}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          background: "white",
          width: "52%",
          overflowY: "auto",
          // height: "90vh",
          position: "absolute",
          left: "25%",
          top: "10%",
        }}
      >
        <ModalHeader    title={"Add Offer"} onClose={(data)=>{setOpen(data)}}/>  
        <Container>
        
          <Box sx={{ mt: 4, my: 5 }} display="flex" justifyContent="center">
            <form onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12}>
                  <FormControl fullWidth margin="normal">
                    <TextField
                      label="Customer Name"
                      name="customerName"
                      value={formData.customerName}
                      onChange={handleChange}
                      required
                      InputLabelProps={{ className: "mulish" }}
                      InputProps={{ classes: { input: "mulish" } }}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth margin="normal">
                    <TextField
                      label="Email"
                      name="mailId"
                      type="email"
                      value={formData.mailId}
                      onChange={handleChange}
                      required
                      InputLabelProps={{ className: "mulish" }}
                      InputProps={{ classes: { input: "mulish" } }}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth margin="normal">
                    <TextField
                      label="Mobile Number"
                      name="mobileNumber"
                      type="number"
                      value={formData.mobileNumber}
                      onChange={handleChange}
                      required
                      InputLabelProps={{ className: "mulish" }}
                      InputProps={{ classes: { input: "mulish" } }}
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth margin="normal">
                    <InputLabel id="tags-label" className="mulish">
                      Services
                    </InputLabel>
                    <Select
                      labelId="tags-label"
                      id="services"
                      //   multiple
                      name="tags"
                      value={formData.service}
                      onChange={handleTagsChange}
                      input={<OutlinedInput label="Services" />}
                      //   renderValue={(selected) => selected.join(", ")}
                      MenuProps={MenuProps}
                    >
                      {servicesList?.map((name) => (
                        <MenuItem
                          key={name.itemDescription}
                          value={name.itemDescription}
                        >
                          {/* <Checkbox checked={formData.tags.indexOf(name) > -1} /> */}
                          <ListItemText primary={name.itemDescription} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl>
                    <FormLabel
                      id="demo-row-radio-buttons-group-label"
                      className="mulish"
                    >
                      Gender:
                    </FormLabel>
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                    >
                      <FormControlLabel
                        value="Female"
                        control={<Radio />}
                        checked={formData.gender == "Female"}
                        label="Female"
                        className="mulish"
                        onClick={gender}
                      />
                      <FormControlLabel
                        value="Male"
                        control={<Radio />}
                        checked={formData.gender == "Male"}
                        label="Male"
                        className="mulish"
                        onClick={gender}
                      />
                      <FormControlLabel
                        value="Other"
                        control={<Radio />}
                        checked={formData.gender == "Other"}
                        label="Other"
                        className="mulish"
                        onClick={gender}
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth margin="normal">
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        label="Select Date"
                        value={dateValue}
                        minDate={dayjs()}
                        onChange={(newValue) => {
                          // setDateValue(date);
                          setDate(newValue);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            fullWidth
                            InputLabelProps={{ className: "mulish" }}
                            InputProps={{ classes: { input: "mulish" } }}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth margin="normal">
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <TimePicker
                        label="Select Time"
                        value={timeValue}
                        onChange={(newValue) => {
                          setTime(newValue);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            fullWidth
                            InputLabelProps={{ className: "mulish" }}
                            InputProps={{ classes: { input: "mulish" } }}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth margin="normal">
                    <TextField
                      fullWidth
                      id="Value"
                      readOnly={true}
                      label="Employee"
                      size="small"
                      value={employeDetail?.name}
                      onChange={(e) => {}}
                      InputProps={{
                        style: {
                          // height: "45px"
                        },

                        endAdornment: (
                          <PersonIcon
                            onClick={() => {
                              setOpenT(true);
                              setFormData((prev)=>{
                                
                                let temp={...prev};
                                temp.employeeId=employeDetail?.id
                                console.log("employee detail",employeDetail)
                                return temp;
                              })
                            }}
                            sx={{
                              color: (theme) => theme.palette.primary.main,
                              cursor: "pointer",
                            }}
                          />
                        ),
                      }}
                    />
                  </FormControl>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sx={{ display: "flex", justifyContent: "end" }}
                >
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    sx={{
                      backgroundColor: "#3695B6",
                      padding: "14px",
                      fontSize: "16px",
                    }}
                  >
                    {selectedCustomer?"Update":"Book"} an  Appointment
                  </Button>
                  {businessChooseTab?.value=="Unasigned" && <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    sx={{
                      backgroundColor: "#3695B6",
                      padding: "14px",
                      fontSize: "16px",
                    }}
                  >
                    Cancel
                  </Button>}
                </Grid>
              </Grid>
            </form>
          </Box>
        </Container>{" "}
        <EmployeeModal
          open={openT}
          setOpen={setOpenT}
          callback={(data) => {
            setOpenT(false);
            setEmployeeDetail(data)
            setFormData((prev)=>{
                                
              let temp={...prev};
              temp.employeeId=data?.id
              console.log("employee detail",data)
              return temp;
            })
            console.log(data,"callback ");

          }}
          onClose={() => {
            setOpenT(false);
            
          }}
          employeeList={employeeList}
         
        />
      </Box>
    </Modal>
  );
};

export default Appoinmentadle;
