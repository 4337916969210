import { createSlice } from "@reduxjs/toolkit";

import { routePath } from "../../constants/routePath";

const initialState = {
  loading: false,
  snackbar: {
    open: false,
    message: "",
    severity: "success",
    duration: 2000,
  },
  navBarInputFields: {
    location: "",
    bussiness: "",
  },
  drawerShow: true,
  mobileNumner: null,
  billNumber: 0,
  mobileTableDrawer: false,
  apppoinmentUerType: "",
  tableName: null,
  currentDataBillNo: 1,
  userDetail: {},
  actvieBussinessDetails: {},
  customerAppointmentId: 0,
  sideBarMenu: [],
  updateBillData: {},
  changeRoll: "dashboard",
  favProduct: {},
  headerMenu: [],
  serviceViceSearch: "rate",
  rateChangeHistoryPayload: {},
  cashCounterSaleSummaryPayload: {},
  detail: {},

  BussinessSideBar: false,
  customerManagmentDisplay: false,
  billingSide: false,
  analaticDisplay: true,
  businessChooseTab: null,
  barName: "bussinessMangment",
  billerMenuseletec: 0,
  billerMenu: [
    { systemFuctionName: "New Bill", route: routePath.COUNTERSELL },
    { systemFuctionName: "Previous Bill", route: routePath.COUNTERSELL },
  ],
  bussinessMangementMenu: [
    {
      tab: [
        { value: "expense", label: "Expense" },
        { value: "ExpenceChategory", label: "Expense Category" },
      ],
      systemFuctionName: "Expenses",
      route: routePath.EXPENCE,
    },
    {
      tab: [
        { value: "stock", label: "Stock" },
        { value: "Purchase", label: "Purchase" },
      ],
      systemFuctionName: "Purchase",
      route: routePath.INVENTORY,
    },
    {
      tab: [
        { value: "itemDetail", label: "Item Details" },
        { value: "itemcategory", label: "Item Category" },
      ],
      systemFuctionName: "Items Information",
      route: routePath.ITEMSERVICES,
    },
    {
      tab: [
        { value: "PersnalInfo", label: "Personal Info" },
        { value: "EducatoinalDetail", label: "Educational Details" },
        { value: "expence", label: "Experience Details" },
      ],
      systemFuctionName: "Assistant Payroll",
      route: routePath.EMPLOYEEINFO,
    },

    {
      tab: [
        { value: "outstandingReport", label: "Outstanding Report" },
        {
          value: "payment Report",
          label: "Payment Report",
        },
      ],
      systemFuctionName: "Payment Receipt",
      route: routePath.OUTSTANDINGREPORT,
    },

    {
      systemFuctionName: "Associate Users",
      route: routePath.ASSOCIATEDUSERS,
    },

    {
      tab: [
        { value: "settledandpaidAmount", label: "Settled & Paid Amount" },
        { value: "Salary", label: "Salary" },
        { value: "AdvancePayment", label: "Advance Payment" },
      ],
      systemFuctionName: "Employee Payroll",
      route: routePath.EMPLOYEEPAYROLL,
    },
    {
      tab: [
        { value: "SalaryComponents", label: "Salary Components" },
        { value: "incentrive", label: "Incentive" },
        { value: "otherAllowence", label: "Other Allowence" },

        { value: "Deductions", label: "Deductions" },
      ],
      systemFuctionName: "Payment Structure",
      route: routePath.PAYMENTSTRUCT,
    },

    { systemFuctionName: "Bulk Message", route: routePath.BULTMESSAGE },
    { systemFuctionName: "Bulk Upload", route: routePath.BLUESERIVE },
  ],
  customerChategory: [
    {
      systemFuctionName: "Offers",
      route: routePath.ADDOFFER,
    },
    {
      tab: [
        { value: "Unassigned", label: "Unassigned" },
        { value: "Assigned", label: "Assigned" },
      ],
      systemFuctionName: "Appointment",
      route: routePath.APPOINMENTDASHBOARD,
    },
    {
      systemFuctionName: "Business Information",
      route: routePath.MYBUSSINESS,
    },

    {
      systemFuctionName: "Offers & Discounts",
      route: routePath.SHARESESSAGE,
    },
    {
      tab: [
        { value: "customerhistory", label: "customer history" },
        { value: "customer details", label: "customer details" },
      ],
      systemFuctionName: "Customer History",
      route: routePath.CUSTOMERINFORMATION,
    },
  ],
  analatics: [
    {
      systemFuctionName: "Dashboard",
      route: routePath.DASHBOARD,
    },

    {
      tab: [
        {
          label: "Business Reports",
          value: "br",
          br: true,
        },
        {
          label: "Discontinued items / Services list ",
          value: "DiscountinueditemsServiceslist",
        },
        { label: "Gst Report", value: "GstReport" },

        {
          label: "Item Category wise daily counter sales",
          value: "ItemCatogery",
        },
        { label: "Total counter sale report", value: "Totalcountersalereport" },
        {
          label: "Item Wise Counter Sale Report",
          value: "ItemWiseCounterSaleReport",
        },
        {
          label: " Raw Material Report ",
          value: "RawMaterialReport",
        },
        {
          label: "Counter sale of selected item",
          value: "CounterSaleOfSelectedItem",
        },

        {
          label: "List of items/ Services",
          value: "ListOfItemSerivces",
        },
        {
          label: "Purchase Report",
          value: "PurchaseReportData",
        },
        {
          label: "Expense report",
          value: "ExpenceReport",
        },
        {
          label: "Inventory report",
          value: "InventoryReport",
        },
        {
          label: "Employee  Reports",
          value: "br",
          br: true,
        },
        {
          label: "Employee Payroll Report",
          value: "EmployeePaymentRole",
        },
        {
          label: "Employee Advance Payment Report",
          value: "EmployeeAdvanceReport",
        },
        { label: "Employee Details Report", value: "EmploDeReport" },
        {
          label: "Customer Reports",
          value: "br",
          br: true,
        },

        {
          label: "Customer History Report ",
          value: "CustomerHistoryReport",
        },
        { label: "Customer Information Report", value: "CustomerDetailReport" },
      ],
      systemFuctionName: "Reports",
      route: routePath.REPORTS,
    },
  ],

  billingsideOption: [
    {
      systemFuctionName: "New Bill",
      route: routePath.DASHBOARD,
    },
    {
      systemFuctionName: "Previous Bill",
      route: routePath.DASHBOARD,
    },
  ],
  urlHeaderMenu: [
    // {
    //   systemFuctionName: "Dashboard",
    //   route: routePath.DASHBOARD,
    // },
    {
      systemFuctionName: "Dashboard",
      route: routePath.DASHBOARD,
    },

    {
      systemFuctionName: "Business Mangment",
      route: routePath.DASHBOARD,
    },
    // { systemFuctionName: "Reports", route: routePath.REPORTS },

    {
      systemFuctionName: "Customer Managment",
      route: routePath.DASHBOARD,
    },

    { systemFuctionName: "Cash Sale Bill", route: routePath.COUNTERSELL },
    { systemFuctionName: "Bills", route: routePath.COUNTERSELLBILLING },

    // { systemFuctionName: "Purchase", route: routePath.INVENTORY },

    // { systemFuctionName: "Stock", route: routePath.STOCKS },
    // { systemFuctionName: "Purchase", route: routePath.PURCHASE },
  ],

  urlSideBarMenu: [
    {
      systemFuctionName: "My Profile",
      route: routePath.MYPROFILE,
      icon: "PersonIcon",
    },
    {
      systemFuctionName: "Business Information",
      route: routePath.MYBUSSINESS,
      icon: "StorefrontIcon",
    },
    {
      systemFuctionName: "Table Master",
      route: routePath.TABLEMASTER,
      icon: "TableBarIcon",
    },
    {
      systemFuctionName: "Table Section",
      route: routePath.TABLESECTION,
      icon: "TableRestaurantIcon",
    },
    {
      systemFuctionName: "KYC Documents",
      route: routePath.KYCPROCESS,
      icon: "SearchIcon",
    },

    {
      systemFuctionName: "Items Categories",
      route: routePath.ITEMCATEGORIES,
      icon: "DashboardIcon",
    },
    {
      systemFuctionName: "SMB Account Recharge",
      route: routePath.SMBACCOUNTREACHARGE,
      icon: "CurrencyRupeeOutlinedIcon",
    },
    {
      systemFuctionName: "Settings",
      route: routePath.SETTING,
      icon: "SettingsOutlinedIcon",
    },
    {
      systemFuctionName: "Quick Login Methods",
      route: routePath.RESETMPIN,
      icon: "LockOutlinedIcon",
    },
    {
      systemFuctionName: "Help and Support",
      route: routePath.HELPSUPPORT,
      icon: "HeadsetMicOutlinedIcon",
    },
    {
      systemFuctionName: "Share SMB App",
      route:
        "https://play.google.com/store/apps/details?id=com.mybills.mybillsapplication&hl=en_IN&gl=US",
      icon: "ShareOutlinedIcon",
    },
    {
      systemFuctionName: "Sign Out",
      route: null,
      icon: "LogoutOutlinedIcon",
    },
  ],
};

const reducer = createSlice({
  name: "reducer",
  initialState,
  reducers: {
    setBillNo: (state, action) => {
      state.billNumber = action.payload;
    },
    setUpdateBillData: (state, action) => {
      state.updateBillData = action.payload;
    },
    setSideBarMenu: (state, action) => {
      state.sideBarMenu = action.payload;
    },
    setUserDetail: (state, action) => {
      state.userDetail = action.payload;
    },
    setActvieBussinessDetails: (state, action) => {
      state.actvieBussinessDetails = action.payload;
    },
    setHeaderMenu: (state, action) => {
      state.headerMenu = action.payload;
    },
    startLoading: (state, action) => {
      state.loading = true;
    },
    stopLoading: (state, action) => {
      state.loading = false;
    },
    setTableName: (state, action) => {
      state.tableName = action.payload;
    },
    setDrawerShow: (state, action) => {
      state.drawerShow = action.payload;
    },
    setSnackbar: (state, action) => {
      state.snackbar = action.payload;
    },
    setMobileTableDrawer: (state, action) => {
      state.mobileTableDrawer = action.payload;
    },
    setServiceViceSearch: (state, action) => {
      state.serviceViceSearch = action.payload;
    },
    setRateChangeHistoryPayload: (state, action) => {
      state.rateChangeHistoryPayload = action.payload;
    },

    setCashCounterSaleSummaryPayload: (state, action) => {
      state.cashCounterSaleSummaryPayload = action.payload;
    },
    setNavBarInputFields: (state, action) => {
      state.navBarInputFields = {
        ...state.navBarInputFields,
        ...action.payload,
      };
    },
    setAppoinmentType: (state, action) => {
      state.apppoinmentUerType = action.payload;
    },
    setCustomerAppointmentId: (state, action) => {
      state.customerAppointmentId = action.payload;
    },
    setfavProduct: (state, action) => {
      state.favProduct = action.payload;
    },
    setDetail: (state, action) => {
      state.detail = { ...state.detail, ...action.payload };
    },
    setBarSide: (state, action) => {
      state.billingSide = action.payload;
    },
    setCustomerManagmentDisplay: (state, action) => {
      state.customerManagmentDisplay = action.payload;
    },

    setBussinessSideBar: (state, action) => {
      state.BussinessSideBar = action.payload;
    },
    setchangeRoll: (state, action) => {
      state.changeRoll = action.payload;
    },
    setbusinessChooseTab: (state, action) => {
      console.log("click on tab ty", action.payload);

      state.businessChooseTab = action.payload;
    },
    setAnalaticDisplay: (state, action) => {
      state.analaticDisplay = action.payload;
    },
    setbillerMenuseletected: (state, action) => {
      state.billerMenuseletec = action.payload;
    },
    setBarName: (state, action) => {
      state.barName = action.payload;
    },
    setMobile: (state, action) => {
      state.mobileNumner = action.payload;
    },
  },
});

export const {
  setUserDetail,
  setMobile,
  startLoading,
  setAnalaticDisplay,
  stopLoading,
  setBillNo,
  setchangeRoll,
  setCustomerManagmentDisplay,
  setHeaderMenu,
  setSideBarMenu,
  setTableName,
  setDrawerShow,
  setUpdateBillData,
  setSnackbar,
  setMobileTableDrawer,
  setServiceViceSearch,
  setNavBarInputFields,
  setRateChangeHistoryPayload,
  setCashCounterSaleSummaryPayload,
  setDetail,
  setAppoinmentType,
  setCustomerAppointmentId,
  setfavProduct,
  setbusinessChooseTab,
  setBussinessSideBar,
  setActvieBussinessDetails,
  setBarSide,
  setbillerMenuseletected,
  setBarName,
} = reducer.actions;

export default reducer.reducer;
