
// import {    GETCOUNTERSALESREPORT, GETMONTLYGSTDETAILS, GETOVERALLCOUNTERDETAILS } from " ../../utils/api";
import Swal from "sweetalert2";
import { ADDEXPENSE, ADDEXPENSESCATEGORY, GETALLEXPENSESCATEGORY, GETDETAILSEXPENSE, GETMONTLYGSTDETAILS, GETOVERALLCOUNTERDETAILS, GETPURCHASEDETAILS, PURCHASEADD, UDATEEXPENSESCATEGORY, UPDATEEXPENSE } from "../../utils/api";
import axios from "../../utils/axios"; 
import { swalAlertPopProps } from "../../utils/commonUtils";

export const GetDetailsExpense=async(param)=>{
    const res=await axios.post(GETDETAILSEXPENSE,param);
    return res;
}

export const addExpense=async(param)=>{
    const res=await axios.post(ADDEXPENSE,param);
    return res;
    
}
export const updateExpense=async(param)=>{
    const res=await axios.post(UPDATEEXPENSE,param)
    return res;
}


export const getCategory=async(callback)=>{
    await axios.get(GETALLEXPENSESCATEGORY).then((res)=>{
        callback(res)
    }).catch((err)=>{
        console.log("err ",err)
    })



}

export const addCategory=async(payload,callback)=>{
    await axios.post(payload?.id?UDATEEXPENSESCATEGORY:ADDEXPENSESCATEGORY,payload).then((res)=>{
        callback(res)
        Swal.fire({
            title: "",
            text: "Successfull "+(payload?.id?" Update ":" Added")+ "",
            icon: "success",

            ...swalAlertPopProps,
          });
    }).catch((err)=>{
        console.log("err ",err)
    })



}

