import { Box, Grid, Tab, Tabs } from "@mui/material";
import React from "react";
import Purchase from "../purchase/purchase";
import Stocks from "../stocks/stocks";
import ItemServices from "../items_services/ItemServices";
import ItemCategories from "../ItemsCategories/itemCategories";
import DynamicBreadcrumbs from "../../common/Breadcrumb";
import { useSelector } from "react-redux";

export const ItemService=()=>{
  const [value, setValue] = React.useState("all");

  const handleChange = (event, newValue) => {
    // setPageNo(1);
    
    setValue(newValue);
  };
const businessChooseTab=useSelector((state)=>state.reducer.businessChooseTab)
    return ( 
    
   <>
        <DynamicBreadcrumbs />
    <Box   sx={{
          margin: {
            xs: "10px",
            sm: "50px 10px 10px 10px ",
            md: "50px",
          },
        }}>
       
      <Grid container>
      <Grid item xs={12}>
          {(!businessChooseTab || businessChooseTab?.value=="itemDetail" )&& 
            <ItemServices />}
            {
              businessChooseTab?.value=="itemcategory" &&
              <ItemCategories/>
            }
      </Grid>
      </Grid>
    </Box>
    </>
      )
}