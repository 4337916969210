import { Box, Grid, Typography } from "@mui/material";

import { useEffect, useState } from "react";
import { ItemCategoryApi, ItemGETApi } from "../utils/utils";

import Swal from "sweetalert2";
import { swalAlertPopProps } from "../utils/commonUtils";

export default function MenuSideBar({
  CollectionTableItems,
  setCollectionTableItems,

  setIndexTableItem,
}) {
  const [category, setCategory] = useState();

  const tablePostApiData = {
    myBusinessId: 0,
    itemCategoryId: 0,
    isCounterSalesItem: true,
    page: 1,
    pageSize: 20,
    filterByColumn: null,
    filterValue: null,
    searchByColumn: null,
    searchValue: null,
    orderByColumn: null,
    orderBy: null,
  };
  const data = {
    page: 1,
    pageSize: 20,
    filterByColumn: "string",
    filterValue: "string",
    searchByColumn: "string",
    searchValue: "string",
    orderByColumn: "string",
    orderBy: "string",
  };
  const getData = async () => {
    await ItemCategoryApi(data)
      .then(async (res) => {
        setCategory([
          {
            categoryName: "All",
            id: 0,
            size: 4,
          },
          ...res.data.data,
        ]);
      })
      .catch(() => { });
    await ItemGETApi({
      myBusinessId: 0,
      itemCategoryId: 0,
      isCounterSalesItem: true,
      page: 1,
      pageSize: 1500,
      filterByColumn: null,
      filterValue: null,
      searchByColumn: null,
      searchValue: null,
      orderByColumn: null,
      orderBy: null,
    })
      .then(async (res2) => {
        let itemId = 0;
        let arry = res2.data.data;

        let arr = [...CollectionTableItems];
        arr.push({
          itemId: itemId,
          tableItems: arry.reverse(),
        });
        const index2 = arr.findIndex((obj) => obj.itemId === 0);
        setIndexTableItem(index2);
        setCollectionTableItems(arr);
      })
      .catch(() => { });
  };
  useEffect(() => {
    getData();
  }, []);
  const [selectedTab, setSelected] = useState(0);
  return (
    <Grid container>
      <Grid
        xs={12}
        sx={{
          // backgroundColor: (theme) => theme.palette.primary.main,
          // overflowY: "auto",
          backgroundColor: (theme) => theme.palette.primary.main,
          overflowY: "auto",
          overflowX: "scroll", // Add overflowX property to enable horizontal scrolling
          whiteSpace: "nowrap",
          scrollbarWidth: "none", // Firefox
          msOverflowStyle: "none", // IE 10+
          "&::-webkit-scrollbar": {
            display: "none", // Hide the scrollbar for Chrome, Safari, and Opera
          },
        }}
      >
        {" "}
        {category?.map((item, ind) => {
          return (
            <Box
              key={"CategoryBill" + ind}
              bgcolor="primary"
              sx={{
                padding: "20px",
                display: {
                  xs: "inline-block",
                  sm: "flex",
                },

                borderBottom: "1px solid white",
                height: "1rem",

                cursor: "pointer",
                background: selectedTab === ind ? "#127093" : "",

                // padding: "20px",
                // display: "inline-block", // Use inline-block to keep the boxes side by side
                // cursor: "pointer",
                // background: selectedTab === ind ? "#127093" : "",
              }}
              onClick={async () => {
                setSelected(ind);
                const index = CollectionTableItems.findIndex(
                  (obj) => obj.itemId === item.id
                );

                if (index !== -1) {
                  setIndexTableItem(index);
                  return null;
                }
                await ItemGETApi({
                  ...tablePostApiData,
                  itemCategoryId: item.id,
                })
                  .then((res2) => {
                    let arr = [...CollectionTableItems];
                    let itemId = item.id;
                    let arry = res2.data.data;

                    arr.push({
                      itemId: itemId,
                      tableItems: arry,
                    });
                    const index2 = arr.findIndex(
                      (obj) => obj.itemId === item.id
                    );
                    setIndexTableItem(index2);
                    setCollectionTableItems(arr);
                  })
                  .catch((err) => {
                    console.error("err ", err);
                    Swal.fire({
                      text: "error ",
                      icon: "error",
                      ...swalAlertPopProps,
                    });
                  });
              }}
            >
              <Typography underline="none" color="white">
                {item.categoryName}
              </Typography>
            </Box>
          );
        })}
      </Grid>
    </Grid>
  );
}
