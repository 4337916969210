import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  TextField,
  Typography,
  RadioGroup,
  FormControlLabel,
  Radio,
  Modal,
  Grid,
  Stack,
  Pagination,
  Checkbox,
  Select,
  OutlinedInput,
  MenuItem,
  ListItemText,
} from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";
import React, { useEffect, useRef, useState } from "react";

import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";

import { useSelector } from "react-redux";

import DatePicker from "react-datepicker";
import EventIcon from "@mui/icons-material/Event";
import SearchIcon from "@mui/icons-material/Search";
import {
  AddCashPayment,
  addCustomerAdvance,
  getCounterSalesCustomers,
  getUnpaidUser,
} from "./paymentReceiptApi";
import UserCard from "./userCard";
import Swal from "sweetalert2";
import { displayDateFormat, getFirstDateOfCurrentMonthPayloadFormate, getLastDateOfCurrentMonthPayloadFormat, swalAlertPopProps } from "../../utils/commonUtils";
import { validationAdd } from "./paymentReceiptValidations";
import { searchedElementDisplay } from "./receiptFunctions";
import { monthInText } from "./paymentReceiptDateFunction";
import PayrolSearchModal from "../employeePayRoll/payrollSearchModal";
import { GetAssistancePayrollDetail } from "../employeePayRoll/outstandingApi";

export default function AddReceipt({ open, setOpen, getRecept, unPaidBill, setUnPaidBill, upPaidBillData, setUnPaidBillData, billData, setBillData, searchModal, setSearchModal,callback }) {
  const [pagination, setPagination] = useState({ page: 1, total: 10 });
  const [search, setSearch] = useState("");
  // const [upPaidBillData, setUnPaidBillData] = useState();

  const data = {
    customerMobile: "",
    amount: 0,
  };
  
  const { userDetail } = useSelector((state) => state.reducer);

  const onClose = () => {
    setOpen(false);
  };
  const handleChange = (event, value) => {
    setPagination({
      ...pagination,
      page: value,
    });
  };
  const getUnpaid = async (param) => {
    await getUnpaidUser(param)
      .then((res) => {
        console.log("unPaid user", res?.data?.data[0]);

        setUnPaidBillData(res?.data?.data[0]);
      })
      .catch((err) => { });
  };

  const [USerObject, setUSerObject] = useState(null)

  const [startDateFormat, setstartDateFormat] = useState(getFirstDateOfCurrentMonthPayloadFormate());
  const [endDate, setEndDate] = useState(getLastDateOfCurrentMonthPayloadFormat());
  const [counterSalesHeader, setCounterSalesHeader] = useState(null)
  const [billFilter, setBillFilter] = useState(null);

  const [payloadGetCounterSales, setPayloadGetCounterSales] = useState({
    page: 1,
    pageSize: pagination.pageSize,
    sortBy: "",
    order: 0,
    buyerGroupId: 0,
    filter: "",
    search: "",
  });
  const getCounterSalesCusomter = async (param) => {
    await getCounterSalesCustomers(param).then((res) => {
      console.log("countersales res data   ", res?.data?.data, " ", {
        page: res.data.pageNumber,
        total: res.data.totalPages,
      });
      setPagination({
        page: res.data.pageNumber,
        total: res.data.totalPages,
      });
      setUnPaidBill([...res?.data?.data]);
    });
  };
  const addBill = async (param) => {
    await AddCashPayment(param)
      .then((res) => {
        console.log("add Bill ", res?.data?.succeeded);
        if (res?.data?.succeeded) {
          getRecept();
          Swal.fire({
            text: "Saved Record",
            icon: "success",

            ...swalAlertPopProps,
          });
        } else {
          Swal.fire({
            text: res?.data?.message,
            icon: "error",

            ...swalAlertPopProps,
          });
        }
      })
      .catch((err) => {
        console.log("err ", err);
      });
  };
  useEffect(() => {
    if (open === true) {
      getCounterSalesCusomter({
        filter: "",
        search: "",
        page: 1,
        pageSize: 1000,
      });
      setBillData({ ...billData, mybusinessId: userDetail?.myBusinessId });
    } else {
      console.log("close");
      setBillData(data);
      setUnPaidBillData();
    }
  }, [open]);
  const addLeadingZero = (value) => {
    return value < 10 ? "0" + value : value;
  };
  const [date, setDate] = useState(displayDateFormat());

  const [startDate, setStartDate] = useState(new Date());
  const datePickerRef = useRef();

  const openDatePicker = () => {
    if (datePickerRef.current) {
      datePickerRef.current.setOpen(true);
    }
  };
  useEffect(() => {
    console.log("billData ", billData)
  }, [billData])


  const handleTagsChange = (e) => {
    const { value } = e.target;
    setBillData({
      ...billData,
      paymentMethod: value, // Corrected typo from paymentMedthod to paymentMethod
    });
  };

  // const [status, setStatus] = useState("all");

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >



      <Box
        sx={{
          background: "white",
          width: "40%",
          overflowY: "auto",
          height: "auto",
          position: "absolute",
          left: "30%",
          top: "10%",
        }}
      >
        <DialogTitle
          color="primary"
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            height: 'auto',
            p: "0px 10px !important"
          }}
        >
          Advance Receipts{" "}
          <CloseIcon sx={{ float: "right", cursor: "pointer" }} onClick={() => setOpen(false)} />
        </DialogTitle>
        <Divider />
        <Grid container sx={{ position: "relative" }}>

          <Grid item xs={12} sm={12}>
            <DialogContent >
              <Box sx={{ cursor: "pointer" }}>
                <TextField
                  fullWidth
                  // disabled
                  readonly
                  onClick={openDatePicker}
                  id="date"
                  value={date}
                  label={
                    <Box
                      sx={{
                        flex: 4,
                        color: "grey",
                        cursor: "pointer",
                        whiteSpace: "nowrap",
                      }}
                    >
                      Date : {/* {new Date().toLocaleDateString()} */}
                    </Box>
                  }
                  onChange={(e) => { }}
                  InputProps={{
                    style: {
                      height: '40px',
                      // background:"yellow"
                    },
                    endAdornment: (
                      <EventIcon
                        sx={{
                          color: (theme) => theme.palette.primary.main,
                          cursor: "pointer",
                        }}
                      />
                    ),
                  }}
                />
                <DatePicker
                  hidden={true}
                  selected={startDate}
                  maxDate={new Date()}
                  onChange={(date) => {
                    const dateStr2 = date.toLocaleDateString("en-GB", {
                      year: "numeric",
                      month: "long",
                      day: "2-digit",
                    });
                    let dateContainer = String(dateStr2).split(" ");
                    var year2 = dateContainer[2];
                    var month2 = dateContainer[1];
                    var day2 = dateContainer[0];
                    var formattedDate2 = day2 + "-" + month2 + "-" + year2;

                    setDate(formattedDate2);
                    setStartDate(date);
                  }}
                  className="custom-datepicker"
                  ref={datePickerRef}
                  onClickOutside={() => datePickerRef.current.setOpen(false)}
                />
              </Box>
              
              <Box

                onClick={() => {
                  setSearchModal(true)

                }}
                sx={{
                  display: "flex",
                  height: "23px",
                  justifyContent: "space-between",
                  border: "1px solid #c9c9c9",
                  p: 1,
                  mt: 2,
                  borderRadius: "4px ",
                  color: "grey",
                  '&:hover': {
                    cursor: 'pointer !important',
                  },
                }}
              >
                <Typography variant="p">
                  {console.log(USerObject?.name, "billData.name")}
                  {USerObject?.name !== undefined ? USerObject.name : "Search by Name/Mobile Number"}
                </Typography>
                <SearchIcon
                  onClick={() => {
                    setSearchModal(true)

                  }}
                  sx={{
                    color: (theme) => theme.palette.primary.main,
                    cursor: "pointer",
                  }}
                />
              </Box>
              <Box sx={{ mt: 2 }}>
                <TextField
                  fullWidth
                  id="amount"
                  label="Amount"
                  size="small"
                  // InputProps={{
                  //   style: {
                  //     height: '40px',
                  //     // background:"yellow"
                  //   }
                  // }}
                  onChange={(e) => {
                    setBillData({ ...billData, amount: e.target.value });
                  }}
                />
              </Box>
              <Box sx={{ mt: 2,display:'flex',alignItems:'center' }}>
            <Typography>Payment Method </Typography>
            <Select
            sx={{
                margin:'0px 10px '
            }}
              labelId="tags-label"
              id="services"
              name="tags"
              value={billData?.paymentMethod}
              onChange={handleTagsChange}
              input={<OutlinedInput label="Payment Method" />}
            >
              <MenuItem value={"Online"}>
                <ListItemText primary={"Online"} />
              </MenuItem>
              <MenuItem value={"Cash"}>
                <ListItemText primary={"Cash"} />
              </MenuItem>
              <MenuItem value={"Cheque"}>
                <ListItemText primary={"Cheque"} />
              </MenuItem>
            </Select>
          </Box>
          <Box sx={{ mt: 2 }}>
            <TextField
              fullWidth
              id="transactionDetails"
              size="small"
              label="Transaction Details"
              value={billData?.transactionDetails}
              onChange={(e) => {
                setBillData({
                  ...billData,
                  transactionDetails: e.target.value,
                });
              }}
            />

          </Box>
          <Box sx={{ mt: 2 }}>
            <TextField
              fullWidth
              id="reason"
              size="small"
              label="reason "
              value={billData?.reason}
              onChange={(e) => {
                setBillData({
                  ...billData,
                  reason: e.target.value,
                });
              }}
            />

          </Box>
            </DialogContent>
          </Grid>
        </Grid>

        <DialogActions sx={{}}>
          {/* <Button onClick={onClose}>Cancel</Button> */}
          <Button
            variant="contained"
            fullWidth
            onClick={() => {
              setOpen(false);
              
              // const valid = validationAdd(billData, upPaidBillData);
              // if (valid.status) {
                addCustomerAdvance({
                  "buyerInfoId": USerObject?.id,
                  "amount":   parseFloat(billData?. amount),
                  "date": startDate,
                  "paymentMethod": billData?.paymentMethod,
                  "transactionDetails": billData?.transactionDetails,
                  "reason": billData?.reason
 
                },()=>{
                  setOpen(false)
                  callback()
                });
              // } else {
              //   Swal.fire({
              //     text: valid.msg,

              //     ...swalAlertPopProps,
              //     icon: "warning",
              //   });
              // }
            }}
          >
            Save
          </Button>
        </DialogActions>
        <PayrolSearchModal
          searchModal={searchModal}
          setSearchModal={setSearchModal}
          unPaidBill={unPaidBill}
          getUnpaid={getUnpaid}
          setBillData={setBillData}
          search={search}
          setSearch={setSearch}
          setUSerObject={(data) => {
            console.log("data ",data)
            GetAssistancePayrollDetail({
              fromDate: startDateFormat,
              toDate: endDate,
              employeeMasterId: data?.id,
              "page": 1,
              "pageSize": 1000,
              "workingDays": 0
            }, (data) => {
              console.log("data == ", data)
              setCounterSalesHeader(data?.data)
            })
            setUSerObject(data)
          }}
          setUnPaidBill={setUnPaidBill}
          setUnPaidBillData={setUnPaidBillData}
          setPagination={setPagination}
          billData={billData}
          callSearchApi={(searchName) => {
            setBillFilter("")
            // payloadGetCounterSales.search
            setPayloadGetCounterSales({ ...payloadGetCounterSales, search: "" })
            // getCounterSales(
            //   {
            //     page: 1,
            //     pageSize: 10000,
            //     sortBy: "",
            //     order: 0,
            //     buyerGroupId: 0,
            //     filter: "buyername",
            //     search: searchName,
            //   },
            //   outStandingBills,
            //   getCounterSalesStatement,
            //   setNumberOfRecords,
            //   setOutStandingBills
            // );

          }}
        //  userDetail={userDetail}
        />
      </Box>
    </Modal>
  );
}
