import Swal from "sweetalert2";
import axios from "../../utils/axios";
import { swalAlertPopProps } from "../../utils/commonUtils";

export const addDataServeice=async(api,payload,callback)=>{
     await axios.post(api, payload, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }).then((res)=>{
        callback(res);
        if(res?.data?.succeeded) {
          Swal.fire({
            text:"Successfully added" ,
            icon: "success",
            ...swalAlertPopProps ,
          });
        } 
    
        else{
          Swal.fire({
            text:res?.data?.message ,
            icon: "error",
            ...swalAlertPopProps,
          });
        }
      }).catch((err)=>{

        console.log("err ",err)
      });
}