import {
  Avatar,
  Box,
  Button,
  Container,
  TextField,
  Typography,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import Grid from "@mui/material/Grid";

import React, { useEffect, useRef, useState } from "react";

import {
  GetUserProfileApi,
  UpdateUserProfileApi,
  UpdateUserProfileImageApi,
  getUserProfileImg,
} from "./myProfileApi";
import Swal from "sweetalert2";
import Language from "./languageModal";
import { getData, updateProfile } from "./myProfileCustomApiHandler";
import { validationUpdateProfile } from "./myProfileValidation";
import { swalAlertPopProps } from "../../utils/commonUtils";
import DynamicBreadcrumbs from "../../common/Breadcrumb";
export default function MyProfile() {
  const [imageContainer, setImageContainer] = useState("");
  const profileImgRef = useRef();
  const [open, setOpen] = useState(false);
  const [profileData, setProfileData] = useState(null);

  useEffect(() => {
    getData(GetUserProfileApi, setProfileData, getUserProfileImg);
  }, []);
  return (
    <>
    <DynamicBreadcrumbs />
    <Box sx={{
          margin: {
            xs: "10px",
            sm: "50px 10px 10px 10px ",
            md: "50px",
          },
        }}>
      {profileData && (
        <Container maxWidth="sm">
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              mt: 4,
            }}
          >
            {profileData.profilePicBase64 === "" &&
              imageContainer.length === 0 && (
                <Avatar
                  sx={{
                    mb: 2,
                    fontSize: "200px",
                    height: "100px",
                    width: "100px",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    profileImgRef.current.click();
                  }}
                />
              )}

            <input
              type="file"
              accept="image/*"
              ref={profileImgRef}
              hidden
              onChange={(e) => {
                var filePath = e.target.value;
                var allowedExtensions = /(\.jpg|\.jpeg|\.png|\.gif)$/i;

                if (!allowedExtensions.exec(filePath)) {
                  Swal.fire({
                    text: "Invalid file type",
                    icon: "error",

                    ...swalAlertPopProps,
                  });
                  e.target.value = "";
                  return false;
                }
                const file = e.target.files[0];
                const reader = new FileReader();
                reader.onload = (e) => {
                  const base64 = e.target.result;
                  setImageContainer(base64);
                  setProfileData({
                    ...profileData,
                    fileBase64: base64?.split(",")[1],
                  });
                };
                reader.readAsDataURL(file);
              }}
            />
            {imageContainer.length > 0 && (
              <img
                src={
                  profileData.profilePicBase64 !== ""
                    ? profileData.profilePicBase64
                    : imageContainer
                }
                alt={"profile pic"}
                style={{ height: "100px", width: "100px", borderRadius: "50%" }}
                onClick={() => {
                  profileImgRef.current.click();
                }}
              />
            )}
            <Typography component="h1" variant="h5">
              Profile
            </Typography>

            <Box sx={{ mt: 3, width: "100%" }}>
              <Grid container spacing={2} alignItems="center">
                <Grid item xs={1}></Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    id="name"
                    label="Unit Name"
                    value={profileData?.name}
                    onChange={(e) => {
                      setProfileData({ ...profileData, name: e.target.value });
                    }}
                    InputProps={{
                      endAdornment: (
                        <EditIcon
                          sx={{ color: (theme) => theme.palette.primary.main }}
                        />
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    id="address"
                    label="Brief Address"
                    value={profileData?.address}
                    onChange={(e) => {
                      setProfileData({
                        ...profileData,
                        address: e.target.value,
                      });
                    }}
                    InputProps={{
                      endAdornment: (
                        <EditIcon
                          sx={{
                            color: (theme) => theme.palette.primary.main,
                            pointerEvents: "none",
                          }}
                        />
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    id="mobileNumber"
                    label="Mobile Number"
                    value={profileData?.mobile}
                    disabled={true}
                  />
                </Grid>

                <Grid item xs={12}>
                  <div
                    onClick={() => setOpen(true)}
                    style={{ cursor: "pointer" }}
                  >
                    <TextField
                      fullWidth
                      id="language"
                      sx={{
                        cursor: "pointer",
                        "&:hover": {
                          cursor: "pointer",
                        },
                      }}
                      label="Language"
                      value={profileData?.language}
                    />
                  </div>
                </Grid>
              </Grid>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                onClick={() =>
                  updateProfile(
                    validationUpdateProfile,
                    profileData,
                    Swal,
                    UpdateUserProfileApi,
                    GetUserProfileApi,
                    setProfileData,
                    getUserProfileImg,
                    UpdateUserProfileImageApi
                  )
                }
              >
                Save
              </Button>
            </Box>
          </Box>
        </Container>
      )}
      <Language open={open} setOpen={setOpen} />
    </Box>
    </>
  );
}
