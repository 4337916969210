import { Box, Typography } from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";

import Grid from "@mui/material/Grid";

import React, { useEffect, useState } from "react";

import {
  addTableSection,
  getNotAssignmentTable,
  getTableSections,
  updateTableSection,
} from "./tableApi";
import AddTableSection from "./addTableSection";
import Swal from "sweetalert2";
import {
  addTable,
  getTableNotAssigned,
  getTablesSec,
  updateTableSectionData,
} from "./tableSectionCustomApiHandler";
export default function TableSection() {
  const [tableSection, setTableSection] = useState([]);
  const [open, setOpen] = useState(false);
  const [allTables, setAllTables] = useState([]);
  const [selectedTables, setSelectedTables] = useState([]);
  const [selectedSection, setSelectedSection] = useState(null);

  useEffect(() => {
    getTablesSec(
      {
        isActive: false,
      },
      getTableSections,
      setTableSection
    );
  }, []);
  useEffect(() => {
    if (open === false) {
      setSelectedSection(null);
    }
  }, [open]);
  return (
    <Box>
      <Box
        sx={{
          overflowY: "auto",
          height: { sm: "calc(100vh - 70px)", xs: "calc(100vh - 130px)" },
        }}
      >
        <Box
          sx={{
            mb: "10px",
            position: {
              xs: "fixed",
              sm: "relative",
            },
            pt: {
              xs: "0px",
              sm: "10px",
            },
            background: "white",
            width: "100%",
            top: {
              xs: "61px",
              sm: "0px",
            },
          }}
        >
          <Typography
            component="h1"
            variant="h5"
            textAlign="center"
            color="primary"
          >
            Table Section
          </Typography>
        </Box>
        <Box
          sx={{
            p: "20px",
          }}
        >
          <Grid
            container
            spacing={2}
            alignItems="center"
            sx={{
              pt: {
                xs: "40px",
                sm: "0px",
              },
            }}
          >
            {tableSection.map((item, index) => (
              <Grid item xs={12} sm={4} md={3}>
                <Box
                  fullWidth
                  onClick={() => {
                    getTableNotAssigned(
                      {
                        sectionId: item?.id,
                        isActive: true,
                      },
                      getNotAssignmentTable,
                      setAllTables
                    );
                    setOpen(true);
                    setSelectedSection(item);
                  }}
                  sx={{
                    height: "40px",
                    border: "1px solid grey",
                    borderRadius: "10px",
                    borderRight: `8px solid ${item.isActive ? "green" : "red"}`,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    cursor: "pointer",
                    cursor: "pointer",
                    ":hover": {
                      bgcolor: "#2892ba17",
                    },
                  }}
                >
                  {item?.name}
                </Box>
              </Grid>
            ))}
          </Grid>
        </Box>
        <AddCircleIcon
          color="primary"
          onClick={async () => {
            await getTableNotAssigned(
              {
                sectionId: 0,
                isActive: true,
              },
              getNotAssignmentTable,
              setAllTables
            );
            setOpen(true);
          }}
          sx={{
            fontSize: "60px",
            cursor: "pointer",
            position: "fixed",
            bottom: { lg: "10px", md: "10px", sm: "10px", xs: "4rem" },
            right: "10px",
            zIndex: "9999",
          }}
        />
      </Box>

      <AddTableSection
        open={open}
        section={selectedSection}
        setOpen={setOpen}
        selectedTables={selectedTables}
        setSelectedTables={setSelectedTables}
        setAllTables={setAllTables}
        allTables={allTables}
        addTable={(data) =>
          addTable(
            data,
            addTableSection,
            Swal,
            getTableSections,
            setTableSection,
            setSelectedTables
          )
        }
        updateTableSectionData={(data) =>
          updateTableSectionData(
            data,
            updateTableSection,
            Swal,
            getTableSections,
            setTableSection
          )
        }
        setSelectedSection={setSelectedSection}
      />
    </Box>
  );
}
