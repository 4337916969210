import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  Switch,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";

import CloseIcon from "@mui/icons-material/Close";
import { ModalHeader } from "../../common/modalHeader";
const data = {
  categoryName: "",
  items: [],
};

export default function AddCategories({
  selectedCategories,
  section,
  selectedItems,
  setSelectedItems,
  allItems,
  open,
  addCategoriesData,
  updateCategoriesData,
  setOpen,
}) {
  const [itemData, setItemData] = useState(data);
  const onClose = () => {
    setItemData(data);
    setOpen(false);
    setSelectedItems([]);
  };

  useEffect(() => {
    if (allItems && selectedCategories) {
      const aa = allItems?.filter((i) => {
        return i.itemCategoryId === selectedCategories?.id;
      });
      if (aa.length > 0) setSelectedItems([...aa]);
    }
  }, [allItems, selectedCategories]);

  useEffect(() => {
    if (selectedCategories) {
      setItemData({ ...itemData, ...selectedCategories });
    }
  }, [selectedCategories]);

  return (
    <Dialog open={open} fullWidth={true} maxWidth="xs" onClose={onClose}>
     
      <ModalHeader title={"Item Information"} 
        onClose={(data)=>{
          setOpen(data)
        }}
        />
      {/* <DialogTitle color="primary"></DialogTitle> */}
      
      <DialogContent sx={{ p: 1 }}>
        <Box sx={{ mt: 2, paddingX: "1rem" }}>
          <TextField
            fullWidth
            size="small"
            variant="outlined"
            value={itemData.categoryName}
            onChange={(e) => {
              setItemData({ ...itemData, categoryName: e.target.value });
            }}
            label={
              <span>
                Item Categories<span style={{ color: "red" }}>*</span>
              </span>
            }
          />
          <FormControl sx={{ width: "100%" }} component="fieldset">
            {allItems
              .filter((i) => {
                if (selectedCategories) {
                  return (
                    i.itemCategoryId === 0 ||
                    i.itemCategoryId === selectedCategories?.id
                  );
                } else {
                  return i.itemCategoryId === 0;
                }
              })
              ?.map((item2, index) => {
                return (
                  <FormGroup
                    sx={{ width: "100%" }}
                    aria-label="position"
                    row
                    key={"notAssigntable" + index}
                  >
                    <FormControlLabel
                      sx={{
                        width: "100%",
                        justifyContent: "space-between",
                        px: 2,
                        m: 0,
                      }}
                      control={
                        <Checkbox
                          checked={
                            selectedItems.findIndex(
                              (item) => item.id === item2.id
                            ) !== -1
                          }
                          onChange={(e) => {
                            if (
                              selectedItems.findIndex(
                                (item) => item.id === item2.id
                              ) !== -1
                            ) {
                              const tempArry = [...selectedItems];
                              const ind = tempArry.findIndex(
                                (i) => i.id === item2.id
                              );

                              if (ind !== -1) tempArry.splice(ind, 1);

                              setSelectedItems([...tempArry]);
                            } else {
                              const tempArry = [
                                ...selectedItems,
                                { ...item2, isActive: true },
                              ];
                              setSelectedItems([...tempArry]);
                            }
                          }}
                        />
                      }
                      label={item2?.itemDescription}
                      labelPlacement="start"
                    />
                  </FormGroup>
                );
              })}
          </FormControl>
        </Box>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button
          disabled={
            itemData?.categoryName?.trim().length === 0 ||
            selectedItems.length === 0
          }
          variant="contained"
          onClick={() => {
            if (section) {
              let arr = [...selectedItems];
              const arrcp = arr.map((item) => {
                return {
                  itemDescription: item.itemDescription,
                  itemId: item.id,
                  itemCategoryId: item.itemCategoryId,
                };
              });
              updateCategoriesData({ ...itemData, items: arrcp });
            } else {
              let arr = [...selectedItems];
              const arrcp = arr.map((item) => {
                return {
                  itemDescription: item.itemDescription,
                  itemId: item.id,
                };
              });
              addCategoriesData({ ...itemData, items: arrcp });
            }
            setOpen(false);
            setItemData(data);
          }}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}
