import { swalAlertPopProps } from "../../utils/commonUtils";

export const getTablesSec = async (param,getTableSections,setTableSection) => {
    await getTableSections(param)
      .then((res) => {
        setTableSection(res.data?.data?.tableList);
      })
      .catch((err) => { });
  }; 
  export   const getTableNotAssigned = async (param,getNotAssignmentTable,setAllTables) => {
    await getNotAssignmentTable(param)
      .then((res) => {
        setAllTables(res?.data?.data?.tableList);
      })
      .catch((err) => {});
  }; 
export const addTable = async (param,addTableSection,Swal,getTableSections,setTableSection,setSelectedTables) => {
    await addTableSection(param)
      .then((res) => {
        if (res?.data?.succeeded === true) {
          Swal.fire({
            text: "Add Table Successfully",
            icon: "success",
            
            ...swalAlertPopProps,
          });
          setSelectedTables([]);
          getTablesSec(
            {
              isActive: false,
            },
            getTableSections,
            setTableSection
          );
        }
      })
      .catch((err) => {});
  }; 
export  const updateTableSectionData = async (payload,updateTableSection,Swal,getTableSections,setTableSection) => {
    await updateTableSection(payload)
      .then(async (res) => {
        if (res?.data?.succeeded) {
          Swal.fire({
            title: "Successfully Update Table",
            icon: "success",
            
            ...swalAlertPopProps,
          });

          await getTablesSec(
            {
              isActive: false,
            },
            getTableSections,
            setTableSection
          );
        } else {
          Swal.fire({
            title: res?.data?.data?.message,
            icon: "error",
            
            ...swalAlertPopProps,
          });
        }
      })
      .catch(() => {});
  };