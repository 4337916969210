export const dateFunction = (date, setDate, setStartDate) => {
  const dateStr2 = date.toLocaleDateString("en-GB", {
    year: "numeric",
    month: "long",
    day: "2-digit",
  });
  let dateContainer = String(dateStr2)?.split(" ");
  var year2 = dateContainer[2];
  var month2 = dateContainer[1];
  var day2 = dateContainer[0];
  var formattedDate2 = day2 + "-" + month2 + "-" + year2;

  setDate(formattedDate2);
  setStartDate(date);
};

export const paymentReceptDatePicker = (
  setGetPaymentObj,
  setDate,
  setStartDate,
  date,
  getPaymentObj
) => {
  const dateStr = date?.toLocaleDateString("en-GB", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  });
  const dateStr2 = date?.toLocaleDateString("en-GB", {
    year: "numeric",
    month: "long",
    day: "2-digit",
  });
  const [month, day, year] = dateStr?.split("/");

  const formattedDate = `${year}-${day?.padStart(2, "0")}-${month?.padStart(
    2,
    "0"
  )}`;

  setGetPaymentObj({ ...getPaymentObj, date: formattedDate });

  let dateContainer = String(dateStr2)?.split(" ");
  var year2 = dateContainer[2];
  var month2 = dateContainer[1];
  var day2 = dateContainer[0];
  var formattedDate2 = day2 + "-" + month2 + "-" + year2;
  setDate(formattedDate2);

  setStartDate(date);
};

export const monthInText=(inputDateStr)=>{
 

// Create a Date object from the input string
const inputDate = new Date(inputDateStr);

// Define an array of month names
const monthNames = [
  "Jan", "Feb", "Mar", "Apr", "May", "Jun",
  "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
];

// Get the day, month, and year components from the Date object
const day = inputDate.getDate();
const month = monthNames[inputDate.getMonth()];
const year = inputDate.getFullYear(); 
// Create the desired output format 

return `${day}-${month}-${year}`;
}