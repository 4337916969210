import React, { useEffect, useState } from 'react'
import { getPrivacyPolicy } from './privacyTermApi'
import { Container } from '@mui/material';

export const PrivacyPolicy = () => {
    const [documentContent, setDocumentContent] = useState('');
    const getPrivacyPolicyData=()=>{
        getPrivacyPolicy((data)=>{
          console.log("Data privacy policy",data)
          setDocumentContent(data)
        })
       }


    useEffect(() => {
        getPrivacyPolicyData()
    }, [])
    
  return (
    <>
        <Container>
      <div
        dangerouslySetInnerHTML={{ __html: documentContent }}
      />

</Container>
    </>
  )
}
