import Swal from "sweetalert2";
import { LOGIN, RESENDOTP, SENDOTP, VALIDATEUSEROTP } from "../../utils/api";
import axios from "../../utils/axios";
import { swalAlertPopProps } from "../../utils/commonUtils";

export const LoginApi = async (params) => {
    console.log("login api");
    const res = await axios.post(LOGIN, params, {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    });
    return res;
  };
  

  export const getOtp=(payload,callback)=>{
    axios.post(SENDOTP,payload).then((res)=>{
      callback(res)
      if(res?.status){
        Swal.fire({
          title: "Successfully Send Otp",
          // text: val.text,
          icon: "success",
  
          ...swalAlertPopProps,
        });
      }
      else{
        Swal.fire({
          title: res.message,
          // text: val.text,
          icon: "warning",
  
          ...swalAlertPopProps,
        });
      }
    }).catch((err)=>{
      console.log("err ",err)
    })

  }



  export const getresemndOtp=(payload,callback)=>{
    axios.post(RESENDOTP,payload).then((res)=>{
      callback(res)
      if(res?.status){
        Swal.fire({
          title: "Successfully Resent Otp",
          // text: val.text,
          icon: "success",
  
          ...swalAlertPopProps,
        });
      }
      else{
        Swal.fire({
          title: res.message,
          // text: val.text,
          icon: "warning",
  
          ...swalAlertPopProps,
        });
      }
    }).catch((err)=>{
      console.log("err ",err)
    })

  }



  export const getValidateOtp=(payload,callback)=>{
    axios.post(VALIDATEUSEROTP,payload).then((res)=>{
      callback(res)
      if(res?.status){
        Swal.fire({
          title: "Successfully Resent Otp",
          // text: val.text,
          icon: "success",
  
          ...swalAlertPopProps,
        });
      }
      else{
        Swal.fire({
          title: res.message,
          // text: val.text,
          icon: "warning",
  
          ...swalAlertPopProps,
        });
      }
    }).catch((err)=>{
      console.log("err ",err)
    })

  }

  