import React, { useState } from "react";
import FileUpload from "./fileUpload";
import { BULKCOUNTERSELLCUSTOMERIMPORT, BULKIMPORTMPORT, BULKSERVICESIMPORT } from "../../utils/api";
import DynamicBreadcrumbs from "../../common/Breadcrumb";
import { Button, Container, Grid } from "@mui/material";

const ExcelUpload = () => {
  const downloadFile = (url, fileName) => {
    const fileUrl = url;
    const link = document.createElement("a"); // Create a link
    link.href = fileUrl; // Set the href to the file URL
    link.setAttribute("download", fileName); // Set the download attribute with the file name
    document.body.appendChild(link); // Append link to the document
    link.click(); // Trigger download
    document.body.removeChild(link); // Remove the link after download
  };

  return (
    <>
      <DynamicBreadcrumbs />
   <Container>
   <Grid container mt={3}>
        <Grid item xs={4}>
          <Button
            variant="contained"
            onClick={() => {
              downloadFile("/BulkServicesUpload.xlsx", "BulkServicesUpload.xlsx");
            }}
          >
            Download Services Format
          </Button>
        </Grid>
        <Grid item xs={4}></Grid>
        <Grid item xs={4}>
          <FileUpload api={BULKSERVICESIMPORT} text={"Bulk Services Upload"} />
        </Grid>
      </Grid>

      <Grid sx={{ mt: "4px" }} container>
        <Grid item xs={4}>
          <Button
            variant="contained"
            onClick={() => {
              downloadFile("/BulkProductUpload.xlsx", "BulkProductUpload.xlsx");
            }}
          >
            Download Products Format
          </Button>
        </Grid>
        <Grid item xs={4}></Grid>
        <Grid item xs={4}>
          <FileUpload api={BULKIMPORTMPORT} text={"Bulk Products Upload"} />
        </Grid>
      </Grid>

      <Grid container sx={{ mt: "4px" }}>
        <Grid item xs={4}>
          <Button
            variant="contained"
            onClick={() => {
              downloadFile("/BulkCustomerUpload.xlsx", "BulkCustomerUpload.xlsx");
            }}
          >
            Download Customers Format
          </Button>
        </Grid>
        <Grid item xs={4}></Grid>
        <Grid item xs={4}>
          <FileUpload
            api={BULKCOUNTERSELLCUSTOMERIMPORT}
            text={"Bulk Customers Upload"}
          />
        </Grid>
      </Grid>
   </Container>
    </>
  );
};

export default ExcelUpload;
