import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import { Button } from "@mui/material";
import ReactToPrint from "react-to-print";
import { useSelector } from "react-redux";
import "./printBilling.css";
import { ApplyAccordingREAndCBUser } from "../features/counterSellBilling/counterSellBillFunctions";

import {
  receiptDateFormate,
  receptTime,
} from "./commonUtils/commonUtilFunction";
import QrCodeGenrate from "./qrCodeGenerate";
export default function PrintBilling({
  componentRef,
  currentDataArr,
  tableName,
  userDetail,
  addDataCallBack,
  setKOT,
  paybill,
  setOpen2,
  payableBilUpdateBill,
  totalAmountBill,
  discountAmount,paymentAttribute,
  addtoCardProductCBUser,
  bill,
  priceWithoutGst,
}) {
  const billNumber = useSelector((state) => state.reducer.billNumber);
  const handlePrint = () => {
    if (componentRef && componentRef.current) {
      componentRef?.current?.onClick();
    }
  };

  React.useEffect(()=>{
    console.log("componentRef ",componentRef)
  },[])


  // const userDetail = useSelector((state) => state.reducer.userDetail);

console.log(paybill,'paybillpaybill')
  return (
    <Box
      sx={{
        background: "white",
        display: "none",
        position: "absolute",
        left: "30%",
        top: "10vh",
        overflowY: "auto",
        maxHeight: "80vh",
        p: "0px 10px",
        width: userDetail?.printerSize == 3 ? "80mm" : "48mm",
      }}
    >
      <Box
        ref={componentRef}
        sx={{
          minWidth: userDetail?.printerSize == 2 ? "48mm" : "none",
          maxWidth: userDetail?.printerSize == 3 ? "80mm" : "none", // Adjust the maximum width to fit the print paper size
          margin: userDetail?.printerSize == 3 ? "10px" : "0px",

          boxSizing: "border-box",

        }}
      > 



<Box 
        sx={{
          textAlign:'center'

        }}
      > 


{userDetail?.uploadedLogo && 
        ( <img
          src={userDetail?.uploadedLogo}
          alt="Selected"
          // onClick={handleIconClick}
          style={{ width: '120px', height: '120px', cursor: 'pointer', borderRadius: '50%' }}
        />)

        }
      </Box>






        
        <Typography
          variant="subtitle1"
          textAlign="center"
          sx={{
            margin: "0px",
            p: "0px",
            fontSize: "18px",

            lineHeight: "1.2",
            fontWeight: "600",
          }}
        >
          {userDetail?.myBusinessName}
        </Typography>
        <Box
          sx={{
            width: "100%",
          }}
        >
          <Typography
            variant="subtitle1"
            textAlign="center"
            sx={{
              margin: "0px",
              p: "0px",
              fontSize: userDetail?.printerSize == 3 ? "15px" : "12px",
              lineHeight: "1.2",
            }}
          >
            {userDetail?.billerAddress}
          </Typography>
          <Typography
            variant="subtitle1"
            textAlign="center"
            sx={{
              margin: "0px",
              lineHeight: "1.2",
              p: "0px",
              fontSize: userDetail?.printerSize == 3 ? "15px" : "12px",
            }}
          >
            PH-NO:{userDetail?.mobileNumber}
          </Typography>

          <Typography
            variant="subtitle1"
            textAlign="center"
            sx={{
              margin: "0px",
              lineHeight: "1.2",
              // p: "0px 0px 0px 0px",
              m: "0px 10px 0px 0px",
              fontSize: userDetail?.printerSize == 3 ? "15px" : "11px",
            }}
          >
            BILL NO :{billNumber + 1}
            {"    "}
            <span style={{ margin: "4px" }}></span>
            {receiptDateFormate()}
            {"  "}
            {receptTime()}
          </Typography>
          <Typography
            variant="subtitle1"
            textAlign="center"
            sx={{
              margin: "0px",
              p: "0px",
              lineHeight: "1.2",
              fontSize: "20px",
              fontWeight: "600",
            }}
          >
            {ApplyAccordingREAndCBUser(
              userDetail,
              () => tableName,
              () => ""
            )}
          </Typography>
        </Box>

        <Box
          sx={{
            paddingLeft: userDetail?.printerSize === 3 ? "10px" : "0px",
          }}
        >
          <Grid container>
            <Grid
              item
              container
              sx={{
                borderBottom: "1px dashed black",
                borderTop: "1px dashed black",
              }}
            >
              <Grid
                item
                container
                sx={{
                  borderBottom: "1px dashed black",
                }}
              >
                <Grid item xs={12} sx={{ fontSize: "14px" }}>
                  ITEM
                </Grid>
                <Grid item xs={2.5} sx={{ fontSize: "14px" }}>
                  QTY
                </Grid>
                <Grid
                  item
                  xs={5}
                  sx={{ fontSize: "14px", textAlign: "center" }}
                >
                  RATE
                </Grid>
                {/* <Grid
                  sx={{ fontSize: "14px", textAlign: "center" }}
                  item
                  xs={3}
                >
                  GST
                </Grid> */}
                <Grid sx={{ fontSize: "14px" }} item xs={4}>
                  TOTAL
                </Grid>
              </Grid>

              {ApplyAccordingREAndCBUser(
                userDetail,
                () => {
                  return currentDataArr;
                },
                () => {
                  return addtoCardProductCBUser;
                }
              )?.length > 0 &&
                ApplyAccordingREAndCBUser(
                  userDetail,
                  () => {
                    return currentDataArr;
                  },
                  () => {
                    return addtoCardProductCBUser;
                  }
                )?.map((ele, index) => {
                  console.log("eee update ", ele, " ", index);
                  return (
                    <React.Fragment key={"Billinglist" + index}>
                      <Grid
                        item
                        xs={12}
                        sx={{ fontSize: "15px", marginY: "3px" }}
                      >
                        <Typography></Typography>

                        { }
                        {ApplyAccordingREAndCBUser(
                          userDetail,
                          () => {
                            return ele[1] === undefined
                              ? ele.itemDescription
                              : ele[1].itemDescription;
                          },
                          () => {
                            return ele.itemDescription;
                          }
                        )}
                      </Grid>
                      <Grid
                        item
                        xs={2.5}
                        sx={{
                          fontSize: "14px",
                          overflowWrap: "break-word !important",
                        }}
                      >
                        {" "}
                        {ApplyAccordingREAndCBUser(
                          userDetail,
                          () => {
                            return ele[1] === undefined
                              ? ele.quantity
                              : ele[1].suppliedQuantity;
                          },
                          () => {
                            return ele.suppliedQuantity;
                          }
                        )}
                        { }
                      </Grid>
                      <Grid
                        item
                        xs={5}
                        sx={{
                          fontSize: "14px",
                          textAlign: "center",
                          paddingX: "2px",
                          overflowWrap: "break-word !important",
                        }}
                      >
                        {ApplyAccordingREAndCBUser(
                          userDetail,
                          () => {
                            return ele[1] === undefined
                              ? ele.rate?.toFixed(1)
                              : ele[1].currentPrice?.toFixed(1);
                          },
                          () => {
                            return ele.currentPrice
                              ? ele.currentPrice?.toFixed(1)
                              : ele.rate?.toFixed(1);
                          }
                        )}
                      </Grid>

                      {/* <Grid
                        item
                        xs={3}
                        sx={{
                          fontSize: "14px",
                          textAlign: "center",
                          paddingX: "2px",
                          overflowWrap: "break-word !important",
                        }}
                      >
                        {" "}
                        {ApplyAccordingREAndCBUser(
                          userDetail,
                          () => {
                            return ele[1] === undefined
                              ? ele.gst?.toFixed(1)
                              : ele[1].gst == 0
                                ? "-"
                                : ele[1].gst?.toFixed(1) + "%";
                          },
                          () => {
                            return ele.gst?.toFixed(1) <= 0
                              ? "-"
                              : ele.gst?.toFixed(1) + "%";
                          }
                        )}
                        { }
                      </Grid> */}
                      <Grid
                        item
                        xs={4}
                        sx={{
                          fontSize: "14px",
                          textAlign: "left",
                          overflowWrap: "break-word !important",
                        }}
                      >
                        {ApplyAccordingREAndCBUser(
                          userDetail,
                          () => {
                            return ele[1] === undefined
                              ? (ele.rate + ele.rate).toFixed(
                                1
                              )
                              : (
                                ele[1].currentPrice * ele[1].suppliedQuantity
                              )?.toFixed(1);
                          },
                          () => {
                            return ele?.suppliedQuantity
                              ? (ele.currentPrice * ele.suppliedQuantity)?.toFixed(1)
                              : (ele.rate + ele.rate).toFixed(
                                1
                              );
                          }
                        )}
                        { }
                        {/* {ele[1].currentPrice +
                            ele[1].currentPrice * (ele[1].gst / 100)} */}
                      </Grid>
                    </React.Fragment>
                  );
                })}
            </Grid>
            {discountAmount > 0 && (
              <Grid
                item
                xs={12}
                sx={{
                  textAlign: "center",
                  fontWeight: "500",

                  borderBottom: "1px dashed black",
                  paddingY: "5px",
                }}
              >
                <Typography
                  sx={{
                    overflowWrap: "break-word !important",
                    fontWeight: "bold",
                    fontSize: "12px",
                  }}
                >
                  Sub Total : RS. {paybill ? totalAmountBill : totalAmountBill}{" "}
                </Typography>
                <Typography
                  sx={{
                    overflowWrap: "break-word !important",
                    fontWeight: "bold",
                    fontSize: "12px",
                  }}
                >
                  Discount: RS. {(discountAmount ? discountAmount : 0)}{" "}
                </Typography>
              </Grid>
            )}

             <Grid
                item
                xs={12}
                sx={{
                  textAlign: "center",
                  fontWeight: "500",

                  borderBottom: "1px dashed black",
                  paddingY: "5px",
                }}
              >
                <Typography
                  sx={{
                    overflowWrap: "break-word !important",
                    fontWeight: "bold",
                    fontSize: "12px",
                  }}
                >
                  Sub Total : RS.{priceWithoutGst} {" "}
                </Typography>
                {/* <Typography
                  sx={{
                    overflowWrap: "break-word !important",
                    fontWeight: "bold",
                    fontSize: "12px",
                  }}
                >
                  GST: RS.{paybill-priceWithoutGst?.toFixed(2)} {" "}
                </Typography> */}
                 <Typography
                  sx={{
                    overflowWrap: "break-word !important",
                    fontWeight: "bold",
                    fontSize: "12px",
                  }}
                >
                  CGST: RS.{paymentAttribute?paymentAttribute.cgst:bill?.cgst} {" "}
                </Typography>
                <Typography
                  sx={{
                    overflowWrap: "break-word !important",
                    fontWeight: "bold",
                    fontSize: "12px",
                  }}
                >
                           IGST: RS.{ (paymentAttribute?paymentAttribute.igst:bill?.igst)} {" "}
                </Typography>
                <Typography
                  sx={{
                    overflowWrap: "break-word !important",
                    fontWeight: "bold",
                    fontSize: "12px",
                  }}
                >
                           SGST: RS.{(paymentAttribute?paymentAttribute?.sgst:bill?.sgst)} {" "}
                </Typography>
                
              </Grid> 

            <Grid
              item
              xs={12}
              sx={{
                textAlign: "center",
                fontWeight: "500",

                borderBottom: "1px dashed black",
                paddingY: "5px",
              }}
            >
              <Typography
                sx={{
                  overflowWrap: "break-word !important",
                  fontWeight: "bold",
                  fontSize: "12px",
                }}
              >
                Total Amount: RS.
                {/* {paybill ? paybill?.toFixed(2) : 0} */}
                {paybill
                  ? paybill?.toFixed(2)
                  : payableBilUpdateBill
                    ? payableBilUpdateBill()?.toFixed(2)
                    : 0?.toFixed(2)}{" "}
                {/* // : payableBilUpdateBill() // ? */}
                {/* payableBilUpdateBill()?.toFixed(2) // : 0?.toFixed(2)}{" "} */}
              </Typography>
            </Grid>

            <Grid
              item
              xs={12}
              sx={{
                textAlign: "center",
                mt: 1
              }}
            >
              <Typography sx={{ fontSize: "13px" }}>
                THANKS VISIT AGAIN
              </Typography>
            </Grid>
            {userDetail?.isPrintQRCodeOnBill && (
              <Grid item xs={12} sx={{
                display: "grid",
                mt: 1,
                placeItems: "center"
              }}>
                <QrCodeGenrate amount={paybill
                  ? paybill?.toFixed(2)
                  : payableBilUpdateBill
                    ? payableBilUpdateBill()?.toFixed(2)
                    : 0?.toFixed(2)} />
              </Grid>
            )}
            <Grid
              item
              xs={12}
              sx={{
                textAlign: "center",
                fontWeight: "500",
                borderTop: "1px dashed black",
                borderBottom: "1px dashed black",
                paddingY: "5px",
                mt: 1
              }}
            >
              <Typography
                sx={{
                  overflowWrap: "break-word !important",
                  fontWeight: "bold",
                  fontSize: "12px",
                }}
              >
                Scan To Pay
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Box>

      <Box
        sx={{
          display: "flex",

          justifyContent: "space-around",
        }}
      >
        <ReactToPrint
          trigger={() => (
            <Button
              variant="contained"
              sx={{
                mt: "10px",
                display: "inline-block",
                mb: "10px",
              }}
              onClick={() => {
                setKOT();
                addDataCallBack();
                setOpen2(false);
              }}
            >
              {" "}
              Print
            </Button>
          )}
          content={() => componentRef.current}
          onBeforePrint={() => {
            setOpen2(false);

            addDataCallBack();
          }}
        ></ReactToPrint>
        <Button
          variant="contained"
          sx={{
            m: "10px",
          }}
          onClick={() => {
            handlePrint();
            // if (bill?.customerMobile?.length === 10) {
            //   addDataCallBack();
            //   shareOnWhatsApp(
            //     userDetail,
            //     getItemDetails,
            //     bill,
            //     billNumber,
            //     paybill,
            //     tableName,
            //     totalAmountBill,
            //     ApplyAccordingREAndCBUser,

            //     currentDataArr,
            //     addtoCardProductCBUser
            //   );
            // } else {
            //   Swal.fire({
            //     text: "Please Enter Mobile Number",
            //     icon: "error",

            //     ...swalAlertPopProps,
            //   });
            // }
            // setOpen2(false);
          }}
        >
          {" "}
          Share
        </Button>
      </Box>
    </Box>
  );
}
