

import {  GETALLOFFERSBYBILLER } from "../../utils/api";
import axios from "../../utils/axios"; 

export const GetAllOffersByBiller = async (params) => {
    const res = await axios.post(GETALLOFFERSBYBILLER, params);
    return res;
  };

