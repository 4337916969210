import React, { useEffect, useState } from "react";
import {
  TextField,
  Button,
  FormControl,
  Container,
  Grid,
  Box,
  Stack,
  OutlinedInput,
  InputLabel,
  MenuItem,
  ListItemText,
  Select,
  Checkbox,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormLabel,
  Modal,
} from "@mui/material";
// import "./test.css";

import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
// import { bookApoinment } from "./asppoinmentApi";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";  
// import dayjs from 'dayjs';
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { getAllEmployeeList } from "../features/employeeInfo/appoinmentDashboardApi";
// import { getAlldetailSerice } from "../services/servicesListApi";
// import { getAllEmployeeList } from "../features/employeeInfo/appoinmentDashboardApi";

dayjs.extend(utc);
dayjs.extend(timezone);

const EmployeeModal = ({open,setOpen,callback}) => {
  const appnType = useSelector((state) => state.reducer.apppoinmentUerType);
  const dispatch = useDispatch();
  const info = useSelector((state) => state.reducer.detail);
  const struct={
    billerId: "",
    customerName: "",
    mobileNumber: "",
    // otp: "",
    mailId: "",
    gender: "",
    service: "",
    date: "",
    hour: "",
    minute: "",
    status: "Unassigned",
  }
  const [formData, setFormData] = useState({...struct});

  useEffect(() => {
    console.log("formate date  ", timeValue);
    console.log("ppoinment22 ", info);
  }, [formData]);
  const [dateValue, setDateValue] = useState(dayjs());
  const [timeValue, setTimeValue] = useState(dayjs());
  const [otpEnabled, setOtpEnabled] = useState(true);

  const handleChange = (e) => {
    const { name, value } = e.target;
    // console.log()
    if (typeof value == "object") {
      setFormData({
        ...formData,
        [name]: value[0],
      });
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  const setDate = (newValue) => {
    setDateValue(newValue);

    // const date = dayjs(newValue).tz('UTC').format('YYYY-MM-DDTHH:mm:ss.SSZ');

    // console.log("date ",date)

    const date = dayjs(newValue).tz("UTC").format("YYYY-MM-DDTHH:mm:ss");

    console.log("date", date);

    // console.log("data ",date)
    setFormData((prev) => {
      return { ...prev, date: date };
      // return {...prev,date:date}
    });
  };

  const setTime = (newValue) => {
    setFormData((prev) => {
      if (newValue["$H"] == 20) {
        prev.hour = newValue["$H"] + 12;
      } else {
        prev.hour = newValue["$H"];
      }
      prev.minute = newValue["$m"];
      return { ...prev };
    });
    setTimeValue(newValue);
  };

  const handleTagsChange = (e) => {
    const { value } = e.target;

    console.log("val8e ", value);
    setFormData({
      ...formData,
      service: value,
    });
  };

  const gender = (e) => {
    setFormData((prev) => {
      let temp = { ...prev };
      if (e.target) {
        temp.gender = e.target.value;
      } else {
        temp.gender = e;
      }
      return { ...temp };
    });
  };

  const handleVerifymobileNumber = () => {
    if (formData.mobileNumber) {
      setOtpEnabled(true);
    }
  };

  const navigate = useNavigate();
  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(appnType, "appnType");

    const formDataobj = new FormData();
    Object.keys(formData).forEach((key, index) => {
      formDataobj.append(key, formData[key]);
    });
    const condition = appnType == "profile";

    // bookApoinment(
    //   appnType,
    //   condition
    //     ? { ...formData, date: formData.date }
    //     : { ...formData, date: formData.date },
    //   async (re) => {
    //     await setSession("appoinment", re);
    //     console.log("data 222 ", re?.data?.id);
    //     dispatch(setCustomerAppointmentId(re?.data?.id));
    //     if (!condition) {
    //       // alert('navigating to otp')
    //       navigate(routePath.OTP);
    //     }
    //   }
    // );
  
  
  console.log("value ",{ ...formData, date: formData.date })
  };

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };
  const [servicesArr, setservicesArr] = useState([
    {
      serviceName: "Shaving",
      rate: "100.00/Per Head",
    },
    {
      serviceName: "Hair cutting",
      rate: "150.00/Per Head",
    },
  ]);
  const names = [
    "Haircut",
    "Hair Coloring",
    "Hair Styling",
    "Manicure",
    "Pedicure",
    "Facial",
    "Waxing",
    "Massage",
    "Makeup",
    "Bridal Makeup",
    "Threading",
    "Hair Spa",
    "Nail Art",
    "Skin Treatment",
  ];
  useEffect(() => {
    setFormData({
      ...formData,
      billerId: info.billerId,
    });
    setDate(timeValue);
    setTime(timeValue);
    gender("male");
  }, []);

  const infoD = useSelector((state) => state.reducer.detail);
  useEffect(() => {
    
  }, []);
  

  const [tables,setTables]=useState([])
  useEffect(() => {
      if(open==true){
        getAllEmployeeList((res) => {
            console.log("getAllEmployeeList", res);
            setTables(res?.list);
          });
           
      } 
  }, [open]);

  return (
    <Modal
      open={open}
      onClose={()=>{
        setFormData({...struct})
        setOpen(false)}}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          background: "white",

          width: "52%",
          overflowY: "auto",
          zIndex:'99999',
          // height: "90vh",
          position: "absolute",
          left: "25%",
          top: "10%",
        }}
      >
        <Container>
        <Grid
                item
                xs={12}
                sx={{
                  overflowY: "auto",
                  maxHeight: {
                    xs: "calc(100vh - 60px)",
                    sm: "calc(100vh - 60px)",
                  },
                }}
              > 
                {tables.map((item2, index) => {
                  return (
                    <Box
                      key={"tableListBill" + index}
                      sx={{
                        display: "inline-block",
                        m: "10px 0px",
                      }}
                    >
                      <Box
                        onClick={() => {
                        //   dispatch(setTableName(item2.name));
                        //   navigate(routePath.COUNTERSELLBILLING);


                        callback(item2)
                        }}
                        sx={{
                          margin: "0px 10px",
                          cursor: "pointer",
                        //   boxShadow:
                        //     bookedTables?.indexOf(item2.name) !== -1
                        //       ? "0 4px 8px rgb(255 33 33 / 81%)"
                        //       : "0 4px 8px rgb(27 87 21)",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          width: "100px",
                          p: "0px",
                          fontWeight: "bold",
                          height: "60px",
                          borderRadius: "10px",
                        }}
                      >
                        {item2.name}
                      </Box>
                    </Box>
                  );
                })}
              </Grid>
        </Container>{" "}
      </Box>
    </Modal>
  );
};

export default EmployeeModal;
