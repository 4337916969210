import {
  Box,
  Card,
  CardActionArea,
  Divider,
  Grid,
  Typography,
} from "@mui/material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { Rating } from "./rating";

import * as React from "react";
import { styled } from "@mui/material/styles";
import CardHeader from "@mui/material/CardHeader";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Collapse from "@mui/material/Collapse";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import { red } from "@mui/material/colors";
import FavoriteIcon from "@mui/icons-material/Favorite";
import ShareIcon from "@mui/icons-material/Share";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MoreVertIcon from "@mui/icons-material/MoreVert";

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme }) => ({
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
  variants: [
    {
      props: ({ expand }) => !expand,
      style: {
        transform: "rotate(0deg)",
      },
    },
    {
      props: ({ expand }) => !!expand,
      style: {
        transform: "rotate(180deg)",
      },
    },
  ],
}));
export const ReviewCart = ({ ele }) => {
  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };
  return (
    <Card
     sx={{minHeight:'170px', position:'relative'}}
    >
    
  
        <CardHeader sx={{'& .MuiCardHeader-content .MuiCardHeader-title':{fontWeight:'bold'}}}
          avatar={
            <Avatar sx={{ bgcolor: '#bb4a9b' }} aria-label="recipe">
              <AccountCircleIcon />
            </Avatar>
          }
         
          title={ele?.profilename}
          subheader={ele?.createdDate?.split("T")[0]}
        />
        <CardContent sx={{py:0}}>
          <Typography
            variant="body2"
            sx={{
              color: "text.secondary",
              display: "-webkit-box",
              WebkitLineClamp: 2,
              WebkitBoxOrient: "vertical",
              overflow: "hidden",
              textOverflow: "ellipsis",
              maxHeight: "4.5em",
              lineHeight: "1.5em",
            }}
          >
            {ele?.review}
          </Typography>
        </CardContent>
       <Box sx={{position:'absolute', bottom:'0px', width:'100%'}}>
       <CardActions disableSpacing>
          <Rating ratting={ele?.rating}  selectedColor={"#f78b01"}/>
          <ExpandMore
            expand={expanded}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label="show more"
          >
            <ExpandMoreIcon />
          </ExpandMore>
        </CardActions>
       </Box>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <CardContent>{ele?.review}</CardContent>
        </Collapse>
      </Card>
  );
};
