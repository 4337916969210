import React, { useState } from 'react';
import { Box, Button, Typography } from '@mui/material';

const FileUploader = ({ onFileSelect }) => {
  const [selectedFile, setSelectedFile] = useState(null);

  // Handle file selection and notify the parent
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
    onFileSelect(file); // Pass the selected file to the parent component
  };

  // Handle file reset
  const handleFileReset = () => {
    setSelectedFile(null);
    onFileSelect(null); // Clear the selected file in the parent
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mt: 2 }}>
      {/* Hidden input element to select file */}
      <input
        type="file"
        accept="image/*"
        id="file-input"
        style={{ display: 'none' }}  // Hide the default file input
        onChange={handleFileChange}
      />
      
      {/* Custom button to trigger file input */}
      <Button
        variant="contained"
        color="primary"
        onClick={() => document.getElementById('file-input').click()}
      >
        {selectedFile ? 'Change File' : 'Upload File'}
      </Button>

      {/* Display file name if a file is selected */}
      {selectedFile && (
        <Box sx={{ mt: 2, textAlign: 'center' }}>
          <Typography variant="body1">Selected File: {selectedFile.name}</Typography>
          <Button 
            variant="outlined" 
            color="secondary" 
            sx={{ mt: 1 }}
            onClick={handleFileReset}
          >
            Remove File
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default FileUploader;
