import {
  GETDASHBOARDDATE,
  GETMENU,
  GETMENUHEADER,
  GETUSER,
  ITEMCATEGORY,
  ITEMGET,
  UPDATEBILL,
} from "./api";
import axios from "./axios";

export const GetUserApi = async () => {
  const res = await axios.get(GETUSER);
  return res;
};

export const GetUserMenuApi = async (businessLineId, languageCode) => {
  const res = await axios.get(GETMENU + businessLineId + "/" + languageCode);
  return res;
};

export const GetUserMenuHeaderApi = async (businessLineId, languageCode) => {
  const res = await axios.get(
    GETMENUHEADER + businessLineId + "/" + languageCode
  );
  return res;
};

export const ItemCategoryApi = async (params) => {
  const res = await axios.post(ITEMCATEGORY, params);

  return res;
};
export const ItemGETApi = async (params) => {
  const res = await axios.post(ITEMGET, params);
  return res;
};

export const getdashBoardApi = async () => {
  const res = await axios.get(GETDASHBOARDDATE);
  return res;
};
