import {
  Box,
  Switch,
  Button,
  FormControlLabel,
  FormGroup,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import Grid from "@mui/material/Grid";
import React, { useEffect, useState } from "react";
import { addTable, getMasterdApi, updateTableData } from "./tableMasterApi";
import CloseIcon from "@mui/icons-material/Close";
import Swal from "sweetalert2";
import { addTables, getDataa, updateData } from "./tableMasterCustomApiHandler";
import { swalAlertPopProps } from "../../utils/commonUtils";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  width: "40%",
  transform: "translate(-50%, -50%)",
  border: "none",
  bgcolor: "background.paper",
};
export default function TableMaster() {
  const [modelTable, setModelTable] = useState();
  const [open, setOpen] = React.useState(false);
  const handleClose = () => setOpen(false);
  const [table, setTable] = useState([]);
  useEffect(() => {
    if (open === false) {
      setModelTable();
    }
  }, [open]);

  useEffect(() => {
    getDataa(getMasterdApi, setTable);
  }, []);
  return (
    <Box>
      <Box
        sx={{
          overflowY: "auto",
          height: { sm: "calc(100vh - 70px)", xs: "calc(100vh - 130px)" },
        }}
      >
        <Box
          sx={{
            mb: "10px",
            position: {
              xs: "absolute",
              sm: "relative",
            },
            pt: {
              xs: "10px",
              sm: "10px",
            },
            background: "white",
            width: "100%",
            top: {
              xs: "61px",
              sm: "0px",
            },
          }}
        >
          <Typography
            component="h1"
            variant="h5"
            textAlign="center"
            sx={{
              mb: "10px",
            }}
            color="primary"
          >
            Table Master
          </Typography>
        </Box>

        <Box
          sx={{
            p: "20px",
          }}
        >
          <Grid
            container
            spacing={2}
            alignItems="center"
            sx={{
              pt: {
                xs: "40px",
                sm: "0px",
              },
            }}
          >
            {table?.map((item, index) => (
              <Grid item xs={12} sm={4} md={3}>
                <Box
                  fullWidth
                  onClick={() => {
                    setOpen(true);

                    setModelTable({ ...item, index: index });
                  }}
                  sx={{
                    height: "40px",
                    border: "1px solid grey",
                    borderRadius: "10px",
                    borderRight:
                      item?.isActive === true
                        ? "8px solid green"
                        : "8px solid red",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    cursor: "pointer",
                    ":hover": {
                      bgcolor: "#2892ba17",
                    },
                  }}
                >
                  {item?.name}
                </Box>
              </Grid>
            ))}
          </Grid>

          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box>
              <Box
                sx={{
                  ...style,
                  width: {
                    xs: "90%",
                    sm: "40%",
                  },
                  background: "1",
                  borderRadius: "5px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    p: "10px",
                    alignItems: "center",
                    justifyContent: "space-between",
                    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
                  }}
                >
                  <Typography
                    component="h1"
                    variant="h5"
                    textAlign="center"
                    sx={{ display: "inline-block" }}
                    color="primary"
                  >
                    Table Info
                  </Typography>
                  <CloseIcon
                    sx={{ float: "right", cursor: "pointer" }}
                    onClick={() => setOpen(false)}
                  />
                </Box>

                <Box
                  sx={{
                    p: "2rem",
                  }}
                >
                  <TextField
                    fullWidth
                    id="BrifeAddress"
                    label="Table Name"
                    name="BrifeAddress"
                    value={modelTable?.name}
                    onChange={(e) => {
                      setModelTable({ ...modelTable, name: e.target.value });
                    }}
                  />
                  {modelTable?.id && (
                    <FormGroup>
                      <FormControlLabel
                        sx={{
                          justifyContent: "space-between",
                          m: 0,
                          flexDirection: "row-reverse",
                        }}
                        control={
                          <Switch
                            checked={modelTable?.isActive}
                            onChange={(e) => {
                              setModelTable({
                                ...modelTable,
                                isActive: e.target.checked,
                              });
                            }}
                          />
                        }
                        label="Status"
                      />
                    </FormGroup>
                  )}

                  <Button
                    variant="contained"
                    sx={{
                      width: "100%",
                      mt: "10px",
                    }}
                    onClick={() => {
                      setOpen(false);
                      if (
                        modelTable?.name?.trim()?.length == 0 ||
                        modelTable?.name === undefined
                      ) {
                        Swal.fire({
                          title: "Please Enter Table Name",
                          icon: "error",

                          ...swalAlertPopProps,
                        });
                      } else {
                        if (modelTable?.id) {
                          updateData(
                            { ...modelTable, name: modelTable?.name },
                            updateTableData,
                            Swal,
                            getDataa,
                            getMasterdApi,
                            setTable
                          );
                        } else {
                          addTables(
                            { ...modelTable, name: modelTable?.name },
                            getDataa,
                            addTable,
                            Swal,
                            getMasterdApi,
                            setTable
                          );
                        }
                      }
                    }}
                  >
                    {" "}
                    Save
                  </Button>
                </Box>
              </Box>
            </Box>
          </Modal>
        </Box>
        <AddCircleIcon
          color="primary"
          onClick={() => {
            setOpen(true);

            setModelTable();
          }}
          sx={{
            fontSize: "60px",
            cursor: "pointer",
            position: "fixed",
            bottom: { xs: "4rem", md: "10px", sm: "10px", lg: "10px" },
            right: "10px",
            zIndex: "9999",
          }}
        />
      </Box>
    </Box>
  );
}
