import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  TextField,
  Typography,
  RadioGroup,
  FormControlLabel,
  Radio,
  Modal,
  Grid,
  Stack,
  Pagination,
  Checkbox,
} from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";
import React, { useEffect, useRef, useState } from "react";

import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";

import { useSelector } from "react-redux";

import DatePicker from "react-datepicker";
import EventIcon from "@mui/icons-material/Event";
import SearchIcon from "@mui/icons-material/Search";
import {
  AddCashPayment,
  getCounterSalesCustomers,
  getUnpaidUser,
} from "./paymentReceiptApi";
import UserCard from "./userCard";
import Swal from "sweetalert2";
import { displayDateFormat, swalAlertPopProps } from "../../utils/commonUtils";
import { validationAdd } from "./paymentReceiptValidations";
import { searchedElementDisplay } from "./receiptFunctions";
import { monthInText } from "./paymentReceiptDateFunction";
import { ModalHeader } from "../../common/modalHeader";

export default function AddReceipt({ open, setOpen, getRecept, unPaidBill, setUnPaidBill, upPaidBillData, setUnPaidBillData, billData, setBillData, searchModal, setSearchModal }) {
  const [pagination, setPagination] = useState({ page: 1, total: 10 });
  const [search, setSearch] = useState("");
  // const [upPaidBillData, setUnPaidBillData] = useState();

  const data = {
    customerMobile: "",
    amount: 0,
  };
  const { userDetail } = useSelector((state) => state.reducer);

  const onClose = () => {
    setOpen(false);
  };
  const handleChange = (event, value) => {
    setPagination({
      ...pagination,
      page: value,
    });
  };
  const getCounterSalesCusomter = async (param) => {
    await getCounterSalesCustomers(param).then((res) => {
      console.log("countersales res data   ", res?.data?.data, " ", {
        page: res.data.pageNumber,
        total: res.data.totalPages,
      });
      setPagination({
        page: res.data.pageNumber,
        total: res.data.totalPages,
      });
      setUnPaidBill([...res?.data?.data]);
    });
  };
  const addBill = async (param) => {
    await AddCashPayment(param)
      .then((res) => {
        console.log("add Bill ", res?.data?.succeeded);
        if (res?.data?.succeeded) {
          getRecept();
          Swal.fire({
            text: "Saved Record",
            icon: "success",

            ...swalAlertPopProps,
          });
        } else {
          Swal.fire({
            text: res?.data?.message,
            icon: "error",

            ...swalAlertPopProps,
          });
        }
      })
      .catch((err) => {
        console.log("err ", err);
      });
  };
  useEffect(() => {
    if (open === true) {
      getCounterSalesCusomter({
        filter: "",
        search: "",
        page: 1,
        pageSize: 1000,
      });
      setBillData({ ...billData, mybusinessId: userDetail?.myBusinessId });
    } else {
      console.log("close");
      setBillData(data);
      setUnPaidBillData();
    }
  }, [open]);
  const addLeadingZero = (value) => {
    return value < 10 ? "0" + value : value;
  };
  const [date, setDate] = useState(displayDateFormat());

  const [startDate, setStartDate] = useState(new Date());
  const datePickerRef = useRef();

  const openDatePicker = () => {
    if (datePickerRef.current) {
      datePickerRef.current.setOpen(true);
    }
  };
  useEffect(() => {
    console.log("billData ", billData)
  }, [billData])
  // const [status, setStatus] = useState("all");

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >



      <Box
        sx={{
          background: "white",
          width: "40%",
          overflowY: "auto",
          height: "auto",
          position: "absolute",
          left: "30%",
          top: "10%",
        }}
      >
     
        <ModalHeader title={"Associated Users"} 
        onClose={(data)=>{
          setOpen(data)
        }}
        />

        <Grid container sx={{ position: "relative" }}>

          <Grid item xs={12} sm={12}>
            <DialogContent >
              <Box sx={{ cursor: "pointer" }}>
                <TextField
                  fullWidth
                  // disabled
                  readonly
                  onClick={openDatePicker}
                  id="date"
                  value={date}
                  label={
                    <Box
                      sx={{
                        flex: 4,
                        color: "grey",
                        cursor: "pointer",
                        whiteSpace: "nowrap",
                      }}
                    >
                      Date : {/* {new Date().toLocaleDateString()} */}
                    </Box>
                  }
                  onChange={(e) => { }}
                  InputProps={{
                    style: {
                      height: '40px',
                      // background:"yellow"
                    },
                    endAdornment: (
                      <EventIcon
                        sx={{
                          color: (theme) => theme.palette.primary.main,
                          cursor: "pointer",
                        }}
                      />
                    ),
                  }}
                />
                <DatePicker
                  hidden={true}
                  selected={startDate}
                  maxDate={new Date()}
                  onChange={(date) => {
                    const dateStr2 = date.toLocaleDateString("en-GB", {
                      year: "numeric",
                      month: "long",
                      day: "2-digit",
                    });
                    let dateContainer = String(dateStr2).split(" ");
                    var year2 = dateContainer[2];
                    var month2 = dateContainer[1];
                    var day2 = dateContainer[0];
                    var formattedDate2 = day2 + "-" + month2 + "-" + year2;

                    setDate(formattedDate2);
                    setStartDate(date);
                  }}
                  className="custom-datepicker"
                  ref={datePickerRef}
                  onClickOutside={() => datePickerRef.current.setOpen(false)}
                />
              </Box>
              {/* <Box sx={{ mt: 2 }}>
                <TextField
                  fullWidth
                  label="Search by Name/Mobile Number"
                  value={search}
                  readonly={true}
                  onClick={() => {
                    setSearchModal(true)
                  }}
                  onChange={(e) => {
                    // getUnpaid({
                    //   customerMobile: e.target.value,
                    // });
                    // setSearch(e.target.value);
                  }}
                  InputProps={{
                    endAdornment: (
                      <SearchIcon
                        sx={{
                          color: (theme) => theme.palette.primary.main,
                        }}
                      />
                    ),
                  }}
                />
              </Box> */}
              {/* <Box sx={{
                mt: 2,
                cursor: "pointer"
              }}
                onClick={() => {

                  setSearchModal(true)
                }}>
                <TextField
                  fullWidth
                  id="name"
                  size="small"
                  // readOnly
                  // disabled

                  label={<Typography sx={{ marginTop: "0px" }}>
                    Search by Name/Mobile Number
                  </Typography>}
                  value={billData.name}
                  onChange={(e) => {
                    // getUnpaid({
                    //   customerMobile: e.target.value,
                    // });
                    // setBillData({
                    //   ...billData,
                    //   customerMobile: e.target.value,
                    // });
                  }}
                  // sx={{ cursor: "pointer" }}
                  InputProps={{
                    style: {
                      // height: '40px',
                      // background: "yellow",
                      '&:hover': {
                        // cursor: "pointer"
                        // Define hover styles here
                      },
                    },
                    endAdornment: (
                      <SearchIcon
                        sx={{
                          color: (theme) => theme.palette.primary.main,
                          cursor: "pointer"

                        }}
                        onClick={() => {

                          setSearchModal(true)
                        }}
                      />
                    ),
                  }}
                />
              </Box> */}
              <Box

                onClick={() => {
                  setSearchModal(true)

                }}
                sx={{
                  display: "flex",
                  height: "23px",
                  justifyContent: "space-between",
                  border: "1px solid #c9c9c9",
                  p: 1,
                  mt: 2,
                  borderRadius: "4px ",
                  color: "grey",
                  '&:hover': {
                    cursor: 'pointer !important',
                  },
                }}
              >
                <Typography variant="p">
                  {console.log(billData.name, "billData.name")}
                  {billData.name !== undefined ? billData.name : "Search by Name/Mobile Number"}
                </Typography>
                <SearchIcon
                  onClick={() => {
                    setSearchModal(true)

                  }}
                  sx={{
                    color: (theme) => theme.palette.primary.main,
                    cursor: "pointer",
                  }}
                />
              </Box>
              <Box sx={{ mt: 2 }}>
                <TextField
                  fullWidth
                  id="briefAddress"
                  label="Brief Address"
                  size="small"
                  // InputProps={{
                  //   style: {
                  //     height: '40px',
                  //     // background:"yellow"
                  //   }
                  // }}
                  onChange={(e) => {
                    setBillData({ ...billData, briefAddress: e.target.value });
                  }}
                />
              </Box>
              {upPaidBillData?.date && (
                <Box
                  sx={{
                    // float: "right",
                    display: "flex",
                    justifyContent: "space-between"
                    // justifyContent: "",
                  }}
                >
                  <Box sx={{ display: 'flex' }}>
                    <Checkbox color="secondary" checked={true} sx={{ m: 0 }} />
                    <Typography
                      color="primary"
                      sx={{
                        fontSize: "15px",
                        overflow: "Hidden",
                        mt: 2,
                        // m: 2,
                        textOverflow: "ellipsis",
                        display: "-webkit-box",
                        "-webkit-line-clamp": "1",
                        "-webkit-box-orient": "vertical",
                        fontWeight: 500,
                      }}
                    >
                      {upPaidBillData?.billNumber}
                    </Typography>

                  </Box>
                  <Box
                    sx={{
                      display: "flex"
                    }}>
                    <Typography
                      color="primary"
                      sx={{
                        fontSize: "15px",
                        overflow: "Hidden",
                        mt: 2,
                        m: 2,
                        textOverflow: "ellipsis",
                        display: "-webkit-box",
                        "-webkit-line-clamp": "1",
                        "-webkit-box-orient": "vertical",
                        fontWeight: 500,
                      }}
                    >
                      {monthInText(upPaidBillData?.date.split("T")[0])}
                    </Typography>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <CurrencyRupeeIcon
                        sx={{ fontSize: "15px" }}
                        color="primary"
                      />
                      <Typography
                        color="primary"
                        sx={{
                          fontSize: "15px",
                          overflow: "Hidden",
                          mt: 2,
                          textOverflow: "ellipsis",
                          display: "-webkit-box",
                          "-webkit-line-clamp": "1",
                          "-webkit-box-orient": "vertical",
                          m: 2,
                          ml: 0,
                          fontWeight: 600,
                        }}
                      >
                        {upPaidBillData?.balanceAmount}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              )}
              <Box>
                <Divider
                  sx={{
                    background: (theme) => theme.palette.primary.main,
                    width: "100%",
                    mt: 1,
                  }}
                />
                <Typography
                  color="primary"
                  sx={{
                    fontSize: "15px",
                    overflow: "Hidden",
                    mt: 1,
                    textOverflow: "ellipsis",
                    display: "-webkit-box",
                    "-webkit-line-clamp": "1",
                    "-webkit-box-orient": "vertical",
                    fontWeight: 600,
                  }}
                >
                  Outstanding:
                </Typography>
                <RadioGroup
                  row
                  sx={{
                    //   justifyContent: "center",
                    fontWeight: "500",
                    width: "100%",
                  }}
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  // value={bill.paymentMode}

                  value={billData?.paymentMode}
                  onChange={(e) => {
                    setBillData({ ...billData, paymentMode: e.target.value });
                  }}
                >
                  <FormControlLabel
                    sx={{ color: (theme) => theme.palette.primary.main }}
                    value="cash"
                    control={<Radio color="secondary" />}
                    label="Cash Payment"
                  />
                  <FormControlLabel
                    color="primary"
                    value="online"
                    sx={{ color: (theme) => theme.palette.primary.main }}
                    control={<Radio color="secondary" />}
                    label="Online Payment"
                  />
                </RadioGroup>
                <Box sx={{ mt: 1 }}>
                  <TextField
                    fullWidth
                    size="small"
                    id="receiveAmount"
                    label="Recive Amount"
                    value={billData?.amount !== 0 ? billData?.amount : ""}
                    onChange={(e) => {
                      let val = e.target.value
                        .replace(/[^\d.]+/g, "")
                        .replace(/\.(?=.*\.)/g, "");

                      // let val = e.target.value;
                      val = val.replace(/\D/g, "");
                      if (upPaidBillData?.balanceAmount >= val) {
                        setBillData({
                          ...billData,
                          amount: val,
                        });
                      }
                    }}
                    InputProps={{
                      style: {
                        height: '40px',
                        // background:"yellow"
                      },
                    }}
                  />
                </Box>
              </Box>
            </DialogContent>
          </Grid>
        </Grid>

        <DialogActions sx={{}}>
          {/* <Button onClick={onClose}>Cancel</Button> */}
          <Button
            variant="contained"
            fullWidth
            onClick={() => {
              setOpen(false);
              const valid = validationAdd(billData, upPaidBillData);
              if (valid.status) {
                addBill({
                  customer: billData?.customer,
                  customerMobile: billData?.customerMobile,
                  amount: parseInt(billData?.amount),
                  isCounterSalesBill: true,
                  paymentMode: billData?.paymentMode,
                });
              } else {
                Swal.fire({
                  text: valid.msg,

                  ...swalAlertPopProps,
                  icon: "warning",
                });
              }
            }}
          >
            Save
          </Button>
        </DialogActions>
      </Box>
    </Modal>
  );
}
