import React, { useState, useRef } from "react";
import { TextField } from "@mui/material";
import { useEffect } from "react";

const AutofocusTextFields = ({ itemData, setItemData }) => {
  const [inputValues, setInputValues] = useState([]);
  const inputRefs = useRef([]);
  useEffect(() => {
    if (itemData?.passcode) {
      inputValues.length === 0 && setInputValues(itemData?.passcode.split(""));
    }
  }, [itemData]);
  useEffect(() => {
    const fullOtp = [...inputValues].join("");
    inputValues.length !== 0 && setItemData({ ...itemData, passcode: fullOtp });
  }, [inputValues]);
  const handleInputChange = (event, index) => {
    let value = event.target.value.replace(/\D/g, "");
    if (value.length > 1) {
      return;
    }
    const nextIndex = index + 1;

    const updatedInputValues = [...inputValues];
    updatedInputValues[index] = value || "";
    setInputValues(updatedInputValues);

    // const fullOtp = [...inputValues, value].join("");
    const fullOtp = [...inputValues, value].join("");

    setItemData({ ...itemData, passcode: fullOtp });

    if (value && nextIndex < inputRefs.current.length) {
      inputRefs.current[nextIndex].focus();
    }
  };
  const renderTextFields = () => {
    const numTextFields = 4;
    const textFields = [];

    for (let i = 0; i < numTextFields; i++) {
      textFields.push(
        <TextField
          key={i}
          inputRef={(el) => (inputRefs.current[i] = el)}
          type="text"
          sx={{
            m: "10px",
            width: "20%",
            textAlign: "center",
          }}
          inputProps={{
            style: { textAlign: "center" },
          }}
          value={inputValues[i] || ""}
          onChange={(event) => handleInputChange(event, i)}
          margin="normal"
          variant="outlined"
        />
      );
    }

    return textFields;
  };

  return (
    <div
      style={{
        margin: "0px",
        padding: "0px",
        width: "100%",
        display: "flex",
        justifyContent: "center",
      }}
    >
      {renderTextFields()}
    </div>
  );
};

export default AutofocusTextFields;
