import { ADDREVIEWANDRATING, GETALLREVIEWANDRATING } from "../../utils/api"
import axios from "../../utils/axios"


export const addReview=async(payload,callback)=>{
    

     await axios.post(ADDREVIEWANDRATING,payload).then((res)=>{
      console.log("ADDREVIEWANDRATING ADDREVIEWANDRATING ",res)
      callback(res)
     }).catch((err)=>{
        console.log("err",err)
     })
}

export const getReview=async(payload,callback)=>{
    

   await axios.post(GETALLREVIEWANDRATING,payload).then((res)=>{
    console.log("ADDREVIEWANDRATING ADDREVIEWANDRATING ",res)
    callback(res)
   }).catch((err)=>{
      console.log("err",err)
   })
}