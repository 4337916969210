import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  TextField,
  Typography,
  RadioGroup,
  FormControlLabel,
  Radio,
  Modal,
  Grid,
} from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";
import React, { useEffect, useRef, useState } from "react";


import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";

export default function DisplayOutStandingReport({ open, setOpen, modalData }) {
  const [pagination, setPagination] = useState({ page: 1, total: 10 });

  const data = {
    customerMobile: "",
  };
  const onClose = () => {
    setOpen(false);
  };
  const handleChange = (event, value) => {
    setPagination({
      ...pagination,
      page: value,
    });
  };

  useEffect(() => {
    if (open === true) {
    } else {
      console.log("close");
    }
  }, [open]);

  const datePickerRef = useRef();

  const openDatePicker = () => {
    if (datePickerRef.current) {
      datePickerRef.current.setOpen(true);
    }
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          background: "white",
          overflowY: "auto",
          width: "50%",

          height: "90vh",
          position: "absolute",
          left: "25%",
          top: "5%",
        }}
      >
        <DialogTitle
          color="primary"
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",

            p: "0px 10px !important"
          }}
        >
          OutStanding Bill{" "}
          <CloseIcon sx={{ float: "right" }} onClick={() => setOpen(false)} />
        </DialogTitle>
        <Divider />
        <Grid container sx={{ p: "10px" }}>
          <Grid item xs={12} sx={{ p: "0px 10px " }}>
            <Box
              sx={{
                border: "1px solid grey",
                // padding: "16.5px 14px",
                borderRadius: "4px",
                display: "flex",

                marginTop: "4px",
                height: "40px",
                alignItems: 'center'

              }}
            >
              <Typography sx={{ margin: "0px 10px" }}>Table No: </Typography>
              <Typography
                sx={{
                  fontWeight: "600",
                  color: (theme) => theme.palette.secondary.main,
                }}
              >
                table{" "}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={4} sx={{ p: "0px 10px " }}>
            <Box
              sx={{
                border: "1px solid grey",

                mt: 2,
                // padding: "16.5px 14px",
                height: '40px',
                borderRadius: "4px",
                display: "flex",

                // marginTop: "4px",
                alignItems: 'center'
              }}
            >
              <Typography sx={{ margin: "0px 10px" }}>Bill No: </Typography>
              <Typography>{modalData?.billNumber}</Typography>
            </Box>
          </Grid>
          <Grid item xs={8} sx={{ p: "0px 10px " }}>
            <Box
              sx={{
                mt: 2,
                border: "1px solid grey",
                // padding: "16.5px 14px",
                height: '40px',
                borderRadius: "4px",
                display: "flex",
                alignItems: 'center'
              }}
            >
              <Typography sx={{ margin: "0px 10px" }}>Bill Date: </Typography>
              <Typography>{modalData?.billDate?.split("T")[0]}</Typography>
            </Box>
          </Grid>
          <Grid item xs={6} sx={{ p: "0px 10px " }}>
            <TextField
              fullWidth
              label="Mobile Number"
              value={modalData?.buyerMobile}
              sx={{
                mt: 2,
              }}
              InputProps={{
                style: {
                  height: '40px',
                  // background:"yellow"
                },
              }
              }
              onChange={(e) => {
                // getUnpaid({
                //   customerMobile: e.target.value,
                // });
                // setSearch(e.target.value);
              }}
            />
          </Grid>

          <Grid item xs={6} sx={{ p: "0px 10px " }}>
            <TextField
              fullWidth
              label="Customer Name"
              value={modalData?.buyerName}
              sx={{
                mt: 2,
              }}
              InputProps={{
                style: {
                  height: '40px',
                  // background:"yellow"
                },
              }}
              onChange={(e) => {
                // getUnpaid({
                //   customerMobile: e.target.value,
                // });
                // setSearch(e.target.value);
              }}
            />
          </Grid>
          {modalData?.counterSalesChild.map((item2, index) => {
            return (
              <Grid item xs={12} sm={12} sx={{ p: "5px 10px" }}>
                <Typography
                  sx={{
                    fontWeight: "600",
                    padding: "0px !important",
                    margin: "0px important",
                    color: (theme) => theme.palette.secondary.main,
                  }}
                >
                  {item2.itemDescription}
                </Typography>
                <Grid container>
                  <Grid item xs={2} sx={{
                    padding: "0px !important",
                    margin: "0px important",
                  }}>
                    <Typography
                      sx={{
                        padding: "0px !important",
                        margin: "0px important", color: (theme) => theme.palette.primary.main
                      }}
                    >
                      {item2?.quantity}
                    </Typography>
                  </Grid>
                  <Grid item xs={8} sx={{
                    display:'flex',
                    alignItems:'center'
                  }}> 
                  <CurrencyRupeeIcon color="primary" sx={{ fontSize: "14px" }} />
                    
                    <Typography 
                      sx={{ 
                        padding:"0px !important",
                        margin:"0px important",
                        color: (theme) => theme.palette.primary.main }}
                    >
                      {item2?.rate?.toFixed(2)}/Nos
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={2}
                    sx={{ 
                      color: (theme) => theme.palette.secondary.main,
                      display:'flex',
                      

                      alignItems:'center'
                     }}
                  >
                  <CurrencyRupeeIcon color="secondary" sx={{ fontSize: "14px" }} />

                    <Typography 
                    sx={{
                      padding:"0px !important",
                      margin:"0px important",
                    }}>{item2?.amount?.toFixed(2)}</Typography>
                  </Grid>
                </Grid>

                <Divider
                  sx={{
                    backgroundColor: "black",
                    // backgroundColor: (theme) => theme.palette.primary.main,
                    mt: "2px",
                  }}
                />
              </Grid>
            );
          })}

          <Grid item xs={12} sx={{ p: "10px" }}>
            <Divider
              sx={{ backgroundColor: (theme) => theme.palette.primary.main }}
            />
          </Grid>

          <Grid item xs={6} sx={{ p: "0px 10px " }}>
            <Typography
              sx={{
                fontWeight: "600",
                color: (theme) => theme.palette.primary.main,
              }}
            >
              Bill Amount
            </Typography>
          </Grid>
          <Grid item xs={6} sx={{ p: "0px 10px ", textAlign: "right" }}>
            <Typography
              sx={{
                fontWeight: "600",
                color: (theme) => theme.palette.secondary.main,
              }}
            >
              {modalData?.billAmount?.toFixed(2)}
            </Typography>
          </Grid>
          <Grid item xs={6} sx={{ p: "0px 10px " }}>
            <Typography
              sx={{
                fontWeight: "600",
                color: (theme) => theme.palette.primary.main,
              }}
            >
              Discount
            </Typography>
          </Grid>
          <Grid item xs={6} sx={{ p: "0px 10px ", textAlign: "right" }}>
            <Typography
              sx={{
                fontWeight: "600",
                color: (theme) => theme.palette.secondary.main,
              }}
            >
              {modalData?.discount?.toFixed(2)}
            </Typography>
          </Grid>
          <Grid item xs={6} sx={{ p: "0px 10px " }}>
            <Typography
              sx={{
                fontWeight: "600",
                color: (theme) => theme.palette.primary.main,
              }}
            >
              Payable Amount
            </Typography>
          </Grid>
          <Grid item xs={6} sx={{ p: "0px 10px ", textAlign: "right" }}>
            <Typography
              sx={{
                fontWeight: "600",
                color: (theme) => theme.palette.secondary.main,
              }}
            >
              {modalData?.balanceAmount?.toFixed(2)}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              // value={billData?.paymentMode}
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "space-around",
                alignItems: "center",
              }}
              onChange={(e) => { }}
            >
              <FormControlLabel
                disabled
                sx={{
                  color: (theme) => theme.palette.primary.main,
                }}
                value="Cash"
                control={
                  <Radio
                    sx={{
                      color: (theme) => theme.palette.primary.main,
                    }}
                  />
                }
                label="CashPayment"
              />
              <FormControlLabel
                value="Credit"
                sx={{
                  color: (theme) => theme.palette.primary.main,
                }}
                selected={true}
                checked={true}
                control={
                  <Radio
                    sx={{
                      color: (theme) => theme.palette.primary.main,
                    }}
                  />
                }
                label="Credit Sale"
              />
              <FormControlLabel
                value="Digital"
                sx={{
                  color: (theme) => theme.palette.primary.main,
                }}
                disabled
                control={
                  <Radio
                    sx={{
                      color: (theme) => theme.palette.primary.main,
                    }}
                  />
                }
                label="Online Payment"
              />
            </RadioGroup>
            {/* {billData?.discountAmount} */}
          </Grid>
        </Grid>
        {/* 
        <DialogActions>
          <Button
            variant="contained"
            onClick={() => {
              setOpen(false);
            }}
          >
            Save
          </Button>
        </DialogActions> */}
      </Box>
    </Modal>
  );
}
