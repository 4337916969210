import { Box, Button, Modal, TextField, Typography } from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";
import { addCategory, getCategory } from "./expanceApi";
import { ModalHeader } from "../../common/modalHeader";



const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    width: "40%",
    transform: "translate(-50%, -50%)",
    border: "none",
    bgcolor: "background.paper",
  };
export const CategoryPop=({open,setOpen,setSelectedCategory,selectedCategory,callback})=>{
    return (
        <Modal
        open={open}
        onClose={()=>{
          setSelectedCategory({name:''})
            setOpen(false)
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box>
          <Box
            sx={{ 

                ...style,
              width: {
                xs: "90%",
                sm: "40%",
              },
              background: "1",
              borderRadius: "5px",
            }}
          >
            <ModalHeader title={"Expenses"} 
        onClose={(data)=>{
          setOpen(data)
        }}
        />
           

            <Box
              sx={{
                p: "2rem",
              }}
            >
              <TextField
                fullWidth
                id="BrifeAddress"
                label="Expense Category"
                name="BrifeAddress"
                value={selectedCategory?.name}
                // value={modelTable?.name}
                onChange={(e) => {
                  setSelectedCategory({...selectedCategory,name:e.target.value})
                //   setModelTable({ ...modelTable, name: e.target.value });
                }}
              />
               
              <Button
                variant="contained"
                sx={{
                  width: "100%",
                  mt: "10px",
                }}
                onClick={() => {
                  setOpen(false);
                 callback(selectedCategory)
                }}
              >
                {" "}
                Save
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>
    )
}