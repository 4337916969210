import { api, GETBILLERITEM, GETBUSINESS } from "../../utils/api";
import axios from "../../utils/axios";

export const getAllServices=async(payload,callback)=>{
    await axios
    .post(GETBUSINESS, payload)
    .then((res) => {
      console.log("res search biller ", res);
      callback(res.data.data) 
    })
    .catch((err) => {
      console.log(err);
    });
}
export const getAlldetailSerice=async(payload,callback)=>{
    await axios
    .post( GETBILLERITEM, payload)
    .then((res) => {
      console.log("res search ", res);
      callback(res.data) 
    })
    .catch((err) => {
      console.log(err);
    });
}