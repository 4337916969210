import { Box, Typography } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { useEffect, useState } from "react";

export const TextFieldSearch=({search,setOpen, label ,error })=>{
  
   
    return (
        <>
          <Box
                  // disabled
                  // size="small"
                  readonly
                  fullWidth
                  onClick={() => {
                    // setSearchOutStandingsModal(true)
                  }}
                  // id="name"
                  // label="Search by Name/Mobile Number"
                  // value={search}
                  sx={{
                    display: "flex",
                    height: "23px",
                    justifyContent: "space-between",
                    // width: "70%",
                    border: "1px solid #c9c9c9",
                    p: 2,
                    borderRadius: "4px ",
                    color: "grey",
                    "&:hover": {
                      cursor: "pointer !important",
                    },
                  }}
                  onChange={(e) => {
                    // setSearch(e.target.value);
                  }}
                >
                  <Typography variant="p">
                    {/* {((search !== "") )?label:"Search by Name/Mobile Number"} */}
                    { search ? search :   label}
                  </Typography>
                  <SearchIcon
                    onClick={() => {
                      // setSearchModal(true)
                      setOpen(true);
                    }}
                    sx={{
                      color: (theme) => theme.palette.primary.main,
                      cursor: "pointer",
                    }}
                  />
                  </Box>
                  {error && (
        <Typography
          variant="body2"
          color="error"
          sx={{
            mt: 0.5,
            fontSize: "12px",
          }}
        >
          Please enter a valid search term.
        </Typography>
      )}
                  </>
    )
}