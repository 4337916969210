import {
  Card,
  CardContent,
  Checkbox,
  FormControlLabel,
  Grid,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import { CheckBox } from "@mui/icons-material";
export default function UserCard({
  data,
  callBack,
  selectedValue,
  setSelectedValue,feature
}) {
  const [checkValue, setCheckValue] = useState({});
  return (
    <Card
      sx={{
        width: "100%",
        m: "0px",
        mt: "10px",
        p: "0px",

        cursor: "pointer",
        ":hover": {
          bgcolor: "#2892ba17",
        },
      }}
      onClick={callBack}
      //   onClick={setEditedItem}
    >
      <CardContent sx={{ p: "0px 10px !important" }}>
        <Grid container>
          <Grid item xs={8}>
            <Typography
              color="primary"
              sx={{
                fontSize: "15px",
                overflow: "Hidden",
                textOverflow: "ellipsis",
                display: "-webkit-box",
                "-webkit-line-clamp": "1",
                "-webkit-box-orient": "vertical",
                fontWeight: 600,
              }}
            >
              {data?.name}
            </Typography>
            <Typography sx={{ fontSize: "14px" }}>
              {" "}
              {data?.customerMobile}
            </Typography>
          </Grid>
          {
            feature=="bulkupload" &&     <Grid
            item
            xs={4}
            sx={{
              display: "flex",
              alignItems: "center",
            }}
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <FormControlLabel
              value={data.customerMobile} // This is used to identify the checkbox, but it's not necessary if you are using `checked`
              checked={selectedValue.some(
                (ele) => ele.customerMobile === data.customerMobile
              )} // Check if `data` is in selectedValue
              onChange={(e) => {
                console.log("etttdata", data);
                let arr = [...selectedValue];
                let ind = arr.findIndex(
                  (ele) => ele.customerMobile === data.customerMobile
                );

                if (e.target.checked) {
                  // If the checkbox is checked
                  if (ind === -1) {
                    // Only add if not already in the array
                    setSelectedValue([...arr, data]);
                  }
                } else {
                  // If the checkbox is unchecked
                  if (ind !== -1) {
                    // Only remove if the item is already in the array
                    arr.splice(ind, 1);
                    setSelectedValue([...arr]);
                  }
                }
              }}
              required
              control={<Checkbox />} // No need for defaultChecked here since we control the checked state via selectedValue
              label="Required"
            />
          </Grid>
          }
      
        </Grid>
      </CardContent>
    </Card>
  );
}
