import { Card, CardContent, Stack, Tooltip, Typography } from "@mui/material";
import React from "react";

export default function CategoriesCard({ item, setEditedItem }) {
  return (
    <Card
      sx={{
        cursor: "pointer",
        ":hover": {
          bgcolor: "#2892ba17",
        },


      }}
      onClick={setEditedItem}
    >
      <CardContent sx={{ padding: "15px 20px !important" }}>
        <Tooltip title={item.itemDescription}>
          <Typography
            color='primary'
            sx={{
              overflow: "Hidden",
              textOverflow: "ellipsis",
              display: "-webkit-box",
              "-webkit-line-clamp": "1",
              "-webkit-box-orient": "vertical",
              fontWeight: 600,
            }}
          >
            {item?.categoryName}
          </Typography>
        </Tooltip>
        <Stack direction='row'>
          <Typography sx={{ fontSize: "14px" }}>
            Number of Items {item?.size}
          </Typography>
        </Stack>
      </CardContent>
    </Card>
  );
}
