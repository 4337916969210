import {
    Avatar,
    Box,
    Button,
    Container,
    TextField,
    Typography,
  } from "@mui/material";
  import EditIcon from "@mui/icons-material/Edit";
  import Grid from "@mui/material/Grid";
  
  import React, { useEffect, useRef, useState } from "react";
  
  import {
    GetUserProfileApi,
    UpdateUserProfileApi,
    UpdateUserProfileImageApi,
    getProfile,
    updateProfile,
    getUserProfileImg,
  } from "./myProfileApi";
  import Swal from "sweetalert2";
  import Language from "./languageModal"; 
  import { validationUpdateProfile } from "./myProfileValidation";
  import { swalAlertPopProps } from "../../utils/commonUtils";
import { useSelector } from "react-redux";
  export default function UserProfile() {
    
  const userDetail = useSelector((state) => state.reducer.userDetail);
    const [imageContainer, setImageContainer] = useState("");
    const profileImgRef = useRef();
    const [open, setOpen] = useState(false);
    const [profileData, setProfileData] = useState(null);
   const getProfileDataaCB=()=>{
    getProfile((data)=>{
      console.log("Data profile",data)
    setProfileData({...profileData,name: data?.name,mobile:data?.mobile,address:data?.address})

    })
   }
    useEffect(() => {

      // setProfileData({...profileData,name: userDetail?.name,mobile:userDetail?.mobileNumber,address:userDetail?.billerAddress})
    //   getData(GetUserProfileApi, setProfileData, getUserProfileImg);
    }, [userDetail]);
    useEffect(()=>{
      getProfileDataaCB()
    },[])
    return (
      <Box>
        {true && (
          <Container maxWidth="sm">
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                mt: 4,
              }}
            >
              {profileData?.profilePicBase64 === "" &&
                imageContainer.length === 0 && (
                  <Avatar
                    sx={{
                      mb: 2,
                      fontSize: "200px",
                      height: "100px",
                      width: "100px",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      profileImgRef.current.click();
                    }}
                  />
                )}
  
            
           
              <Typography component="h1" variant="h5">
                Profile
              </Typography>
  
              <Box sx={{ mt: 3, width: "100%" }}>
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={1}></Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      id="name"
                      label="Name"
                      value={profileData?.name}
                      onChange={(e) => {
                        setProfileData({ ...profileData, name: e.target.value });
                      }}
                      InputProps={{
                        style: {
                          paddingTop: '15px',
                        },
                        endAdornment: (
                          <EditIcon
                            sx={{ color: (theme) => theme.palette.primary.main }}
                          />
                        ),
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      id="address"
                      label="Address"
                      value={profileData?.address}
                      onChange={(e) => {
                        setProfileData({
                          ...profileData,
                          address: e.target.value,
                        });
                      }}
                      placeholder="Your Placeholder"
                    
                      InputProps={{
                        style: {
                          paddingTop: '15px',
                        },
                        endAdornment: (
                          <EditIcon
                            sx={{
                              color: (theme) => theme.palette.primary.main,
                              pointerEvents: "none",
                            }}
                          />
                        ),
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      id="mobileNumber"
                      label="Mobile Number"
                      value={profileData?.mobile}
                      disabled={true}
                      InputProps={{
                        style: {
                          paddingTop: '15px',
                        },
                      }}
                    />
                  </Grid>
  
                 
                </Grid>
                <Button
                  // type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                  onClick={()=>{
                    updateProfile({name:profileData?.name,address:profileData?.address},(data)=>{
                         console.log(data)
                         getProfileDataaCB()
                        
                    })

                  }}
                >
                  Save
                </Button>
              </Box>
            </Box>
          </Container>
        )}
        {/* <Language open={open} setOpen={setOpen} />  */}
      </Box>
    );
  }
  