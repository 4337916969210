import Swal from "sweetalert2";

export const validation = (objectData) => {
  let arrData = Object.keys(objectData);
  let objEmty = {};
  for (let i = 0; i < arrData.length; i++) {
    objEmty[arrData[i]] = "";
    if (
      !objectData[arrData[i]]
      // ||
      // objectData[arrData[i]].replace(/ /g, "") === ""
    ) {
      // return String(arrData[i]);
      return { status: false, obj: objEmty };
    }
  }
  return { status: true, obj: objEmty };
};

export const sweetAlertMessage = (fileData) => {
  let msg = validation(fileData);
  Swal.fire(
    msg,
    "Please Insert Value in " + msg,
    msg === "successfull" ? "success" : "error"
  );

  return msg === "successfull" ? true : false;
};

export function capitalizeFirstLetter(str) {
  if(str){
  return str.split(' ').map(word => {
    return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
  }).join(' ');
}
return ""
}