import React from 'react';
import QRCode from 'qrcode.react';
import { useSelector } from 'react-redux';
const QrCodeGenrate = ({ amount }) => {


    const userDetail = useSelector((state) => state.reducer.userDetail)
    // const upiPaymentLink = `upi://pay?pa=7499297417@axl&pn=Ankit&am=${amount}&cu=INR&tn=trail`;
    const upiPaymentLink = `upi://pay?pa=${userDetail?.upiid}&pn=${userDetail?.name}&am=${amount}&cu=INR&tn=trail`;


    return (

        <QRCode value={upiPaymentLink} style={{ height: '100px', width: '100px' }} />
    )
}
export default QrCodeGenrate