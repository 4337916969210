import { Tab, Tabs } from "@mui/material"

export const TabCp=({value,setValue})=>{
    return (
        <Tabs
        value={value}
        onChange={ (event, newValue) => {
          // setPageNo(1);
          
          setValue(newValue);
        }}
        textColor="primary"
        indicatorColor="primary"
        aria-label="primary tabs example"
      >
        <Tab
          value="Service"
          label="Service"
          onClick={() => {
            // setPaidBills("");
            // paginationApiCall(date, pageNo, "null");
          }}
          sx={{ fontWeight: value === 'Service' ? '700' : '',width:'50%'  }}
        />
        
        <Tab
           value="Product"
          label="Product"
          onClick={() => {
            // paginationApiCall(date, pageNo, "null", "paid");
          }}
          sx={{ fontWeight: value === 'paid' ? '700' : '',width:'50%' }}
        />
      </Tabs>
    )
}