import { Box, Button, Fab, FormControl, InputLabel, ListItemText, MenuItem, OutlinedInput, Select, Switch, TextField, Typography } from "@mui/material";

import Grid from "@mui/material/Grid";

import React, { useEffect, useState } from "react";
import { getAllOffer } from "./apiFunction";
import Card from "./card";
import CardEle from "./card";
import PopUp from "./PopUp";
import AddIcon from "@mui/icons-material/Add";
import PopUpWp from "./PopUpWp";
import { PaginationCm } from "../../common/pagination";
import DynamicBreadcrumbs from "../../common/Breadcrumb";

export default function BulkMessage({}) {
    const [numberOfPage,setnumberOfpages]=useState(1)
    const [collection, setColletion] = useState([])
    useEffect(() => {
        getAllOffer((data) => {
            console.log("Salon/GetAllOffers ", data?.data?.list)
            setColletion(data?.data?.list)
        })
    }, [])
    const [open, setOpen] = useState(false)
    const [opent, setOpent] = useState(false)
    
    const [data,setData]=useState(null)
    return (
       <>
       <DynamicBreadcrumbs />
        <Box sx={{
          margin: {
            xs: "10px",
            sm: "50px 10px 10px 10px ",
            md: "50px",
          },
        }}>
            <Grid container spacing={2} alignItems="center" padding={2}>
                <Grid item md={12} xs={12} >
                    <Box sx={{width:'100%',textAlign:'right'}}>
                        <Fab
                            onClick={() => {
                                // setOpen(true);
                                setData(null)
                                setOpen(true);
                            }}
                            sx={{
                                textTransform: "capitalize",
                                width: {
                                    xs: "100%",
                                    sm: "auto",
                                },
                                // p: "10px",
                                whiteSpace: "nowrap",
                                     zIndex:'0'
                            }}
                            size="small"
                            color="primary"
                            variant="extended"
                            aria-label="add"
                        >
                            <AddIcon />
                            Add New Message
                            
                        </Fab>
                    </Box>
                </Grid>
                <Grid item md={12} xs={12}>
                  
                    {collection.map((ele, index) => {
                        return (<CardEle item={ele} callback={(data) => {
                            setOpen(true)
                            setData(ele)
                        }}
                        cbt={(data)=>{
                            setOpent(true)
                            // setData()
                            setData(ele)
                        }} />)
                    })}
                </Grid>
                <Grid item xs={12}>
                {/* <PaginationCm
           change={()=>{}}
          /> */}
                </Grid>
            </Grid> 
            <PopUp open={open} setOpen={setOpen} data={data}/>

            <PopUpWp open={opent} setOpen={setOpent} data={data} feature={"bulkupload"}/>



        </Box></>
    );
}
