import {
  Box,
  Container,
  Grid,
  List,
  ListItem,
  Typography,
} from "@mui/material";
import React from "react";
import Calendar1 from "../../assets/icon/calendar.png";
import { ListItemText } from "@mui/material";
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import { HeaderLandingPage } from "./header";
import { Footer } from "./footer";
const MonthlyBill = () => {
  return (
    <>
      <HeaderLandingPage />
      <Box bgcolor={"#ffceec"} py={2}>
        <Container>
          <Grid container alignItems={"center"}>
            <Grid item md={6}>
              <Box textAlign={"center"}>
                <Box
                  width={"90px"}
                  component={"img"}
                  src={Calendar1}
                  alt="icon"
                />
                <Typography fontSize={"2.1em"} fontWeight={"500"}>
                  MONTHLY BILLING
                </Typography>
              </Box>
            </Grid>
            <Grid item md={6}>
              <Typography fontSize={"20px"}>
                Monthly Recurring Bills Module is suitable for below business
                lines:
              </Typography>
              <List>
                <ListItem
                  sx={{
                    padding: "0px",
                    "& .MuiTypography-root": { fontSize: "1.2em" },
                  }}
                >
                  <ListItemText sx={{ margin: "0px" }}>
                    <CheckRoundedIcon sx={{ verticalAlign: "sub" }} />
                    Milk Distributors/ Dairy Products
                  </ListItemText>
                </ListItem>
                <ListItem
                  sx={{
                    padding: "0px",
                    "& .MuiTypography-root": { fontSize: "1.2em" },
                  }}
                >
                  <ListItemText sx={{ margin: "0px" }}>
                    <CheckRoundedIcon sx={{ verticalAlign: "sub" }} />
                    Newspaper Distributors/ Newspaper stalls/ Newspaper agencies
                  </ListItemText>
                </ListItem>
                <ListItem
                  sx={{
                    padding: "0px",
                    "& .MuiTypography-root": { fontSize: "1.2em" },
                  }}
                >
                  <ListItemText sx={{ margin: "0px" }}>
                    <CheckRoundedIcon sx={{ verticalAlign: "sub" }} />
                    Water Can Distributors
                  </ListItemText>
                </ListItem>
                <ListItem
                  sx={{
                    padding: "0px",
                    "& .MuiTypography-root": { fontSize: "1.2em" },
                  }}
                >
                  <ListItemText sx={{ margin: "0px" }}>
                    <CheckRoundedIcon sx={{ verticalAlign: "sub" }} />
                    Mess/Tiffin Services
                  </ListItemText>
                </ListItem>
                <ListItem
                  sx={{
                    padding: "0px",
                    "& .MuiTypography-root": { fontSize: "1.2em" },
                  }}
                >
                  <ListItemText sx={{ margin: "0px" }}>
                    <CheckRoundedIcon sx={{ verticalAlign: "sub" }} />
                    Laundry Services
                  </ListItemText>
                </ListItem>
              </List>
            </Grid>
          </Grid>

          <Grid container>
            <Grid item md={6} pr={2}>
              <Typography
                padding={"30px 0px"}
                fontSize={"41px"}
                fontWeight={"bold"}
                color={"#2d3c58"}
                fontFamily={"Be Vietnam Pro"}
              >
                Features :
              </Typography>
              <Box py={1}>
                <Typography
                  fontFamily={"Be Vietnam Pro"}
                  fontSize={"20px"}
                  fontWeight={"600"}
                >
                  Auto Bill Generation
                </Typography>
                <Typography fontFamily={"Be Vietnam Pro"}>
                  Biller can Generate bills automatically, once he/she updates
                  records and verify Record keeping.
                </Typography>
              </Box>
              <Box py={1}>
                <Typography
                  fontFamily={"Be Vietnam Pro"}
                  fontSize={"20px"}
                  fontWeight={"600"}
                >
                  Customer-wise bill generation
                </Typography>
                <Typography fontFamily={"Be Vietnam Pro"}>
                  Selected Customer-wise Bill Generation facility is available.
                </Typography>
              </Box>
              <Box py={1}>
                <Typography
                  fontFamily={"Be Vietnam Pro"}
                  fontSize={"20px"}
                  fontWeight={"600"}
                >
                  Partial Payment
                </Typography>
                <Typography fontFamily={"Be Vietnam Pro"}>
                  Customer can pay the partial bill and it is updated for the
                  next bill cycle and in the outstanding report.
                </Typography>
              </Box>
              <Box py={1}>
                <Typography
                  fontFamily={"Be Vietnam Pro"}
                  fontSize={"20px"}
                  fontWeight={"600"}
                >
                  Pending + Current Bill Addition
                </Typography>
                <Typography fontFamily={"Be Vietnam Pro"}>
                  Pending + Current Bill Addition facility is available.
                </Typography>
              </Box>
              <Box py={1}>
                <Typography
                  fontFamily={"Be Vietnam Pro"}
                  fontSize={"20px"}
                  fontWeight={"600"}
                >
                  Multi-device login
                </Typography>
                <Typography fontFamily={"Be Vietnam Pro"}>
                  The same login can be used on another device.
                </Typography>
              </Box>
              <Box py={1}>
                <Typography
                  fontFamily={"Be Vietnam Pro"}
                  fontSize={"20px"}
                  fontWeight={"600"}
                >
                  Bill Amount with QR
                </Typography>
                <Typography fontFamily={"Be Vietnam Pro"}>
                  Every bill is printed with the amount and QR code for easy UPI
                  payment.
                </Typography>
              </Box>
              <Box py={1}>
                <Typography
                  fontFamily={"Be Vietnam Pro"}
                  fontSize={"20px"}
                  fontWeight={"600"}
                >
                  Inventory/stock
                </Typography>
                <Typography fontFamily={"Be Vietnam Pro"}>
                  Auto deduction of Inventory or Stock facility is available.
                </Typography>
              </Box>
              <Box py={1}>
                <Typography
                  fontFamily={"Be Vietnam Pro"}
                  fontSize={"20px"}
                  fontWeight={"600"}
                >
                  Assistant User
                </Typography>
                <Typography fontFamily={"Be Vietnam Pro"}>
                  Assistant user facility is available for staff members of
                  restaurants with restricted access.
                </Typography>
              </Box>
              <Box py={1}>
                <Typography
                  fontFamily={"Be Vietnam Pro"}
                  fontSize={"20px"}
                  fontWeight={"600"}
                >
                  Credit Bills & Outstanding Report
                </Typography>
                <Typography fontFamily={"Be Vietnam Pro"}>
                  Customers can receive their credit bills or outstanding
                  balance reports as well.
                </Typography>
              </Box>
              <Box py={1}>
                <Typography
                  fontFamily={"Be Vietnam Pro"}
                  fontSize={"20px"}
                  fontWeight={"600"}
                >
                  Online/Cash Bill Payment Report
                </Typography>
                <Typography fontFamily={"Be Vietnam Pro"}>
                  Segregate cash and online payment received, to tally counter
                  at day end
                </Typography>
              </Box>
              <Box py={1}>
                <Typography
                  fontFamily={"Be Vietnam Pro"}
                  fontSize={"20px"}
                  fontWeight={"600"}
                >
                  expence
                </Typography>
                <Typography fontFamily={"Be Vietnam Pro"}>
                  Record expence details to get the Profit Margin report.
                </Typography>
              </Box>
              <Box py={1}>
                <Typography
                  fontFamily={"Be Vietnam Pro"}
                  fontSize={"20px"}
                  fontWeight={"600"}
                >
                  Supporting Hardware
                </Typography>
                <Typography fontFamily={"Be Vietnam Pro"}>
                  Application Works on Android Mobile/Tab/POS.
                </Typography>
              </Box>
              <Box py={1}>
                <Typography
                  fontFamily={"Be Vietnam Pro"}
                  fontSize={"20px"}
                  fontWeight={"600"}
                >
                  Payment Gateway
                </Typography>
                <Typography fontFamily={"Be Vietnam Pro"}>
                  Payment Gateway is configured to settle all bills with T+1
                  settlement policy.
                </Typography>
              </Box>
            </Grid>
            <Grid item md={6}>
              <Box py={1}>
                <Typography
                  fontFamily={"Be Vietnam Pro"}
                  fontSize={"20px"}
                  fontWeight={"600"}
                >
                  Dashboard
                </Typography>
                <Typography fontFamily={"Be Vietnam Pro"}>
                  Daily/Weekly/Monthly Reports are available with a single click
                  through the dashboard.
                </Typography>
              </Box>
              <Box py={1}>
                <Typography
                  fontFamily={"Be Vietnam Pro"}
                  fontSize={"20px"}
                  fontWeight={"600"}
                >
                  Various Reports
                </Typography>
                <Typography fontFamily={"Be Vietnam Pro"}>
                  Various reports can be downloaded in PDF and Excel formats
                </Typography>
              </Box>
              <Box py={1}>
                <Typography
                  fontFamily={"Be Vietnam Pro"}
                  fontSize={"20px"}
                  fontWeight={"600"}
                >
                  Discounted Bills
                </Typography>
                <Typography fontFamily={"Be Vietnam Pro"}>
                  Bill discount feature is added for every bill.
                </Typography>
              </Box>
              <Box py={1}>
                <Typography
                  fontFamily={"Be Vietnam Pro"}
                  fontSize={"20px"}
                  fontWeight={"600"}
                >
                  GST Bills
                </Typography>
                <Typography fontFamily={"Be Vietnam Pro"}>
                  GST Billing is optional (depending on the product). Provision
                  for monthly GST Billed report.
                </Typography>
              </Box>
              <Box py={1}>
                <Typography
                  fontFamily={"Be Vietnam Pro"}
                  fontSize={"20px"}
                  fontWeight={"600"}
                >
                  Payment Receipts
                </Typography>
                <Typography fontFamily={"Be Vietnam Pro"}>
                  Payment Receipts can be printed/sent through SMS & WhatsApp.
                </Typography>
              </Box>
              <Box py={1}>
                <Typography
                  fontFamily={"Be Vietnam Pro"}
                  fontSize={"20px"}
                  fontWeight={"600"}
                >
                  Business profile
                </Typography>
                <Typography fontFamily={"Be Vietnam Pro"}>
                  Business profile is used for Payment Gateway Configuration .
                </Typography>
              </Box>
              <Box py={1}>
                <Typography
                  fontFamily={"Be Vietnam Pro"}
                  fontSize={"20px"}
                  fontWeight={"600"}
                >
                  Item or Services
                </Typography>
                <Typography fontFamily={"Be Vietnam Pro"}>
                  Items can be added/deleted in the Item List and it can be
                  edited easily through this functionality.
                </Typography>
              </Box>
              <Box py={1}>
                <Typography
                  fontFamily={"Be Vietnam Pro"}
                  fontSize={"20px"}
                  fontWeight={"600"}
                >
                  Calendar Entry
                </Typography>
                <Typography fontFamily={"Be Vietnam Pro"}>
                  Calendar Entry helps simplify record keeping.
                </Typography>
              </Box>
              <Box py={1}>
                <Typography
                  fontFamily={"Be Vietnam Pro"}
                  fontSize={"20px"}
                  fontWeight={"600"}
                >
                  Customer Details
                </Typography>
                <Typography fontFamily={"Be Vietnam Pro"}>
                  The list of Customer Details List is very easy to handle.
                  Details can be added through the phone book and customers can
                  check their bills.
                </Typography>
              </Box>
              <Box py={1}>
                <Typography
                  fontFamily={"Be Vietnam Pro"}
                  fontSize={"20px"}
                  fontWeight={"600"}
                >
                  Delivery Line Sequence
                </Typography>
                <Typography fontFamily={"Be Vietnam Pro"}>
                  Delivery Line Sequence helps flat-wise or society-wise
                  distribution process for Newspaper or Milk.
                </Typography>
              </Box>
              <Box py={1}>
                <Typography
                  fontFamily={"Be Vietnam Pro"}
                  fontSize={"20px"}
                  fontWeight={"600"}
                >
                  Supporting Printer
                </Typography>
                <Typography fontFamily={"Be Vietnam Pro"}>
                  Provides support for thermal printers through both Bluetooth
                  and cable connections.
                </Typography>
              </Box>
              <Box py={1}>
                <Typography
                  fontFamily={"Be Vietnam Pro"}
                  fontSize={"20px"}
                  fontWeight={"600"}
                >
                  Bill Sharing
                </Typography>
                <Typography fontFamily={"Be Vietnam Pro"}>
                  Restaurant owners can send bill details to the customer
                  through SMS & WhatsApp.
                </Typography>
              </Box>
              <Box py={1}>
                <Typography
                  fontFamily={"Be Vietnam Pro"}
                  fontSize={"20px"}
                  fontWeight={"600"}
                >
                  Day-wise bill details
                </Typography>
                <Typography fontFamily={"Be Vietnam Pro"}>
                  Service provider can check day-wise bill details of all
                  customer.
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>

      <Footer />
    </>
  );
};

export default MonthlyBill;
