import {
  Box,
  Container,
  Grid,
  List,
  ListItem,
  Typography,
} from "@mui/material";
import React from "react";
import Outlet from "../../assets/icon/outliet.png";
import { ListItemText } from "@mui/material";
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import { HeaderLandingPage } from "./header";
import { Footer } from "./footer";
const Franchise = () => {
  return (
    <>
      <HeaderLandingPage />
      <Box bgcolor={"#ffceec"} py={2}>
        <Container>
          <Grid container alignItems={"center"}>
            <Grid item md={6}>
              <Box textAlign={"center"}>
                <Box
                  width={"150px"}
                  component={"img"}
                  src={Outlet}
                  alt="icon"
                />
                <Typography fontSize={"2.1em"} fontWeight={"500"}>
                  Franchise-Outlet Business
                </Typography>
              </Box>
            </Grid>
            <Grid item md={6}>
              <Typography fontSize={"20px"}>
                Franchise -Outlet Business Module is suitable for below business
                lines:
              </Typography>
              <List>
                <ListItem
                  sx={{
                    padding: "0px",
                    "& .MuiTypography-root": { fontSize: "1.2em" },
                  }}
                >
                  <ListItemText sx={{ margin: "0px" }}>
                    <CheckRoundedIcon sx={{ verticalAlign: "sub" }} />
                    Tea Franchise
                  </ListItemText>
                </ListItem>
                <ListItem
                  sx={{
                    padding: "0px",
                    "& .MuiTypography-root": { fontSize: "1.2em" },
                  }}
                >
                  <ListItemText sx={{ margin: "0px" }}>
                    <CheckRoundedIcon sx={{ verticalAlign: "sub" }} />
                    Food Item Franchise
                  </ListItemText>
                </ListItem>
                <ListItem
                  sx={{
                    padding: "0px",
                    "& .MuiTypography-root": { fontSize: "1.2em" },
                  }}
                >
                  <ListItemText sx={{ margin: "0px" }}>
                    <CheckRoundedIcon sx={{ verticalAlign: "sub" }} />
                    All kinds of Franchise-Outlet Stores
                  </ListItemText>
                </ListItem>
              </List>
            </Grid>
          </Grid>

          <Grid container>
            <Grid item md={6} pr={2}>
              <Typography
                padding={"30px 0px"}
                fontSize={"41px"}
                fontWeight={"bold"}
                color={"#2d3c58"}
                fontFamily={"Be Vietnam Pro"}
              >
                Features :
              </Typography>
              <Box py={1}>
                <Typography
                  fontFamily={"Be Vietnam Pro"}
                  fontSize={"20px"}
                  fontWeight={"600"}
                >
                  SMB Partner Login for Franchise owner
                </Typography>
                <Typography fontFamily={"Be Vietnam Pro"}>
                  Separate login is provided to Franchise owner.
                </Typography>
              </Box>
              <Box py={1}>
                <Typography
                  fontFamily={"Be Vietnam Pro"}
                  fontSize={"20px"}
                  fontWeight={"600"}
                >
                  Franchise owner's view on stock availability
                </Typography>
                <Typography fontFamily={"Be Vietnam Pro"}>
                  Franchise owners can have information on the available stock
                  in their outlets.
                </Typography>
              </Box>
              <Box py={1}>
                <Typography
                  fontFamily={"Be Vietnam Pro"}
                  fontSize={"20px"}
                  fontWeight={"600"}
                >
                  Outlet's Business Details Report for Franchise Owners
                </Typography>
                <Typography fontFamily={"Be Vietnam Pro"}>
                  Outlet Business Details Report on single click for Franchise
                  Owners.
                </Typography>
              </Box>
              <Box py={1}>
                <Typography
                  fontFamily={"Be Vietnam Pro"}
                  fontSize={"20px"}
                  fontWeight={"600"}
                >
                  Assistant User
                </Typography>
                <Typography fontFamily={"Be Vietnam Pro"}>
                  Assistant user facility is available for staff members of
                  restaurants with restricted access.
                </Typography>
              </Box>
              <Box py={1}>
                <Typography
                  fontFamily={"Be Vietnam Pro"}
                  fontSize={"20px"}
                  fontWeight={"600"}
                >
                  Credit Bills & Outstanding Report
                </Typography>
                <Typography fontFamily={"Be Vietnam Pro"}>
                  Customers can receive their credit bills or outstanding
                  balance reports as well.
                </Typography>
              </Box>
              <Box py={1}>
                <Typography
                  fontFamily={"Be Vietnam Pro"}
                  fontSize={"20px"}
                  fontWeight={"600"}
                >
                  Online/Cash Bill Payment Report
                </Typography>
                <Typography fontFamily={"Be Vietnam Pro"}>
                  Segregate cash and online payment received, to tally counter
                  at day end
                </Typography>
              </Box>
              <Box py={1}>
                <Typography
                  fontFamily={"Be Vietnam Pro"}
                  fontSize={"20px"}
                  fontWeight={"600"}
                >
                  expence
                </Typography>
                <Typography fontFamily={"Be Vietnam Pro"}>
                  Record expence details to get the Profit Margin report.
                </Typography>
              </Box>
              <Box py={1}>
                <Typography
                  fontFamily={"Be Vietnam Pro"}
                  fontSize={"20px"}
                  fontWeight={"600"}
                >
                  Dashboard
                </Typography>
                <Typography fontFamily={"Be Vietnam Pro"}>
                  Daily/Weekly/Monthly status is available with a single click
                  through the dashboard utility.
                </Typography>
              </Box>
              <Box py={1}>
                <Typography
                  fontFamily={"Be Vietnam Pro"}
                  fontSize={"20px"}
                  fontWeight={"600"}
                >
                  Various Reports
                </Typography>
                <Typography fontFamily={"Be Vietnam Pro"}>
                  Various reports can be downloaded in PDF and Excel formats
                </Typography>
              </Box>
              <Box py={1}>
                <Typography
                  fontFamily={"Be Vietnam Pro"}
                  fontSize={"20px"}
                  fontWeight={"600"}
                >
                  Bill Discount
                </Typography>
                <Typography fontFamily={"Be Vietnam Pro"}>
                  Bill discount feature is added for every bill.
                </Typography>
              </Box>
            </Grid>
            <Grid item md={6}>
              <Box py={1}>
                <Typography
                  fontFamily={"Be Vietnam Pro"}
                  fontSize={"20px"}
                  fontWeight={"600"}
                >
                  GST Bills
                </Typography>
                <Typography fontFamily={"Be Vietnam Pro"}>
                  GST Billing is optional (depending on the product). Provision
                  for monthly GST Billed report.
                </Typography>
              </Box>
              <Box py={1}>
                <Typography
                  fontFamily={"Be Vietnam Pro"}
                  fontSize={"20px"}
                  fontWeight={"600"}
                >
                  Payment Receipts
                </Typography>
                <Typography fontFamily={"Be Vietnam Pro"}>
                  Payment Receipts can be printed/sent through SMS & WhatsApp.
                </Typography>
              </Box>
              <Box py={1}>
                <Typography
                  fontFamily={"Be Vietnam Pro"}
                  fontSize={"20px"}
                  fontWeight={"600"}
                >
                  Business profile
                </Typography>
                <Typography fontFamily={"Be Vietnam Pro"}>
                  Business profile is used for Payment Gateway Configuration.
                </Typography>
              </Box>
              <Box py={1}>
                <Typography
                  fontFamily={"Be Vietnam Pro"}
                  fontSize={"20px"}
                  fontWeight={"600"}
                >
                  Item or Services
                </Typography>
                <Typography fontFamily={"Be Vietnam Pro"}>
                  Items can be added/deleted in the Item List and it can be
                  edited easily through this functionality.
                </Typography>
              </Box>
              <Box py={1}>
                <Typography
                  fontFamily={"Be Vietnam Pro"}
                  fontSize={"20px"}
                  fontWeight={"600"}
                >
                  Item Categories
                </Typography>
                <Typography fontFamily={"Be Vietnam Pro"}>
                  Item list can be managed with the help of Item Categories.
                </Typography>
              </Box>
              <Box py={1}>
                <Typography
                  fontFamily={"Be Vietnam Pro"}
                  fontSize={"20px"}
                  fontWeight={"600"}
                >
                  Multi-device login
                </Typography>
                <Typography fontFamily={"Be Vietnam Pro"}>
                  The same login can be used on another device.
                </Typography>
              </Box>
              <Box py={1}>
                <Typography
                  fontFamily={"Be Vietnam Pro"}
                  fontSize={"20px"}
                  fontWeight={"600"}
                >
                  Supports Printer
                </Typography>
                <Typography fontFamily={"Be Vietnam Pro"}>
                  Provides support for thermal printers through both Bluetooth
                  and cable connections.
                </Typography>
              </Box>
              <Box py={1}>
                <Typography
                  fontFamily={"Be Vietnam Pro"}
                  fontSize={"20px"}
                  fontWeight={"600"}
                >
                  Supported Hardware
                </Typography>
                <Typography fontFamily={"Be Vietnam Pro"}>
                  Application Works on Android Mobile/Tab/POS.
                </Typography>
              </Box>
              <Box py={1}>
                <Typography
                  fontFamily={"Be Vietnam Pro"}
                  fontSize={"20px"}
                  fontWeight={"600"}
                >
                  Bill Sharing
                </Typography>
                <Typography fontFamily={"Be Vietnam Pro"}>
                  Business owners can send bill details to the customer through
                  SMS & WhatsApp.
                </Typography>
              </Box>{" "}
              <Box py={1}>
                <Typography
                  fontFamily={"Be Vietnam Pro"}
                  fontSize={"20px"}
                  fontWeight={"600"}
                >
                  Bill Amount with QR
                </Typography>
                <Typography fontFamily={"Be Vietnam Pro"}>
                  Every bill is printed with the amount and QR code for easy UPI
                  payment.
                </Typography>
              </Box>
              <Box py={1}>
                <Typography
                  fontFamily={"Be Vietnam Pro"}
                  fontSize={"20px"}
                  fontWeight={"600"}
                >
                  Inventory/stock details
                </Typography>
                <Typography fontFamily={"Be Vietnam Pro"}>
                  Inventory/stock details are available to Outlet owners.
                </Typography>
              </Box>
              <Box py={1}>
                <Typography
                  fontFamily={"Be Vietnam Pro"}
                  fontSize={"20px"}
                  fontWeight={"600"}
                >
                  Inventory Purchase
                </Typography>
                <Typography fontFamily={"Be Vietnam Pro"}>
                  Outlet owners can fill in the purchase details in the
                  Inventory purchase.
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Footer />
    </>
  );
};

export default Franchise;
