import Swal from "sweetalert2"
import { ADDOFFER, GETALLOFFER, UPDATEOFFERMESSAGE, UPDATEOFFERMessage } from "../../utils/api"
import axios from "../../utils/axios"
import { swalAlertPopProps } from "../../utils/commonUtils"

export   const getAllOffer=async(callback)=>{
    
    await axios.get(GETALLOFFER).then((res)=>{
        console.log("dres ",res?.data)
        callback(res?.data)
    })
}

export   const addOffer=async(payload ,callback)=>{
    
    await axios.post(ADDOFFER,payload).then((res)=>{
        console.log("dres ",res?.data)
        callback(res?.data)
    })
}

export   const updateOffer=async(payload ,callback)=>{
    
    await axios.post(UPDATEOFFERMESSAGE,payload).then((res)=>{
        console.log("dres ",res?.data)
        callback(res?.data)
        if(res?.data?.succeeded) {
            Swal.fire({
              text:"Successfully added" ,
              icon: "success",
              ...swalAlertPopProps ,
            });
          } 
      
          else{
            Swal.fire({
              text:res?.data?.message ,
              icon: "error",
              ...swalAlertPopProps,
            });
          }
    })
}



export const bulkMessageApload=async({payload,callback})=>{
  console.log("payloadbulk message ",payload)
  await axios.post("https://web.wabridge.com/api/createmessage",payload).then((res)=>{
    console.log("rres ")
  }).catch((err)=>{
    console.log("err ",err)    
  })

  
}