import { GETBUSSINESSDATA, UPDATEBUSSINESSDATA } from "../../utils/api";
import axios from "../../utils/axios";

export const GetBussiness = async (params) => {
    const res = await axios.get(GETBUSSINESSDATA, params);
    return res;
  };

  export const UpdateBusiness = async (params) => {
    const res = await axios.post(UPDATEBUSSINESSDATA, params);
    return res;
  };
  