export const information = [
    {
      shopname: "Urban Salon",
      type: "Salon, Men's Parlour",
      location: "Pune",
      contact: "8459139894",
      directionsUrl: "https://maps.app.goo.gl/U7omG1jP78XZfTaj9",
      isMen: true,
      isWomen: true,
      isHome: false,
    },
    {
      shopname: "Rihan Salon",
      type: "Unisex Parlour",
      location: "Wakad",
      contact: "9876543210",
      directionsUrl: "https://maps.app.goo.gl/U7omG1jP78XZfTaj9",
      isMen: true,
      isWomen: true,
      isHome: false,
    },
    {
      shopname: "Shine Salon",
      type: "Men's Parlour",
      location: "Mumbai",
      contact: "9876543210",
      directionsUrl: "https://maps.app.goo.gl/U7omG1jP78XZfTaj9",
      directionsUrl: "https://maps.app.goo.gl/U7omG1jP78XZfTaj9",
      isMen: true,
      isWomen: true,
      isHome: true,
    },
  ];