import { ADDTABLESECTION, GETNOTASSIGNTABLELIST, GETTABLESECTIONS, UPDATETABLESECTION } from "../../utils/api";
import axios from "../../utils/axios";


export const getTableSections=async(param)=>{
    const res= await axios.post(GETTABLESECTIONS,param);
    return res;
    
}


export const getNotAssignmentTable=async(param)=>{
    const res= await axios.post(GETNOTASSIGNTABLELIST,param);
    return res; 
}


 
export const addTableSection=async(param)=>{
    const res= await axios.post(ADDTABLESECTION,param);
    return res;     
}
export const updateTableSection=async(param)=>{
    const res= await axios.post(UPDATETABLESECTION,param);
    return res;     
}