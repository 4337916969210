import { Box, Button, FormControl, InputLabel, ListItemText, MenuItem, OutlinedInput, Select, Switch, TextField, Typography } from "@mui/material";

import Grid from "@mui/material/Grid";

import React, { useEffect, useState } from "react";

import { } from "../../utils/utils";
// import Youtube from '../assets/img/icon/youtube.png'
import Youtube from '../../assets/img/icon/youtube.png'

import Swal from "sweetalert2";
import { useSelector } from "react-redux";
import { GetBussiness, UpdateBusiness } from "./myBusinessApi";

import AddLocationIcon from '@mui/icons-material/AddLocation';
import { formDataFN, swalAlertPopProps } from "../../utils/commonUtils";
import { validationsSubmits } from "./myBusinessValidationFunctions";
import { FormGroup } from "@mui/material";
import { FormControlLabel } from "@mui/material";
import ApModal from "../../common/AppoinmetModalppBtn";
import MediaModel from "../../common/mediaModel";
import MediaModelApp from "../../common/mediaModelApp";
import MediaCardInput from "../../common/mediaCardInput";
import PersonIcon from "@mui/icons-material/Person";
import { cityNameCll } from "../landingPage/landingPageApi";
import ImageUploader from "../../common/imageUpload";
import { formDataFn } from "../../common/commonUtils/commonUtilFunction";
import DynamicBreadcrumbs from "../../common/Breadcrumb";

export default function MyBusiness() {
  const [MyBusinessData, setMyBussinessData] = useState(null);


  const [hide, setHide] = useState(false)

  const userDetail = useSelector((state) => state.reducer.userDetail);
  const getData = async () => {
    await GetBussiness().then((res) => {
      console.log("es.data.data[0] ", res.data.data[0])
      setMyBussinessData({
        ...res.data.data[0],
        mobileNumber: userDetail?.mobileNumber,
      });
    });
  };
  const [dropDown, setDropDown] = useState({
    buinessChategory: [
      "Salon", "Wellness center", "Unisex Parlour", "Mens Parlour", "Beauty Parlour", "Tatoo Art Studio", "Massage Center"
      , "Mehndi Artist", "Skincare", "Panchkarm", "Makeup & Hairstyle Artist"],
    serviceTo: ["Not set", "Men", "Women", "Unisex"],


    serviceFrom: ["Home service", "In shop", "Both"]
  })
  function optionVal(data){
    let dd="";
    let dataColl=[  "google",
    "website",
    "instagram",
    "facebook",
    "youTube",
    "pinterest",
    "twitter",
    "linkedIn"
  ]
    let obj={...MyBusinessData}
    dataColl.forEach((ele,index)=>{
      if(obj[ele]){
        dd+=obj[ele]+","
      }
    })
    
    

    return dd
  }
  const submit = async () => {
    const pattern = /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/;
    const valid = validationsSubmits(MyBusinessData);
    if (valid.status) {
      let payload=formDataFn({...MyBusinessData,   enterpriseName:MyBusinessData?.enterpriseName})
      console.log("payload ",payload)
      
      await UpdateBusiness(formDataFN(payload))
        .then((res) => {
          if (res?.data?.succeeded) {
            Swal.fire({
              title: "Update Successfully",
              icon: "success",

              ...swalAlertPopProps,
            });
          } else {
            Swal.fire({
              title: res?.data?.message,
              icon: "error",

              ...swalAlertPopProps,
            });
          }

          getData();
        })
        .catch((err) => { });
    } else {
      Swal.fire({
        text: valid.msg,
        icon: "warning",

        ...swalAlertPopProps,
      });
    }
  };
  useEffect(() => {
  
  }, [])
  useEffect(() => {
    if (userDetail?.mobileNumber) {
      getData();
    }
  }, [userDetail]);
  return (
   <>
   <DynamicBreadcrumbs />
    <Box sx={{
          margin: {
            xs: "10px",
            sm: "50px 10px 10px 10px ",
            md: "50px",
          },
        }}>
      {MyBusinessData && (
        <Box
        >
          <Typography
            component="h1"
            variant="h5"
            textAlign="center"
            sx={{
              mb: "1.5rem",
            }}
            color="primary"
          >
            My Bussiness
          </Typography>

          <Grid container spacing={2} alignItems="center" padding={2}>
            <Grid item xs={12}>
              <ImageUploader path={MyBusinessData?.logoPath} callback={(file) => {
                  setMyBussinessData({
                    ...MyBusinessData,
                    File:file,
                  });
                }} /> 
            </Grid>
            <Grid item md={12} xs={12}>
              <MediaCardInput
                title={"Profile Id"}
                url={MyBusiness?.profileId} iIcon={PersonIcon} value={MyBusinessData?.profileId} setValue={() => { }} />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                id="enterpriseName"
                label="Enterprise Name"
                value={MyBusinessData?.enterpriseName}
                onChange={(e) => {
                  setMyBussinessData({
                    ...MyBusinessData,
                    enterpriseName: e.target.value,
                  });
                }}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <FormControl fullWidth margin="normal">
                <InputLabel id="tags-label" className="mulish">
                  Business Category
                </InputLabel>
                <Select
                  labelId="tags-label"
                  id="businessCategory"
                  //   multiple
                  name="businessCategory"
                  value={MyBusinessData?.businessCategory}
                  onChange={(e) => {
                    setMyBussinessData({
                      ...MyBusinessData,
                      businessCategory: e.target.value,
                    });
                  }}
                  input={<OutlinedInput label="Services" />}
                //   renderValue={(selected) => selected.join(", ")}
                // MenuProps={MenuProps}
                >
                  {dropDown?.buinessChategory?.map((name) => (
                    <MenuItem
                      key={name}
                      value={name}
                    >
                      {/* <Checkbox checked={formData.tags.indexOf(name) > -1} /> */}
                      <ListItemText primary={name} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                id="branchName"
                label="branchName"
                value={MyBusinessData?.branchName}
                onChange={(e) => {
                  setMyBussinessData({
                    ...MyBusinessData,
                    branchName: e.target.value,
                  });
                }}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                id="BrifeAddress"
                label="Brife Address"
                value={MyBusinessData?.address}
                onChange={(e) => {
                  setMyBussinessData({
                    ...MyBusinessData,
                    address: e.target.value,
                  });
                }}
              />
            </Grid>

            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                id="state"
                label="state"
                value={MyBusinessData?.state}
                onChange={(e) => {
                  setMyBussinessData({
                    ...MyBusinessData,
                    state: e.target.value,
                  });
                }}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                id="state"
                label="state"
                value={MyBusinessData?.state}
                onChange={(e) => {
                  setMyBussinessData({
                    ...MyBusinessData,
                    state: e.target.value,
                  });
                }}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                id="district"
                label="district"
                value={MyBusinessData?.district}
                onChange={(e) => {
                  setMyBussinessData({
                    ...MyBusinessData,
                    district: e.target.value,
                  });
                }}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                id="city"
                label="city"
                value={MyBusinessData?.city}
                onChange={(e) => {
                  setMyBussinessData({
                    ...MyBusinessData,
                    city: e.target.value,
                  });
                }}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                id="city"
                label="city"
                value={MyBusinessData?.pincode}
                onChange={(e) => {
                  setMyBussinessData({
                    ...MyBusinessData,
                    pincode: e.target.value,
                  });
                }}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                id="city"
                label="area"
                value={MyBusinessData?.area}
                onChange={(e) => {
                  setMyBussinessData({
                    ...MyBusinessData,
                    area: e.target.value,
                  });
                }}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                id="city"
                label="area"
             sx={{mb:2}}
                value={MyBusinessData?.latitude}
                onChange={(e) => {
                  // setMyBussinessData({
                  //   ...MyBusinessData,
                  //   latitude: e.target.value,
                  // });
                }}
                readOnly={true}
              />
              <TextField
                fullWidth
                id="city"
                label="longitude"
                value={MyBusinessData?.longitude}
                onChange={(e) => {
                  // setMyBussinessData({
                  //   ...MyBusinessData,
                  //   longitude: e.target.value,
                  // });
                }}
                readOnly={true}

              />
            </Grid>
            <Grid item md={6} xs={12} 
            sx={{textAlign:'center'}}
            >
              <Button onClick={()=>{
                  cityNameCll((data) => {
                    console.log("location", data)
                    setMyBussinessData((prev) => {
                      let temp = { ...prev };
                      temp.longitude = data?.lon
                      temp.latitude = data?.lat
                      console.log("temp",temp)
                      return {
                        ...temp,
                        mobileNumber: userDetail?.mobileNumber,
                      }
                    }
                    );
                    // asignCityName(data?.city)
                    // setCurrentLocation(data?.city)
                    // dispatch(setNavBarInputFields({location:data?.city}))
                  })
              }} variant="contained"     startIcon={<AddLocationIcon />} >
                Location
              </Button>
            </Grid>
            <Grid item md={6} xs={12}>
              <FormControl fullWidth margin="normal">
                <InputLabel id="tags-label" className="mulish">
                  Service From
                </InputLabel>
                <Select
                  labelId="tags-label"
                  id="businessCategory"
                  //   multiple
                  name="businessCategory"
                  value={MyBusinessData?.serviceFrom}
                  onChange={(e) => {
                    setMyBussinessData({
                      ...MyBusinessData,
                      serviceFrom: e.target.value,
                    });
                  }}
                  input={<OutlinedInput label="Services" />}
                //   renderValue={(selected) => selected.join(", ")}
                // MenuProps={MenuProps}
                >
                  {dropDown?.serviceFrom?.map((name) => (
                    <MenuItem
                      key={name}
                      value={name}
                    >
                      {/* <Checkbox checked={formData.tags.indexOf(name) > -1} /> */}
                      <ListItemText primary={name} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item md={6} xs={12}>
              <FormControl fullWidth margin="normal">
                <InputLabel id="tags-label" className="mulish">
                  Service To
                </InputLabel>
                <Select
                  labelId="tags-label"
                  id="businessCategory"
                  //   multiple
                  name="businessCategory"
                  value={MyBusinessData?.serviceTo}
                  onChange={(e) => {
                    setMyBussinessData({
                      ...MyBusinessData,
                      serviceTo: e.target.value,
                    });
                  }}
                  input={<OutlinedInput label="Services" />}
                //   renderValue={(selected) => selected.join(", ")}
                // MenuProps={MenuProps}
                >
                  {dropDown?.serviceTo?.map((name) => (
                    <MenuItem
                      key={name}
                      value={name}
                    >
                      {/* <Checkbox checked={formData.tags.indexOf(name) > -1} /> */}
                      <ListItemText primary={name} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                id="openingTime"
                label="openingTime  "
                value={MyBusinessData?.openingTime}
                onChange={(e) => {
                  setMyBussinessData({
                    ...MyBusinessData,
                    openingTime: e.target.value,
                  });
                }}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                id="closingTime"
                label="Closing Time"
                value={MyBusinessData?.closingTime}
                onChange={(e) => {
                  setMyBussinessData({
                    ...MyBusinessData,
                    closingTime: e.target.value,
                  });
                }}
              />
            </Grid>

            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                id="mobileno"
                label="Mobile No"
                disabled={true}
                value={MyBusinessData?.mobileNumber}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                id="mobileno"
                label="mobile No"
                value={MyBusinessData?.mobileNumber2}
                onChange={(e) => {
                  setMyBussinessData({
                    ...MyBusinessData,
                    mobileNumber2: e.target.value,
                  });
                }}
              />
            </Grid>

            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                id="mobileno"
                label="Email Id  "
                value={MyBusinessData?.mailID1}
                onChange={(e) => {
                  setMyBussinessData({
                    ...MyBusinessData,
                    mailID1: e.target.value,
                  });
                }}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                id="mobileno"
                label="Email Id  "
                value={MyBusinessData?.mailID2}
                onChange={(e) => {
                  setMyBussinessData({
                    ...MyBusinessData,
                    mailID2: e.target.value,
                  });
                }}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                id="upiid"
                label="UPI ID  "
                value={MyBusinessData?.upiid}
                onChange={(e) => {
                  setMyBussinessData({
                    ...MyBusinessData,
                    upiid: e.target.value,
                  });
                }}
              />
            </Grid>

            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                id="BandACNumber"
                label="Bank A/C Number"
                readOnly
                value={MyBusinessData?.accountNumber}
                onChange={(e) => {
                  setMyBussinessData({
                    ...MyBusinessData,
                    accountNumber: e.target.value,
                  });
                }}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                id="IFSCCODE"
                label="IFSC code of the branch"
                value={MyBusinessData?.ifsc}
                onChange={(e) => {
                  setMyBussinessData({
                    ...MyBusinessData,
                    ifsc: e.target.value,
                  });
                }}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                id="GSTREGISTRATIONNUMBER"
                label="GST registration number"
                // value={language}
                value={MyBusinessData?.gstRegistrationNumber}
                onChange={(e) => {
                  setMyBussinessData({
                    ...MyBusinessData,
                    gstRegistrationNumber: e.target.value,
                  });
                }}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                id="referenceId"
                label="Reference Id"
                value={MyBusinessData?.referenceId}
                onChange={(e) => {
                  setMyBussinessData({
                    ...MyBusinessData,
                    referenceId: e.target.value,
                  });
                }}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <FormGroup>
                <FormControlLabel
                  sx={{
                    justifyContent: "space-between",
                    m: 0,
                    flexDirection: "row-reverse",
                  }}
                  control={
                    <Switch
                      checked={MyBusinessData?.isPrintQRCodeOnBill}
                      onChange={(e) => {
                        // onChange("isActive", e.target.checked);
                        setMyBussinessData({
                          ...MyBusinessData,
                          isPrintQRCodeOnBill: e.target.checked,
                        });
                      }}
                    />
                  }
                  label="Do you want to print QR code on bill"
                />
              </FormGroup>
            </Grid>
            <Grid item md={6} xs={12}>
              <FormControl fullWidth margin="normal" onClick={() => {






                setHide(true)
              }}>
                <InputLabel id="tags-label" className="mulish">
                  Media Link
                </InputLabel>
                <Select
                  labelId="tags-label"
                  id="businessCategory"
                  readOnly={true}
                  //   multiple
                  name="businessCategory"
                  value={optionVal(MyBusinessData)}
                  onChange={(e) => {
                    setMyBussinessData({
                      ...MyBusinessData,
                      serviceTo: e.target.value,
                    });
                  }}
                  input={<OutlinedInput label="Services" />}
                //   renderValue={(selected) => selected.join(", ")}
                // MenuProps={MenuProps}
                >

<MenuItem value={optionVal(MyBusinessData)}>
                <ListItemText primary={optionVal(MyBusinessData)} />
              </MenuItem>
                </Select>
              </FormControl>
            </Grid>

          </Grid>

          <Button
            type="submit"
            fullWidth
            variant="contained"
            onClick={submit}
            sx={{ mt: 3, mb: 2 }}
          >
            Save
          </Button>
        </Box>
      )}
      {/* <ApModal open={} setOpen={} /> */}
      {MyBusinessData != null && (
        <MediaModelApp open={hide} setOpen={setHide} modelData={{
          google: "",
          website: "",
          instagram: "",
          facebook: "",
          youTube: "",
          pinterest: "",
          twitter: "",
          linkedIn: "",
          ...MyBusinessData

        }} setModalData={setMyBussinessData} />

      )}
    </Box></>
  );
}
