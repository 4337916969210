import { Box, Grid, Pagination, Stack, Typography } from "@mui/material";
import StockCard from "./stockCard";
import { getStockDetail } from "./stockApi";
import FileDownloadIcon from "@mui/icons-material/FileDownload";

import { useEffect } from "react";
import { useState } from "react";
import { getData } from "./stockCustomApiHandler";
import TableComponent from "./table";
import DynamicBreadcrumbs from "../../common/Breadcrumb";

const Stocks = ({gridSize}) => {
  const [stocks, setStocks] = useState();
  const stockPayload = {
    page: 1,
    pageSize: 100,
  };

  const [pagination, setPagination] = useState({
    page: 1,
    total: 1,
    records: 0,
  });

  const handleChange = (event, value) => {
    getData({ ...stockPayload, page: value });
    setPagination({
      ...pagination,
      page: value,
    });
  };

  useEffect(() => {
    getData(stockPayload, getStockDetail, setStocks, setPagination, pagination);
  }, []);
  return (
    <Grid
      container
      sx={{
        p: "20px",
        display: "flex",
      }}
      spacing={2}
    >
              <DynamicBreadcrumbs/>




              <Grid xs={12} sx={{display:'flex',
            justifyContent:'center'}}>
                 <Typography
            color="primary"
            sx={{ display: "flex", alignItems: "center" }}
          >
            <strong>Total Items: &nbsp;</strong>
            <strong> {pagination.records}&nbsp;</strong>
          </Typography>
              <Pagination
            count={pagination.total}
            page={pagination.page}
            onChange={handleChange}
          />
              </Grid>
              
      <Grid xs={12} sx={{textAlign:'right'}}>
      {gridSize && (
            <FileDownloadIcon
            sx={{
              ml: "10px",
              mr: "0px",
              height: "1.4375em",
              cursor: "pointer"
              // m: "16.5px 14px",
            }}/>
        )}
      </Grid>
      <Grid item  container spacing={2}
        sx={{ display: "flex", flexDirection: "column", height: "80vh" }}

      >
           
        <TableComponent stocks={stocks}/>
        
       
      </Grid>

      {/* <Grid container sx={{ pt: "10px" }}>
        <Grid item xs={4}>
          <Typography
            color="primary"
            sx={{ display: "flex", alignItems: "center" }}
          >
            <strong>Total Items: &nbsp;</strong>
            <strong> {pagination.records}&nbsp;</strong>
          </Typography>
        </Grid>
        <Grid
          item
          xs={4}
          sx={{
            // bgcolor: "red",
            display: "flex",
            justifyContent: "center",
          }}
        >
        
        </Grid>
        <Grid item xs={4}></Grid>
      </Grid> */}

    </Grid>
  );
};

export default Stocks;
