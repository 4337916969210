import {
  Card,
  CardContent,
  Grid,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import React from "react";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
export default function CounterSalesItemCard({ item }) {
  return (
    <Card
      sx={{
        width: "100%",
        m: "0px",
        mt: "10px",
        p: "0px",
        ":hover": {
          bgcolor: "#2892ba17",
        },
        // "& .MuiCardContent-root": { p: "0.5rem" }
      }}
    >
      <CardContent sx={{ padding: "0rem 0.7rem !important" }}>
        <Grid container>
          <Grid item xs={4}>
            <Typography
              color='primary'
              sx={{
                fontSize: "15px",
                overflow: "Hidden",
                pt: "2px",
                textOverflow: "ellipsis",
                fontWeight: 600,
              }}
            >
              {item?.itemDescription}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography
              color='primary'
              sx={{
                fontSize: "15px",
                overflow: "Hidden",

                textOverflow: "ellipsis",
                textAlign: "center",

                fontWeight: 600,
              }}
            >
              {item?.quantity?.toFixed(1)}
            </Typography>
          </Grid>

          <Grid
            item
            xs={4}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CurrencyRupeeIcon color='primary' fontSize='10px' />
            <Typography
              color='primary'
              sx={{
                textOverflow: "ellipsis",
                fontWeight: 600,
              }}
            >
              {item?.amount?.toFixed(2)}
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
