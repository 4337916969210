import { Box, Modal } from "@mui/material"

export const TableD = ({ tableData, open, setOpen }) => {









    console.log("tableData ", tableData)
    return (
        <Modal
            open={open}
            onClose={() => {
                setOpen(false)
            }}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box
                sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    
                    transform: "translate(-50%, -50%)",
                    width: 400,
                    bgcolor: "background.paper",
                    borderRadius: "10px",
                    boxShadow: 24,
                    p: 4,
                    height: '300px',
                    overflowY: 'scroll'

                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        width: "100%",
                        flexDirection: "column",
                        marginTop: "10px",
                    }}
                >
                    <Box
                        sx={{
                            display: "flex",
                            borderBottom: "1px solid",

                            borderColor: "divider",
                            backgroundColor: "#f5f5f5",
                            padding: "8px 16px",
                            fontWeight: "600",
                        }}
                    >
                        <Box sx={{ flex: 5.0  }}>name</Box>
                        <Box sx={{ flex:2.0 }}> price</Box>

                        <Box sx={{ flex: 1.5, textAlign: "left" }}>qty</Box>
                        <Box sx={{ flex: 1 }}> gt</Box>
                    </Box>
                </Box>


                {



                    tableData?.arr?.map((ele, index) => {
                        return (
                            <Box
                                sx={{
                                    display: "flex",
                                    width: "100%",
                                    flexDirection: "column",
                                    marginTop: "10px",
                                }}
                            >
                                <Box
                                    sx={{
                                        display: "flex",
                                        borderBottom: "1px solid",

                                        borderColor: "divider",
                                        backgroundColor: "#f5f5f5",
                                        padding: "8px 16px",
                                        fontWeight: "600",
                                    }}
                                >
                                    <Box sx={{ flex: 5.0 }}>{ele?.itemDescription}</Box>
                                    <Box sx={{ flex:2.0 }}> {ele?.currentPrice}</Box>

                                    <Box sx={{ flex: 1.5, textAlign: "left" }}>{ele?.suppliedQuantity}</Box>
                                    <Box sx={{ flex: 1 }}> {ele?.gst}

                                    </Box>
                                </Box>
                            </Box>

                        )
                    })
                }

            </Box>






        </Modal>

    )
}