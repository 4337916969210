import { CustomeDetail } from "../../customerDetail/customerDetail"

     export const CustomerDetailReport=()=>{

    return (
        <CustomeDetail gridSize={{
            sm:6,
            xs:12
          }} />
    )
}
