import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { GetAllOffersByBiller } from "./getAllOffersByBillerApi";
import {
  Card,
  CardContent,
  CardHeader,
  CardMedia,
  Container,
  Divider,
  Grid,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
export const GetAllOfferByBiller = () => {
  const detail = useSelector((state) => state.reducer.detail);
  console.log(detail?.billerId, "detailsssssssss");
  const [data, setData] = useState([]);

  const formatDate = (dateString) => {
    const date = new Date(dateString);

    const day = String(date.getDate()).padStart(2, "0"); // Get day and add leading zero if needed
    const month = date.toLocaleString("default", { month: "short" }); // Get short month name (e.g., Sep)
    const year = date.getFullYear(); // Get full year (e.g., 2024)

    return `${day}-${month}-${year}`;
  };

  useEffect(() => {
    const fetchAllOffers = async () => {
      try {
        let res = await GetAllOffersByBiller({ billerId: detail?.billerId });
        console.log(res?.data, "resss");
        setData(res?.data.data.list);
      } catch (error) {
        console.error("Failed to fetch states:", error);
      }
    };

    fetchAllOffers();
  }, []);

  if (data.length === 0) {
    return  <Typography variant="h5" sx={{p:3}}>No data available </Typography>;
  }
  return (
    <>
      <Container sx={{ mt: 2 }}>
        <Grid container spacing={2}>
          {data.map((item) => (
            <Grid key={item.id} item lg={4} sm={6} xs={12}>
              <Card>
                <CardHeader
                  title={item.offerInformation}
                  subheader={`Status : ${item.status}`}
                />
                <CardMedia
                  sx={{ height: 240 }}
                  image={item.imagePath}
                  title="green iguana"
                />
                <Divider sx={{ pt: 1 }} />
                <CardContent sx={{ ":last-child": { pb: 2 } }}>
                  <Stack direction={"row"} justifyContent={"space-between"}>
                    <Typography gutterBottom variant="body2">
                      <Typography
                        variant="body2"
                        component="span"
                        sx={{ color: "primary.main", fontWeight: "600" }}
                      >
                        Offer Start :
                      </Typography>{" "}
                      {formatDate(item.fromDate)}
                    </Typography>
                    <Typography gutterBottom variant="body2">
                      <Typography
                        variant="body2"
                        component="span"
                        sx={{ color: "primary.main", fontWeight: "600" }}
                      >
                        Offer End :
                      </Typography>{" "}
                      {formatDate(item.toDate)}
                    </Typography>
                  </Stack>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
    </>
  );
};
