import { GETCUSTOMERADVANCE, GETCUSTOMERAPPOINMENTS } from "../../../utils/api";
import axios from "../../../utils/axios";

export const getAllAppoint=async(payload,callback)=>{
    await axios
    .post(GETCUSTOMERADVANCE, payload)
    .then((res) => {
      console.log("res search ", res);
      callback(res.data.data) 
    })
    .catch((err) => {
      console.log(err);
    });
}