import {
  Box,
  Card,
  CardContent,
  Grid,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import React from "react";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
export default function ExpenseModalCard({
  index,
  item,
  ExpenseItemssArr,
  setExpenseItemssArr,
}) {
  console.log("item ", item);
  return (
    <Card
      sx={{
        mt: 1,
        // mr: 2,
        cursor: "pointer",
        ":hover": {
          bgcolor: "#2892ba17",
        },
      }}
      onClick={() => {}}
    >
      <CardContent>
        <Grid container>
          <Grid xs={8} sx={{ display: "flex" }}>
            <Typography
              color="primary"
              sx={{
                overflow: "Hidden",
                textOverflow: "ellipsis",
                display: "-webkit-box",
                "-webkit-line-clamp": "1",
                "-webkit-box-orient": "vertical",
                // fontWeight: 600,
                // color: "blac",
              }}
            >
              {item?.description}
            </Typography>
          </Grid>
          <Grid xs={3} sx={{ display: "flex", alignItems: "center" }}>
            <CurrencyRupeeIcon fontSize="10px" />

            <Typography
              sx={{
                overflow: "Hidden",
                textOverflow: "ellipsis",
                display: "-webkit-box",
                "-webkit-line-clamp": "1",
                "-webkit-box-orient": "vertical",
                // fontWeight: 600,
              }}
            >
              {item?.amount?.toFixed(2)}
            </Typography>
          </Grid>
          <Grid xs={1} sx={{ display: "flex", alignItems: "center" }}>
            <DeleteIcon
              onClick={() => {
                let arr = [...ExpenseItemssArr];

                // if (arr.length > 1) {
                arr.splice(index, 1);
                // }
                setExpenseItemssArr(arr);
              }}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
