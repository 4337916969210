import React from 'react';
import { Box, IconButton, TextField, Typography } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ShareIcon from '@mui/icons-material/Share';

const MediaCardInput = ({ iIcon, url, Icon,title,value,setValue }) => {
  const currentLink = url;

  const handleCopy = () => {
    navigator.clipboard.writeText(currentLink);
    alert('Link copied to clipboard!');
  };

  const handleShare = () => {
    if (navigator.share) {
      navigator.share({
        title: 'Share Link',
        url: currentLink,
      }).catch((error) => console.error('Error sharing:', error));
    } else {
      alert('Share not supported on this browser. Link copied instead.');
      handleCopy();
    }
  };

  return (
    <Box sx={{
      padding: "10px",
      backgroundColor: "#fff",
      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
      borderRadius: "8px",
      maxWidth: "400px",
      margin: "10px auto",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between"
    }}>
      <IconButton component="a" href={currentLink} target="_blank" aria-label="go to link">
       {Icon && <Box
          height={"2.2rem"}
          component={"img"}
          src={Icon}
          alt="playstorelogo"
        />}
         
        <iIcon   sx={{
                              color: (theme) => theme.palette.primary.main,
                              cursor: "pointer",
                            }}/>

       
      </IconButton>
      <Box component="a" href={currentLink} target="_blank" aria-label="go to link" sx={{ flexGrow: 1, marginLeft: "10px", overflow: "hidden", textOverflow: "ellipsis", textDecoration: 'none', fontWeight: '500', fontSize: '15px' }}>
        {currentLink}
      </Box>
      <Box sx={{ width: '100%' }}>
        <Typography color="grey" sx={{
          margin: "0px !important",
          padding: "0px !important"

        }}>{title}</Typography>
     {              <TextField
          fullWidth

          id="enterpriseName"
          label={iIcon ?"":"add link here "}
          variant="standard"
          sx={{
            margin: "0px !important",
            padding: "0px !important"

          }}
          value={value}
          // value={MyBusinessData?.enterpriseName}
          onChange={(e) => {
            setValue(e.target.value)
            // setMyBussinessData({
            //   ...MyBusinessData,
            //   enterpriseName: e.tsarget.value,
            // });
          }}
        />}
      </Box>
      <IconButton onClick={handleCopy} aria-label="copy link">
        <ContentCopyIcon />
      </IconButton>
      <IconButton onClick={handleShare} aria-label="share link">
        <ShareIcon />
      </IconButton>

    </Box>
  );
};

export default MediaCardInput;
